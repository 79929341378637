import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import theme from '../../theme';

const StyledServiceItem = styled.div`
  background-color: ${theme.colours.white};
  margin: ${theme.spacing(0.5)} 0;
  padding: ${theme.spacing(3)} ${theme.spacing(2)};
  ${theme.media.md`
  margin: ${theme.spacing(1)} 0;
  padding: ${theme.spacing(3)};
  `};
`;

const StyledContentLoader = styled(props => <ContentLoader {...props} />)`
  width: 100%;
`;

const StyledRect = styled.rect`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const ServiceListItemLoader = () => (
  <StyledServiceItem>
    <StyledContentLoader
      height={64}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      uniquekey="service-list-item-loader"
    >
      <StyledRect x={0} y={0} rx={4} ry={4} width="30%" height="24px" />
      <StyledRect x={0} y={36} rx={4} ry={4} width="25%" height="12px" />
      <StyledRect x={0} y={52} rx={4} ry={4} width="75%" height="12px" />
    </StyledContentLoader>
  </StyledServiceItem>
);

export default ServiceListItemLoader;
