import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Row, Column } from '@lux/components';
import { noop } from '@lux/helpers';
import ShowMore from 'react-show-more';
import { api } from '@detox/actions';

import { H4, SubtitleSecondary, SubtitlePrimary } from '../Base';
import Button from '../Button';
import Notification from '../Notification';
import Ribbon from '../Ribbon';
import RoundedPlanCard from '../RoundedPlanCard';

import formatPrice from '../../helpers/formatPrice';
import isAddonGroup from '../../helpers/is-addon-group';

import addonErrors from '../../config/addon-errors.js';

const Ruler = styled.div`
  border-bottom: 1px solid ${p => p.theme.colours.grey_400};

  &:last-child {
    border: none;
  }
`;

const StyledRow = styled(props => <Row {...props} />)`
  padding: ${p => p.theme.spacing(3)} 0;

  &:nth-last-child(2) {
    padding-bottom: 0;
  }
  ${p => p.theme.media.md`
    padding: ${p.theme.spacing(4)} 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  `};

  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
  }
`;

const StyledPromoDescription = styled.div`
  ${p => p.theme.media.sm`
    margin: 25px 0px 0px 0px;
  `};
  ${p => p.theme.media.lg`
    margin: 0px 35px 0px 0px;
  `};
`;

const StyledPrice = styled.div`
  ${p => p.theme.media.sm`
    float: left;
  `};
  ${p => p.theme.media.lg`
    margin: 12px 15px 0px 0px;
    float: right;
  `};
`;

const StyledContractTerm = styled(SubtitleSecondary)`
  color: ${p => p.theme.colours.grey_600};
`;

const StyledButton = styled(Button)`
  float: right;
  min-width: 100px;
  width: 100px;
  height: 32px;

  ${p => p.theme.media.md`
    height: 48px;
    margin-left: ${p.theme.spacing(5)};
  `};
`;

const ExpandText = styled.span`
  font-family: inherit;
  font-size: inherit;
  color: ${p => p.theme.colours.link};

  &:hover {
    color: ${p => p.theme.colours.linkVariant};
  }
`;

const ShowMoreWrapper = styled.div`
  line-height: 1.5;
`;

const StyledProductDescription = styled(SubtitleSecondary)`
  display: inline;
`;

const NotificationWrapper = styled.div`
  margin-top: ${p => p.theme.spacing(2)};

  ${p => p.theme.media.md`
    margin-top: ${p.theme.spacing(2)};
  `};

  ${p => p.theme.media.lg`
    margin-top: 0;
  `};
`;

const StyledAddonInfo = styled.div`
  display: flex;
`;

const StyledAddonIcon = styled(Img)`
  min-height: 72px;
  max-height: 72px;
  min-width: 72px;
  max-width: 72px;
  border-radius: ${p => p.theme.spacing(2)};
  margin-right: ${p => p.theme.spacing(2)};
  border: ${p => `1px solid ${p.theme.colours.grey_400}`};

  ${p => p.theme.media.md`
    min-height: 80px;
    max-height: 80px;
    min-width: 80px;
    max-width: 80px;
  `};
`;

const StyledAddonDescription = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const AddonItem = ({
  addon,
  // addonInContract,
  isGroupItem,
  // orderType,
  onAdd,
  onRemove,
  onUpgrade
}) => {
  const { helpersAddons } = api.mcss;
  const { disabled, addonInContract, removedAddon } = addon;
  const ACTION = {
    ADD: {
      text: 'Add',
      onClick: () => onAdd(addon)
    },
    REMOVE: {
      text: 'Remove',
      onClick: () => onRemove(addon)
    },
    UPGRADE: {
      text: 'Upgrade',
      onClick: () => {
        onUpgrade({ addon, removedAddon: addonInContract || removedAddon });
      }
    }
  };
  const canAdd = helpersAddons.canAddBillingOffer(addon);
  const canUpgrade = addon.allowedActions.find(
    action => action.type === 'UP' && action.allowed
  );
  let action = canUpgrade ? 'UPGRADE' : canAdd ? 'ADD' : 'REMOVE';

  // TODO: Should refactor
  // Move MobileSwop to updateAddon in addon-helper
  if (isGroupItem) {
    // Grouped item has a different layout than standalone ones
    const contractText =
      !addon.contract || addon.contract === '0'
        ? 'No Contract'
        : `${addon.contract}-mth contract`;

    const productTitleBlacklist = [
      'SPOTIFY',
      'APPLEMUSIC',
      'PRODUCTIVITYDATAPASS'
    ];
    const showProductTitle = !productTitleBlacklist.some(key =>
      isAddonGroup(addon, key)
    );

    return (
      <Fragment>
        <StyledRow disabled={Boolean(disabled) && !canUpgrade}>
          <Column sm={12} lg={12}>
            <Row>
              <Column sm={12} md={9} lg={6}>
                {showProductTitle && (
                  <SubtitlePrimary data-testid="addonTitle">
                    {addon.productTitle}
                  </SubtitlePrimary>
                )}
                <StyledPromoDescription>
                  {addon.promoDescription && (
                    <Ribbon>{addon.promoDescription}</Ribbon>
                  )}
                </StyledPromoDescription>

                {!addon.promoDescription &&
                  addon.promotionText &&
                  addon.atomic && <Ribbon>{addon.promotionText}</Ribbon>}

                <StyledContractTerm as="span">
                  {contractText}
                </StyledContractTerm>
              </Column>
              <Column sm={6} md={9} lg={3}>
                <StyledPrice>
                  <SubtitleSecondary as="div">
                    {addon.onetimePrice &&
                    addon.onetimePrice.trim() &&
                    addon.onetimePrice !== '0' &&
                    (!addon.price || addon.price === '0' || !addon.price.trim())
                      ? `${formatPrice(addon.onetimePrice)}`
                      : `${formatPrice(addon.price)}/mth`}
                  </SubtitleSecondary>
                </StyledPrice>
              </Column>
              <Column sm={6} md={3} lg={3}>
                <StyledButton
                  data-testid={`add-addon-${addon.productTitle}`}
                  disabled={disabled && !canUpgrade}
                  tertiary
                  onClick={ACTION[action].onClick}
                >
                  {ACTION[action].text}
                </StyledButton>
              </Column>
            </Row>
          </Column>
        </StyledRow>
        <Ruler />
      </Fragment>
    );
  }

  const selectedAddonError = addonErrors[addon.productSpecContainmentID];
  const addonErrorMessage = selectedAddonError && selectedAddonError.message;

  return (
    <RoundedPlanCard>
      <Row>
        <Column sm={12} lg={5}>
          <StyledAddonInfo>
            {addon.iconImage && (
              <StyledAddonIcon
                image={addon.iconImage.childImageSharp.gatsbyImageData}
                alt={addon.productTitle}
              />
            )}
            <StyledAddonDescription>
              <H4>{addon.productTitle}</H4>
              <ShowMoreWrapper>
                <ShowMore
                  lines={2}
                  more={<ExpandText>more</ExpandText>}
                  less={<ExpandText>less</ExpandText>}
                >
                  <StyledProductDescription>
                    {addon.productDescription}
                  </StyledProductDescription>
                </ShowMore>
              </ShowMoreWrapper>
            </StyledAddonDescription>
          </StyledAddonInfo>
        </Column>
        <Column sm={12} lg={6} lgOffset={1}>
          {disabled && (
            <NotificationWrapper>
              <Notification state="info">{addonErrorMessage}</Notification>
            </NotificationWrapper>
          )}
          <Row>
            <Column sm={12} md={9} lg={6}>
              <StyledPromoDescription>
                {addon.promoDescription && (
                  <Ribbon>{addon.promoDescription}</Ribbon>
                )}
              </StyledPromoDescription>

              {!addon.promoDescription &&
                addon.promotionText &&
                addon.atomic && <Ribbon>{addon.promotionText}</Ribbon>}

              {addon.contract && addon.contract !== '0' && (
                <StyledContractTerm as="span">
                  {addon.contract}-mth contract
                </StyledContractTerm>
              )}
            </Column>
            <Column sm={6} md={9} lg={3}>
              <StyledPrice>
                <SubtitleSecondary as="div">
                  {addon.price !== '0'
                    ? `${formatPrice(addon.price)}/mth`
                    : `${formatPrice(addon.onetimePrice)}`}
                </SubtitleSecondary>
              </StyledPrice>
            </Column>
            <Column sm={6} md={3} lg={3}>
              <StyledButton
                disabled={disabled}
                data-testid={`add-addon-${addon.productTitle}`}
                tertiary
                onClick={ACTION[action].onClick}
              >
                {ACTION[action].text}
              </StyledButton>
            </Column>
          </Row>
        </Column>
      </Row>
    </RoundedPlanCard>
  );
};

AddonItem.defaultProps = {
  onAdd: noop,
  onRemove: noop,
  onUpgrade: noop
};

AddonItem.propTypes = {
  /**Item might be part of a group */
  isGroupItem: PropTypes.bool,
  addon: PropTypes.object,
  addonInContract: PropTypes.object,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onUpgrade: PropTypes.func
};

export default AddonItem;
