/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ReactElement,
  useEffect,
  useReducer,
  useCallback,
  useRef,
  ReactNode,
  useState
} from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import LoadingOverlayComponent from '../../LoadingOverlay';
import {
  fulFillmentDocActions,
  fulFillmentActions,
  checkoutActions
} from '@detox/actions';
import { Form } from '@wec-core/form-engine';
import useTranslate from '../../../hooks/useTranslation';
import useStockCheck, { STOCK_CHECK_TYPE } from '../../../hooks/useStockCheck';
import {
  genderListData,
  getSalutationListData,
  getFormDataMapping,
  getTitleAndInfoGroup,
  getNRIC,
  getNRICGroup,
  getContactGroup,
  getPostalCode,
  getDocumentTitleGroup,
  getDocumentsUploadGroup,
  getStaffPassUploadGroup,
  getProofUploadTitleGroup,
  getProofUploadGroup,
  getPersSectionDivider,
  getBillingGroup,
  getSubmitGroup,
  getNewNewIniValues,
  getNewNewFlowNonPerIniValues,
  getResetKycValues,
  getValidationSchema,
  documentTypeValidation,
  documentSizeValidation,
  FORM_MAPPING_ACTIONS,
  getKycUploadGroup,
  getKycUploadTitleGroup,
  getFormConfigForNewNewFlow,
  checkNricSilver,
  fileUploaded,
  uploadedFileDeleted,
  createCustomer,
  updateCustomer,
  floorValidation,
  unitValidation,
  getFloorAndUnitField,
  handleOnFloorNumberChange
} from '../helper';
import {
  Address,
  AddressStatus,
  TObject,
  TFile,
  BillingPrefType,
  ErrorScenarioType,
  UploadedDocument,
  SelectionObject,
  FormConfig
} from '../../../types/registrationCheckout';
import {
  checkoutFlowReducer as newNewFlowReducer,
  checkoutInitialState as newNewFlowInitialState
} from '../checkoutFlowState';
import { useDocumentUpload } from '../hooks/useDocumentUpload';
import { usePostalCodeHelper } from '../hooks/usePostalCodeHelper';
import { navigation } from '../../../middlewares/navigation-constants';
import isSimOnlyPlan from '../../../helpers/is-sim-only-plan';
import { getCartError, getCartErrorDataModel } from '../../../helpers/cart';
import { KeyValue } from '../../../types/common.types';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import ORDER_CONSTANT from '../../../constants/order';
import CHECKOUT_CONSTANT from '../../../constants/checkout';
import MyInfoEKYCModal from '../../MyInfoEKYCModal/MyInfoEKYCModal';
import { isInCompleteMyInfoFilled } from '../myinfoHelper';

interface TProps {
  enableReinitialize?: boolean;
  stackSpacing: number;
  isCisFlow: boolean;
  prefilledData?: KeyValue;
  isCompleteMyInfoData?: boolean;
  idCardLinkClicked?: () => void;
  proofLinkClicked?: () => void;
  kycLinkClicked?: () => void;
  onValuesChange?: (values: TObject) => void;
  onFormSubmit?: (values: TObject, navigateTo: string) => void;
  billingPrefData: BillingPrefType[];
  errorScenariosData: ErrorScenarioType[];
  onErrorScenarioFound?: (values: TObject) => void;
  handleCheckoutHistoryState?: (needPushState?: boolean) => void;
  goToOrderSummary: () => void;
  onGettingFormConfigs: (formConfigs: FormConfig) => void;
}

const NewNewFlow: React.FC<TProps> = ({
  isCisFlow,
  idCardLinkClicked,
  proofLinkClicked,
  kycLinkClicked,
  onValuesChange,
  onFormSubmit,
  billingPrefData,
  errorScenariosData,
  onErrorScenarioFound,
  handleCheckoutHistoryState,
  goToOrderSummary,
  onGettingFormConfigs,
  prefilledData,
  isCompleteMyInfoData = false,
  ...props
}): ReactElement => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const {
    uploadDocument,
    deleteUploadedDocument,
    retrieveDocumentContent
  } = fulFillmentDocActions;

  const {
    addressInfo,
    verificationData,
    checkoutFormData,
    checkoutFormDocumentData,
    productOrderReferenceNumber,
    documentUpload,
    downloadedDocData,
    device,
    order,
    selectedPlan,
    selectedProduct,
    isLoading,
    checkout,
    allocateOrReserve,
    cart
  } = useSelector((state: TObject) => ({
    addressInfo: state.fulfillment?.addressInfo,
    verificationData: state.checkout?.verificationData,
    checkoutFormData: state.checkout?.checkoutFormData,
    checkoutFormDocumentData: state.checkout?.checkoutFormDocumentData,
    documentData: state.checkout?.documentData,
    productOrderReferenceNumber:
      state.order?.productOrder?.productOrderReferenceNumber,
    order: state.order,
    documentUpload: state.checkout?.documentUpload as UploadedDocument[],
    downloadedDocData: state.documents.documentData as UploadedDocument[],
    device: state.cart?.cartOrder?.device,
    selectedProduct: state.product?.selectedProduct,
    selectedPlan: state.plan?.selectedPlan,
    isLoading: state.checkout?.isLoading,
    checkout: state.checkout,
    allocateOrReserve: state.fulfilment?.allocateOrReserve,
    cart: state.cart
  }));
  const { productOrder, addedToContact } = order;
  const [formState, formDispatch] = useReducer(
    newNewFlowReducer,
    newNewFlowInitialState
  );

  const [myInfoInComplete, setMyInfoInComplete] = useState(false);

  const {
    setPostalCodeGroup,
    postalSearchValidation,
    handlePostalSearchEvent,
    togglePostalAddressDetail
  } = usePostalCodeHelper(t, formDispatch, dispatch);

  const { fetchUploadedDocuments } = useDocumentUpload({
    dispatch,
    documentUpload,
    productOrderReferenceNumber,
    retrieveDocumentContent,
    checkoutFormData,
    formDispatch,
    checkoutFormDocumentData,
    downloadedDocData
  });

  const preNricFront = checkoutFormDocumentData['nricFrontUpload'];
  const preNricBack = checkoutFormDocumentData['nricBackUpload'];
  const preStaff = checkoutFormDocumentData['staffPassUpload'];
  const preProof = checkoutFormDocumentData['proofUpload'];
  const preKyc = checkoutFormDocumentData['kycUpload'];
  const genderList = genderListData(t);
  const salutationList = getSalutationListData(t);
  const nric = verificationData?.passTypeText
    ? null
    : getNRIC(t, verificationData);
  const titleAndInfoGroup = getTitleAndInfoGroup(t, nric);
  const contactGroup = getContactGroup(t);
  const postalCode = getPostalCode(t);
  const documentsTitleGroup = getDocumentTitleGroup(t);
  // file upload groups
  const documentsUploadGroup = getDocumentsUploadGroup(
    t,
    !!preNricFront,
    !!preNricBack
  );
  const staffPassUploadGroup = getStaffPassUploadGroup(t, !!preStaff);
  const proofUploadGroup = getProofUploadGroup(t, !!preProof);

  const proofUploadTitleGroup = getProofUploadTitleGroup(t);
  const kycUploadGroup = getKycUploadGroup(t, !!preKyc);
  const kycUploadTitleGroup = getKycUploadTitleGroup(t);
  const persSectionDivider = getPersSectionDivider();
  const billingPrefGroup = getBillingGroup(t, billingPrefData);
  const nricGroup = getNRICGroup(t, verificationData);
  const formData = {
    genderList: genderList,
    salutationList: salutationList
  };
  const isNricSilverRef = useRef<boolean>(false);
  const hasDocumentErrors = useRef<TObject>({});
  const enableFormReinitialize = useRef<boolean>(true);
  const previousPostal = useRef<KeyValue>(addressInfo?.address?.postcode);
  const hasAccessories = cart?.cartOrder?.accessories?.length > 0;
  const simType = cart?.order?.newlyAddedSimDetails?.simDetails?.simType;
  const { renderError } = useErrorHandler({
    states: [checkout]
  });

  const getFlowConfigs = (isNricSilver = false) => {
    return getFormConfigForNewNewFlow({
      device: device,
      isCis: isCisFlow,
      isNric: nric,
      isNricSilver,
      hasAccessories,
      simType
    });
  };

  const getNonPersonalGroup = (isNricSilver = false) => {
    const flowConfigs = getFlowConfigs(isNricSilver);

    const buttonText = flowConfigs.byPassFulfilment
      ? t('CO_NN_PROCEED_ORDER_SUMMARY_BTN')
      : t('CO_NN_PROCEED_BTN');
    const submitGroup = getSubmitGroup(t, true, true, buttonText);

    return {
      documentsTitle: documentsTitleGroup,
      documents: documentsUploadGroup,
      staffPass: flowConfigs.showStaffUploadDocument
        ? staffPassUploadGroup
        : null,
      proofTitle: flowConfigs.showAddressProofUploadDoc
        ? proofUploadTitleGroup
        : null,
      proof: flowConfigs.showAddressProofUploadDoc ? proofUploadGroup : null,
      kycTitle: flowConfigs.showKYCUploadDocument ? kycUploadTitleGroup : null,
      kyc: flowConfigs.showKYCUploadDocument ? kycUploadGroup : null,
      persSectionDivider: flowConfigs.showBillingPref
        ? persSectionDivider
        : null,
      billingPref: flowConfigs.showBillingPref ? billingPrefGroup : null,
      submit: submitGroup
    };
  };

  // initial default values for the form
  const initialValues = {
    ...getNewNewIniValues(verificationData?.mobileNumber),
    ...getNewNewFlowNonPerIniValues(isCisFlow)
  };

  // initial config for the form
  const formFieldsData = {
    title: titleAndInfoGroup,
    nric: nric,
    nricGroup: nricGroup,
    contact: contactGroup,
    postal: postalCode,
    postalAddress: null,
    documentsTitle: null,
    documents: null,
    staffPass: null,
    proofTitle: null,
    proof: null,
    kycTitle: null,
    kyc: null,
    persSectionDivider: null,
    billingPref: null,
    submit: null
  };

  const handlePostalSearch = (postalCode: string) => {
    handlePostalSearchEvent(postalCode, addressInfo);
  };

  const setOverAllFormState = useCallback((): void => {
    const payload = {
      formInputsMapping: { ...formFieldsData },
      formValues: checkoutFormData
        ? { ...initialValues, ...prefilledData, ...checkoutFormData }
        : { ...initialValues, ...prefilledData }
    };

    formDispatch({
      type: FORM_MAPPING_ACTIONS.SET_OVERALL_FORM_STATE,
      payload
    });
  }, []);

  const additionalAfterPostal = getNonPersonalGroup();

  const setPostalCodeGroupData = (
    address: Address,
    status: AddressStatus
  ): void => {
    formDispatch({
      type: FORM_MAPPING_ACTIONS.SET_VALUES_CHANGE_ON_DEMAND,
      payload: []
    });
    setPostalCodeGroup(
      address,
      status,
      checkoutFormData,
      initialValues,
      additionalAfterPostal,
      enableFormReinitialize,
      getResetKycValues()
    );

    if (
      previousPostal.current &&
      previousPostal.current !== addressInfo?.address?.postcode
    ) {
      previousPostal.current = addressInfo?.address?.postcode;
      formDispatch({
        type: FORM_MAPPING_ACTIONS.SET_VALUES_CHANGE_ON_DEMAND,
        payload: getFloorAndUnitField()
      });
    }
  };

  const postalCodeValidation = (code = '') => {
    const preValidation = postalSearchValidation(
      code,
      addressInfo,
      enableFormReinitialize
    );

    return preValidation;
  };

  const addressFloorValidation = (floor: string) => {
    const floorValue = floor.length === 1 ? '0' + floor : floor;
    return floorValidation(floorValue, addressInfo?.validFloors);
  };

  const addressUnitValidation = (unit: string) => {
    const floorNumber = checkoutFormData['floorNumber'];
    const floorValue =
      floorNumber.length === 1 ? '0' + floorNumber : floorNumber;
    const unitValue = unit.length === 1 ? '0' + unit : unit;
    return unitValidation(unitValue, addressInfo?.validFloors, floorValue);
  };

  const uploadFile = (fieldName, fieldsGroupName, docGroup, uploadedFile) => {
    return fileUploaded({
      uploadedFile,
      fieldName,
      fieldsGroupName,
      docGroup,
      hasDocumentErrors,
      productOrderReferenceNumber,
      uploadDocument,
      dispatch,
      formDispatch,
      t
    });
  };

  const deleteFile = (fileName, fieldName, fieldsGroupName, docGroup) => {
    return uploadedFileDeleted({
      fileName,
      fieldName,
      fieldsGroupName,
      docGroup,
      productOrderReferenceNumber,
      documentUpload,
      dispatch,
      formDispatch,
      deleteUploadedDocument
    });
  };

  const onAddressCheckChange = (changedData: SelectionObject): void => {
    togglePostalAddressDetail(changedData.value, addressInfo.address);
  };

  const callbacks = () => {
    const documentsGroup = formState.formInputsMapping['documents'];
    const staffGroup = formState.formInputsMapping['staffPass'];
    const proofGroup = formState.formInputsMapping['proof'];
    const kycGroup = formState.formInputsMapping['kyc'];

    return {
      onFloorNumberChange: handleOnFloorNumberChange,
      floorValidation: addressFloorValidation,
      unitValidation: addressUnitValidation,
      postalCodeValidation: postalCodeValidation,
      rightIconLinkClicked: handlePostalSearch,
      idCardLinkClicked: idCardLinkClicked,
      proofLinkClicked: proofLinkClicked,
      onAddressCheckChange: onAddressCheckChange,
      kycLinkClicked: kycLinkClicked,
      documentTypeValidation: (file: File) => documentTypeValidation(file, t),
      documentSizeValidation: (file: File) => documentSizeValidation(file, t),
      nricFrontDropped: (file: TFile) =>
        uploadFile('nricFrontUpload', 'documents', documentsGroup, file),
      nricFrontDeleted: fileName =>
        deleteFile(fileName, 'nricFrontUpload', 'documents', documentsGroup),
      nricBackDropped: (file: TFile) =>
        uploadFile('nricBackUpload', 'documents', documentsGroup, file),
      nricBackDeleted: fileName =>
        deleteFile(fileName, 'nricBackUpload', 'documents', documentsGroup),
      staffPassDropped: (file: TFile) =>
        uploadFile('staffPassUpload', 'staffPass', staffGroup, file),
      staffPassDeleted: fileName =>
        deleteFile(fileName, 'staffPassUpload', 'staffPass', staffGroup),
      proofDropped: (file: TFile) =>
        uploadFile('proofUpload', 'proof', proofGroup, file),
      proofDeleted: fileName =>
        deleteFile(fileName, 'proofUpload', 'proof', proofGroup),
      kycDropped: (file: TFile) =>
        uploadFile('kycUpload', 'kyc', kycGroup, file),
      kycDeleted: fileName => deleteFile(fileName, 'kycUpload', 'kyc', kycGroup)
    };
  };

  const {
    stockAvailable: { notAvailableSkus }
  } = useStockCheck(STOCK_CHECK_TYPE.MTPOS);

  useEffect(() => {
    const isInCompleteFilled = isInCompleteMyInfoFilled(
      prefilledData,
      checkoutFormData
    );

    if (!isInCompleteFilled && isCompleteMyInfoData) {
      setMyInfoInComplete(true);
    }
  }, []);

  // sequence of useEffect is important to update form preloaded
  useEffect(() => {
    const flowConfigs = getFlowConfigs();
    if (onGettingFormConfigs) {
      onGettingFormConfigs({
        hasBillingPref:
          flowConfigs.showBillingPref || flowConfigs.showExistingBillingAddress,
        hasUploadID:
          flowConfigs.showNricFIN || flowConfigs.showStaffUploadDocument,
        byPassFulfilment: flowConfigs.byPassFulfilment
      });
    }
    if (handleCheckoutHistoryState) {
      handleCheckoutHistoryState();
    }
    dispatch(checkoutActions.resetCreateCustomerStatus());
  }, []);

  useEffect(() => {
    setOverAllFormState();
    fetchUploadedDocuments();
  }, [setOverAllFormState]);

  useEffect(() => {
    const { address, status } = addressInfo;

    setPostalCodeGroupData(address, status);
  }, [addressInfo]);

  useEffect(() => {
    if (checkout?.customerCreated) {
      const hasError =
        cart?.checkoutErrorData &&
        cart?.checkoutOrderError &&
        cart?.checkoutErrorScenarioCheckCompleted;
      const finishedWithoutError =
        cart?.checkoutErrorScenarioCheckCompleted &&
        cart?.checkoutOrderError === false;
      if (hasError) {
        showValidateError();
      } else if (finishedWithoutError) {
        onCustomerCreated();
      }
    }
  }, [
    checkout?.customerCreated,
    cart?.checkoutErrorData,
    cart?.checkoutOrderError,
    cart?.checkoutErrorScenarioCheckCompleted
  ]);

  const showValidateError = () => {
    const errorScenarioData = getCartError(
      cart?.checkoutErrorData,
      errorScenariosData
    );
    const cartErrorDataModel = getCartErrorDataModel(
      errorScenarioData,
      selectedPlan?.planName
    );
    onErrorScenarioFound(cartErrorDataModel);
  };

  const onCustomerCreated = async () => {
    await checkoutActions.allocateEquipmentForAvailableProducts({
      cart,
      simOptions: productOrder?.sim,
      dispatch,
      productOrderId: productOrder?.productOrderId,
      notAvailableSkus
    });
    dispatch(checkoutActions.resetCreateCustomerStatus());

    const flowConfig = getFlowConfigs();
    if (flowConfig.byPassFulfilment) {
      return goToOrderSummary();
    }

    onFormSubmit({}, `/${navigation.ORDER_FULFILMENT_PAGE}`);
  };

  const formattedFormFieldsData = getFormDataMapping(
    formState.formInputsMapping
  );

  const goToUserIdentityCheck = () => {
    dispatch({
      type: CHECKOUT_CONSTANT.CHECKOUT_CLEAR_CUSTOMER_MY_INFO
    });
    dispatch({
      type: CHECKOUT_CONSTANT.RESET_CHECKOUT_FORM_DATA
    });
    navigate(`/${navigation.USER_IDENTITY_CHECK}`, { replace: true });
  };

  const handleProceedMyInfoManual = async () => {
    if (prefilledData?.postalCode) {
      await dispatch(
        fulFillmentActions.getAddressInfo(prefilledData?.postalCode, true)
      );
    }
    setMyInfoInComplete(false);
  };

  const renderMyInfoManualModal = (): ReactNode => {
    return (
      <MyInfoEKYCModal
        isModalOpen={myInfoInComplete}
        modaltitle={t('EKYC_MYINFO_INCOMPLETE_MANUAL_TITLE') as string}
        modalContent={t('EKYC_MYINFO_INCOMPLETE_MANUAL_DESCRIPTION') as string}
        ctaText={t('EKYC_MYINFO_INCOMPLETE_MANUAL_NAVIGATE_BUTTON') as string}
        secondaryType={true}
        onClose={goToUserIdentityCheck}
        onConfirm={handleProceedMyInfoManual}
        disableClose={true}
      />
    );
  };

  const _onValueChanged = (values, _, getFieldStatus): void => {
    const dob = values?.birthday;
    if (dob && nric && getFieldStatus('birthday') && values.postalCode) {
      const isNricSilver = checkNricSilver(dob, !!nric);
      // only dispatch to change formInputsMapping when isNricSilver change value with previous one
      if (
        (isNricSilver && !isNricSilverRef.current) ||
        (!isNricSilver && isNricSilverRef.current)
      ) {
        formDispatch({
          type: FORM_MAPPING_ACTIONS.SET_FORM_INPUT_MAPPING,
          payload: {
            ...getNonPersonalGroup(isNricSilver)
          }
        });
      }
      isNricSilverRef.current = isNricSilver;
    }

    onValuesChange(values);
  };

  const _onValidate = <TValuesObj,>(_: TValuesObj): TValuesObj => {
    let errorState = {};
    errorState = { ...errorState, ...hasDocumentErrors.current };
    return errorState as TValuesObj;
  };

  const _onFormSubmit = async values => {
    const identityCheckResult: KeyValue = await dispatch(
      checkoutActions.checkIdentity({
        identityType: checkout.verificationData?.passType,
        identity: checkout.verificationData?.id
      })
    );
    const params = {
      formValues: values,
      addressInfo: addressInfo.address,
      passType: verificationData.passType,
      passId: verificationData.id,
      addedToContact,
      portIn: productOrder?.type === ORDER_CONSTANT.TYPE.PORTIN,
      productOrder,
      isSimOnlyIPP: isSimOnlyPlan(selectedPlan) && selectedProduct,
      dispatch
    };

    if (identityCheckResult?.customerExists && identityCheckResult?.contactId) {
      return await updateCustomer({
        ...params,
        contactId: identityCheckResult.contactId,
        customerId: identityCheckResult?.customerIds?.[0],
        selectedPlan,
        billingPrefData
      });
    }

    await createCustomer({
      billingPrefData,
      identityCheckResult,
      selectedPlan,
      ...params
    });
  };

  return formattedFormFieldsData && formattedFormFieldsData.length > 0 ? (
    <div className="fs-mask">
      <Form
        {...props}
        enableReinitialize={formState.enableReinitialize}
        formFieldsConfig={formattedFormFieldsData}
        initialValues={formState.formValues}
        data={formData}
        callbacks={callbacks()}
        onValueChange={_onValueChanged}
        onSubmit={_onFormSubmit}
        onValidate={_onValidate}
        validationSchema={getValidationSchema(t)}
        valuesChangeOnDemand={formState.valuesChangeOnDemand}
        enableInputErrorFocus
      />
      {(isLoading || allocateOrReserve?.isLoading) && (
        <LoadingOverlayComponent />
      )}
      {renderMyInfoManualModal()}
      {renderError()}
    </div>
  ) : null;
};

export default NewNewFlow;
