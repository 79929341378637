import React, { useEffect, useRef, useState } from 'react';
import { graphql, StaticQuery, navigate } from 'gatsby';
import { Header as DlsHeader } from '@singtel-web/widgets';
import { useTheme } from '@dls/web';
import {
  initializeSiteSearch360,
  siteSearch360InputClassName
} from '@wec-core/helpers';

import { getCookie, remCalc } from '@lux/helpers';
import { HeaderWrapper } from './style';

import { getUIAMLoginUrl, sdAccountSummary } from '../../config';

import { api, apigeeAuthActions } from '@detox/actions';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { UIAM_FLOW } from '../../types/featureFlag.types';
import { useDispatch } from 'react-redux';
import useInterceptor from '../../hooks/useInterceptor';
import CONSTANT from '../../constants/common';
import { deleteCookie } from '../../helpers/set-cookie';
import useIsWebViewFlow from '../../hooks/useIsWebViewFlow';
import FingerprintJS from '@wec-core/fingerprintjs';

const revokeTokenApi = api.onlineApi.revokeToken;

export const PureHeader = props => {
  const {
    data,
    baseUrl,
    shoppingCartUrl,
    isRrpHeader,
    hideHeader = false
  } = props;
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const apiGeeResponseReceivedRef = useRef(false);
  const { header } = data;
  const logo = {
    src: header.logo.href,
    alt: header.logo.alt,
    href: header.logo.link,
    height: remCalc(40)
  };

  const isUIAMFeatureEnable = isFeatureFlagEnabled(UIAM_FLOW);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const isAccessTokenExists = window.sessionStorage.getItem(
      'ApigeeAuthToken'
    );
    if (isUIAMFeatureEnable && !isAccessTokenExists) {
      dispatch(
        apigeeAuthActions.getApigeeAuthToken(
          () => (apiGeeResponseReceivedRef.current = true)
        )
      );
    }
    // set a device unique id cookie consistency for parallel api calls
    FingerprintJS.load({ cookies: {'max-age': '2592000'} })
  }, []);

  useInterceptor();

  useEffect(() => {
    if (isUIAMFeatureEnable) {
      const uiamAccessToken = getCookie(CONSTANT.UIAM_ACCESS_TOKEN);
      if (uiamAccessToken && uiamAccessToken.length > 0) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    }
  }, []);

  useEffect(() => {
    if (isUIAMFeatureEnable) {
      const uiamAccessToken = getCookie(CONSTANT.UIAM_ACCESS_TOKEN);
      if (uiamAccessToken && uiamAccessToken.length > 0) {
        const revokeTokenCall = async () => {
          try {
            const result = await revokeTokenApi(uiamAccessToken);
            if (result?.code === CONSTANT.REVOKE_SUCCESS) {
              deleteCookie(CONSTANT.UIAM_ACCESS_TOKEN);
              window.open(getUIAMLoginUrl(sdAccountSummary, true), '_self');
            }
          } catch (e) {
            setIsLoggedIn(false);
          }
        };

        header.avatarMenuItems.push({
          title: header.logoutButtonText,
          href: '/logout', // This href does nothing but provide a vanity url if user hovers over the anchor tag
          onClick: e => {
            e.preventDefault();
            revokeTokenCall();
          }
        });
      }
    }
  }, [baseUrl, header.avatarMenuItems, header.logoutButtonText]);

  // Set Products & Services as default selection
  header.menuItems[0].item.active = true;

  const menuIcons = [
    {
      icon: {
        desktop: 'personal',
        desktopHover: 'personal',
        mobile: 'personal',
        onClick: e => {
          if (!isLoggedIn) {
            e.preventDefault();
            window.location.href = `${baseUrl}${header.loginButtonUrl}`;
          }
        }
      },
      subMenu: isLoggedIn ? header.avatarMenuItems : null
    },
    {
      icon: {
        desktop: 'cart',
        desktopHover: 'cart',
        mobile: 'cart',
        onClick: () => {
          navigate(shoppingCartUrl);
        }
      }
    }
  ];

  const onSearchInputActivated = () => {
    initializeSiteSearch360();
  };

  // if (hideHeader) {
  //   return null;
  // }

  return (
    <HeaderWrapper>
      <DlsHeader
        baseUrl={baseUrl}
        logo={logo}
        showHamburger
        showLogoHamburger
        searchConfig={{
          onSearchInputActivated,
          searchInputClassName: siteSearch360InputClassName,
          placeholder: 'Search',
          isIconHoverRequired: true,
          onSearchIconClick: () => {
            window.open(process.env.GATSBY_SEARCH_CDN_URL, '_self');
          }
        }}
        firstLevelLinks={header.firstLevelLinks}
        menuItems={header.menuItems}
        menuIcons={menuIcons}
        backgroundColor={theme?.colorTokens?.background?.standard}
      />
    </HeaderWrapper>
  );
};

/* istanbul ignore next */
const Header = props => {
  const { isClient, isWebView, flow } = useIsWebViewFlow();

  const hideHeader = !isClient || (isClient && isWebView);

  return (
    <StaticQuery
      key={flow}
      query={graphql`
        {
          header {
            menuItems: headerList {
              item: linkItem {
                href: src
                title
              }
              subMenu {
                subMenu: menuList {
                  item: linkItem {
                    subMenu: menuList {
                      item: linkItem {
                        title
                        href: src
                      }
                    }
                    href: src
                    title
                  }
                }
                item: menuSectionTitle {
                  title
                  href: src
                }
              }
            }
            firstLevelLinks: linkItemList {
              active
              title
              href: src
            }
            avatarMenuItems: links {
              title
              href: src
            }
            logo {
              link
              alt
              href: src
            }
            loginButtonUrl
            logoutButtonText
          }
        }
      `}
      render={data => (
        <PureHeader data={data} {...props} hideHeader={hideHeader} />
      )}
    />
  );
};

export default Header;
