import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Column, Grid, Row } from '@lux/components';
import { GatsbyImage as Img } from 'gatsby-plugin-image';

import Button from '../Button';
import { SubtitlePrimary, SubtitleSecondary } from '../Base';
import useTranslate from '../../hooks/useTranslation';

const StyledCartSummary = styled.div`
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  background: rgb(255, 255, 255);
`;

const StyledGrid = styled(props => <Grid {...props} />)`
  padding-top: ${p => p.theme.spacing(3)};
  padding-bottom: ${p => p.theme.spacing(3)};

  ${p => p.theme.media.md`
    padding-top: ${p.theme.spacing(4)};
    padding-bottom: ${p.theme.spacing(4)};
  `};
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ImageContainer = styled.div`
  min-width: 55px;
  min-height: 55px;
  margin-right: ${p => p.theme.spacing(2)};
`;

const Product = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${p => p.theme.spacing(3)};

  ${p => p.theme.media.md`
    justify-content: flex-start;
    margin-bottom: 0;
  `};
`;

const ProductDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`;

const FixedWidthButton = styled(Button)`
  width: 100%;

  margin-right: ${p => (p.left ? p.theme.spacing(1) : '0')};
  margin-left: ${p => (p.right ? p.theme.spacing(1) : '0')};

  ${p => p.theme.media.md`
    width: 200px;
    margin: 0 ${p.theme.spacing(1)}
  `};
`;

const CartSummaryWithButtons = props => {
  const { t } = useTranslate();
  const {
    icon = () => null,
    isPreOrderPhone = '',
    productPrimaryText = '',
    productSecondaryText = '',
    productImage = null,
    primaryCtaText = '',
    secondaryCtaText = '',
    onPrimaryCtaClick,
    onSecondaryCtaClick,
    showAccessories
  } = props;

  const Icon = styled(icon)`
    margin-right: ${p => p.theme.spacing(2)};
  `;

  let renderProductImage = null;

  if (productImage) {
    renderProductImage = (
      <ImageContainer>
        <Img
          image={productImage.childImageSharp.gatsbyImageData}
          alt={productPrimaryText}
        />
      </ImageContainer>
    );
  } else if (icon) {
    renderProductImage = <Icon width={55} height={55} />;
  }

  return (
    <StyledCartSummary>
      <StyledGrid>
        <Row middle="sm">
          <Column sm={12} md={5} mdOffset={1} lg={5} lgOffset={2}>
            <Product>
              {renderProductImage}
              <ProductDesc>
                {isPreOrderPhone && (
                  <SubtitleSecondary>{t('PRE-ORDER')}</SubtitleSecondary>
                )}
                <SubtitlePrimary>{productPrimaryText}</SubtitlePrimary>
                <SubtitleSecondary>{productSecondaryText}</SubtitleSecondary>
              </ProductDesc>
            </Product>
          </Column>
          <Column sm={12} md={5} lg={4}>
            <ButtonGroup>
              {secondaryCtaText && (
                <FixedWidthButton
                  secondary={showAccessories}
                  left
                  onClick={onSecondaryCtaClick}
                >
                  {secondaryCtaText}
                </FixedWidthButton>
              )}
              {showAccessories && (
                <FixedWidthButton right onClick={onPrimaryCtaClick}>
                  {primaryCtaText}
                </FixedWidthButton>
              )}
            </ButtonGroup>
          </Column>
        </Row>
      </StyledGrid>
    </StyledCartSummary>
  );
};

CartSummaryWithButtons.propTypes = {
  /** Receives a svg element */
  /** This prop should be PropTypes.function but it throws an error in jest,
   * due to the svg test stub resolving to a string. TODO - resolve svg imports to a function and
   * clean up all other tests requiring a svg */
  icon: PropTypes.any,
  productPrimaryText: PropTypes.string,
  productSecondaryText: PropTypes.string,
  productImage: PropTypes.object,
  primaryCtaText: PropTypes.string,
  onPrimaryCtaClick: PropTypes.func,
  secondaryCtaText: PropTypes.string,
  onSecondaryCtaClick: PropTypes.func
};

export default CartSummaryWithButtons;
