import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { noop } from '@lux/helpers';
import { Spacing, Row, Column, Button, Text } from '@dls/web';
import Modal from '../Modal';
import Notification from '../Notification';
import notifications from '../../config/notifications';

const StyledSelectBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${p => p.theme.spacing(2)};
  padding: ${p => p.theme.spacing(3)} ${p => p.theme.spacing(2)};
  border-radius: 4px;
  box-shadow: 0 0 0 1px ${p => p.theme.colours.grey_400};
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    ${p =>
      !p.active &&
      css`
        box-shadow: 0 0 0 1px ${p.theme.colours.grey_600};
      `};
  }

  ${p =>
    p.active &&
    css`
      box-shadow: 0 0 0 2px ${p.theme.colours.link};
    `};
`;

const ChangeSimModal = ({
  open,
  title,
  primaryItem,
  secondaryItem,
  ctaText,
  onClose,
  onPrimaryClick,
  onSecondaryClick,
  onButtonClick,
  message
}) => {
  return (
    <Modal open={open} title={title} onClose={onClose}>
      {message && (
        <Row>
          <Column sm={12} md={12}>
            <Spacing bottom={3}>
              <Notification state={notifications.ADDON_SHOW_5G_NOTE.state}>
                {message}
              </Notification>
            </Spacing>
          </Column>
        </Row>
      )}
      <Row>
        <Column sm={12} md={8}>
          <Spacing bottom={3}>
            {primaryItem && (
              <StyledSelectBox
                active={primaryItem.selected}
                onClick={onPrimaryClick}
              >
                <Text type="smallBody">{primaryItem.title}</Text>
                <Text type="smallBody">{primaryItem.price}</Text>
              </StyledSelectBox>
            )}
            {secondaryItem && (
              <StyledSelectBox
                active={secondaryItem.selected}
                onClick={onSecondaryClick}
              >
                <Text type="smallBody">{secondaryItem.title}</Text>
                <Text type="smallBody">{secondaryItem.price}</Text>
              </StyledSelectBox>
            )}
          </Spacing>
        </Column>
      </Row>
      {ctaText && (
        <Button
          data-testid="changeSimModalCtaBtn"
          onClick={onButtonClick}
          text={ctaText}
        />
      )}
    </Modal>
  );
};

ChangeSimModal.defaultProps = {
  onClose: noop,
  onPrimaryClick: noop,
  onSecondaryClick: noop,
  onButtonClick: noop
};

ChangeSimModal.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  primaryItem: PropTypes.shape({
    title: PropTypes.string,
    price: PropTypes.string,
    selected: PropTypes.bool
  }),
  secondaryItem: PropTypes.shape({
    title: PropTypes.string,
    price: PropTypes.string,
    selected: PropTypes.bool
  }),
  ctaText: PropTypes.string,
  onPrimaryClick: PropTypes.func,
  onSecondaryClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  message: PropTypes.string
};

export default ChangeSimModal;
