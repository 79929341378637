import { APP_TYPE_ANY, KeyValue } from './common.types';
import { SelectedPlan } from './shoppingCart.types';

export interface FieldObject {
  [key: string]: APP_TYPE_ANY;
}

export interface SelectionObject {
  name: string;
  value: never;
  setFieldValue: (
    field: string,
    value: APP_TYPE_ANY,
    shouldValidate?: boolean
  ) => void;
}

export interface TObject extends FieldObject {
  [key: string]: FieldObject;
}
export interface FormDispatch {
  type: string;
  payload?:
    | FieldObject
    | FieldObject[]
    | string
    | number
    | boolean
    | FieldValueConfig[]
    | Record<string, unknown>;
}

export interface FlowFormState {
  formInputsMapping: unknown;
  formValues: unknown;
  addNewAddressModalOpen?: boolean;
  hasFulfillment?: boolean;
  formConfigs?: FormFlowConfigResult;
  valuesChangeOnDemand?: FieldValueConfig[];
  enableReinitialize?: boolean;
}

export interface GeoGraphicAddress {
  floor: string;
  postcode: string;
  unitNumber: string;
  streetName: string;
  blockOrHouseNum: string;
  blockOrHouseAddr: string;
  buildingName: string;
  unstructAddress: boolean;
  buildingClassification: string;
  block: string;
  addressId: string;
  isNewAddress?: boolean;
}

export interface DeliveryDetail {
  contactAddress?: ContactAddress;
  billingAccountDetails: BillingAccountDetail[];
  appointmentSlots?: AppointmentSlots;
  addressInfo?: GeoGraphicAddress[];
}

export interface AppointmentSlots {
  transactionId: string;
  transactionStatusCode: string;
  slots: Slot[];
}

export interface Slot {
  slotCode: string;
  slotStartDate: string;
  timeDescription: string;
}

export interface BillingPreferencePayload {
  baStatus: string;
  billDeliveryMethod: string;
  billType: string;
  faId: number;
}

export interface UploadDocumentNeededPayload {
  answer: string;
  isLocal: boolean;
  numOfMonth: number;
  transactionId: string;
}

export interface BillingAccountDetail {
  physicalAddress: ContactAddress;
  billingAccountNumber: string;
  name: string;
  billType: BillType;
  billDeliveryMethod?: string;
  faId?: string;
  baStatus?: string;
  faStatus?: number;
  customerId?: string;
  subscribers: SubscribersList[];
}

export interface SubscribersList {
  subscriberID?: number;
  subscriberResourceVal?: string;
  subscriberType?: string;
}

export interface ContactAddress extends BaseAddress {
  apartment: string;
  unitNumber: string;
  floor: string;
  postcode: string;
  state: string;
  streetName: string;
  blockOrHouseNum: string;
  countryCode: string;
  country: string;
  unitDescription: string;
  unstructAddress: boolean;
  buildingClassification: string;
  addressId: string;
  newAddressSubTitle?: string;
}

export enum BillType {
  E_BILL = 'E_BILL',
  PAPER_BILL = 'PAPER_BILL'
}

export interface FieldValidation {
  valid: boolean;
  message?: string;
}

export interface AddressInfo {
  status: string;
  address: Address;
  savedAddress?: Address;
  previousSearch?: string;
  error?: APP_TYPE_ANY;
  hasInvalid?: boolean;
}

export interface UploadedDocument {
  fileName: string;
  fieldName?: string;
  documentId?: string;
  documentID?: string;
  fileContent?: string;
}

export interface TFile extends File {
  src: string;
}

export interface IFormFieldConfig {
  component?: string;
  name?: string;
  dataSource?: string;
  loading?: boolean;
  disabled?: boolean;
  groupElements?: IFormFieldConfig[];
  [key: string]: APP_TYPE_ANY;
}

export type TranslateFn = (a: string, b?: string[]) => string;
export type TranslateHelperFn = (
  id: string,
  replaceMap?: { [key: string]: string | number }
) => string | JSX.Element;

export interface BillingPrefType {
  billingPreferenceKey: string;
  billingPreferenceDesc: string;
  billingPreferencePrice: string;
  billingPreferenceTitle: string;
}

export interface ErrorScenarioType {
  bccErrorCode: string;
  ctaActionType: string;
  ctaText: string;
  ctaType: string;
  ctaURL: string;
  errorText: string;
  enableCTA: boolean;
  errorCode: string;
  footerList: FootList[];
  title: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryButtonLink: string;
  secondaryButtonLink: string;
}

export interface FootList {
  label: string;
  type: string;
}

export interface FormFlowConfig {
  device?: boolean | string;
  selectedPlan?: SelectedPlan;
  isCis?: boolean;
  isNric?: boolean | IFormFieldConfig;
  isNricSilver?: boolean;
  eBill?: boolean;
  hasSimCard?: boolean;
  hasAccessories?: boolean;
  simType: string;
}

export interface FormFlowConfigResult {
  showStaffUploadDocument?: boolean;
  showKYCUploadDocument?: boolean;
  showAddressProofUploadDoc?: boolean;
  showNricFIN?: boolean;
  showBillingPref?: boolean;
  showExistingBillingAddress?: boolean;
  byPassDocUpload?: boolean;
  byPassFulfilment?: boolean;
}

export interface FieldValueConfig {
  field: string;
  value: APP_TYPE_ANY;
  shouldValidate?: boolean;
}
export interface FileUploadValidation {
  valid: boolean;
  message?: string;
}

export interface FormReducerState {
  formInputsMapping: unknown;
  formValues: unknown;
  addNewAddressModalOpen?: boolean;
  hasFulfillment?: boolean;
  formConfigs?: FormFlowConfigResult;
  valuesChangeOnDemand?: FieldValueConfig[];
  enableReinitialize?: boolean;
}

export interface FormConfig {
  hasPersonalInfo?: boolean;
  hasUploadID?: boolean;
  hasBillingPref?: boolean;
  byPassFulfilment?: boolean;
  byPassDocUpload?: boolean;
}

export interface CallbackValidation {
  valid: boolean;
  message?: string;
}

export type AddressStatus = 'IDLE' | 'PENDING' | 'FAILED' | 'SUCCEEDED';

export interface ValidFloor {
  floorNo: string;
  unitNo: string;
}

export interface DeliveryState {
  isLoading: boolean;
  isDeliveryLoading?: boolean;
  isError: boolean;
  deliveryDetails?: DeliveryDetail;
  deliveryDetailsFromUser?: DeliveryDetail;
  newAddressDefault?: boolean;
}

export enum SelectedBillingAddress {
  NewBillingAddress = 'newBillingAddress',
  BillingAddress = 'billingAddress',
  BillAddressValidValue = 99,
  BillAddressDefaultValue = 0
}

export interface CustomerInfo extends KeyValue {
  birthDate?: string;
  name?: string;
  phone: string;
  salutation?: string;
  gender?: string;
  email?: string;
  passType?: string;
  passId?: string;
}

export interface CustomerInfoPayLoad extends CustomerInfo {
  personIdentificationDetails?: {
    type: string;
    docNumber: string;
  };
  givenName?: string;
  familyName?: string;
  custType?: string;
  custSubType?: string;
  personGender?: string;
  emailId?: string;
  phone: string;
  address: Address;
  billingAddressSame?: boolean;
  salutation?: string;
  birthDate?: string;
  nationality?: string;
  race?: string;
  customerOwningEntity?: string;
  customerAccountCategory?: string;
  timeZone?: string;
  language?: string;
  contactId?: APP_TYPE_ANY;
  selectedOption?: string;
  contactVipCodeX9?: string;
}

export interface BaseAddress extends KeyValue {
  floor: string;
  postcode: string;
  unitNumber: string;
  streetName: string;
  blockOrHouseNum: string;
  blockOrHouseAddr: string;
  buildingName: string;
  unstructAddress: boolean;
  buildingClassification: string;
  block: string;
}

export interface Address extends BaseAddress {
  apartment: string;
  city: string;
  room: string;
  houseNumber: string;
  state: string;
  country: string;
  countryCode: string;
  unstructAddress: boolean;
  buildingClassification: string;
  addressType: string;
}

export interface UpdateCustomerPayload {
  formValues: KeyValue;
  passType: string;
  passId: string;
  addressInfo: BaseAddress;
  dispatch: APP_TYPE_ANY;
  contactId: string;
  customerId: string;
  productOrder: KeyValue;
  isSimOnlyIPP: boolean;
  addedToContact: boolean;
  portIn: boolean;
  selectedPlan: SelectedPlan;
  billingPrefData: BillingPrefType[];
}

export interface CreateCustomerPayload {
  productOrder: KeyValue;
  isSimOnlyIPP: boolean;
  formValues: KeyValue;
  addressInfo: BaseAddress;
  passType: string;
  passId: string;
  billingPrefData: BillingPrefType[];
  dispatch: APP_TYPE_ANY;
  addedToContact: boolean;
  identityCheckResult: KeyValue;
  portIn: boolean;
  selectedPlan: SelectedPlan;
}

export interface ProductItem {
  name: string;
  quantity?: string;
  imageSrc?: string;
  icon?: string;
}

export interface ProductsListInputs {
  cartOrder: FieldObject;
  selectedProduct: FieldObject;
  fulfillment: FieldObject;
  selectedAccessories: FieldObject;
  isBuyingWithoutDevice: boolean;
  priceNeeded?: boolean;
  needFreebies?: boolean;
  nounderline?: boolean;
}

export interface ProductsListOutputs {
  productsList: ProductItem[];
  hasAccessories: boolean;
  hasTradeIn: boolean;
  hasFreebies: boolean;
}

export interface AvailableFreeBiesInputs {
  freebiesList: FieldObject[];
  mtposStockList: FieldObject[];
}

export interface StoreIds {
  available: string;
  availableQty: number;
  storeID: string;
}

export interface RetrieveMyInfoUrl {
  transactionId?: string;
  url?: string;
}
