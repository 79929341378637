import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noop } from '@lux/helpers';

import ErrorModal from '../ErrorModal';
import Notification from '../Notification';
import notifications from '../../config/notifications';
import { ChooseSIMType } from '../ChooseSIMType';
import { Selector, Spacing, Row, Column, Text, TextLink } from '@dls/web';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { FEATURE_FLAG_ENABLE_ESIM } from '../../types/featureFlag.types';
import colours from '../../theme/colours';

const NumberWrapper = styled.div`
  margin-right: -8px;
  margin-left: -8px;
`;

const NumberSelectNew = ({
  errorCount,
  errorStatus,
  isNgnisError,
  numbers,
  onClick,
  onModalClose,
  onRefresh,
  chooseSIMOptions,
  selectedProduct
}) => {
  const [selectedNumber, setSelectedNumber] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const errorMessage = errorStatus && notifications[errorStatus];
  const isEsimEnabled = isFeatureFlagEnabled(FEATURE_FLAG_ENABLE_ESIM);
  /**
   *
   * @param {String} number - The number the user has selected
   */
  const handleNumberClick = number => {
    setSelectedNumber(number);
    !isEsimEnabled && onClick(number);
  };

  const handleAddOnsClick = selectedSIMType => {
    onClick(selectedNumber, selectedSIMType);
  };

  /**
   * Handles the button click in the modal
   *
   * When reserved number clicked exceeds 3 times, the modal button will trigger the function to refresh the page
   */
  const handleModalButtonClick = () => {
    if (errorCount >= 3) {
      onRefresh();
      setSelectedNumber('');
    }

    setModalOpen(false);
    onModalClose();
  };

  useEffect(() => {
    if (errorStatus) {
      setModalOpen(true);
    }
  }, [errorStatus]);

  const renderNGNISResult = () => {
    const ngnisFragment = (
      <NumberWrapper>
        <Row>
          {numbers.map((number, index) => (
            <Column key={number} xs={6} sm={3}>
              <Selector
                value={number}
                selected={selectedNumber === number}
                onClick={v => handleNumberClick(v)}
              >
                <Selector.Body title={number} css={{ textAlign: 'center' }} />
              </Selector>
            </Column>
          ))}
        </Row>
        {isEsimEnabled && selectedNumber && (
          <ChooseSIMType
            transactionTypeValue="newSignup"
            scrollToSimType
            data={chooseSIMOptions}
            onSimTypeOnSelection={handleAddOnsClick}
            selectedProduct={selectedProduct}
          />
        )}
      </NumberWrapper>
    );

    return ngnisFragment;
  };

  return (
    <Fragment>
      {isNgnisError ? (
        <Row>
          <Column sm={12} md={8} noGutter>
            <Spacing bottom={3}>
              <Notification
                state={notifications.NGNIS_SEARCH_NUMBERS_ERROR.state}
              >
                {notifications.NGNIS_SEARCH_NUMBERS_ERROR.text}
              </Notification>
            </Spacing>
            <Text type="smallBody" color={colours.grey_600}>
              Please{' '}
              <TextLink inline onClick={onRefresh}>
                try again
              </TextLink>{' '}
              by refreshing this page. If the error still persists, please visit
              our retail store or call 1609.
            </Text>
          </Column>
        </Row>
      ) : (
        renderNGNISResult()
      )}
      {errorMessage && (
        <ErrorModal
          open={modalOpen}
          errorMessage={{
            title: errorMessage.title,
            text: errorMessage.text,
            ctaText: errorMessage.ctaText
          }}
          onClose={onModalClose}
          onCtaClick={handleModalButtonClick}
        />
      )}
    </Fragment>
  );
};

NumberSelectNew.defaultProps = {
  onClick: noop,
  onModalClose: noop,
  onRefresh: noop
};

NumberSelectNew.propTypes = {
  /**
   * Number of error a reserved number is triggered
   */
  errorCount: PropTypes.number,
  /**
   * The error status
   */
  errorStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Check if the error is a NGNIS error
   */
  isNgnisError: PropTypes.bool,
  /**
   * Load the numbers to be displayed
   */
  numbers: PropTypes.arrayOf(PropTypes.string),
  /**
   * Fire the click event
   */
  onClick: PropTypes.func,
  /**
   * Fire the modal close event
   */
  onModalClose: PropTypes.func,
  /**
   * Handles the refresh event when clicked
   */
  onRefresh: PropTypes.func
};

export default NumberSelectNew;
