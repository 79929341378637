import {
  FormFlowConfig,
  FormFlowConfigResult
} from '../../../types/registrationCheckout';
import { simCardType } from '../../../config/simcard-types';

export const getFormConfigForReconFlow = ({
  isNric,
  device,
  isCis,
  eBill,
  isNricSilver = false,
  hasSimCard = false,
  hasAccessories = false,
  simType
}: FormFlowConfig): FormFlowConfigResult => {
  const showStaffUploadDocument = !!isCis;
  const showNricFIN = (isNricSilver && !isCis) || !isNric;
  const showBillingPref = !eBill;
  const byPassDocUpload = eBill && !isCis && !isNricSilver && !!isNric;
  const byPassFulfilment =
    !device &&
    (!hasSimCard || simType === simCardType.simTypeESim) &&
    !hasAccessories;

  return {
    showStaffUploadDocument,
    showNricFIN,
    showBillingPref,
    showExistingBillingAddress: false,
    byPassDocUpload,
    byPassFulfilment
  };
};
