import { useEffect } from 'react';
import { withTheme } from 'styled-components';
import { useThemeToggle } from '../ThemeProviderWrapper';

// This component updates the theme in ThemeProviderWrapper
// to grey_50 and then changes the theme back to white after unmounting.
// This is to make sure some pages that have grey background.

export const BackgroundColourToggle = props => {
  const { theme, enabled } = props;
  const { toggle } = useThemeToggle();

  useEffect(() => {
    if (enabled && theme.colours.background_primary !== theme.colours.grey_50) {
      const updatedColours = {
        ...theme.colours,
        background_primary: theme.colours.grey_50
      };

      toggle({ ...theme, colours: updatedColours });
    }

    return () => {
      if (theme.colours.background_primary !== 'transparent') {
        const updatedColours = {
          ...theme.colours,
          background_primary: 'transparent'
        };

        toggle({ ...theme, colours: updatedColours });
      }
    };
  }, [enabled, theme, toggle]);

  return props.children;
};

export default withTheme(BackgroundColourToggle);
