/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { OrderConfirmation } from '@common-modules/shopping-cart';
import { ExistingSubscriberModal } from '../ExistingSubscriberModal';
import { Spacing, Text, Row, Column, Grid, useDevice } from '@dls/web';
import { navigate } from 'gatsby';
import useTranslate from '../../hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';
import useUser from '../../hooks/useUser';
import ORDER_CONSTANTS from '../../constants/order';
import FULFILMENT_CONSTANTS from '../../constants/fulfillment';
import CONSTANTS from '../../constants/common';
import styled from 'styled-components';
import lihook_desktop from '../../assets/images/lihook-desktop.png';
import lihook_mobile from '../../assets/images/lihook-mobile.png';
import applestore from '../../assets/images/applestore.png';
import playstore from '../../assets/images/playstore.png';

import {
  getCreateCustomerProblemRequestData,
  getOrderStatus,
  isCashPayment,
  isPaymentSuccessFromUrlParams,
  isOrderFailed,
  isOrderSuccess,
  updatePromotionsObj,
  isZeroUpfront,
  isOnlinePayment,
  isDistributedPromoAvailable,
  getOrderDataForGTM,
  geteCommerceDataForGTM,
  isPaymentFailureFromUrlParams,
  surveyPaymentMethodMapping,
  surveyFulfilmentMapping
} from './helper';
import {
  ACTION_TYPES,
  orderSummaryActions,
  authActions,
  fulFillmentActions,
  tokenActions
} from '@detox/actions';
import { ACTION_TYPES as SHOP_ACTION_TYPES } from '../../constants/actions';
import { OrderSummaryState } from '../../reducers/orderSummary';
import { ListSkeletonLoader } from '../SkeletonLoader';
import { isZeroUpfrontCharges } from '../OrderSummary/helper';
import {
  getAccountBillingInfo,
  getQueryData,
  dataLayerPush
} from '../../helpers/common';
import {
  clearBuyFlowData,
  PaymentState,
  resetPayment
} from '../../reducers/payment';
import { useFlowCheck } from '../../hooks/useFlowCheck/useFlowCheck';
import isMobileSharePlan from '../../helpers/is-mobile-share-plan';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import {
  LI_HOOK_SIGNUP,
  FEATURE_FLAG_ENABLE_QUALTRICS
} from '../../types/featureFlag.types';
import { CHOOSE_SIM_TYPE } from '../../constants/choose-sim-type';
import { getThankYouDataLayer } from '../../helpers/dataLayerHelpers';

declare global {
  interface Window {
    QSI: KeyValue;
    surveyData: string;
  }
}

const StyledNoteText = styled.div`
  font-weight: normal;
  span {
    font-weight: bold;
  }
`;

const StyledImg = styled.img`
  width: 205px;
  height: 62px;
`;

const LihookWithoutTokenURL = process.env.GATSBY_LI_HOOK_BILL_PROTECT_URL;

export const ThankYou = ({
  location
}: {
  location: Location;
}): ReactElement => {
  const { t } = useTranslate();
  const { isMobile } = useDevice();
  const [isModalOpen, setIsModalOpen] = useState(false);
  useUser();
  const dispatch = useDispatch();

  const {
    auth,
    user,
    order,
    fulfillment,
    payment,
    orderSummary,
    product,
    promotions,
    addons,
    checkout,
    plan,
    cart,
    accessory
  } = useSelector((state: KeyValue) => ({
    auth: state.auth,
    user: state.user,
    order: state.order,
    fulfillment: state.fulfillment,
    payment: state.payment as PaymentState,
    orderSummary: state.orderSummary as OrderSummaryState,
    product: state.product,
    promotions: state.promotions,
    addons: state.addons,
    checkout: state.checkout,
    plan: state.plan,
    cart: state.cart,
    accessory: state.accessory
  }));
  const userInfo = user?.information;
  const { productOrder, selectedSimType } = order;
  const { delivery } = fulfillment;
  const contact = userInfo?.clientContext?.contact;
  const selectedProduct = product?.selectedProduct;
  let customerId, contactID;
  const isMobileShare =
    isMobileSharePlan(plan?.selectedPlan) ||
    productOrder?.type === ORDER_CONSTANTS.TYPE.MS_NEW;

  const getCustomerId = () => {
    const { customerId } = getAccountBillingInfo({
      checkout,
      delivery,
      userInformation: userInfo,
      productOrder
    });
    return customerId;
  };

  const isLiHookInsurance = isFeatureFlagEnabled(LI_HOOK_SIGNUP);
  const isQualtricsEnabled = isFeatureFlagEnabled(
    FEATURE_FLAG_ENABLE_QUALTRICS
  );

  const worryFreeStatus = auth?.worryFree?.status;
  const cleanWorryFree = auth?.worryFree?.otpVerified;
  const sessionToken = auth?.sessionToken;
  const [token, setToken] = useState<KeyValue>({});

  const retrieveToken = useCallback(async (contact: APP_TYPE_ANY) => {
    setToken(
      await tokenActions.getEncryptionToken({
        contactId: contact?.contactId
      })(dispatch)
    );
  }, []);

  useEffect(() => {
    retrieveToken(contact);
  }, [contact]);

  useFlowCheck({
    flowData: [
      orderSummary?.quoteFetched,
      getQueryData(location, ORDER_CONSTANTS.URL_PARAMS.ONEPAY_STATUS)
    ]
  });

  useEffect(() => {
    window.sessionStorage.removeItem('det-onepay-ref-id');
  }, []);

  useEffect(() => {
    if (!sessionToken || cleanWorryFree) {
      authActions.getAuthToken({ cleanWorryFree });
    } else if (sessionToken && worryFreeStatus) {
      authActions.resetWorryFree();
    }
  }, [
    cleanWorryFree,
    worryFreeStatus,
    authActions.getAuthToken,
    authActions.resetWorryFree,
    sessionToken
  ]);

  useEffect(() => {
    let intervalId;
    if (isQualtricsEnabled) {
      const checkForQualtrics = () => {
        if (window?.QSI?.API && window?.surveyData) {
          window.QSI.API.load();
          window.QSI.API.run();
          intervalId && clearInterval(intervalId);
        }
      };
      checkForQualtrics();
      intervalId = setInterval(checkForQualtrics, 1000);
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, []);

  const getProductOrderData = () => {
    return {
      productOrderId: payment.productOrderId || productOrder?.productOrderId,
      productOrderReferenceNumber:
        payment.productOrderReferenceNumber ||
        productOrder?.productOrderReferenceNumber,
      orderAmount:
        payment.orderAmount ||
        orderSummary.quoteData?.checkOutPriceBreakdown?.finalAmountIncludingTax,
      selectedDeliveryMode:
        payment.selectedDeliveryMode ||
        fulfillment.delivery?.selectedDeliveryMode,
      customerId: payment.customerId || getCustomerId()
    };
  };

  const productOrderData = getProductOrderData();
  const hasDelivery = !!productOrderData.selectedDeliveryMode;
  if (userInfo?.anonymousUser && userInfo?.anonymousCustomerId) {
    customerId = userInfo?.anonymousCustomerId;
    contactID = userInfo?.anonymousContactId;
  } else {
    customerId = productOrderData.customerId;
    contactID = contact?.contactId;
  }
  useEffect(() => {
    if (customerId) {
      const orderStatus = getOrderStatus(location);
      if (isPaymentFailureFromUrlParams(location)) {
        handleEcommGTagEvent(false);
      }
      dispatch({
        type: SHOP_ACTION_TYPES.PAYMENT.RESET_PAYMENT
      });
      dispatch({
        type: ACTION_TYPES.ORDER_SUMMARY.SET_PAYMENT_STATUS,
        value: {
          orderStatus,
          productOrderId: productOrder?.productOrderId,
          productOrderReferenceNumber:
            productOrder?.productOrderReferenceNumber,
          orderAmount:
            orderSummary.quoteData?.checkOutPriceBreakdown
              ?.finalAmountIncludingTax,
          selectedDeliveryMode: fulfillment.delivery?.selectedDeliveryMode,
          customerId: getCustomerId()
        }
      });
      if (isZeroUpfront(location)) {
        return handleRetrievePromoCode(
          customerId,
          productOrderData.productOrderReferenceNumber,
          updatedPromoOrderDetails
        );
      }
    }
  }, [customerId]);

  const handleAffiliateEvent = (): void => {
    // fire affiliate event only for new orders
    const orderType = productOrder?.type;
    if (
      orderType === ORDER_CONSTANTS.TYPE.MS_NEW ||
      orderType === ORDER_CONSTANTS.TYPE.NEW
    ) {
      const orderCompleteData = getOrderDataForGTM({
        order,
        orderSummary,
        fulfillment
      });
      dispatch({
        type: 'ORDER_COMPLETE',
        payload: orderCompleteData
      });
    }
  };

  const getHashedEmail = (): string => {
    const { hashedEmailId, hashedEmailIdX8 } =
      userInfo?.clientContext?.contact || {};

    return hashedEmailId || hashedEmailIdX8 || '';
  };

  const setQualtricsData = (OrderInformation: KeyValue) => {
    try {
      window.surveyData = ''; //reset surveyData
      const paymentMethod =
        surveyPaymentMethodMapping[payment?.paymentMethod] || 'NA';
      const selectedDeliveryMode =
        payment.selectedDeliveryMode ||
        fulfillment.delivery?.selectedDeliveryMode;
      const fulfillmentMethod =
        surveyFulfilmentMapping[selectedDeliveryMode] || 'NA';

      const surveyData = {
        customerName: userInfo?.clientContext?.customers[0]?.customerName,
        paymentMethod,
        orderId: productOrderData.productOrderId,
        fulfillmentMethod,
        product: [
          {
            product: 'WS',
            transactionType: OrderInformation?.mobile[0]?.productOrderItemType,
            planDetails: OrderInformation?.mobile[0]?.planDetails[0]?.planName
          }
        ]
      };

      window.surveyData = JSON.stringify(surveyData);
    } catch (e) {}
  };

  const handleEcommGTagEvent = (paymentStatus: boolean): void => {
    const orderECommerceData = geteCommerceDataForGTM({
      order,
      orderSummary,
      fulfillment,
      plan,
      cart,
      addons,
      product,
      paymentStatus,
      isMobileShare
    });

    if (productOrder) {
      dispatch({
        type: 'ORDER_ECOMMERCE_DATA',
        payload: orderECommerceData
      });
    }

    //Set Qualtrics data from the orderInformation
    isQualtricsEnabled && setQualtricsData(orderECommerceData.OrderInformation);
  };

  const handleEnhancedConversion = (): void => {
    const hashedEmailValue = getHashedEmail();
    if (hashedEmailValue && productOrder) {
      dispatch({
        type: 'ORDER_ENHANCED_CONVERSION',
        payload: { gec_email_sha256: hashedEmailValue }
      });
    }
  };

  const updatedPromoOrderDetails = updatePromotionsObj(
    promotions.promoOrderDetails,
    'addonIds',
    addons.selectedAddons
  );

  const handleRetrievePromoCode = (
    customerId,
    productOrderReferenceNumber,
    updatedPromoOrderDetails
  ) => {
    dispatch(
      orderSummaryActions.getDistributedPromoCode(
        customerId,
        productOrderReferenceNumber,
        updatedPromoOrderDetails,
        productOrderData.productOrderId
      )
    );

    handleAffiliateEvent();
    handleEcommGTagEvent(true);
    handleEnhancedConversion();

    dataLayerPush(
      getThankYouDataLayer({
        selectedPlan: plan?.selectedPlan,
        selectedVariant: selectedProduct,
        accessories: accessory?.selectedAccessories,
        location: window.location,
        order: productOrder
      }),
      true
    );

    setTimeout(() => {
      dispatch(clearBuyFlowData());
    }, 10);
  };

  useEffect(() => {
    // only proceed further we have success payment.
    if (userInfo?.clientContext && isPaymentSuccessFromUrlParams(location)) {
      if (isOnlinePayment(location)) {
        handleResultFromPaymentGateway();
      } else if (isCashPayment(location)) {
        handleResultFromPaymentViaCash();
      }
    }
  }, [userInfo?.clientContext]);

  // handle result from verify payment api
  useEffect(() => {
    const needProceedPaymentResult =
      !isZeroUpfront(location) &&
      payment?.status &&
      userInfo?.clientContext &&
      payment.isDoneVerifyPayment;
    if (needProceedPaymentResult) {
      handleResultFromVerifyPayment(payment?.status);
    }
  }, [payment?.status, userInfo, payment.isDoneVerifyPayment]);

  const handlePaymentsFailedCase = () => {
    const { barId } = getAccountBillingInfo({
      checkout,
      delivery: fulfillment.delivery,
      userInformation: userInfo
    });
    orderSummaryActions.createCustomerProblem(
      contactID,
      getCreateCustomerProblemRequestData({
        orderId: productOrderData.productOrderId,
        customerId: customerId,
        barId,
        contactId: contactID
      })
    );
    handleEcommGTagEvent(false);
    dispatch(clearBuyFlowData());
  };

  const handleResultFromVerifyPayment = (status: string) => {
    switch (status) {
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.FAILED: {
        return handlePaymentsFailedCase();
      }
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.PENDING:
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.COMPLETED: {
        return handleRetrievePromoCode(
          customerId,
          productOrderData.productOrderReferenceNumber,
          updatedPromoOrderDetails
        );
      }
    }
  };

  const handleResultFromPaymentGateway = () => {
    const onlinePayments = !isZeroUpfront(location) && !isCashPayment(location);
    if (isPaymentSuccessFromUrlParams(location) && onlinePayments) {
      const { barId, faId } = getAccountBillingInfo({
        checkout,
        delivery: fulfillment.delivery,
        userInformation: userInfo
      });
      dispatch(
        orderSummaryActions.verifyPayment({
          contactId: contactID,
          orderId: productOrderData.productOrderId,
          customerId,
          referenceId:
            orderSummary?.refId ||
            getQueryData(location, ORDER_CONSTANTS.URL_PARAMS.MERCHANT_REF_ID),
          amount: productOrderData.orderAmount,
          accountId: faId,
          purchaseToken: getQueryData(
            location,
            ORDER_CONSTANTS.URL_PARAMS.PAYMENT_TOKEN
          ),
          productOrderId: productOrderData.productOrderId,
          productOrderReferenceNumber:
            productOrderData.productOrderReferenceNumber,
          barId
        })
      );
    }
  };

  const handleResultFromPaymentViaCash = () => {
    dispatch(
      fulFillmentActions.verifyPaymentsViaCOD({
        userInfo: {
          customerId: customerId
        },
        productOrder: productOrder || {
          productOrderId: productOrderData.productOrderId
        },
        isOrderConfirm: true
      })
    );
  };

  const renderWhatsNextThirdTitle = () => {
    if (selectedSimType !== CHOOSE_SIM_TYPE.SIM_TYPE_ESIM) {
      return null;
    }

    return (
      <Spacing top={3}>
        <Text type="boldBody">
          {Boolean(selectedProduct)
            ? t('WHAT_NEXT_ESIM_DOWNLOAD_MY_SINGTEL_TITLE_3')
            : t('WHAT_NEXT_ESIM_DOWNLOAD_MY_SINGTEL_TITLE_2')}
        </Text>
        <Text type="body">
          {t('WHAT_NEXT_ESIM_DOWNLOAD_MY_SINGTEL_DESCRIPTION')}
        </Text>
        <StyledImg src={applestore} alt="Download on the App Store" />
        <StyledImg src={playstore} alt="Get it on Google Play" />
      </Spacing>
    );
  };

  const renderWhatsNextSecondTitle = (phone: string) => {
    if (!hasDelivery) {
      return null;
    }
    const deliveryMode = productOrderData.selectedDeliveryMode;
    if (
      selectedSimType === CHOOSE_SIM_TYPE.SIM_TYPE_PhysicalSIM &&
      (deliveryMode ===
        FULFILMENT_CONSTANTS.FULFILMENT_TYPES.DOOR_STEP_DELIVERY ||
        deliveryMode === FULFILMENT_CONSTANTS.FULFILMENT_TYPES.SNAIL_MAIL)
    ) {
      return (
        <Spacing top={3}>
          <Text type="boldBody">
            {t('THANK_YOU_PAGE_WHAT_NEXT_ACTIVE_SIM_CARD_TITLE')}
          </Text>
          <Text type="body">
            {t('THANK_YOU_PAGE_WHAT_NEXT_ACTIVE_SIM_CARD_DESCRIPTION')}
          </Text>
        </Spacing>
      );
    }

    if (deliveryMode === FULFILMENT_CONSTANTS.FULFILMENT_TYPES.SNAIL_MAIL) {
      return (
        <Spacing top={3}>
          <Text type="boldBody">
            {t('THANK_YOU_PAGE_WHAT_NEXT_SNAIL_MAIL_TITLE_2')}
          </Text>
          <Text type="body">
            {t('THANK_YOU_PAGE_WHAT_NEXT_SNAIL_MAIL_DESCRIPTION_2')}
          </Text>
        </Spacing>
      );
    }

    // Default case
    return (
      <Spacing top={3}>
        <Text type="boldBody">{t('THANK_YOU_PAGE_WHAT_NEXT_TITLE_2')}</Text>
        <Text type="body">
          {t('THANK_YOU_PAGE_WHAT_NEXT_DESCRIPTION_2', [phone])}
          <Text
            type="link"
            href={ORDER_CONSTANTS.URL.SINGTEL_APP}
            target="_blank"
          >
            {t('MY_SINGTEL_APP')}
          </Text>
        </Text>
      </Spacing>
    );
  };

  const renderWhatsNextFirstTitle = (hasDelivery, email, phone) => {
    if (selectedSimType === CHOOSE_SIM_TYPE.SIM_TYPE_ESIM) {
      return (
        <>
          <Text type="boldBody">
            {t('THANK_YOU_PAGE_WHAT_NEXT_ESIM_TITLE')}
          </Text>
          <Text type="body">
            {t('THANK_YOU_PAGE_WHAT_NEXT_ESIM_DESCRIPTION', [email])}
          </Text>
        </>
      );
    }

    if (selectedSimType === CHOOSE_SIM_TYPE.SIM_TYPE_PhysicalSIM) {
      if (
        productOrderData.selectedDeliveryMode ===
        FULFILMENT_CONSTANTS.FULFILMENT_TYPES.SNAIL_MAIL
      ) {
        return (
          <>
            <Text type="boldBody">
              {t('THANK_YOU_PAGE_WHATS_NEXT_RECEIVE_SIM_CARD')}
            </Text>
            <Text type="body">
              {t('THANK_YOU_PAGE_WHATS_NEXT_RECEIVE_SIM_CARD_DESCRIPTION')}
            </Text>
          </>
        );
      }

      if (
        productOrderData.selectedDeliveryMode ===
        FULFILMENT_CONSTANTS.FULFILMENT_TYPES.DOOR_STEP_DELIVERY
      ) {
        return (
          <>
            <Text type="boldBody">
              {t('THANK_YOU_PAGE_WHATS_NEXT_RECEIVE_DELIVERY')}
            </Text>
            <Text type="body">
              {t('THANK_YOU_PAGE_WHATS_NEXT_RECEIVE_DELIVERY_DESCRIPTION', [
                phone
              ])}
              <Text
                type="link"
                href={ORDER_CONSTANTS.URL.SINGTEL_APP}
                target="_blank"
              >
                {t('MY_SINGTEL_APP')}
              </Text>
            </Text>
          </>
        );
      }
    }

    // Default case
    return (
      <>
        <Text type="boldBody">
          {hasDelivery
            ? t('THANK_YOU_PAGE_WHAT_NEXT_TITLE_1')
            : t('THANK_YOU_PAGE_WHAT_NEXT_TITLE_NO_NUMBERING')}
        </Text>
        <Text type="body">
          {hasDelivery
            ? t('THANK_YOU_PAGE_WHAT_NEXT_DESCRIPTION_1', [email])
            : t('THANK_YOU_PAGE_WHAT_NEXT_DESCRIPTION_NO_DELIVERY', [email])}
        </Text>
      </>
    );
  };

  const renderCustomWhatNext = () => {
    const contact = userInfo?.clientContext?.contact;
    const { email, phone } = contact || {};

    switch (payment?.status) {
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.COMPLETED: {
        return (
          <Spacing top={3}>
            {renderWhatsNextFirstTitle(hasDelivery, email, phone)}
            {renderWhatsNextSecondTitle(phone)}
            {renderWhatsNextThirdTitle()}
          </Spacing>
        );
      }
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.FAILED: {
        return (
          <Spacing top={2}>
            <Text type="body">
              {t('THANK_YOU_PAGE_FAILURE_LINER', [email])}
            </Text>
          </Spacing>
        );
      }
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.PENDING: {
        return (
          <Spacing top={2}>
            <Text type="body">
              {t('THANK_YOU_PAGE_WHAT_NEXT_DESCRIPTION_1_PENDING', [email])}
            </Text>
          </Spacing>
        );
      }
      default: {
        return {};
      }
    }
  };

  const renderHelpBox = () => {
    return (
      <Row start="xs">
        <Column xs={12}>
          <Spacing top={isMobile ? 0 : 0.2} bottom={isMobile ? 0 : 2}>
            <Spacing topBottom={1}>
              <Text type="header">{t('THANK_YOU_PAGE_NEED_HELP_TITLE')}</Text>
            </Spacing>

            <Text type="body">
              {t('THANK_YOU_PAGE_NEED_HELP_TEXT')}
              <Text type="link" href={ORDER_CONSTANTS.URL.FAQ} target="_blank">
                {t('THANK_YOU_PAGE_NEED_HELP_TEXT_SUFFIX')}
              </Text>
            </Text>
          </Spacing>
        </Column>
      </Row>
    );
  };

  const renderYouMayNeedToKnow = () => {
    return {
      title: t('THANK_YOU_PAGE_YOU_MAY_NEED_TO_KNOW'),
      data: [
        {
          text: t('THANK_YOU_PAGE_NEED_TO_KNOW_LINK_TEXT1'),
          url: ORDER_CONSTANTS.URL.BILLING
        },
        {
          text: t('THANK_YOU_PAGE_NEED_TO_KNOW_LINK_TEXT2'),
          url: ORDER_CONSTANTS.URL.ONE_PASS
        },
        {
          text: t('THANK_YOU_PAGE_NEED_TO_KNOW_LINK_TEXT3'),
          onClick: () => {
            setIsModalOpen(true);
          }
        }
      ]
    };
  };

  const renderImportantNoteContent = () => {
    const contentStart = t('THANKYOU_PAGE_IMPT_NOTE_CONTENT_1');
    const contentMinutes = t('THANKYOU_PAGE_IMPT_NOTE_CONTENT_2');
    const contentEnd = t('THANKYOU_PAGE_IMPT_NOTE_CONTENT_3');

    return (
      <div>
        <StyledNoteText>
          {contentStart}
          <span>{contentMinutes}</span>
          {contentEnd}
        </StyledNoteText>
      </div>
    );
  };

  const composeThankYouPageData = ({
    orderId = productOrderData.productOrderId,
    email
  }: {
    orderId?: string;
    email?: string;
    status?: string;
  }) => {
    return {
      customContent: {
        WhatNext: renderCustomWhatNext(),
        HelpBox: renderHelpBox()
      },
      orderNo: orderId,
      customerEmail: email,
      customerSupport: CONSTANTS.THANK_YOU_PAGE_CUSTOMER_SUPPORT,
      helpLinks:
        payment.status === ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.COMPLETED &&
        renderYouMayNeedToKnow(),
      promocode: {
        distributePromoCode: payment?.distributePromoCode,
        distributeLoading: payment?.distributeLoading,
        distributeSuccess: payment?.distributeSuccess,
        distributeError: payment?.distributeError
      },
      importantNote: {
        title: t('THANKYOU_PAGE_IMPT_NOTE_TITLE'),
        content: renderImportantNoteContent()
      },
      lihook: {
        desktopImgSrc: lihook_desktop,
        mobileImgSrc: lihook_mobile
      }
    };
  };

  const getZeroUpfrontStatus = () => {
    return {
      title: t('THANK_YOU_PAGE_STATUS_SUCCESS'),
      message: ' '
    };
  };

  const getOrderStatusText = () => {
    switch (payment?.status) {
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.COMPLETED: {
        return {
          title: t('THANK_YOU_PAGE_STATUS_SUCCESS'),
          message: ' '
        };
      }
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.FAILED: {
        return {
          title: t('THANK_YOU_PAGE_STATUS_FAILED'),
          message: t('THANK_YOU_PAGE_STATUS_FAILED_MESSAGE')
        };
      }
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.PENDING: {
        return {
          title: t('THANK_YOU_PAGE_STATUS_PENDING'),
          message: t('THANK_YOU_PAGE_STATUS_PENDING_MESSAGE')
        };
      }
      default: {
        return {};
      }
    }
  };

  if (user.isLoading || payment?.isLoading || payment?.distributeLoading) {
    return (
      <>
        <Grid>
          <ListSkeletonLoader numberOfItem={3} />
        </Grid>
      </>
    );
  }

  const orderStatus = isZeroUpfrontCharges(orderSummary?.quoteData)
    ? getZeroUpfrontStatus()
    : getOrderStatusText();

  if (!payment?.status) {
    return null;
  }

  const getActionButtonText = () => {
    let buttonText = '';
    if (isOrderFailed(payment)) {
      buttonText = t('THANK_YOU_PAGE_FAILURE_CTA_TEXT');
    } else if (isLiHookInsurance) {
      buttonText = t('THANK_YOU_PAGE_SIGNUP');
    } else if (isDistributedPromoAvailable(payment)) {
      buttonText = t('THANK_YOU_PAGE_CTA_PROMO_TEXT');
    } else {
      buttonText = t('THANK_YOU_PAGE_CTA_TEXT');
    }
    return buttonText;
  };

  const getEncryptionTokenAndFormURL = () => {
    const urlWithToken = `${LihookWithoutTokenURL}${token?.encryptedString}`;
    if (token?.encryptedString) window.open(urlWithToken, '_blank');
  };

  const resetPaymentAndReturnToRoot = () => {
    dispatch(resetPayment());
    navigate('/', { replace: true });
  };

  const onBackTomHome = () => {
    isLiHookInsurance
      ? getEncryptionTokenAndFormURL()
      : resetPaymentAndReturnToRoot();
  };

  return (
    <>
      <OrderConfirmation
        data={composeThankYouPageData({})}
        configs={{
          orderStatus: payment?.status,
          displayMapping: [
            { itemType: 'OrderStatus' },
            { itemType: 'OrderInfo' },
            { itemType: 'ImportantNote' },
            {
              itemType: 'WhatNext'
            },
            {
              itemType: isOrderFailed(payment) && 'NextStep'
            },
            { itemType: isOrderSuccess(payment) && 'YouMayNeedToKnow' },
            {
              itemType:
                !isLiHookInsurance &&
                isDistributedPromoAvailable(payment) &&
                'PromoCode'
            },
            { itemType: isLiHookInsurance && 'LiHookInsurance' },
            { itemType: 'BackHome' },
            { itemType: 'HelpBox' }
          ]
        }}
        localisation={{
          ORDER_STATUS_TITLE: orderStatus.title,
          ORDER_STATUS_BODY_TEXT: orderStatus.message,
          ORDER_INFO_SUBTITLE: t('THANK_YOU_PAGE_STATUS_SUBTITLE'),
          ACTION_BUTTON_BACK_TO_HOME: getActionButtonText(),
          ORDER_NEXT_STEP_TITLE: t('WHATS_NEXT')
        }}
        callbacks={{
          onClickBackBtn: onBackTomHome,
          onClickFeedbackBtn: (v: APP_TYPE_ANY) => {
            console.log('value', v);
          }
        }}
      />
      {isModalOpen ? (
        <ExistingSubscriberModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
    </>
  );
};
