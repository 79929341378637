import { navigate } from 'gatsby';
import isSimOnlyPlan from '../helpers/is-sim-only-plan';
import { api, bridgeActions } from '@detox/actions';
import {
  saveNewOrder,
  saveReconOrder,
  setSessionToken
} from '../helpers/save-order-in-browser-session';
import isFeatureFlagEnabled from '../helpers/feature-flags';
import { springDBridgeUrl } from '../config/links';
import { isSilverTagPlan } from '../helpers/is-silver-tag-plan';
import { navigation } from './navigation-constants';
import {
  ACCESSORIES_FLOW,
  SHOP_CHECKOUT,
  SHOP_ORDER_SUMMARY
} from '../types/featureFlag.types';
import isPlanFlowReContractWithNoSim from '../helpers/is-mobile-share-recon-with-no-sim';
import isMobileSharePlan from '../helpers/is-mobile-share-plan';
import { getBridgeData } from '../helpers/bridgeHelper';

const { getDevicesList } = api.mcss.helpersCheckout;
const { saveBridgeData } = bridgeActions;

export const getDetoxStore = store => {
  const state = store.getState();
  const { auth, order, plan, product, user, fulfillment, cart } = state;
  const { selectedPlan } = plan;
  const { selectedProduct } = product;
  const { productOrder } = order;
  const { information: userInformation, selectedService } = user;
  const { information: cis } = user.cis || {};

  return {
    auth,
    selectedPlan,
    selectedProduct,
    selectedService,
    productOrder,
    userInformation,
    fulfillment,
    cart,
    cis
  };
};

export const sendUserToSpringD = async (options, store) => {
  const {
    auth,
    productOrder,
    selectedService,
    selectedPlan,
    selectedProduct,
    userInformation,
    planOnly,
    flowType,
    type = 'NEW',
    service,
    page = 'add-ons',
    fulfillment,
    cis = {},
    cart
  } = options;

  let cisData = null;

  const deviceSku = cart?.order?.mobile?.device?.sku;
  const mtposStock = fulfillment?.mtposStock;

  const isMtposStockAvailable =
    mtposStock?.data?.find(ms => ms.itemCode === deviceSku)?.availableQty > 0;
  const isStoreScenario = !isMtposStockAvailable && cart?.itemCollectionAtStore;
  const allocateOrReserveData = fulfillment?.allocateOrReserve?.data;

  //CIS Flow
  if (cis && Object.keys(cis).length) {
    const cisMyInfoCustomer = cis.$myInfo
      ? {
          //Point of entry from singpass myinfo
          appType: 'MY_INFO',
          companyName: cis.parentCompanyName,
          companyId: cis.parentCompanyBRN,
          childCompanyName: cis.childCompanyName,
          childCompanyId: cis.childCompanyBRN,
          sessionToken: cis.$myInfo.accessToken
        }
      : {
          //Point of entry from email
          appType: 'CIS',
          sessionToken: cis.sessionToken
        };

    cisData = {
      cisDetails: {
        ...cis,
        ...cisMyInfoCustomer
      }
    };

    // Remove customer data
    if (cisData.cisDetails.$myInfo) {
      delete cisData.cisDetails.cusMyInfo;
    }

    //Delete rates
    if (cisData.cisDetails.rates) {
      delete cisData.cisDetails.rates;
    }
  }

  if (type === 'NEW' || type === 'PORT-IN' || type === 'MS_NEW') {
    let orderData = {
      productOrder,
      selectedPlan,
      selectedProduct,
      userInformation,
      planOnly,
      flowType,
      allocateOrReserveData,
      isStoreScenario
    };

    if (cisData) {
      orderData.cis = {
        ...cisData
      };
    }

    if (isSilverTagPlan(selectedPlan.tags)) {
      orderData.silverComponent = true;
    }

    if (isSimOnlyPlan(selectedPlan) && selectedProduct) {
      orderData.isPPOrder = true;
    }

    saveNewOrder(orderData);
  }

  if (type === 'RECON') {
    const { customerId, serviceId } = service;
    let reConOrderData = {
      auth,
      customerId,
      selectedService,
      selectedProduct,
      selectedPlan,
      productOrder,
      userInformation,
      serviceId,
      flowType,
      allocateOrReserveData,
      isStoreScenario
    };

    if (userInformation && userInformation.otpUser) {
      reConOrderData.worryFree = auth.worryFree;
    }

    if (cisData) {
      reConOrderData.cis = {
        ...cisData
      };
    }

    if (isSilverTagPlan(selectedPlan.tags)) {
      reConOrderData.silverComponent = true;
    }

    if (isSimOnlyPlan(selectedPlan) && selectedProduct) {
      reConOrderData.isPPOrder = true;
    }

    saveReconOrder(reConOrderData);
  }
  navigateToCheckOut(page, store);
};

const isSDOrderSummary = pageToNavigate => {
  return (
    !isFeatureFlagEnabled(SHOP_ORDER_SUMMARY) &&
    pageToNavigate === navigation.ORDER_SUMMARY
  );
};

const isSDAccessory = pageToNavigate => {
  return (
    !isFeatureFlagEnabled(ACCESSORIES_FLOW) &&
    pageToNavigate === navigation.ACCESSORIES_PAGE
  );
};

export const navigateToCheckOut = async (pageToNavigate, store) => {
  await store.dispatch(saveBridgeData(getBridgeData()));
  setSessionToken();

  if (
    !isFeatureFlagEnabled(SHOP_CHECKOUT) ||
    isSDOrderSummary(pageToNavigate) ||
    isSDAccessory(pageToNavigate)
  ) {
    return window.location.assign(`${springDBridgeUrl}?page=${pageToNavigate}`);
  }
  navigate('/' + pageToNavigate);
};

export const handleGoToAddons = (store, action) => {
  const {
    auth,
    selectedService,
    selectedPlan,
    selectedProduct,
    productOrder,
    userInformation,
    cis
  } = getDetoxStore(store);

  const { telcoType, type } = action.value;

  const isAddonsFeatureFlag = isFeatureFlagEnabled('ADDON_FLOW');
  const isGomoFlow = isFeatureFlagEnabled('GOMO_FLOW');

  const isTelcoSingtel =
    telcoType === 'SINGTELPOSTPAID' || telcoType === 'SINGTELPREPAID';

  // Gomo porting in
  if (type === 'PORT-IN') {
    // We need more information about the user before proceeding on to addons page
    if (isGomoFlow && isTelcoSingtel && productOrder.changeOwnershipRequired) {
      return navigate('/number-verification');
    }
    // We have enough about the user, proceed to addons page
    if (isGomoFlow && isTelcoSingtel && !productOrder.changeOwnershipRequired) {
      return navigate('/addons', { replace: true });
    }
    // block the navigation if gomo flow is turned off and is a singtel number
    if (!isGomoFlow && isTelcoSingtel) {
      return false;
    }
  }

  // PP2.0 (Sim Only + Device) needs to go to SpringD until Detox addons page is ready
  if (!isAddonsFeatureFlag && isSimOnlyPlan(selectedPlan) && selectedProduct) {
    const { service } = action.value;

    return sendUserToSpringD(
      {
        auth,
        productOrder,
        selectedPlan,
        selectedProduct,
        userInformation,
        service,
        type,
        page: 'add-ons',
        cis
      },
      store
    );
  }

  if (isSimOnlyPlan(selectedPlan) || isAddonsFeatureFlag) {
    return navigate('/addons');
  }

  if (type === 'MS_NEW') {
    sendUserToSpringD(
      {
        productOrder,
        selectedPlan,
        selectedProduct,
        userInformation,
        type,
        planOnly: true,
        flowType: 'mobileSharePlan',
        cis
      },
      store
    );
  }

  // It is at this stage we want to save the product order
  // in the browser's session for when we redirect back
  // to SpringD.

  if ((type === 'NEW' || type === 'PORT-IN') && !isSimOnlyPlan(selectedPlan)) {
    sendUserToSpringD(
      {
        productOrder,
        selectedPlan,
        selectedProduct,
        userInformation,
        type,
        page: 'add-ons',
        cis
      },
      store
    );
  }

  if (type === 'RECON' && !isSimOnlyPlan(selectedPlan)) {
    const { service } = action.value;

    sendUserToSpringD(
      {
        auth,
        productOrder,
        selectedService,
        selectedPlan,
        selectedProduct,
        userInformation,
        service,
        type,
        page: 'add-ons',
        cis
      },
      store
    );
  }
};

export const handleGoToSpringD = async (store, action) => {
  const {
    auth,
    selectedService,
    selectedPlan,
    productOrder,
    selectedProduct,
    userInformation,
    fulfillment,
    cis,
    cart
  } = getDetoxStore(store);

  const { type } = productOrder || {};
  const isSimOnlyFlow =
    isSimOnlyPlan(selectedPlan) && !(selectedProduct && selectedProduct.sku);

  const { value: page } = action;

  if (type === 'NEW' || type === 'PORT-IN') {
    await sendUserToSpringD(
      {
        productOrder,
        selectedPlan,
        userInformation,
        planOnly: isSimOnlyFlow,
        flowType: isSimOnlyFlow ? 'simOnlyPlan' : 'deviceFirst',
        selectedProduct,
        type,
        page,
        cis,
        cart
      },
      store
    );
  }

  if (type === 'RECON') {
    const flowType = isMobileSharePlan(selectedPlan)
      ? 'mobileSharePlan'
      : isSimOnlyFlow
      ? 'simOnlyPlan'
      : 'deviceFirst';

    await sendUserToSpringD(
      {
        auth,
        productOrder,
        selectedService,
        selectedPlan,
        userInformation,
        service: productOrder.service,
        planOnly: isSimOnlyFlow,
        flowType,
        selectedProduct,
        type,
        page,
        fulfillment,
        cis,
        cart
      },
      store
    );
  }

  if (type === 'MS_NEW') {
    await sendUserToSpringD(
      {
        productOrder,
        selectedPlan,
        selectedProduct,
        userInformation,
        type,
        planOnly: true,
        flowType: 'mobileSharePlan',
        page,
        cis
      },
      store
    );
  }
};

export const handleCheckout = async (store, action) => {
  const { productOrder, userInformation, selectedPlan, cart } = getDetoxStore(
    store
  );
  if (Boolean(cart?.cartOrder?.accessories?.[0]?.ippDetails)) return; // TODO: prevent redirection from middleware
  const isUserHavingInfo = userInformation && userInformation?.clientContext;
  let page;
  const deviceDetails = getDevicesList(cart);
  if (isFeatureFlagEnabled(ACCESSORIES_FLOW)) {
    page = navigation.ACCESSORIES_PAGE;
  } else if (productOrder && isUserHavingInfo) {
    const passType = userInformation?.clientContext.contact.indentType;
    const isPlanFlowWithNoEquipment = isPlanFlowReContractWithNoSim(
      passType,
      deviceDetails,
      selectedPlan,
      productOrder
    );
    //Check if the fulfilment page is required.
    //It can be derived from the action as it contains the flag skipOF.
    //skipOF is set based on if billingAccount is paperBill, document required, if barId selection is required.
    const skipOF = action?.value?.skipOF;
    if (isPlanFlowWithNoEquipment && skipOF) {
      page = navigation.ORDER_SUMMARY;
    } else {
      page = navigation.ORDER_FULFILMENT_PAGE;
    }
  } else {
    page = navigation.LOGIN_GUEST_CHECKOUT;
    if (isFeatureFlagEnabled(SHOP_CHECKOUT)) {
      return navigate('/' + page);
    }
  }

  if (isFeatureFlagEnabled(SHOP_CHECKOUT) && !isSDOrderSummary(page)) {
    page = navigation.CHECKOUT;
    return navigate('/' + page);
  }

  //Continue to springd
  return await handleGoToSpringD(store, { value: page });
};

const handleOrderError = (store, action) => {
  if (action.value?.errorMsg === 'PORT_IN_GENERIC_ERROR') {
    return navigate('/number-selection#port-in');
  }
};

const eventsMap = {
  GO_TO_SPRINGD_PAGE: handleGoToSpringD,
  SET_PRODUCT_ORDER_SUCCESS: handleGoToAddons,
  CHECKOUT_SUCCESS: handleCheckout,
  SET_PRODUCT_ORDER_ERROR: handleOrderError
};

const navigationMiddleware = store => next => action => {
  next(action);
  eventsMap[action.type] && eventsMap[action.type](store, action);
};

export default navigationMiddleware;
