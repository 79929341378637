import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H4 } from '../Base';
import { isEscapeKey, noop } from '@lux/helpers';

import IconClose from '../../assets/svgs/close.svg';

const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: ${p => p.theme.colours.nearBlack};
  opacity: 0.9;
  z-index: 99999;
`;

const ModalDialog = styled.div`
  width: calc(100% - ${p => p.theme.spacing(4)});
  max-height: calc(100% - ${p => p.theme.spacing(4)});
  position: fixed;
  top: 50%;
  left: 50%;
  background: ${p => p.theme.colours.white};
  overflow-y: auto;
  transform: translate(-50%, -50%);
  z-index: 99999;

  ${p => p.theme.media.md`
    max-width: 700px;
    min-height: 240px;
  `};
`;

const ModalHeader = styled.div`
  background: ${p => p.theme.colours.white};
  padding: ${p => p.theme.spacing(6)} ${p => p.theme.spacing(2)}
    ${p => p.theme.spacing(1)};

  ${p => p.theme.media.md`
    padding: ${p.theme.spacing(6)} ${p.theme.spacing(5)} ${p.theme.spacing(2)};
  `};
`;

const ModalContent = styled.div`
  padding: ${p => p.theme.spacing(1)} ${p => p.theme.spacing(2)}
    ${p => p.theme.spacing(5)};

  ${p => p.theme.media.md`
    padding-left: ${p.theme.spacing(5)};
    padding-right: ${p.theme.spacing(5)};
    padding-bottom: ${p.theme.spacing(5)};
  `};
  overflow-y: auto;
`;

const StyledIcon = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${p => p.theme.spacing(2)};
  cursor: pointer;
`;

const Modal = props => {
  const { open, children, disableClose, title, serviceNumber, onClose } = props;

  const [openModal, setOpenModal] = useState(open);

  // Disable body scroll when modal is open
  useEffect(() => {
    openModal
      ? (document.body.style.overflow = 'hidden')
      : document.body.removeAttribute('style');

    return () => {
      document.body.removeAttribute('style');
    };
  }, [openModal]);

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  // Close the modal when "Escape" is pressed
  useEffect(() => {
    const handleKeyDown = e => {
      if (openModal && isEscapeKey(e)) {
        closeModal(e);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  });

  const closeModal = e => {
    setOpenModal(false);
    onClose(e);
  };

  return (
    openModal && (
      <Fragment>
        <ModalOverlay onClick={closeModal} data-testid="overlay" />
        <ModalDialog data-testid="modaldialog">
          <ModalHeader>
            {!disableClose && (
              <StyledIcon>
                <IconClose
                  width={16}
                  height={16}
                  onClick={closeModal}
                  data-testid="button-close"
                />
              </StyledIcon>
            )}
            {serviceNumber ? (
              <H4>
                {title}
                <span className="fs-mask">{serviceNumber}</span>
              </H4>
            ) : (
              <H4>{title}</H4>
            )}
          </ModalHeader>
          <ModalContent>{children}</ModalContent>
        </ModalDialog>
      </Fragment>
    )
  );
};

Modal.defaultProps = {
  disableClose: false,
  open: false,
  onClose: noop
};

Modal.propTypes = {
  /** Set if the close button is shown */
  disableClose: PropTypes.bool,
  /** Set if the modal is open */
  open: PropTypes.bool,
  /** Set the content of the modal */
  children: PropTypes.node,
  /** Set the title of the modal */
  title: PropTypes.any,
  /** Handle the event when the modal is close */
  onClose: PropTypes.func
};

export default Modal;
