import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BodySecondary } from '../../components/Base';
import FadeAnimation from '../../components/FadeAnimation';

import sortCapacity from '../../helpers/sort-capacity';
import sortMonth from '../../helpers/sort-month';
import { isNullOrUndefinedOrEmptyCheck } from '../../helpers/common';
import { trans as t } from '../../helpers/localisation';
import theme from '../../theme';

const StyledAnchor = styled(BodySecondary)`
  color: ${theme.colours.link};
  cursor: pointer;
  text-align: right;
`;

/**
 * Gets all unique sizes in an array of skus
 * @param {*} skusByPlan
 */

export const getAllSizes = skus => {
  const utilMap = {};
  return skus.reduce((accum, current) => {
    if (!utilMap[current.size]) {
      accum.push({ size: current.size, price: current.price });
      utilMap[current.size] = true;
    }
    return accum;
  }, []);
};

// Returns a product if there is stock, returns null otherwise
export const getProductAvailability = (product, stockAvailability) => {
  if (product && stockAvailability.length > 0) {
    const productStockAvail = stockAvailability.find(
      sku => sku.itemCode === product.sku
    );

    if (productStockAvail.availableQty > 0) {
      // Return the product if the availableQty is more than 0
      return product;
    } else {
      // Intentionally return null because there is no such product to return for availableQty is 0 or less (negative)
      return null;
    }
  }
  return product;
};

/**
 * Return all the products matched with plans, colour, size and their availability
 * @param {*} allSku
 * @param {*} allPlans
 * @param {*} stockAvailable
 */
export const getAllProducts = (allSku, allPlans, stockAvailable) => {
  return allSku
    .filter(sku => allPlans.some(plan => plan.mecId === sku.planId))
    .map(sku => {
      const stock = stockAvailable.find(({ itemCode }) => itemCode === sku.sku);

      return {
        planId: sku.planId,
        colour: sku.colour,
        size: sku.size,
        available: stock ? stock.availableQty > 0 : true
      };
    });
};

export const getColoursAndSizesToRender = ({
  skusByPlan,
  skuVariants,
  renderPlans,
  colours,
  selectedColour,
  selectedMonth,
  selectedPlanGroupName,
  selectedSize,
  sizes,
  stockAvailability
}) => {
  const isSimOnlyPlus = selectedPlanGroupName === 'Sim Only Plus';
  const sizesByColour = skusByPlan.filter(sku => sku.colour === selectedColour);

  let renderSizesByProduct = skuVariants
    .filter(sku => {
      let matchingProduct =
        sku.colour === selectedColour &&
        sku.size === selectedSize &&
        renderPlans.some(plan => plan.mecId === sku.planId);

      if (isSimOnlyPlus && selectedMonth) {
        matchingProduct = matchingProduct && sku.monthlyTerm === selectedMonth;
      }

      return matchingProduct;
    })
    .map(sku => {
      const stock = stockAvailability.find(
        ({ itemCode }) => itemCode === sku.sku
      );

      return {
        ...sku,
        active: selectedSize === sku.size,
        available: stock ? stock.availableQty > 0 : true, // tie-in with stock availability
        name: sku.size
      };
    });

  const renderColours = colours.map(colour => {
    //check if the colour has any available stock
    const allOutOfStock =
      getAllProducts(skuVariants, renderPlans, stockAvailability).filter(
        color => color.available && colour.name === color.colour
      ).length <= 0 || false;

    return {
      name: colour.name,
      hexCode: colour.hexCode,
      active: colour.name === selectedColour,
      available: !allOutOfStock //Mark stocks for every colour
    };
  });

  renderSizesByProduct = sortCapacity(renderSizesByProduct, 'name');

  let renderSizes;
  if (!selectedColour) {
    // show all sizes in disabled state
    renderSizes = sizes.map(sku => ({
      name: sku.size,
      available: false,
      active: false,
      price: sku.price
    }));
  } else {
    // if colour is selected
    // show all sizes in the colour
    renderSizes = sizesByColour.map(sku => {
      const stock = stockAvailability.find(
        availability => availability.itemCode === sku.sku
      );

      return {
        ...sku,
        active: selectedSize === sku.size,
        available: stock ? stock.availableQty > 0 : true, // tie-in with stock availability
        name: sku.size
      };
    });
  }

  renderSizes = sortCapacity(renderSizes, 'name');

  // Get the unique monthly term to render
  let renderMonths = [
    ...new Set(
      skusByPlan
        .filter(({ monthlyTerm }) => monthlyTerm)
        .map(({ monthlyTerm }) => monthlyTerm)
        .sort((a, b) => a - b)
    )
  ];

  renderMonths = sortMonth(renderMonths);

  return {
    renderColours,
    renderMonths,
    renderSizes,
    renderSizesByProduct
  };
};

export const getPlansWithCisPrices = (plans, cisPlanRates) => {
  return plans
    .map(plan => {
      const matched = cisPlanRates.find(
        r => r.planID === plan.basePriceSchemaId
      );

      // If plan is not in cis rates, return undefined so we can filter out later
      if (!matched) {
        return undefined;
      }

      const discountedPrice = matched && Number(matched.discountedPrice);
      const originalPrice = Number(plan.monthlyCharges);

      // If for some unforeseen error the two prices are the same,
      // we do not want to show the price slash. So we just return the plan here
      if (discountedPrice === originalPrice) {
        return plan;
      } else {
        return {
          ...plan,
          monthlyCharges: `${discountedPrice}`,
          originalPrice: plan.monthlyCharges
        };
      }
    })
    .filter(Boolean);
};

// to show/hide accessories based on AEM flags (phones, lifestyle)
export const getShowAccessories = (isLifeStyleAccessory, switches) => {
  if (!switches) {
    return true;
  }

  const {
    DETOX_CONTEXTUAL_ACCESSORIES_LIFESTYLE = true,
    DETOX_CONTEXTUAL_ACCESSORIES_PHONE = true
  } = switches;

  return (
    (isLifeStyleAccessory && DETOX_CONTEXTUAL_ACCESSORIES_LIFESTYLE) ||
    (!isLifeStyleAccessory && DETOX_CONTEXTUAL_ACCESSORIES_PHONE)
  );
};

export const getAdditionalMetaData = ({
  metaTitle = '',
  metaDescription = '',
  metaKeywords = '',
  title = '',
  isRRP = false
} = {}) => {
  const defaultTitle = t(
    isRRP ? 'RRP_ADDITIONAL_META_TITLE' : 'ADDITIONAL_META_TITLE',
    [title]
  );
  const defaultDescription = t(
    isRRP ? 'RRP_ADDITIONAL_META_DESCRIPTION' : 'ADDITIONAL_META_DESCRIPTION',
    [title]
  );
  const defaultKeywords = t(
    isRRP ? 'RRP_ADDITIONAL_META_KEYWORDS' : 'ADDITIONAL_META_KEYWORDS',
    new Array(isRRP ? 8 : 6).fill(title)
  );
  const metaTitleValue = isNullOrUndefinedOrEmptyCheck(metaTitle)
    ? defaultTitle
    : metaTitle;
  const metaDescriptionValue = isNullOrUndefinedOrEmptyCheck(metaDescription)
    ? defaultDescription
    : metaDescription;
  const metaKeywordsValue = isNullOrUndefinedOrEmptyCheck(metaKeywords)
    ? defaultKeywords
    : metaKeywords;

  return {
    metaTitle: metaTitleValue,
    metaDescription: metaDescriptionValue,
    metaKeywords: metaKeywordsValue
  };
};

export const getSectionTestId = disableSection => {
  const status = disableSection ? 'disabled' : 'active';
  return `phone-section-${status}`;
};

export const ChangeButton = props => {
  const { collapsed, onClick } = props;

  if (!collapsed) {
    return null;
  }
  return (
    <FadeAnimation
      animateFadeOut={!collapsed}
      fadeOutSpeed={0.1}
      fadeInSpeed={1}
    >
      <StyledAnchor onClick={onClick}>Change</StyledAnchor>
    </FadeAnimation>
  );
};

ChangeButton.propTypes = {
  collapsed: PropTypes.bool,
  onClick: PropTypes.func
};
