/**
 * Split the Sim card option into title and price
 *
 * @param {string}
 * @returns {string}
 */

export const getSimCardOptionTitle = text => {
  return text.includes('|') ? text.split(`|`)[0] : text;
};

export const getSimCardOptionPrice = text => {
  return text.includes('|') ? text.split(`|`)[1] : '';
};
