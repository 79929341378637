import { api } from '@detox/actions';
import setCookie from './set-cookie';
import { CART } from '../constants';
import { getBridgeData, setBridgeData } from './bridgeHelper';

const { getToken } = api.mcss.helpers;

/**
 * The ability to do cross-domain session storage is by having
 * control of both domains (both shop.singtel.com and www.singtel.com)
 *
 * Save the order as a cookie on the *.singtel.com domain and
 * www.singtel.com will read this cookie when the customer reaches
 * the SpringD page.
 *
 * When customer reaches SpringD, SpringD convert the cookie into
 * a session storage for UXF to understand.
 *
 * SpringD requires these session set:
 *
 * businesswidget/repository_l9_common/common/viewmodel/contextTopics/DeviceIdAndPlanIdInContextTopic
 * businesswidget/repository_l9_common/common/viewmodel/contextTopics/MiniCartInContextTopic
 * businesswidget/repository_l9_common/common/viewmodel/contextTopics/OrderInContextTopicViewModel
 *
 * @param {Object} productOrder
 * @param {Object} selectedPlan
 * @param {Object} selectedProduct
 * @param {Object} userInformation
 * @param {String} planOnly
 * @param {String} flowType
 * @param {String} cis
 * @param {String} silverComponent
 * @param {String} isPPOrder
 *
 */
export const saveNewOrder = options => {
  const {
    productOrder,
    selectedPlan,
    selectedProduct,
    userInformation,
    planOnly = false,
    flowType = 'deviceFirst',
    cis = {},
    silverComponent,
    isPPOrder = false,
    allocateOrReserveData,
    isStoreScenario
  } = options;

  const {
    sim,
    productOrderId,
    productOrderReferenceNumber,
    productOrderItemId,
    productId
  } = productOrder;
  const deviceId = selectedProduct && selectedProduct.productId;
  const { planId, planRelId, basePriceSchemaId } = selectedPlan;
  const isBackOrder = selectedProduct && selectedProduct.isBackOrder;
  const isPreOrder = selectedProduct && selectedProduct.isPreOrder;

  let contact, billingArragments;
  let customerId;
  let userData;
  if (userInformation && userInformation.clientContext) {
    userData = userInformation.clientContext;
    contact = userData.contact;
    billingArragments = userData?.billingArrangements?.[0];
    customerId = userData.customers.map(item => item.customerId);
  }

  let userDetails;
  if (userInformation && userInformation.userDetails) {
    userDetails = userInformation.userDetails;
  }

  // OrderInContextTopicViewModel
  let order;
  order = {
    // customerID: customerId,
    productOrderReferenceNumber: productOrderReferenceNumber,
    productOrderID: productOrderId,
    productOrderItemID: productOrderItemId,
    productID: productId,
    orderActions: [
      {
        productOrderItemID: productOrderItemId,
        productID: productId,
        level: 'S',
        lob: 'WS',
        planOnly: planOnly,
        flowType: flowType,
        orderType: 'Provide',
        isPPOrder
      }
    ],
    currentOrderActions: [
      {
        productOrderItemID: productOrderItemId,
        productID: productId,
        level: 'S',
        lob: 'WS',
        planOnly: planOnly,
        flowType: flowType,
        orderType: 'Provide',
        isPPOrder
      }
    ]
  };

  if (contact) {
    order.updatedOrderActions = [productOrderItemId];
    order.promoOrderActions = [productOrderItemId];
    order.barId = billingArragments?.barId;
  }

  setBridgeData('Detox_SD_OrderInContextTopicViewModel', JSON.stringify(order));

  // MiniCartInContextTopic
  const miniCart = {
    flowData: {
      deviceColor: selectedProduct?.colour,
      deviceSize: selectedProduct?.title + selectedProduct?.size,
      devicePrice: selectedProduct?.price,
      planName: selectedPlan?.planName
    },
    id: flowType
  };
  setBridgeData('Detox_SD_MiniCartInContextTopic', JSON.stringify(miniCart));

  //DeviceIdAndPlanIdInContextTopic
  const isCISCustomer = Object.keys(cis).length ? 'cis' : null;
  const device = {
    customerType: isCISCustomer,
    isIppPlan: isPPOrder,
    selectPayment: null,
    tempDeviceId: null,
    deviceId: deviceId,
    planId: planId,
    relationId: planRelId,
    numberType: 'WIRELESS',
    priceSchemaId: basePriceSchemaId,
    warehouseId: 'warehouseId',
    isBackOrder: isBackOrder,
    isPreOrder: isPreOrder,
    ...cis
  };

  if (contact && isStoreScenario) {
    device.isStoreScenario = true;
  }

  const allocationStatusArray = allocateOrReserveData?.allocationStatusX9 || [];

  if (
    contact &&
    (sim?.hasSim || (allocationStatusArray && allocationStatusArray.length > 0))
  ) {
    if (!isMobileShareOrSimOnly(flowType) || isPPOrder) {
      let allocationIDs = [];
      let deviceResDetails = [];
      allocationStatusArray.map(item => {
        allocationIDs.push(item.allocationIDX9);
        deviceResDetails.push({
          id: item.type,
          storeID: 'D2519', // storeID always D2519 for all eshop orders
          itemCodeX9: item.itemCodeX9,
          orderActionIDX9: productOrderItemId,
          orderStoreId: '0',
          quantity: '1',
          reservationId: item.allocationIDX9
        });
      });
      device.recontractBarId = billingArragments?.barId;
      device.allocationIDs = allocationIDs;
      device.deviceResDetails = deviceResDetails;
      device.warehouseId = 'warehouseId';
    }
  }

  if (isPPOrder) {
    device.installmentDuration = selectedProduct?.monthlyTerm;
    device.ippPlanName =
      selectedPlan?.planName + ' - $' + selectedPlan?.monthlyCharges + '/mth';
    setBridgeData('ippPlanId', selectedPlan?.mecId);
    setBridgeData('isIPPModalShown', true);
  }

  setBridgeData(
    'Detox_SD_DeviceIdAndPlanIdInContextTopic',
    JSON.stringify(device)
  );

  // OnePassTopicViewModel, ImplUserContextTopicViewModel, ContactInContextTopicViewModel
  if (contact) {
    setBridgeData(
      'Detox_SD_OnePassTopicViewModel',
      JSON.stringify({
        customerIdType: contact.indentType,
        billPayer: userDetails.billPayer,
        nric: contact.indentValue,
        email: contact.email
      })
    );

    setBridgeData(
      'Detox_SD_IndividualInContextTopicViewModel',
      JSON.stringify({
        dob: contact.dateOfBirth,
        vmStatus: 'dateOfBirthSet',
        personId: contact.contactId
      })
    );

    setBridgeData(
      'Detox_SD_ImplUserContextTopicViewModel',
      JSON.stringify({
        permissions: {},
        userInfo: {
          contactDetails: contact
        }
      })
    );
    setBridgeData(
      'Detox_SD_ContactInContextTopicViewModel',
      JSON.stringify({
        ID: contact.contactId
      })
    );

    setBridgeData(
      'Detox_SD_CustomerInContextTopicViewModel',
      JSON.stringify({
        ID: customerId[0],
        customerIds: customerId
      })
    );
  }

  if (silverComponent) {
    setBridgeData('Detox_Silver_Componnet', silverComponent);
  }

  setSessionToken();
  return { device, miniCart, order };
};

export const saveReconOrder = options => {
  const {
    auth,
    customerId,
    selectedService,
    selectedProduct,
    selectedPlan,
    productOrder,
    userInformation,
    cis = {},
    silverComponent,
    serviceId,
    flowType = 'deviceFirst',
    allocateOrReserveData,
    isStoreScenario,
    isPPOrder = false
  } = options;

  const {
    sim,
    productOrderId,
    productOrderReferenceNumber,
    productOrderItemId,
    productId
  } = productOrder;

  const deviceId = selectedProduct && selectedProduct.productId;
  const { planId, planRelId, basePriceSchemaId } = selectedPlan;
  const isBackOrder = selectedProduct && selectedProduct.isBackOrder;
  const isPreOrder = selectedProduct && selectedProduct.isPreOrder;

  let contact, billingArragments;
  let userData;
  if (userInformation && userInformation?.clientContext) {
    userData = userInformation.clientContext;
    contact = userData.contact;
    billingArragments = userData?.billingArrangements?.[0];
  }
  let userDetails;
  if (userInformation && userInformation?.userDetails) {
    userDetails = userInformation.userDetails;
  }
  let worryFree;
  if (userInformation && userInformation?.otpUser) {
    worryFree = auth.worryFree;
  }

  //Send customer type
  const isCISCustomer = Object.keys(cis).length ? 'cis' : null;

  const device = {
    oldOfferId: selectedService?.offeringId,
    customerType: isCISCustomer,
    isIppPlan: isPPOrder,
    deviceId: deviceId,
    planId: planId,
    relationId: planRelId,
    numberType: 'WIRELESS',
    priceSchemaId: basePriceSchemaId,
    reContractFlag: true,
    mobileReContractFlag: true,
    isBackOrder,
    isPreOrder,
    ...cis
  };

  if (isStoreScenario) {
    device.isStoreScenario = true;
  }

  const allocationStatusArray = allocateOrReserveData?.allocationStatusX9 || [];

  if (
    sim?.hasSim ||
    (allocationStatusArray && allocationStatusArray.length > 0)
  ) {
    if (!isMobileShareOrSimOnly(flowType) || isPPOrder) {
      let allocationIDs = [];
      let deviceResDetails = [];
      allocationStatusArray.map(item => {
        allocationIDs.push(item.allocationIDX9);
        deviceResDetails.push({
          id: item.type,
          storeID: 'D2519', // storeID always D2519 for all eshop orders
          itemCodeX9: item.itemCodeX9,
          orderActionIDX9: productOrderItemId,
          orderStoreId: '0',
          quantity: '1',
          reservationId: item.allocationIDX9
        });
      });
      device.recontractBarId = billingArragments?.barId;
      device.allocationIDs = allocationIDs;
      device.deviceResDetails = deviceResDetails;
      device.warehouseId = 'warehouseId';
    }
  }

  if (isPPOrder) {
    device.installmentDuration = selectedProduct?.monthlyTerm;
    device.ippPlanName =
      selectedPlan?.planName + ' - $' + selectedPlan?.monthlyCharges + '/mth';
    setBridgeData('ippPlanId', selectedPlan?.mecId);
    setBridgeData('isIPPModalShown', true);
  }

  setBridgeData(
    'Detox_SD_DeviceIdAndPlanIdInContextTopic',
    JSON.stringify(device)
  );

  const miniCart = {
    flowData: {
      planName: '',
      selectedNumber: worryFree?.mobileNumber || serviceId
    }
  };

  setBridgeData('Detox_SD_MiniCartInContextTopic', JSON.stringify(miniCart));

  const order = {
    orderActions: [
      {
        productOrderItemID: productOrderItemId,
        productID: productId,
        level: 'S',
        lob: 'WS',
        flowType: flowType,
        orderType: 'Change',
        isPPOrder
      }
    ],
    currentOrderActions: [
      {
        productOrderItemID: productOrderItemId,
        productID: productId,
        level: 'S',
        lob: 'WS',
        flowType: flowType,
        orderType: 'Change',
        isPPOrder
      }
    ],
    customerID: customerId,
    productOrderReferenceNumber: productOrderReferenceNumber,
    productOrderID: productOrderId,
    updatedOrderActions: [productOrderItemId],
    promoOrderActions: [productOrderItemId],
    barId: billingArragments?.barId
  };

  setBridgeData('Detox_SD_OrderInContextTopicViewModel', JSON.stringify(order));

  if (contact) {
    const implUserModel = {
      permissions: {},
      userInfo: {
        contactDetails: contact
      }
    };

    setBridgeData(
      'Detox_SD_ImplUserContextTopicViewModel',
      JSON.stringify(implUserModel)
    );

    setBridgeData(
      'Detox_SD_IndividualInContextTopicViewModel',
      JSON.stringify({
        dob: contact.dateOfBirth,
        vmStatus: 'dateOfBirthSet',
        personId: contact.contactId
      })
    );

    if (userData) {
      setBridgeData(
        'Detox_SD_OnePassTopicViewModel',
        JSON.stringify({
          customerIdType: contact.indentType,
          billPayer: userDetails.billPayer,
          nric: contact.indentValue,
          email: contact.email,
          isWorryFreeLogin: true
        })
      );
      setBridgeData(
        'Detox_SD_ContactInContextTopicViewModel',
        JSON.stringify({
          ID: contact.contactId
        })
      );
    }
  }

  if (worryFree) {
    setBridgeData(
      'Detox_SD_WorryFreeContextTopicViewModel',
      JSON.stringify({
        otpData: {
          contactId: worryFree.contactId,
          customerIds: worryFree.customerIds,
          faCheckMandatory: worryFree.facheckRequired,
          indentType: worryFree.indentType,
          indentValue: worryFree.indentValue,
          lob: worryFree.lob,
          mobileNumber: worryFree.mobileNumber,
          otp: worryFree.otp
        }
      })
    );
  }

  setSessionToken();

  if (silverComponent) {
    setBridgeData('Detox_Silver_Componnet', silverComponent);
  }
};

export const setSessionToken = () => {
  setCookie('Detox_LightsaberSessionToken', JSON.stringify(getToken()));
};

const isMobileShareOrSimOnly = flowType => {
  return (
    flowType === CART.FLOW_TYPES.SIM_ONLY_PLAN ||
    flowType === CART.FLOW_TYPES.MOBILE_SHARE_PLAN
  );
};

export default { saveNewOrder, saveReconOrder };
