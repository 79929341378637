import {
  CustomFilterItem,
  FilterGroup,
  FilterItem,
  IVariant
} from '@common-modules/product-catalogue';
import {
  AemFilterItem,
  FilterDataItem,
  FormattedProduct,
  GetFilterDataParams,
  GetProductListParams
} from '../types/productCatalogs.types';
import CATALOGUE from '../constants/catalogue';
import trans from '../hooks/useTranslation';
import { Plan } from '../types/plan.types';
import { KeyValue } from '../types/common.types';
import formatPrice from './formatPrice';
import {
  getFiltersFromQueryParams,
  getRibbon,
  sortByRankingAndTitle
} from './catalogue-helpers';
import { phoneTagsConfig } from '../config/phone-config';
import slugify from 'slugify';
import { PriceFilter, Product } from '../types/product.types';
import CONSTANTS from '../constants/common';
import CATALOG_CONSTANTS from '../constants/catalogue';
import { CisPlanRates } from '../types/user.types';
import { PRODUCT_CATALOG } from '@common-modules/product-catalogue';
import { SORT_TYPE } from '../constants/product';
import { setUrlParams, getUrlParams } from '@lux/helpers';
import {
  RRP_FALLBACK_URL,
  RRP_MAX_CART_ITEMS,
  RRP_MAX_CART_VALUE
} from '../config/rrp';
import { OrderItemDetail } from '@detox/actions';

const { t } = trans();
const urlParamToActualValueMapping = {};
export const setUrlParamsMapping = (
  aemFilterItemsArray: AemFilterItem[][]
): KeyValue => {
  aemFilterItemsArray.forEach(aemFilterItems => {
    aemFilterItems.forEach(filterItem => {
      urlParamToActualValueMapping[slugify(filterItem.text.toLowerCase())] =
        filterItem.value;
    });
  });
  return urlParamToActualValueMapping;
};
export const getFilterFromUrlParams = (
  queryParams: KeyValue = {},
  filterData: FilterGroup[]
): FilterGroup[] => {
  if (filterData?.length) {
    return filterData.map(filterGroup => {
      const paramsIndex = Object.keys(queryParams).indexOf(filterGroup.type);
      if (paramsIndex >= 0) {
        const valueFromUrl = (queryParams[filterGroup.type] as string)
          ?.toLowerCase()
          ?.split(',');
        filterGroup.items.forEach(item => {
          item.selected =
            item.selected ||
            valueFromUrl?.includes(slugify(item.name.toLowerCase()));
        });
      }
      return filterGroup;
    });
  }
  return Object.keys(queryParams).reduce((result: FilterGroup[], key) => {
    const filterValue = queryParams[key]?.toLowerCase() as string;
    const filterItems: FilterItem[] = filterValue.split(',').map(value => {
      return {
        selected: true,
        value: urlParamToActualValueMapping[value] || value,
        name: value
      };
    });
    result.push({
      type: key,
      id: key,
      items: filterItems
    } as FilterGroup);
    return result;
  }, []);
};

const formatPlanProduct = ({
  selectedPlan,
  products,
  simOnlyPlans
}: GetProductListParams): FormattedProduct[] => {
  const { mecId, selectedMonthlyTerm } = selectedPlan;
  const simsByMecId = simOnlyPlans.map(({ mecId }) => mecId);
  return products.map(product => {
    const filteredVariants = product.variants.filter(({ planId }) =>
      simsByMecId.includes(planId)
    );
    const matchingVariant = filteredVariants.find(
      ({ planId, monthlyTerm }) =>
        planId === mecId && monthlyTerm === selectedMonthlyTerm
    );
    const lowestSkuPrice = getLowestSkuPlanPrice(product, selectedPlan);
    const lowestPartialUpfrontPrice = Math.min(
      ...filteredVariants
        .filter(({ planId }) => planId === mecId)
        .map(({ partialUpfrontAmount }) => partialUpfrontAmount)
    );
    const priceText = formatPrice(lowestSkuPrice, 0, '');
    let customPrice;
    const ribbonForPlan = getRibbon(product.ribbon, selectedPlan);
    if (
      matchingVariant?.installmentPrice >= 0 &&
      (!lowestPartialUpfrontPrice || lowestPartialUpfrontPrice >= 0)
    ) {
      const installmentPriceText = t('INSTALLMENT_PRICE_TEXT', [
        formatPrice(matchingVariant?.installmentPrice),
        matchingVariant?.monthlyTerm
      ]);
      customPrice = installmentPriceText;
    }

    return {
      ...product,
      brandId: product.id,
      ribbon: ribbonForPlan?.title,
      heroImage: product.heroImage,
      deviceTitle: product.title,
      priceFrom: priceText,
      customPrice,
      lowestPrice: lowestSkuPrice
    };
  });
};

const formatRrpProduct = (products: FormattedProduct[]): FormattedProduct[] => {
  return products.map((product, index) => {
    const lowestPrice = getLowestRrpPrice(product);
    return {
      ...product,
      brandId: product.id,
      heroImage: product.heroImage,
      priceValue: lowestPrice?.upfront,
      deviceTitle: product.title,
      usualPrice:
        lowestPrice?.usualPrice && lowestPrice.usualPrice > lowestPrice.upfront
          ? formatPrice(lowestPrice?.usualPrice)
          : null,
      customPrice:
        lowestPrice &&
        `From ${
          lowestPrice.monthly
            ? `${formatPrice(lowestPrice.monthly)}/mth or`
            : ''
        } ${formatPrice(lowestPrice.upfront)}`
    };
  });
};

export const formatProducts = (
  productListParams: GetProductListParams
): FormattedProduct[] => {
  const { selectedPlan, products } = productListParams;
  if (!selectedPlan) {
    return formatRrpProduct(products);
  }
  return formatPlanProduct(productListParams);
};

export const getProductList = (
  params: GetProductListParams
): { filteredProduct: FormattedProduct[]; allProduct: FormattedProduct[] } => {
  const { queryParams, products, filterData, selectedPlan } = params;
  const filterDataFromUrlParams = getFilterFromUrlParams(
    queryParams,

    filterData
  );

  const formattedProducts = formatProducts({ ...params, products });
  const filteredProductByUrlParams = filterProduct({
    products: formattedProducts,
    filterData: filterDataFromUrlParams,
    selectedPlan
  });
  return {
    filteredProduct: filteredProductByUrlParams,
    allProduct: formatProducts({ ...params, getAllProduct: true })
  };
};

export const isProductHasTag = (
  product: FormattedProduct,
  tag: string
): boolean => {
  return product.tags.some(productTag =>
    productTag.toLowerCase().includes(tag.toLowerCase())
  );
};

export const getFilterValueByType = (
  filterData: FilterGroup[] = [],
  type: string
): string[] | CustomFilterItem => {
  const selectedGroup = filterData.find(
    filterGroup => filterGroup.type === type
  );
  if (!selectedGroup) return null;
  if (Array.isArray(selectedGroup.items)) {
    return (
      selectedGroup?.items?.filter(filterItem => filterItem.selected) || []
    ).map(item => item.value.toLowerCase());
  }
  return selectedGroup.items;
};

export const getSelectedFilters = (
  filterData: FilterGroup[]
): {
  planFilters: string[];
  categoryFilters: string[];
  brandFilters: string[];
  priceFilters: CustomFilterItem;
  dealFilters: string[];
  paymentOptionFilters: string[];
  priceRangeFilters: string[];
} => {
  return {
    planFilters: getFilterValueByType(
      filterData,
      CATALOGUE.FILTER_TYPES.PLAN
    ) as string[],
    categoryFilters: getFilterValueByType(
      filterData,
      CATALOGUE.FILTER_TYPES.CATEGORY
    ) as string[],
    brandFilters: getFilterValueByType(
      filterData,
      CATALOGUE.FILTER_TYPES.BRAND
    ) as string[],
    priceFilters: getFilterValueByType(
      filterData,
      CATALOGUE.FILTER_TYPES.PRICE
    ) as CustomFilterItem,
    dealFilters: getFilterValueByType(
      filterData,
      CATALOGUE.FILTER_TYPES.DEAL
    ) as string[],
    paymentOptionFilters: getFilterValueByType(
      filterData,
      CATALOGUE.FILTER_TYPES.PAYMENT_OPTION
    ) as string[],
    priceRangeFilters: getFilterValueByType(
      filterData,
      CATALOGUE.FILTER_TYPES.PRICE_RANGE
    ) as string[]
  };
};

const getFilteredProductsForFilterBar = ({
  groupType,
  selectedPlan,
  filterData,
  allProducts
}: {
  groupType: string;
  allProducts: FormattedProduct[];
  selectedPlan?: Plan;
  filterData: FilterGroup[];
}): FormattedProduct[] => {
  const filterOptions = {
    byPlan: true,
    byCategory: groupType !== CATALOGUE.FILTER_TYPES.CATEGORY,
    byDeal: groupType !== CATALOGUE.FILTER_TYPES.DEAL,
    byBrand: groupType !== CATALOGUE.FILTER_TYPES.BRAND,
    byPaymentOption: true,
    byPriceRange: groupType !== CATALOGUE.FILTER_TYPES.PRICE_RANGE
  };
  return filterProduct({
    products: allProducts,
    filterData,
    selectedPlan,
    options: filterOptions
  });
};

export const getFilterDataWithStatus = ({
  filterData,
  allProducts,
  selectedPlan
}: {
  filterData: FilterGroup[];
  allProducts: FormattedProduct[];
  selectedPlan?: Plan;
}): FilterGroup[] => {
  return filterData.map(filterGroup => {
    if (Array.isArray(filterGroup.items))
      filterGroup.items = filterGroup.items.map(filterItem => {
        switch (filterGroup.type) {
          case CATALOGUE.FILTER_TYPES.DEAL:
          case CATALOGUE.FILTER_TYPES.CATEGORY: {
            const filteredProducts = getFilteredProductsForFilterBar({
              selectedPlan,
              allProducts,
              filterData,
              groupType: filterGroup.type
            });
            const numberOfItem = filteredProducts.filter(product =>
              isProductHasTag(product, filterItem.value)
            ).length;
            const disabled = numberOfItem <= 0;
            return {
              ...filterItem,
              disabled,
              selected: filterItem.selected && !disabled,
              numberOfItem
            };
          }
          case CATALOGUE.FILTER_TYPES.PRICE_RANGE:
          case CATALOGUE.FILTER_TYPES.BRAND: {
            const isBrandFilter =
              filterGroup.type === CATALOGUE.FILTER_TYPES.BRAND;
            const filteredProducts = getFilteredProductsForFilterBar({
              selectedPlan,
              allProducts,
              filterData,
              groupType: filterGroup.type
            });
            const numberOfItem = isBrandFilter
              ? filteredProducts.filter(
                  product =>
                    product.brand?.toLowerCase() ===
                    filterItem.value?.toLowerCase()
                ).length
              : filteredProducts.filter(product => {
                  const priceRange = getPriceRangeValue(filterItem.value);
                  return (
                    product.priceValue >= priceRange.min &&
                    (!priceRange.max || product.priceValue <= priceRange.max)
                  );
                }).length;
            const disabled = numberOfItem <= 0;
            return {
              ...filterItem,
              disabled,
              selected: filterItem.selected && !disabled,
              numberOfItem
            };
          }
          default: {
            return filterItem;
          }
        }
      });

    return filterGroup;
  });
};

const getFilterValueFromUrlParam = (
  queryParams: KeyValue,
  filterType: string
): string[] => {
  return (queryParams[filterType] as string)?.toLowerCase()?.split(',') || [];
};
const flattenFilterData = (filterData: FilterGroup[]): FilterItem[] => {
  return filterData.reduce((result: FilterItem[], filterGroup) => {
    filterGroup.items.forEach(filterItem => {
      filterItem.groupType = filterGroup.type;
      result.push(filterItem);
    });
    return result;
  }, []);
};
const getMatchedCountForFilterItem = (
  products: FormattedProduct[] = [],
  filterData: FilterGroup[] = []
) => {
  const filterItems = flattenFilterData(filterData);
  for (let i = 0; i < products.length; i++) {
    const product = products[i];
    if (!defaultFilter(product)) continue;
    filterItems?.forEach(filterItem => {
      filterItem.numberOfItem = filterItem.numberOfItem || 0;
      switch (filterItem.groupType) {
        case CATALOGUE.FILTER_TYPES.CATEGORY:
        case CATALOGUE.FILTER_TYPES.DEAL: {
          if (isProductHasTag(product, filterItem.value)) {
            filterItem.numberOfItem++;
          }
          break;
        }
        case CATALOGUE.FILTER_TYPES.BRAND: {
          if (product.brand.toLowerCase() === filterItem.value.toLowerCase()) {
            filterItem.numberOfItem++;
          }
          break;
        }
        case CATALOGUE.FILTER_TYPES.PRICE_RANGE: {
          if (comparePriceRange(filterItem.value, product)) {
            filterItem.numberOfItem++;
          }
          break;
        }
        case CATALOGUE.FILTER_TYPES.PAYMENT_OPTION: {
          filterItem.numberOfItem = undefined;
          break;
        }
      }
    });
  }
};

const sortFilter = (filterItems: FilterDataItem[]) => {
  filterItems.sort((a, b) => (a.text > b.text ? 1 : -1));
};

export const getFilterData = (params: GetFilterDataParams): FilterGroup[] => {
  const {
    categories,
    brands,
    queryParams,
    ippOptions,
    hasPriceFilter,
    deals,
    rrpPaymentOptions,
    priceRanges,
    allProduct,
    hasMatchCount = false
  } = params || {};
  const ippUrlParams = getFilterValueFromUrlParam(
    queryParams,
    CATALOGUE.FILTER_TYPES.PLAN
  );
  const categoryUrlParams = getFilterValueFromUrlParam(
    queryParams,
    CATALOGUE.FILTER_TYPES.CATEGORY
  );
  const brandUrlParams = getFilterValueFromUrlParam(
    queryParams,
    CATALOGUE.FILTER_TYPES.BRAND
  );
  const dealUrlParams = getFilterValueFromUrlParam(
    queryParams,
    CATALOGUE.FILTER_TYPES.DEAL
  );

  const paymentOptionsUrlParams = getFilterValueFromUrlParam(
    queryParams,
    CATALOGUE.FILTER_TYPES.PAYMENT_OPTION
  );

  const priceRangeUrlParams = getFilterValueFromUrlParam(
    queryParams,
    CATALOGUE.FILTER_TYPES.PRICE_RANGE
  );

  const filterData = [];

  if (ippOptions?.length) {
    const planFilter: FilterGroup = {
      id: CATALOGUE.FILTER_TYPES.PLAN,
      type: CATALOGUE.FILTER_TYPES.PLAN,
      name: t('TEXT_IPP_GROUP') as string,
      items: ippOptions.map(ippOption => {
        return {
          name: ippOption.text,
          value: ippOption.value,
          selected: ippUrlParams.includes(
            slugify(ippOption.text?.toLowerCase())
          )
        };
      })
    };
    filterData.push(planFilter);
  }

  const getFilterGroup = (
    filterType: string,
    groupName: string,
    filterDataItems: FilterDataItem[],
    urlParams: string[],
    hasUrlPath = false
  ): FilterGroup => {
    sortFilter(filterDataItems);
    return {
      id: filterType,
      type: filterType,
      name: groupName,
      items: filterDataItems.map(filterDataItem => ({
        name: filterDataItem.text,
        value: filterDataItem.value.toLowerCase(),
        selected: hasUrlPath
          ? urlParams.includes(slugify(filterDataItem.value))
          : urlParams.includes(slugify(filterDataItem.text.toLowerCase())),
        urlPath: hasUrlPath && slugify(filterDataItem.value)
      }))
    };
  };

  if (categories?.length) {
    const categoryFilter = getFilterGroup(
      CATALOGUE.FILTER_TYPES.CATEGORY,
      t('CATEGORIES') as string,
      categories,
      categoryUrlParams
    );
    filterData.push(categoryFilter);
  }

  if (brands?.length) {
    const brandFilter = getFilterGroup(
      CATALOGUE.FILTER_TYPES.BRAND,
      t('BRANDS') as string,
      brands,
      brandUrlParams
    );
    filterData.push(brandFilter);
  }

  if (deals?.length) {
    const dealFilter = getFilterGroup(
      CATALOGUE.FILTER_TYPES.DEAL,
      t('DEALS') as string,
      deals,
      dealUrlParams
    );
    filterData.push(dealFilter);
  }
  if (priceRanges?.length) {
    const paymentOptionFilter = getFilterGroup(
      CATALOGUE.FILTER_TYPES.PRICE_RANGE,
      t('TEXT_PRICE_RANGE') as string,
      priceRanges,
      priceRangeUrlParams,
      true
    );
    filterData.push(paymentOptionFilter);
  }
  if (rrpPaymentOptions?.length) {
    const paymentOptionFilter = getFilterGroup(
      CATALOGUE.FILTER_TYPES.PAYMENT_OPTION,
      t('TEXT_PAYMENT_METHOD') as string,
      rrpPaymentOptions,
      paymentOptionsUrlParams,
      true
    );
    filterData.push(paymentOptionFilter);
  }

  if (hasPriceFilter) {
    filterData.push({
      type: PRODUCT_CATALOG.FILTER_TYPES.PRICE_RANGE,
      name: 'Price Range',
      id: 1,
      items: {
        minPrice: { text: 'Min Price', value: queryParams['minPrice'] || '' },
        maxPrice: { text: 'Max Price', value: queryParams['maxPrice'] || '' }
      }
    });
  }
  if (hasMatchCount) {
    getMatchedCountForFilterItem(allProduct, filterData);
  }

  return filterData;
};

export const getSimOnlyPlans = (plans: Plan[] = []) => {
  return plans.filter(
    plan =>
      plan.groupName.toUpperCase() ===
      CATALOGUE.PLANS_GROUP_NAME.SIM_ONLY_PLUS.toUpperCase()
  );
};

export const getPhonePlans = (
  plans: Plan[],
  cisRates?: CisPlanRates[]
): Plan[] => {
  const phonePlans = plans
    ?.filter(plan => plan.enabledOnPhoneCatalog)
    .map(plan => {
      return {
        ...plan,
        text: plan.planName,
        value: plan.mecId,
        selected: plan.selected
      };
    });

  if (cisRates?.length) {
    return phonePlans.reduce((result, plan) => {
      const matched = cisRates.find(
        cisPlan => cisPlan.planID === plan.basePriceSchemaId
      );
      if (matched) {
        result.push({
          ...plan,
          monthlyCharges: matched?.discountedPrice || plan.monthlyCharges
        });
      }

      return result;
    }, []);
  }

  return phonePlans;
};
export const getDefaultPhonePlan = (
  plans: Plan[],
  cisRates?: CisPlanRates[]
): Plan => {
  const phonePlanFilters = getPhonePlans(plans, cisRates);
  return phonePlanFilters.find(f => f.selected) || phonePlanFilters[0];
};

export const getSelectedPlan = ({
  plans,
  location,
  queryParams,
  fromQueryParams = true,
  cisRates,
  selectedPlan
}: {
  plans: Plan[];
  location: Location;
  queryParams?: KeyValue;
  fromQueryParams?: boolean;
  cisRates?: CisPlanRates[];
  selectedPlan?: Plan;
}) => {
  const phonePlanFilters = getPhonePlans(plans, cisRates);
  const defaultPlan = getDefaultPhonePlan(plans, cisRates);
  if (!phonePlanFilters.find(f => f.value === defaultPlan?.mecId)) {
    return defaultPlan;
  }

  if (selectedPlan) {
    return selectedPlan;
  }

  if (queryParams?.plan && fromQueryParams) {
    const queryParamFilters = getFiltersFromQueryParams(
      phonePlanFilters,
      getSimOnlyPlans(plans),
      location.search
    );
    if (queryParamFilters.plan) {
      return queryParamFilters.plan;
    }
  }
  return defaultPlan;
};

const getLowestSkuPlanPrice = (product: Product, plan: Plan) => {
  if (!product.prices) return;
  return Math.min(
    ...product?.prices
      ?.filter(price => price.planId === plan?.mecId)
      .map(plan => plan.publicPrice)
  );
};

const getLowestRrpPrice = (
  product: Product
): { upfront: number; monthly: number; usualPrice?: number } | null => {
  if (!product.variants?.length) return null;
  const isPriceHigher = (price: number, priceToCompare: number | string) => {
    return price <= 0 || (priceToCompare > 0 && price > priceToCompare);
  };
  return product.variants.reduce(
    (result, variant) => {
      if (isPriceHigher(result.upfront, variant.price)) {
        result.upfront = variant.price;
      }
      if (
        variant.monthlyTerm &&
        isPriceHigher(result.monthly, variant.installmentPrice)
      ) {
        result.monthly = variant.installmentPrice;
      }

      if (isPriceHigher(result.usualPrice, variant.usualPrice)) {
        result.usualPrice = variant.usualPrice;
      }
      return result;
    },
    { upfront: 0, monthly: 0, usualPrice: 0 }
  );
};

const getPriceRangeValue = (
  priceRange: string
): { min: number; max?: number } => {
  if (priceRange.includes('-')) {
    const minMaxArr = priceRange.split('-');
    return {
      min: Number(minMaxArr[0]),
      max: Number(minMaxArr[1])
    };
  }
  return {
    min: Number(priceRange)
  };
};

const comparePriceRange = (priceRange: string, product: FormattedProduct) => {
  const priceRangeValue = getPriceRangeValue(priceRange);
  return (
    product.priceValue >= priceRangeValue.min &&
    (!priceRangeValue.max || product.priceValue <= priceRangeValue.max)
  );
};

const defaultFilter = (product: FormattedProduct) => {
  return (
    !(product.isRoi || product.groupCampaignId) && product.variants?.length
  );
};

export const filterProduct = ({
  products,
  filterData,
  selectedPlan,
  options = {
    byBrand: true,
    byCategory: true,
    byPlan: true,
    byDeal: true,
    byPaymentOption: true,
    byPriceRange: true
  }
}: {
  products: FormattedProduct[];
  filterData: FilterGroup[];
  selectedPlan: Plan;
  options?: {
    byBrand: boolean;
    byCategory: boolean;
    byPlan: boolean;
    byDeal: boolean;
    byPaymentOption: boolean;
    byPriceRange: boolean;
  };
}): FormattedProduct[] => {
  const filterCriteria = getSelectedFilters(filterData);
  return products
    .filter(product => {
      const lowestSkuPrice = selectedPlan
        ? getLowestSkuPlanPrice(product, selectedPlan)
        : getLowestRrpPrice(product)?.upfront;
      const brandCondition =
        filterCriteria.brandFilters?.length && options.byBrand
          ? filterCriteria.brandFilters.includes(product.brand?.toLowerCase())
          : true;

      const categoryCondition =
        filterCriteria.categoryFilters?.length && options.byCategory
          ? !!filterCriteria.categoryFilters.find(category =>
              isProductHasTag(product, category)
            )
          : true;

      const instalmentPlansCon =
        filterCriteria.planFilters?.length && options.byPlan
          ? !!filterCriteria.planFilters.find(instalmentPlan =>
              isProductHasTag(product, instalmentPlan)
            )
          : true;
      const dealCondition =
        filterCriteria.dealFilters?.length && options.byDeal
          ? !!filterCriteria.dealFilters.find(
              deal => deal === 'all-deals' || isProductHasTag(product, deal)
            )
          : true;
      const paymentOptionCondition =
        filterCriteria.paymentOptionFilters?.length && options.byPaymentOption
          ? !!filterCriteria.paymentOptionFilters.find(paymentOption => {
              if (paymentOption === CATALOG_CONSTANTS.PAYMENT_TYPES.IPP) {
                return !!product.ipp;
              }
              return true;
            })
          : true;
      const priceRangeCondition =
        filterCriteria.priceRangeFilters?.length && options.byPriceRange
          ? !!filterCriteria.priceRangeFilters.find(priceRange => {
              return comparePriceRange(priceRange, product);
            })
          : true;

      const minPrice = filterCriteria.priceFilters?.minPrice?.value;
      const maxPrice = filterCriteria.priceFilters?.maxPrice?.value;
      const priceFilterCondition =
        minPrice && maxPrice
          ? product.priceValue >= minPrice && product.priceValue <= maxPrice
          : true;

      return (
        defaultFilter(product) &&
        brandCondition &&
        categoryCondition &&
        instalmentPlansCon &&
        isFinite(lowestSkuPrice) &&
        lowestSkuPrice >= 0 &&
        priceFilterCondition &&
        dealCondition &&
        paymentOptionCondition &&
        priceRangeCondition
      );
    })
    .sort(sortByRankingAndTitle);
};

export const checkIppChanged = (
  currentFilterData: FilterGroup[],
  newFilterData: FilterGroup[]
): { isIPPChanged: boolean; newValue: boolean } => {
  const currentSelectedFilters = getSelectedFilters(currentFilterData);
  const newSelectedFilters = getSelectedFilters(newFilterData);
  const oldValue = currentSelectedFilters.planFilters[0];
  const newValue = newSelectedFilters.planFilters[0];
  const isIPPChanged = oldValue !== newValue;
  return {
    isIPPChanged,
    newValue: !!newValue
  };
};

export const setSortUrlParams = (sortType: SORT_TYPE) => {
  const urlParams = getUrlParams();
  urlParams[CONSTANTS.URL_PARAMS.SORT_TYPE] = sortType;

  window.history.replaceState(
    null,
    null,
    setUrlParams(window.location.pathname, urlParams)
  );
};

export const updateUrlParamFromFilter = (
  filterData: FilterGroup[],
  selectedPlan?: Plan,
  queryParams?: KeyValue,
  ignoreParams: string[] = []
) => {
  if (!filterData.length) return;
  const urlParams = selectedPlan
    ? [
        `${CONSTANTS.URL_PARAMS.PLAN}=${slugify(
          selectedPlan?.planName.toLowerCase()
        )}`
      ]
    : [];
  filterData?.forEach(filterGroup => {
    if (Array.isArray(filterGroup.items)) {
      const selectedFilters = filterGroup.items.filter(
        filterItem => filterItem.selected
      );
      if (selectedFilters.length > 0) {
        urlParams.push(
          `${filterGroup.type}=${selectedFilters
            .map(item => item.urlPath || slugify(item.name.toLowerCase()))
            .join(',')}`
        );
      }
    }
  });
  Object.keys(queryParams).forEach(key => {
    if (
      ![
        CATALOGUE.FILTER_TYPES.CATEGORY,
        CATALOGUE.FILTER_TYPES.PLAN,
        CATALOGUE.FILTER_TYPES.BRAND,
        CONSTANTS.URL_PARAMS.PLAN,
        CONSTANTS.URL_PARAMS.DEAL,
        CONSTANTS.URL_PARAMS.PAYMENT_OPTION,
        CONSTANTS.URL_PARAMS.PRICE_RANGE,
        ...ignoreParams
      ].includes(key)
    ) {
      urlParams.push(`${key}=${queryParams[key]}`);
    }
  });
  window.history.replaceState(null, null, `?${urlParams.join('&')}`);
};

export const getMaxItemLimit = ({
  currentVariant,
  itemsInCart,
  maxAllowedQuantityForCurrentProduct
}: {
  currentVariant: IVariant;
  itemsInCart: OrderItemDetail[];
  maxAllowedQuantityForCurrentProduct: number;
}): number => {
  if (!currentVariant) return 0;
  const sameItemInCartCount = getSameItemInCart({
    itemsInCart,
    currentVariant
  });
  return (
    Math.min(
      RRP_MAX_CART_ITEMS - itemsInCart.length,
      currentVariant.stockCount - sameItemInCartCount,
      maxAllowedQuantityForCurrentProduct - sameItemInCartCount
    ) || 0
  );
};

const getSameItemInCart = ({
  itemsInCart,
  currentVariant
}: {
  currentVariant?: IVariant;
  itemsInCart: OrderItemDetail[];
}) => {
  return (
    itemsInCart.filter(
      item =>
        item.sku === currentVariant.sku ||
        item.productDetails?.title?.includes(currentVariant.deviceTitle)
    )?.length || 0
  );
};

export const validateCart = ({
  currentVariant,
  numberOfItemToAdd,
  itemsInCart,
  maxAllowedQuantityForCurrentProduct
}: {
  currentVariant: IVariant;
  numberOfItemToAdd: number;
  itemsInCart: OrderItemDetail[];
  maxAllowedQuantityForCurrentProduct: number;
}): { isValid: boolean; message?: string; title?: string; buttonText } => {
  const sameItemInCartCount = getSameItemInCart({
    itemsInCart,
    currentVariant
  });

  const totalAmount = itemsInCart.reduce((result, item) => {
    result += Number(item.productDetails.price);
    return result;
  }, currentVariant.price * numberOfItemToAdd);
  const stockCheck =
    sameItemInCartCount + numberOfItemToAdd <= currentVariant.stockCount;
  const valueCheck = totalAmount <= RRP_MAX_CART_VALUE;
  const globalLimitCheck =
    itemsInCart.length + numberOfItemToAdd <= RRP_MAX_CART_ITEMS;
  const skuLimitCheck =
    sameItemInCartCount + numberOfItemToAdd <=
    maxAllowedQuantityForCurrentProduct;
  let title,
    message = '';
  let buttonText = t('SELECT_OTHER_ITEM_TEXT');
  switch (true) {
    case !stockCheck: {
      title = t('RRP_OUT_OF_STOCK_TITLE');
      message = t('RRP_OUT_OF_STOCK_MESSAGE');
      break;
    }
    default: {
      title = t('RRP_CART_FULL_TITLE');
      message = t('RRP_CART_FULL_MESSAGE');
      buttonText = t('ADJUST_ITEM_TEXT');
    }
  }
  const isValid = valueCheck && globalLimitCheck && stockCheck && skuLimitCheck;
  return {
    isValid,
    title,
    message,
    buttonText
  };
};

export const getCatalogHeader = (filterData: FilterGroup[] = []): string => {
  const headerFilterTypes = [
    CATALOGUE.FILTER_TYPES.CATEGORY,
    CATALOGUE.FILTER_TYPES.BRAND
  ];
  const selectedFilters: FilterItem[] = [];
  for (let i = 0; i < filterData.length; i++) {
    if (selectedFilters.length > 1) {
      break;
    }
    const filterGroup = filterData[i];
    for (let j = 0; j < filterGroup.items.length; j++) {
      const filterItem = filterGroup.items[j] as FilterItem;
      if (filterItem.selected && headerFilterTypes.includes(filterGroup.id)) {
        selectedFilters.push(filterItem);
        if (selectedFilters.length > 1) {
          break;
        }
      }
    }
  }
  if (selectedFilters.length === 1) {
    return selectedFilters[0].name;
  }

  return t('BROWSE_ALL_PRODUCT_TEXT');
};

export const navigateToSDRRP = () => {
  window.open(RRP_FALLBACK_URL, '_self');
};
