import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { getUrlParams, setUrlParams } from '@lux/helpers';
import { withTheme } from '@dls/web';

const SCIFrame = withTheme(styled.iframe`
  ${({ coreTheme }) => css`
    margin: 0;
    width: 100%;
    height: 511px;
    border: 0;
    overflow: hidden;
    @media (min-width: ${coreTheme.brk_sm}) {
      height: 600px;
    }
    @media (min-width: ${coreTheme.brk_md}) {
      height: 650px;
    }
    @media (min-width: ${coreTheme.brk_lg}) {
      height: 700px;
    }
  `}
`);

interface Props {
  /** Host URL for OnePass */
  hostUrl: string;
  /** Base login URL for OnePass */
  loginUrl: string;
  /** URL to redirect to */
  referenceUrl: string;
  /** Title on the iFrame */
  title?: string;
}

const OnePassLogin: React.FC<Props> = ({
  hostUrl,
  loginUrl,
  referenceUrl,
  title = 'ssoLogin'
}) => {
  if (!loginUrl) {
    console.error('OnePassLogin: Invalid loginUrl prop.');
  }

  const [iFrameSrc, setIFrameSrc] = useState('');

  useEffect(() => {
    const { loginstatus: loginStatus, ...params } = getUrlParams();

    const currentLocationHref = setUrlParams(
      window.location.origin + window.location.pathname,
      params
    );

    const targetUrl = referenceUrl
      ? window.location.origin + referenceUrl
      : currentLocationHref;

    // A set constant url we set as OnePass success URL
    // This page is the one we set as success URL for one pass
    // and does the redirection back to our current location
    const successUrlWithParams = setUrlParams(
      `${hostUrl}/apps/springd/onepasssuccess`,
      {
        target: targetUrl,
        origin: currentLocationHref
      }
    );

    const updatedUrlParams = {
      success_callback_url: successUrlWithParams,
      origin: currentLocationHref,
      view: 'shop'
    };

    if (loginStatus) {
      // If the parent has ?loginstatus=fail then we redirected back
      // here with a failed login status and so when we pop this
      // modal back up, we need to let OnePass show the error message
      updatedUrlParams['loginstatus'] = loginStatus;
    }

    // The base OnePass login page with the encoded success URL
    const loginUrlWithParams = setUrlParams(loginUrl, updatedUrlParams);

    setIFrameSrc(loginUrlWithParams);
  }, [hostUrl, loginUrl, referenceUrl]);

  return (
    <SCIFrame
      data-testid="one-pass-iframe"
      title={title}
      src={iFrameSrc}
      scrolling="no"
      frameBorder="0"
    />
  );
};

export default OnePassLogin;
