import convert from 'htmr/lib/index';
import React from 'react';

// List of custom components to transform to
import Anchor from '../components/Anchor';
import { BodyPrimary } from '../components/Base';

// Converts a HTML string into a ReactComponent while transforming
// any particular HTML tag we are interested in, for example
// H1-H6 -> Heading component
// And also block particular HTML tags from being rendered
// Avoids using dangerouslySetInnerHTML
// Comes useful when another system like a CMS is creating the HTML
// to be placed within one of our components

export default function(htmlString: string) {
  return convert(htmlString, {
    dangerouslySetChildren: [],
    transform: {
      ...htmlTagsTransforms
    }
  });
}

const htmlTagsTransforms = {
  a: Anchor,
  p: BodyPrimary,
  // eslint-disable-next-line react/display-name
  _: (node, props, children) => {
    // Do not render script tags!
    if (node === 'script') {
      return null;
    }

    // Do not render style tags!
    if (node === 'style') {
      return undefined;
    }

    // defaults to 'return node' for text node / string
    // and return React.createElement(node, props, children) for HTML node
    if (typeof props === 'undefined') {
      return node;
    }

    return React.createElement(node, props, children);
  }
};
