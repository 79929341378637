import React from 'react';
import PropTypes from 'prop-types';
import { noop } from '@lux/helpers';

import ServiceListItem from '../ServiceListItem';
import Spacing from '../Spacing';

import notifications from '../../config/notifications.js';

import { api } from '@detox/actions';

import createDescription from '../../helpers/create-description';
import { isTagged } from '../../helpers/common';
import { planTagsConfig } from '../../config/plan-group-config';
import { CI_ELIGIBLE_MONTHS } from '../../config';

const { isServiceSuspended, isEligibleForRecontract } = api.mcss.helpers;
const { isCIEligibleForRecontract } = api.mcss.helpersService;

const ServiceList = ({
  selectedPlan,
  plans = [],
  selectedProduct,
  services = [],
  ctaText,
  onClick = noop
}) => {
  const { dataLayer, location } = window;

  const variantObj = dataLayer.find(event => event.page === location.pathname);
  const recontractCtaVariant = variantObj && variantObj.RECONTRACT_CTA;

  let buttonText = ctaText;
  if (recontractCtaVariant === 'B') {
    buttonText = 'Next';
  }

  return Object.keys(services)
    .map(key => services[key])
    .map(service => {
      let isCIEligible = true,
        message,
        planDescription;

      const contractPlan = plans.find(
        plan => plan.basePriceSchemaId === service.plan?.catalogItemID
      );
      const isSimOnlyPlan = isTagged(
        contractPlan?.tags,
        planTagsConfig['sim-only'].tagName
      );

      if (contractPlan && !isSimOnlyPlan) {
        planDescription = createDescription({
          data: contractPlan.data,
          talktime: contractPlan.talktime,
          sms: contractPlan.sms
        });
      }

      const hasDevice = Boolean(selectedProduct);

      /**
       *  Removing all eligibility check for sim only plans to allow them to recontract
       */
      if (hasDevice) {
        isCIEligible = isCIEligibleForRecontract(service, {
          contractEligibleMonths: CI_ELIGIBLE_MONTHS
        });

        if (!isCIEligible) {
          message = notifications.ELIGIBILITY_NOT_CI_ELIGIBLE.text;
        }
      }

      // A generic check for alone re-contract eligibility.
      const isEligible = isEligibleForRecontract(service);

      // Block customers with paid orders but are pending for clearing
      const hasPaidUnclearedOrder =
        service.errorMsg === 'ELIGIBILITY_HAS_PENDING_PAYMENT_ORDER';

      if (isServiceSuspended(service)) {
        message = notifications.ELIGIBILITY_SUSPENDED.text;
      } else if (!isEligible) {
        message = notifications.ELIGIBILITY_HAS_PENDING_ORDER.text;
      } else if (service.errorMsg) {
        message =
          notifications[service.errorMsg] &&
          notifications[service.errorMsg].text;
      }

      return (
        <Spacing key={service.id} bottom={1} bottomMd={2}>
          <ServiceListItem
            eligible={
              isEligible &&
              !service.disabled &&
              isCIEligible &&
              !hasPaidUnclearedOrder
            }
            message={message}
            planName={service.offeringName}
            planDescription={planDescription}
            serviceNumber={service.serviceId}
            onClick={() => onClick(service)}
            buttonText={buttonText}
          />
        </Spacing>
      );
    });
};

ServiceList.propTypes = {
  /** On Recontract click handler */
  onClick: PropTypes.func,
  /** selected Plan */
  selectedPlan: PropTypes.object,
  /** Set the array of plans */
  plans: PropTypes.array,
  selectedProduct: PropTypes.object,
  /** List of services */
  services: PropTypes.arrayOf(PropTypes.object),
  ctaText: PropTypes.string
};

export default ServiceList;
