import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import '@dls/web/fonts.css';
import Header from '../Header';
import { ThemeProvider, withTheme } from '@dls/web';
import Footer from '../Footer/Footer';
import { web } from '@dls/themes';
import LivePerson from '../LivePerson';
import MaintenanceMessage from '../MaintenanceMessage';
import ThemeProviderWrapper from '../ThemeProviderWrapper';
import rrpHeadersPages from '../../constants/rrpHeadersPages';
import './layout.css';

import { shoppingCartUrl } from '../../config/links';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { FULLSTORY_ENABLE } from '../../types/featureFlag.types';
import FullStory from 'react-fullstory';
import useSessionTimer from '../../hooks/useSessionTimer';
import SessionWarningModal from '../SessionWarningModal';
import theme from '../../theme';
import { isRRPPage } from '../../helpers/common';
import { navigation } from '../../middlewares/navigation-constants';
import { isCartHavingIppItem } from '../../helpers/rrpCart';

const Site = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const Container = withTheme(styled.div`
  ${({ coreTheme }) => css`
    width: 100%;
    flex-grow: 1;
    background-color: ${theme.colours.page_background_pri};
    color: ${theme.colours.page_text_colour_pri};
    margin: 0 auto;
  `}
`);

const Layout = ({ children, location }) => {
  const dlsThemeToApply = process.env.GATSBY_DLS_THEME; //theme set in bamboo
  let dlsTheme = web.dlsStandard1; //default theme
  if (dlsThemeToApply && web?.[dlsThemeToApply]) {
    dlsTheme = web?.[dlsThemeToApply];
  }
  const { cisInformation, rrpCart, productCatalog } = useSelector(state => {
    return {
      cisInformation: state.user?.cis?.information,
      rrpCart: state.rrpCart,
      productCatalog: state.productCatalog
    };
  });
  const isCisFlow = Boolean(cisInformation?.rates);

  const {
    popUpFlag,
    onCloseSessionWarning,
    onExtendSessionWarning
  } = useSessionTimer(location);

  const getShoppingCartUrl = () => {
    const isRrpPage = isRRPPage(location);
    const isRrpIppPage = isRRPPage(location, true);
    if (isRrpIppPage || (isRrpPage && isCartHavingIppItem(rrpCart))) {
      return `/${navigation.RRP_IPP_SHOPPING_CART}`;
    }

    if (productCatalog?.rrpFlow || isRrpPage) {
      return `/${navigation.RRP_SHOPPING_CART}`;
    }
    return '/';
  };
  return (
    <ThemeProvider theme={dlsTheme}>
      <ThemeProviderWrapper>
        <Site>
          <MaintenanceMessage location={location} />
          <Header
            baseUrl={process.env.GATSBY_AEM_URL}
            shoppingCartUrl={getShoppingCartUrl()}
            isRrpHeader={isRRPPage(location)}
          />
          {isFeatureFlagEnabled(FULLSTORY_ENABLE) && (
            <FullStory org={process.env.GATSBY_FULLSTORY_ORG_ID} />
          )}
          <Container data-testid="container" isCisFlow={isCisFlow}>
            {children}
          </Container>
          <Footer />
          <LivePerson
            lpDivId="lpButtonDiv"
            lpEngagementId={process.env.GATSBY_LIVEPERSON_ENGAGEMENT_ID}
            lpSection={process.env.GATSBY_LIVEPERSON_SECTION}
            lpSiteId={process.env.GATSBY_LIVEPERSON_SITE_ID}
          />
          {popUpFlag && (
            <SessionWarningModal
              showModal={popUpFlag}
              closeSessionWarning={onCloseSessionWarning}
              extendSessionWarning={onExtendSessionWarning}
            />
          )}
        </Site>
      </ThemeProviderWrapper>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
