import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { RRPCART, SD_NAVIGATE_MAPPING } from '../../constants';
import setCookie from '../../helpers/set-cookie';
import { setSessionToken } from '../../helpers/save-order-in-browser-session';
import { springDBridgeUrl } from '../../config/links';
import { rrpModal } from '../../config/notifications';

import { ShoppingCart as RRPShoppingCartModule } from '@common-modules/shopping-cart';
import RRPModal from './RRPModal';
import LoadingOverlayComponent from '../LoadingOverlay';
import { ACTION_TYPES } from '../../constants/actions';
import CONSTANT from '../../constants/common';
import { useDispatch } from 'react-redux';

import {
  getShoppingCartDataModel,
  getProductOrderItem,
  flattenRRPProductsList,
  getCatalogPath
} from '../../helpers/rrpCart';
import { dataLayerPush, flattenNodes } from '../../helpers/common';
import { trans as t } from '../../helpers/localisation';

import { rrpCartActions, cartActions } from '@detox/actions';

import GenericError from '../ShoppingCart/GenericError';
import { getCartErrorDataModel } from '../../helpers/cart';
import { useRrpStockCheck } from '../../hooks/useStockCheck';
import { navigation } from '../../middlewares/navigation-constants';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { FEATURE_FLAG_ENABLE_RRP } from '../../types/featureFlag.types';
import { rrpCheckoutDataLayer } from '../../helpers/dataLayerHelpers';

export const RRPShoppingCart = ({
  data,
  rrpOrder,
  bridgeData,
  addedItems,
  removedItems,
  rrpCart,
  retrieveRrpMasterOrderDetails,
  rrpAddMasterOrderItems,
  rrpRemoveMasterOrderItems,
  getShoppingCartData,
  mtposStock,
  rrpCancelMasterOrderItems,
  updateRrpMasterOrderID
}) => {
  const dispatch = useDispatch();
  const allRrpCart = flattenNodes(data.allRrpCart);
  const allRrpProduct = flattenNodes(data.allRrpProduct);
  const [rrpMasterOrderId, setRrpMasterOrderId] = useState('');
  const quantityLimit = allRrpCart?.find(obj => obj.type === 'quantity');
  const amountLimit = allRrpCart?.find(obj => obj.type === 'amount');
  const isRRPEnabled = isFeatureFlagEnabled(FEATURE_FLAG_ENABLE_RRP);
  const isQuantityExceeded = useMemo(() => {
    const totalQuantity = rrpOrder?.ImplRRPOrderDetails?.reduce(
      (acc, orderDetails) => {
        const { orderItems } = orderDetails;
        orderItems.forEach(orderItem => {
          acc += orderItem.quantity;
        });
        return acc;
      },
      0
    );

    return totalQuantity >= quantityLimit?.maxLimit;
  }, [rrpOrder, quantityLimit]);
  const isAmountExceeded =
    rrpOrder?.actualPrice?.afterTax > amountLimit?.maxLimit;
  const canIncreaseQuantity = !isAmountExceeded && !isQuantityExceeded;

  const variantBySku = useMemo(() => {
    return allRrpProduct.reduce((acc, cur) => {
      cur.variants.forEach(variant => {
        const { sku, ...data } = variant;
        if (!(sku in acc)) {
          acc[sku] = {
            ...data,
            productId: cur.id,
            maxAllowedQuantity: cur.maxAllowedQuantity
          };
        }
      });
      return acc;
    }, {});
  }, [allRrpProduct]);

  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [shoppingCartDataModel, setShoppingCartDataModel] = useState(null);

  const [mounted, setMounted] = useState(false);
  const [rrpProductList, setRrpProductList] = useState();

  const [isAddRemoveItemsError, setIsAddRemoveItemsError] = useState(false);

  useRrpStockCheck(mounted, rrpOrder);

  const getMasterOrderDetails = async rrpMasterOrderIdFromCache => {
    setRrpMasterOrderId(rrpMasterOrderIdFromCache);
    await retrieveRrpMasterOrderDetails({
      rrpMasterOrderId: rrpMasterOrderIdFromCache
    });
    setMounted(true);
    updateRrpMasterOrderID({
      rrpMasterOrderId: rrpMasterOrderIdFromCache
    });
  };

  useEffect(() => {
    if (
      bridgeData?.rrpMasterOrderId ||
      (isRRPEnabled && rrpCart?.masterOrderId)
    ) {
      const rrpMasterOrderId = isRRPEnabled
        ? rrpCart?.masterOrderId
        : bridgeData.rrpMasterOrderId;
      getMasterOrderDetails(rrpMasterOrderId);
    } else {
      navigate('/empty-shopping-cart', {
        state: {
          shopUrl: getCatalogPath()
        }
      });
    }
  }, [bridgeData]);

  useEffect(() => {
    if (mtposStock && rrpOrder) {
      const modalData = rrpOrder;

      const { itemQuantity, ...dataModal } = getShoppingCartDataModel(
        modalData,
        mtposStock,
        variantBySku,
        canIncreaseQuantity
      );
      setShoppingCartDataModel(dataModal);
    }
  }, [mtposStock, canIncreaseQuantity]);

  useEffect(() => {
    const addItemErrors = addedItems?.filter(item => {
      if (
        item.mtposReservationId ===
          RRPCART.ORDER_FAILURE_STATUS.MTPOS_RESERVATION_ID &&
        item.orderItemStatus === RRPCART.ORDER_FAILURE_STATUS.ORDER_ITEM_STATUS
      ) {
        return item;
      }
    });
    const removeItemErrors = removedItems?.orderItemsWithRemoveResults.filter(
      item => {
        if (!item.isDeleted) {
          return item;
        }
      }
    );
    setIsAddRemoveItemsError(
      Boolean(addItemErrors?.length) || Boolean(removeItemErrors?.length)
    );
  }, [addedItems, removedItems]);

  useEffect(() => {
    if (rrpOrder?.ImplRRPOrderDetails) {
      const orderItems = flattenRRPProductsList(rrpOrder.ImplRRPOrderDetails);
      setRrpProductList(orderItems);
    }
    dispatch({ type: ACTION_TYPES.RRP.SET_RRP_FLOW });
  }, [rrpOrder?.ImplRRPOrderDetails]);

  const handleChangeQuantity = (value, item) => {
    const isIncreasing = value > item.quantity.value;

    const orderItem = getProductOrderItem(rrpProductList, item.sku);

    if (isIncreasing) {
      rrpAddMasterOrderItems({
        rrpMasterOrderId,
        orderItemDetails: [
          {
            sku: orderItem.sku,
            identifier: orderItem.identifier,
            productDetails: orderItem.productDetails
          }
        ]
      });
    } else {
      rrpRemoveMasterOrderItems({
        rrpMasterOrderId,
        orderId: orderItem.orderId,
        orderItemDetails: [{ orderItemId: orderItem.orderItemIds[0] }]
      });
    }
  };

  const handleRemoveAllItems = selectedOrderItem => {
    const orderItem = getProductOrderItem(
      rrpProductList,
      selectedOrderItem.sku
    );
    const orderItemDetails = orderItem.orderItemIds.map(id => ({
      orderItemId: id
    }));
    rrpRemoveMasterOrderItems({
      rrpMasterOrderId,
      orderId: orderItem.orderId,
      orderItemDetails,
      cancelOrder: orderItem.isOnlyProductInOrder
    });
  };

  const handleCheckout = () => {
    if (isAmountExceeded) {
      setOpenModal(true);
      setModalType(RRPCART.MODAL_TYPE.EXCEED_AMOUNT);
    } else {
      dataLayerPush(
        rrpCheckoutDataLayer({
          baseParams: {
            cartItems: rrpCart?.addedItems,
            location: window.location
          }
        })
      );
      navigate(`/${navigation.CUSTOMER_REGISTRATION}`);
    }
  };

  const handleLink = () => {
    if (isRRPEnabled) {
      return navigate(`/${navigation.RRP_PRODUCT_CATALOG}`);
    }
    const rrpLatestOrder =
      rrpOrder?.ImplRRPOrderDetails?.[rrpOrder.ImplRRPOrderDetails.length - 1];
    const rrpDetoxToSDData = {
      rrpOrderId: rrpLatestOrder?.orderId,
      rrpMasterOrderId: rrpMasterOrderId,
      lastDeviceReservationTime: rrpLatestOrder.sysCreationDate
    };
    setSessionToken();
    setCookie(CONSTANT.RrpData, JSON.stringify(rrpDetoxToSDData));
    const navigationLink = `${springDBridgeUrl}?page=${SD_NAVIGATE_MAPPING.rrpProductCatalogue}`;
    return window.location.assign(navigationLink);
  };

  const handleEmptyCart = () => {
    setOpenModal(true);
    setModalType(RRPCART.MODAL_TYPE.EMPTY_CART);
  };

  const handleConfirmEmptyCart = async () => {
    setOpenModal(false);
    await rrpCancelMasterOrderItems({
      rrpMasterOrderId
    });
  };

  const renderModal = type => {
    switch (type) {
      case RRPCART.MODAL_TYPE.EMPTY_CART:
        return (
          <RRPModal
            header={rrpModal?.[type]?.title}
            message={rrpModal?.[type]?.body}
            ctaText={rrpModal?.[type]?.ctaText}
            visible={openModal}
            onClose={() => setOpenModal(false)}
            onButtonClick={() => handleConfirmEmptyCart()}
          />
        );

      case RRPCART.MODAL_TYPE.SESSION_TIMEOUT:
        return (
          <RRPModal
            header={rrpModal?.[type]?.title}
            message={rrpModal?.[type]?.body}
            ctaText={rrpModal?.[type]?.ctaText}
            visible={openModal}
            onClose={() => setOpenModal(false)}
          />
        );

      case RRPCART.MODAL_TYPE.SESSION_EXPIRED:
        return (
          <RRPModal
            header={rrpModal?.[type]?.title}
            message={rrpModal?.[type]?.body}
            ctaText={rrpModal?.[type]?.ctaText}
            visible={openModal}
            onClose={() => setOpenModal(false)}
            secondary={true}
          />
        );

      case RRPCART.MODAL_TYPE.EXCEED_AMOUNT:
        return (
          <RRPModal
            header={amountLimit.popupTitle}
            visible={openModal}
            message={amountLimit.popupDescription}
            onClose={() => setOpenModal(false)}
            onButtonClick={() => setOpenModal(false)}
            ctaText={amountLimit.popupCTALabel}
            secondary={true}
          />
        );

      default:
        return null;
    }
  };

  const renderCartErrors = () => {
    //TODO:this eeror details will get from AEM
    const cartError = {
      title: 'Sorry, something went wrong',
      description:
        'It seems like we have encountered an error and are unable to proceed. Please try again.'
    };
    const cartErrorDataModel = getCartErrorDataModel(cartError, null);

    return (
      <GenericError
        title={cartErrorDataModel.title}
        errorText={cartErrorDataModel.errorText}
        secondaryButtonText={t('DROP_SHIPPING.BACK_TO_PRODUCT_CATALOGUE.CTA')}
        onSecondaryClick={() => navigate(getCatalogPath())}
      />
    );
  };
  if (shoppingCartDataModel && !rrpOrder?.ImplRRPOrderDetails?.length) {
    navigate('/empty-shopping-cart', {
      state: {
        shopUrl: getCatalogPath()
      }
    });
    return null;
  } else if (rrpCart?.error) {
    return renderCartErrors();
  } else {
    return (
      <>
        {rrpCart?.loading && <LoadingOverlayComponent />}
        <div data-testid="rrp-shopping-cart-module">
          {rrpMasterOrderId && shoppingCartDataModel && (
            <RRPShoppingCartModule
              data={shoppingCartDataModel}
              callbacks={{
                onChangeQuantity: handleChangeQuantity,
                onChangePhone: () => console.log('changephone'),
                onChangePlan: () => console.log('changeplan'),
                onChangeNumber: () => console.log('changenumber'),
                onAddItem: null,
                onRemoveItem: handleRemoveAllItems,
                onViewAvailableProduct: () =>
                  console.log('navigate to ViewAvailableProduct'),
                onCheckout: handleCheckout,
                onLink: handleLink,
                onEmptyCart: handleEmptyCart
              }}
              configs={{
                type:
                  'rrp' in shoppingCartDataModel ? 'RRP_FULLPRICE' : 'RRP_IPP',
                loading: false,
                deviceOutOfStock: false,
                showTradeIn: false,
                showVoucher: false,
                alert: {
                  visible: isQuantityExceeded && !isAmountExceeded
                },
                cartError: isAmountExceeded,
                showOutOfStock: false
              }}
              localisation={{
                SUBTOTAL: t('RRP_BAG_TOTAL'),
                TRADE_IN_MESSAGE: null,
                VOUCHER_MESSAGE: null,
                NOTIFY_MESSAGE: null,
                ALERT_MESSAGE: quantityLimit.description,
                ERROR_MESSAGE: amountLimit.description
              }}
            />
          )}
        </div>
        {renderModal(modalType)}
      </>
    );
  }
};

/* istanbul ignore next */
const mapStateToProps = state => {
  const { rrpCart, fulfillment, bridge } = state;
  return {
    rrpOrder: rrpCart?.rrpOrder,
    addedItems: rrpCart?.addedItems,
    removedItems: rrpCart?.removedItems,
    rrpCart,
    mtposStock: fulfillment?.mtposStock.data,
    bridgeData: bridge?.data
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  const {
    retrieveRrpMasterOrderDetails,
    rrpAddMasterOrderItems,
    rrpRemoveMasterOrderItems,
    rrpCancelMasterOrderItems
  } = rrpCartActions;
  const { getShoppingCartData } = cartActions;
  return {
    updateRrpShoppingCartData: mockData =>
      dispatch({ type: 'RRP_SHOPPING_CART_SUCCESS', value: mockData }),
    retrieveRrpMasterOrderDetails: options =>
      dispatch(retrieveRrpMasterOrderDetails(options)),
    rrpAddMasterOrderItems: options =>
      dispatch(rrpAddMasterOrderItems(options)),
    rrpRemoveMasterOrderItems: options =>
      dispatch(rrpRemoveMasterOrderItems(options)),
    rrpCancelMasterOrderItems: options =>
      dispatch(rrpCancelMasterOrderItems(options)),
    getShoppingCartData: options => dispatch(getShoppingCartData(options)),
    updateRrpMasterOrderID: data =>
      dispatch({ type: ACTION_TYPES.RRP.SET_RRP_MASTER_ORDERID, data })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RRPShoppingCart);
