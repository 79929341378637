import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import PlanCatalog from '../components/PlanCatalog/PlanCatalog';
import PropTypes from 'prop-types';
import { getUrlParams } from '@lux/helpers';
import { connect } from 'react-redux';

import CisError from '../components/CisError';
import SEO from '../components/SEO';

import { userActions, CISCustomerLoginType } from '@detox/actions';
import notifications from '../config/notifications.js';
import { useLocation } from '@reach/router';
import { getUIAMEnabledWithToken } from '../selectors';

export const CisPlansPage = ({
  location,
  data,
  validateCISToken,
  getUserInformation,
  cisError,
  cisLoading,
  cisPlanRates,
  cisUserInfo,
  appState
}) => {
  const apigeeTokenEnabled = getUIAMEnabledWithToken(appState);

  // Check if token exists
  useEffect(() => {
    const urlParams = getUrlParams();
    const hasCisToken = Boolean(urlParams.sessionToken);
    const cisSessionType: CISCustomerLoginType =
      urlParams.source === 'myinfo' ? 'MY_INFO' : 'EMAIL';
    const hasCisData = Boolean(cisPlanRates);

    if (!hasCisToken) {
      navigate('/plans');
      return;
    }

    if (!hasCisData && apigeeTokenEnabled) {
      getUserInformation().then(() => {
        if (urlParams.sessionToken !== cisUserInfo?.sessionToken) {
          validateCISToken(urlParams.sessionToken, cisSessionType);
        }
      });
    }
  }, [cisPlanRates, validateCISToken, apigeeTokenEnabled]);

  let renderContent = (
    <>
      <PlanCatalog data={data} location={location} isCisPage={true} />
    </>
  );

  // if it is invalid, show cis error
  if (cisError) {
    renderContent = (
      <CisError
        title={notifications[cisError].title}
        subTitle={notifications[cisError].subTitle}
        text={notifications[cisError].text}
        ctaText={notifications[cisError].ctaText}
        ctaUrl={notifications[cisError].ctaUrl}
      />
    );
  }

  return (
    <div className="fs-unmask">
      <SEO
        title="CIS Plans"
        description="No Queuing. Beat the queues and shop from the comfort of your own home. Flexible Collection &amp; Delivery."
      />
      {!cisLoading ? renderContent : null}
    </div>
  );
};

CisPlansPage.defaultProps = {
  location: {}
};

CisPlansPage.propTypes = {
  data: PropTypes.object,
  validateCISToken: PropTypes.func,
  cisError: PropTypes.string,
  cisPlanRates: PropTypes.arrayOf(
    PropTypes.shape({
      planID: PropTypes.string,
      discountedPrice: PropTypes.string
    })
  ),
  appState: PropTypes.any
};

/* istanbul ignore next */
const mapStateToProps = state => {
  const { user } = state;
  return {
    cisLoading: user.cis && user.cis.loading,
    cisError: user.cis && user.cis.error,
    cisUserInfo: user.cis?.information,
    appState: state
  };
};

/* istanbul ignore next */
const mapDispatchToProps = {
  validateCISToken: userActions.validateCISToken,
  getUserInformation: userActions.getUserInformation
};

/* istanbul ignore next */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(props => <CisPlansPage {...props} location={useLocation()} />);

export const query = graphql`
  {
    allPhone {
      edges {
        node {
          ...CatalogPhoneDetailFragment
        }
      }
    }
    allPlan(filter: { enabledOnPlanCatalog: { eq: true } }) {
      edges {
        node {
          ...PlanDetailFragment
        }
      }
    }
    allPlanGroup(
      filter: { enabledOnPlanCatalog: { eq: true }, segment: { in: "cis" } }
    ) {
      edges {
        node {
          groupName
          groupDisplayName
          enabledOnPlanCatalog
          shortDescription
          footerLiner
          enableIppForCIS
        }
      }
    }
    allCis {
      nodes {
        companyCode
        companyName
        desktopIconImage {
          publicURL
        }
        mobileIconImage {
          publicURL
        }
      }
    }
    allAemPages {
      edges {
        node {
          components {
            ...AemComponentsFragment
          }
          pageUrl
          title
        }
      }
    }
  }
`;
