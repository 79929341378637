import React, { useState, useContext } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import Theme from '../../theme';

export const ThemeContext = React.createContext();

export const useThemeToggle = () => useContext(ThemeContext);

// This component wraps the ThemeProvider with the toggle function
// We are now able to change the theme from anywhere in the app.

const ThemeProviderWrapper = props => {
  const [theme, setTheme] = useState(Theme);

  const toggle = newTheme => setTheme(newTheme);

  return (
    <ThemeContext.Provider value={{ toggle }}>
      <StyledThemeProvider theme={theme}>{props.children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProviderWrapper;
