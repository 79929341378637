import {
  Color,
  ImageGallery,
  SkuVariant,
  StockAvailability,
  CisRate,
  PreSelection,
  KeyValue,
  OfflineStockResponse,
  OfflineStock
} from '../types/product.types';
import { FLOW, Plan, PlanGroup } from '../types/plan.types';
import { api } from '@detox/actions';
import isFeatureFlagEnabled from './feature-flags';
import { ONLINE_STOCK_FROM_ESHOP } from '../types/featureFlag.types';
import { PRODUCT } from '../constants/product';
import {
  IFulfilment,
  IVariant,
  DeliveryOptions
} from '@common-modules/product-catalogue';
import { setUrlParams } from '@lux/helpers';
import { constructProductUrl, updateUtmUrl } from './catalogue-helpers';
import unlimitedTalktimeSms from '../helpers/unlimited-talktime-sms';
import { FulfilmentMode } from '../types/fulfilment.types';
import { SelectedPlan } from '../types/shoppingCart.types';
import { includes } from './common';
import CONSTANTS from '../constants/common';
import { trans as t } from '../helpers/localisation';
import slugify from 'slugify';

const fetchOnlineStock = api.mcss.stockAvailability;
const fetchOfflineStock = api.aem.offlineStockAvailability;

const getColorHexCode = (colors: Color[] = [], colorName: string) => {
  return colors.find(color => color.name === colorName)?.hexCode;
};
const getImagesByColor = (
  colorCode: string,
  imageGallery: ImageGallery[] = [],
  title: string
) => {
  const filteredImages = imageGallery
    .find(gallery => gallery.name === colorCode)
    ?.images?.map(image => {
      return {
        imageFile: image.image,
        alt: `${title} ${colorCode}`
      };
    });
  return filteredImages || [];
};

const getDeviceVariants = async ({
  skuVariants = [],
  plans = [],
  colors = [],
  seoImageTag,
  imageGallery,
  groupId,
  tags = [],
  brand,
  sellingFastThreshold,
  productTitle
}: {
  skuVariants: SkuVariant[];
  colors: Color[];
  imageGallery: ImageGallery[];
  seoImageTag: string;
  plans?: Plan[];
  groupId?: string;
  tags?: string[];
  brand?: string;
  sellingFastThreshold?: number;
  productTitle?: string;
}): Promise<{ status: boolean; variants: IVariant[] }> => {
  const skus = skuVariants.map(s => s.sku);
  const stockAvailability = await checkStock(skus, groupId);

  const formattedVariants = skuVariants.map(skuVariant => {
    const planGroup = plans.find(plan => plan.mecId === skuVariant.planId)
      ?.groupName;
    const stock = stockAvailability.stock.find(
      stock => stock.itemCode === skuVariant.sku
    );
    const stockCount = stock?.availableQty;
    return {
      ...skuVariant,
      deviceId: skuVariant.productId,
      colorCode: skuVariant.colour,
      colorHexCode: getColorHexCode(colors, skuVariant.colour),
      deviceCode: skuVariant.sku,
      deviceDescription: '',
      modelCode: skuVariant.productId,
      modelDescription: '',
      memorySize: skuVariant.size,
      colorDescription: skuVariant.colour,
      msaHeaderId: '',
      planId: skuVariant.planId,
      price: skuVariant.price,
      discountPrice: 0,
      stockCount,
      groupId,
      images: getImagesByColor(skuVariant.colour, imageGallery, seoImageTag),
      planGroup,
      hasOfflineStock: stock?.hasOfflineStock,
      hasOnlineStock: stock?.hasOnlineStock,
      popStationAvailable: tags.includes(PRODUCT.TAGS.POP_STATION),
      brand,
      seoImageTag: seoImageTag,
      deviceTitle: productTitle,
      installmentPrice:
        plans?.length || skuVariant.monthlyTerm
          ? skuVariant.installmentPrice
          : 0,
      storageHighlightText:
        sellingFastThreshold &&
        stockCount &&
        stockCount <= sellingFastThreshold &&
        t('TEXT_SELLING_FAST')
    };
  });
  return { status: stockAvailability.status, variants: formattedVariants };
};

const getPlanGroups = (
  allPlanGroup: PlanGroup[],
  plans: Plan[],
  flow: FLOW
): PlanGroup[] => {
  const uniqueAvailableGroup = [...new Set(plans.map(plan => plan.groupName))];
  return allPlanGroup.filter(
    group =>
      uniqueAvailableGroup.includes(group.groupName) &&
      group?.segment?.includes(flow)
  );
};

const getPlans = (plans: Plan[], cisRates?: CisRate[]) => {
  return plans.map(plan => {
    const { talktime, sms } = plan;
    const smsAndTalkTimeLiner = unlimitedTalktimeSms({ talktime, sms });

    const returnPlan = {
      ...plan,
      defaultSelected: plan.selected,
      smsAndTalkTimeLiner
    };
    if (!cisRates) {
      return returnPlan;
    }
    const matchedCisRate = cisRates.find(
      cisRate => cisRate.planID === plan.basePriceSchemaId
    );
    const monthlyCharges = Number(plan.monthlyCharges);
    const discountedPrice = Number(matchedCisRate?.discountedPrice);
    if (monthlyCharges === discountedPrice) {
      return returnPlan;
    }
    return {
      ...returnPlan,
      discountedPrice
    };
  });
};

export const getProductSpecsWithoutStorage = (
  specString: string,
  colours?: Color[],
  isRRP?: boolean
) => {
  let color = null,
    planInfo = null;
  const urlColor = colours?.find(colour =>
    specString.includes(slugify(colour?.name.toLowerCase()))
  )?.name;
  if (urlColor) {
    const selectedColor = slugify(urlColor.toLowerCase());
    const splitInfoByColor = specString.split(selectedColor + '-');
    color = selectedColor;
    const planRegex = splitInfoByColor[1]?.split('-') ?? [];
    const hasWrongStorage = /\d/.test(planRegex[0]);
    if (hasWrongStorage && !isRRP) {
      planInfo = planRegex.slice(1);
    } else {
      planInfo = planRegex;
    }
  }

  return {
    colorValue: color,
    planInfoValue: planInfo
  };
};

const getProductSpecsFromUrl = (
  path: string,
  slug: string,
  isRRP = false,
  colours?: Color[],
  selectedPlan?: SelectedPlan
): PreSelection => {
  const productInfoString = path.split(
    `/${
      isRRP ? CONSTANTS.URL_PATHS.RRP_PHONE : CONSTANTS.URL_PATHS.PHONE
    }/${slug}/`
  )[1];
  if (!productInfoString) return {};
  const pattern = /(?:^|)-([0-9.]{1,5}(-)?(gb|mm|inch|l|mah|m){1})+[-\w]*$/g;
  const matchedStorage = pattern.exec(path);
  const storage = matchedStorage?.length && matchedStorage[1];
  const splitByStoragePattern = `-${storage || 'na'}-`;
  let planInfo, color, monthlyTerm, plan;
  if (!productInfoString.includes(splitByStoragePattern)) {
    const { colorValue, planInfoValue } = getProductSpecsWithoutStorage(
      productInfoString,
      colours,
      isRRP
    );
    color = colorValue;
    planInfo = planInfoValue;
  } else {
    const splitInfoByVariant = productInfoString.split(splitByStoragePattern);
    color = splitInfoByVariant[0];
    if (selectedPlan) {
      planInfo = slugify(selectedPlan?.planName.toLowerCase()).split('-');
    } else {
      planInfo = splitInfoByVariant[1]?.split('-') ?? [];
    }
  }

  if (isRRP) {
    monthlyTerm = planInfo.join('-');
  } else {
    if (
      planInfo &&
      planInfo.length >= 2 &&
      !isNaN(Number(planInfo[planInfo.length - 2]))
    ) {
      monthlyTerm = planInfo.splice(planInfo.length - 1, 1)?.join('');
    }
    plan = planInfo && planInfo.join('-');
  }

  return {
    color,
    storage,
    plan,
    monthlyTerm
  };
};

const setUrlAfterChangeVariant = (
  variant: KeyValue,
  slug: string,
  locationStr: string,
  isRRP = false
) => {
  const utmParams = updateUtmUrl(locationStr);
  const { colorCode, planName, memorySize, monthlyTerm, paymentTerm } = variant;
  const productUrl = constructProductUrl(
    colorCode,
    memorySize,
    planName,
    isRRP ? paymentTerm : monthlyTerm
  );
  window.history.replaceState(
    null,
    null,
    setUrlParams(
      `/${
        isRRP ? CONSTANTS.URL_PATHS.RRP_PHONE : CONSTANTS.URL_PATHS.PHONE
      }/${slug}/${productUrl}`,
      utmParams
    )
  );
};

const filterOfflineStocks = (
  offlineStocks: OfflineStock[] = [],
  skus: string[] = []
): StockAvailability[] => {
  return skus.map(skuId => {
    const matchedSku = offlineStocks.find(
      offlineStock => offlineStock.skuId === skuId
    );
    const skuHasStock = matchedSku?.data?.find(
      stockAvailability =>
        PRODUCT.STOCK_AVAILABLE_STATUS.includes(
          stockAvailability.stock_status
        ) &&
        Number(stockAvailability.available_quantity) > 0 &&
        stockAvailability.store_id !== PRODUCT.STORE_ID.WARE_HOUSE
    );
    return {
      itemCode: skuId,
      status: skuHasStock
        ? PRODUCT.STOCK_STATUS.AVAILABLE
        : PRODUCT.STOCK_STATUS.NOT_AVAILABLE,
      availableQty: Number(skuHasStock?.available_quantity) || 0
    };
  }, []);
};

const mergeOfflineAndOnlineStock = (
  offlineStocks: StockAvailability[] = [],
  onlineStocks: StockAvailability[] = []
): StockAvailability[] => {
  return offlineStocks.map(offlineStock => {
    const onlineStock = onlineStocks.find(
      onlineStock => onlineStock.itemCode === offlineStock.itemCode
    );
    return {
      ...offlineStock,
      hasOfflineStock: offlineStock.availableQty > 0,
      hasOnlineStock: onlineStock?.availableQty > 0,
      availableQty: offlineStock.availableQty || onlineStock?.availableQty || 0
    };
  });
};

const getOnlineStockFromWarehouse = (
  offlineStocks: OfflineStock[] = [],
  skus: string[] = []
): StockAvailability[] => {
  return skus.map(skuId => {
    const matchedSku = offlineStocks
      .find(offlineStock => offlineStock.skuId === skuId)
      ?.data?.find(
        stockAvailability =>
          stockAvailability.store_id === PRODUCT.STORE_ID.WARE_HOUSE
      );
    const availableQty =
      (PRODUCT.STOCK_AVAILABLE_STATUS.includes(matchedSku?.stock_status) &&
        Number(matchedSku?.available_quantity)) ||
      0;
    return {
      itemCode: skuId,
      availableQty,
      status:
        availableQty > 0
          ? PRODUCT.STOCK_STATUS.AVAILABLE
          : PRODUCT.STOCK_STATUS.NOT_AVAILABLE
    };
  });
};

const getOfflineStock = async (groupId): Promise<OfflineStockResponse[]> => {
  if (!groupId) return [];
  try {
    return await fetchOfflineStock(groupId);
  } catch (e) {
    return [];
  }
};

const checkStock = async (
  skus: string[],
  groupId?: string
): Promise<{ status: boolean; stock: StockAvailability[] }> => {
  try {
    let onlineStocks = [],
      offLineStocks = [];
    const uniqueSkus = Array.from(new Set(skus));
    const shouldCheckOnlineStockFromEShop = isFeatureFlagEnabled(
      ONLINE_STOCK_FROM_ESHOP
    );
    if (shouldCheckOnlineStockFromEShop) {
      offLineStocks = (await getOfflineStock(groupId)) || [];
      onlineStocks = getOnlineStockFromWarehouse(
        offLineStocks[0]?.items,
        uniqueSkus
      );
    } else {
      [onlineStocks = [], offLineStocks = []] = await Promise.all<
        StockAvailability[],
        OfflineStockResponse[]
      >([fetchOnlineStock(uniqueSkus), getOfflineStock(groupId)]);
    }
    if (!Array.isArray(onlineStocks)) {
      onlineStocks = [];
    }
    const filteredOfflineStocks = filterOfflineStocks(
      offLineStocks[0]?.items,
      skus
    );

    return {
      status: true,
      stock: mergeOfflineAndOnlineStock(filteredOfflineStocks, onlineStocks)
    };
  } catch (e) {
    return { status: false, stock: [] };
  }
};

const getDeliveryOptions = (
  preOrderLinerForProductDetails: string,
  backOrderLinerForProductDetails: string,
  tags: string[],
  fulfilment: FulfilmentMode[] = [],
  extraLiner = ''
): DeliveryOptions => {
  const fulfilmentList = fulfilment.map<IFulfilment>(fulfilment => {
    const disabled =
      fulfilment.fulfilmentId === PRODUCT.FULFILMENT_TYPES.POP_STATION &&
      fulfilment.tags?.length &&
      !tags.includes(fulfilment.tags[0]);
    return {
      type: fulfilment.fulfilmentId,
      cost: fulfilment.mobileFulfilment.price,
      title: fulfilment.mobileFulfilment.title,
      liner: fulfilment.mobileFulfilment.liner,
      disabled
    };
  });

  return {
    preOrderLiner: preOrderLinerForProductDetails,
    backOrderLiner: backOrderLinerForProductDetails,
    fulfilmentList,
    extraLiner
  };
};

const updateAccessories = (formattedItems, selectedItems) => {
  if (!formattedItems) return;
  const updatedAccessories = formattedItems.map(formattedItem => {
    const preselected = selectedItems.find(
      selectedItem => selectedItem.deviceId === formattedItem.deviceId
    );
    if (preselected) {
      return { ...formattedItem, selectedVariant: preselected };
    }
    return formattedItem;
  });
  return updatedAccessories;
};

const getPromotions = ({
  selectedPlan,
  promotions,
  isCIS
}: {
  selectedPlan: SelectedPlan;
  promotions: KeyValue;
  isCIS: boolean;
}) => {
  const phonePromotion = isCIS ? promotions?.cis : promotions?.res;

  const promoDescription = phonePromotion?.promoDescriptions?.find(desc => {
    return desc.planGroups === selectedPlan?.groupName;
  });

  if (!promoDescription) return null;

  const filteredPromotions = phonePromotion?.promotions?.reduce(
    (result, promotion) => {
      const filteredPromos = promotion.promos?.filter(promo => {
        return (
          promo.planGroups?.length === 0 ||
          includes(promo.planGroups, selectedPlan?.groupName)
        );
      });
      if (filteredPromos.length) {
        result.push({
          ...promotion,
          promos: filteredPromos
        });
      }

      return result;
    },
    []
  );

  return {
    productId: phonePromotion?.productId,
    ...promoDescription,
    promotions: filteredPromotions
  };
};

export {
  getDeviceVariants,
  getColorHexCode,
  getImagesByColor,
  getPlanGroups,
  getPlans,
  getProductSpecsFromUrl,
  setUrlAfterChangeVariant,
  checkStock,
  getDeliveryOptions,
  updateAccessories,
  getPromotions
};
