import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';

import AddonCatalog from '../components/AddonCatalog';
import Addons from '../components/AddonCatalog/Addons';
import SEO from '../components/SEO';
import { Spacing } from '@dls/web';
import { KeyValue } from '../types/common.types';
import isFeatureFlagEnabled from '../helpers/feature-flags';
import { NEW_ADDONS_CATALOG } from '../types/featureFlag.types';

interface Props {
  data: KeyValue;
}

const AddonsPage = ({ data }: Props): ReactElement => {
  const isNewAddonCatalogEnabled = isFeatureFlagEnabled(NEW_ADDONS_CATALOG);

  return (
    <div className="fs-unmask">
      <SEO title="Add-ons" />
      {isNewAddonCatalogEnabled ? (
        <Addons data={data} />
      ) : (
        <Spacing bottom={6}>
          <AddonCatalog data={data} />
        </Spacing>
      )}
    </div>
  );
};

export default AddonsPage;

export const query = graphql`
  {
    allAddon {
      edges {
        node {
          flowType
          contract
          groupName
          id
          onetimePrice
          phoneList
          price
          productDescription
          productSpecContainmentID
          productTitle
          ranking
          salesRanking
          atomic
          type
          promotionText
          requiredDevice
          addonPopupTitle
          addonPopupMessage
          addonPopupButtonLabel
          categoryId
          categorySequence
        }
      }
    }
    allAddonGroup {
      edges {
        node {
          flowType
          displayName
          icon
          iconImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          setupFee
          shortDescription
          categoryId
          categorySequence
          type
        }
      }
    }
    allAddonPreTick {
      edges {
        node {
          boIds
          id
          phoneList
          phoneSpecific
          planIds
          segment
          spsIds
          transactionBoIds {
            gaBoIds
            portinBoIds
            reconBoIds
          }
          transactionSpsIds {
            gaSpsIds
            portinSpsIds
            reconSpsIds
          }
          transactionSpecific
          type
        }
      }
    }
    allPhone(filter: { brand: { eq: "Apple" } }) {
      edges {
        node {
          id
          groupId
          productId
          variants {
            productId
          }
        }
      }
    }
    addonSimOnlyPlan {
      data {
        addonId
        price
        quantity
        unit
      }
      sms {
        addonId
        price
        quantity
        unit
      }
      talktime {
        addonId
        price
        quantity
        unit
      }
    }
    simCardOptions {
      changeSIM {
        changeSIMOptionEnabled
        title
        blueNotificationMessage
        changeSIMOption1
        changeSIMOption2
        ctaLabel
      }
      changeSIM4GTo5G {
        changeSIMOptionEnabled
        title
        blueNotificationMessage
        changeSIMOption1
        changeSIMOption2
        ctaLabel
      }
    }
    allPlanGroup {
      edges {
        node {
          enableAccessoriesPageForCIS
          enableAccessoriesPageForRES
          groupName
        }
      }
    }
    allChooseSimOptions {
      edges {
        node {
          descWithDevice
          descWithoutDevice
          notAvailable
          footerWithDevice
          footerWithoutDevice
          footerNotAvailable
          simPrice {
            newSignup
            portIn
            recontract
          }
          simTitle
          simTypeId
          transactionType {
            newSignup
            portIn
            recontract
          }
        }
      }
    }
    allVariableConfig {
      edges {
        node {
          name
          value
        }
      }
    }
  }
`;
