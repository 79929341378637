import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Column, Grid, Row } from '@lux/components';

import useTimer from '../../hooks/useTimer';

import { authActions } from '@detox/actions';

import { SubtitleSecondary } from '../Base';
import Anchor from '../Anchor';
import Button from '../Button';
import Notification from '../Notification';
import TextField from '../TextField';
import Spacing from '../Spacing';
import SEO from '../SEO';

import notifications from '../../config/notifications.js';

import PhoneIcon from '../../assets/svgs/phone.svg';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { UIAM_FLOW } from '../../types/featureFlag.types';
import { getUIAMLoginUrl } from '../../config';
import { navigation } from '../../middlewares/navigation-constants';

const Content = styled(SubtitleSecondary)`
  color: ${p => p.theme.colours.grey_600};
`;

const msToS = t => t / 1000;
const TIME_LIMIT = 180000;

const validationMessages = {
  otpCode: 'Please enter a valid One-time password'
};

const getErrors = ({ otpCode }) => {
  const errors = {};

  if (otpCode.length !== 6 || isNaN(Number(otpCode))) {
    errors.otpCode = validationMessages.otpCode;
  }

  return errors;
};

export const WorryFreeOTP = props => {
  const [otpCode, setOtpCode] = useState('');
  const [errors, setErrors] = useState({});

  const {
    otpDetails,
    verifyOtp,
    sendOtp,
    showOnePass,
    worryFreeStatus,
    setWorryFreeStatus,
    setAuthStatus,
    additionalVerify
  } = props;

  const { otpStartTime, mobileNumber } = otpDetails;
  const timeLeftInMs = useTimer(TIME_LIMIT, otpStartTime);

  useEffect(() => {
    if (timeLeftInMs < 1000) {
      setWorryFreeStatus('OTP_TIMEOUT_ERROR');
    }
  }, [timeLeftInMs, setWorryFreeStatus]);

  const handleOTPChange = e => {
    const code = e.target.value;
    if (code.length <= 6) {
      setOtpCode(code);
    }
  };

  const handleContinueClick = () => {
    const errorMessages = getErrors({ otpCode });
    const isValidForm = Object.keys(errorMessages).length === 0;
    setErrors(errorMessages);

    if (isValidForm) {
      const {
        acceptorIndentType,
        acceptorIndentValue,
        indentType,
        indentValue,
        type
      } = otpDetails;

      verifyOtp({
        acceptorIndentType,
        acceptorIndentValue,
        otpCode,
        mobileNumber,
        indentType,
        indentValue,
        type
      });
    }
  };

  const handleResendOtpClick = () => {
    const {
      acceptorIndentType,
      acceptorIndentValue,
      indentType,
      indentValue,
      type
    } = otpDetails;
    const isSingtelPrepaid = type === 'PREPAID';

    let otpParams = {
      cleanWorryFree: false,
      acceptorIndentType,
      acceptorIndentValue,
      mobileNumber: mobileNumber,
      indentType: indentType,
      indentValue: indentValue,
      type
    };

    if (isSingtelPrepaid) {
      otpParams = {
        ...otpParams,
        type: 'PREPAID'
      };
    }

    sendOtp(otpParams);
  };

  const handleLoginStatus = () => {
    if (isFeatureFlagEnabled(UIAM_FLOW)) {
      window.open(getUIAMLoginUrl(navigation.CHECKOUT), '_self');
    } else {
      setAuthStatus('ONE_PASS_LOGIN');
    }
  };

  return (
    <>
      <SEO title="One-Time Password Login" />
      <Grid>
        <Row>
          <Column sm={12} md={4}>
            <Row center="sm">
              <Column>
                <PhoneIcon size={96} />
              </Column>
            </Row>
          </Column>
        </Row>
        <Row>
          <Column sm={12} md={8}>
            <Spacing top={5} bottom={4}>
              <Content>
                Enter the 6-digit OTP sent to {mobileNumber} within the next{' '}
                {msToS(timeLeftInMs)} seconds.{' '}
                {showOnePass && (
                  <Fragment>
                    Trouble logging in? Use{' '}
                    <Anchor onClick={handleLoginStatus}>OnePass</Anchor>.
                  </Fragment>
                )}
              </Content>
            </Spacing>
          </Column>
        </Row>
        {notifications[worryFreeStatus] && (
          <Row>
            <Column sm={12} md={8}>
              <Spacing bottom={2}>
                <Notification state={notifications[worryFreeStatus].state}>
                  {notifications[worryFreeStatus].text}
                </Notification>
              </Spacing>
            </Column>
          </Row>
        )}
        <Row>
          <Column sm={12} md={4}>
            <Spacing bottom={2}>
              <TextField
                type="tel"
                data-testid="otp-input"
                placeholder="One-time password"
                value={otpCode}
                onChange={e => handleOTPChange(e)}
                validation={errors['otpCode'] && 'danger'}
                hintMessage={errors['otpCode']}
              />
            </Spacing>
          </Column>
        </Row>
        <Row>
          <Column>
            <Anchor onClick={handleResendOtpClick}>Resend OTP</Anchor>
          </Column>
        </Row>
        <Row>
          <Column>
            <Spacing top={5} bottom={5} bottomMd={9}>
              <Button onClick={handleContinueClick}>Next</Button>
            </Spacing>
          </Column>
        </Row>
      </Grid>
    </>
  );
};

WorryFreeOTP.defaultProps = {
  otpDetails: {},
  showOnePass: true
};

WorryFreeOTP.propTypes = {
  otpDetails: PropTypes.object,
  verifyOtp: PropTypes.func,
  sendOtp: PropTypes.func,
  showOnePass: PropTypes.bool,
  worryFreeStatus: PropTypes.string,
  setWorryFreeStatus: PropTypes.func,
  setAuthStatus: PropTypes.func,
  additionalVerify: PropTypes.func
};

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    otpDetails: state.auth.worryFree,
    worryFreeStatus: state.auth.worryFree.status
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  const {
    verifyOtp,
    sendOtp,
    setWorryFreeStatus,
    setAuthStatus,
    additionalVerify
  } = authActions;
  return {
    verifyOtp: payload => dispatch(verifyOtp(payload)),
    sendOtp: payload => dispatch(sendOtp(payload)),
    setWorryFreeStatus: payload => dispatch(setWorryFreeStatus(payload)),
    setAuthStatus: payload => dispatch(setAuthStatus(payload)),
    additionalVerify: payload => dispatch(additionalVerify(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorryFreeOTP);
