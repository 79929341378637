import { AUTH, ORDER } from '../constants';
import isSimOnlyPlan from './is-sim-only-plan';
import { simCardType } from '../config/simcard-types';

const isPlanFlowReContractWithNoSim = (
  passType,
  deviceDetails,
  selectedPlan,
  productOrder,
  simType
) => {
  const { sim } = productOrder;
  const { mobileShareOrderType } = selectedPlan;
  return (
    passType?.includes(AUTH.NRIC) &&
    !(deviceDetails && deviceDetails.length > 0) &&
    (isSimOnlyPlan(selectedPlan) ||
      mobileShareOrderType === ORDER.TYPE.MS_RECONTRACT) &&
    (!sim?.hasSim || simType === simCardType.simTypeESim)
  );
};

export default isPlanFlowReContractWithNoSim;
