import React from 'react';
import styled from 'styled-components';

const StyledItem = styled.div`
  padding: ${p => p.theme.spacing(3)};
  margin-bottom: ${p => p.theme.spacing(3)};
  border-radius: ${p => p.theme.spacing(2)};
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.03),
    0 8px 23px 8px rgba(0, 0, 0, 0.05), 0 24px 27px -8px rgba(0, 0, 0, 0.08);

  &:last-child {
    margin-bottom: 0;
  }

  ${p => p.theme.media.md`
    padding: ${p.theme.spacing(4)};
    box-shadow: 0 8px 23px 8px rgba(0, 0, 0, 0.05);
  `};
`;

const RoundedPlanCard = props => {
  return <StyledItem>{props.children}</StyledItem>;
};

export default RoundedPlanCard;
