import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FadeContainer = styled.div`
  width: 100%;
  opacity: ${p => (p.animateFadeOut ? '0' : '1')};
  transition: ${p =>
    p.animateFadeOut
      ? `opacity ${p.fadeOutSpeed}s`
      : `opacity ${p.fadeInSpeed}s`};
  transition-delay: ${p =>
    p.animateFadeOut ? `${p.fadeOutDelay}s` : `${p.fadeInDelay}s`};
`;

const FadeAnimation = ({
  animateFadeOut,
  fadeInSpeed,
  fadeOutSpeed,
  fadeInDelay,
  fadeOutDelay,
  children
}) => {
  return (
    <FadeContainer
      animateFadeOut={animateFadeOut}
      fadeInSpeed={fadeInSpeed}
      fadeOutSpeed={fadeOutSpeed}
      fadeInDelay={fadeInDelay}
      fadeOutDelay={fadeOutDelay}
    >
      {children}
    </FadeContainer>
  );
};

FadeAnimation.defaultProps = {
  animateFadeOut: false,
  fadeOutSpeed: 0.4,
  fadeInSpeed: 1,
  fadeInDelay: 0,
  fadeOutDelay: 0
};

FadeAnimation.propTypes = {
  /** Animates fade-out when true, and animates fade-in when false */
  animateFadeOut: PropTypes.bool,
  /** Speed of fade-in in seconds */
  fadeInSpeed: PropTypes.number,
  /** Speed of fade-out in seconds */
  fadeOutSpeed: PropTypes.number,
  /** fade-in delay in seconds */
  fadeInDelay: PropTypes.number,
  /** fade-out delay in seconds */
  fadeOutDelay: PropTypes.number,
  /** FadeAnimation can be wrapper around any react component */
  children: PropTypes.node
};

export default FadeAnimation;
