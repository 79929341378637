import FULFILMENT_CONSTANTS from '../../constants/fulfillment';
import CHECKOUT_CONSTANTS from '../../constants/checkout';
import ORDER_CONSTANTS from '../../constants/order';
import { KeyValue, Translate } from '../../types/common.types';
import appleCareIcon from '../../assets/images/checkout/apple-care.png';
import accessoriesIcon from '../../assets/images/checkout/accessories.png';
import {
  FulfilmentMode as RequestFulfilmentMode,
  ProceedToOrderSummaryOptions,
  Popstation,
  PopstationTimeSlot,
  TimeSlot,
  CourierAddress
} from '@detox/actions/dist/types/types/fulfillment.types';
import {
  FieldObject,
  ProductItem,
  ProductsListInputs,
  ProductsListOutputs,
  AvailableFreeBiesInputs,
  BaseAddress,
  Slot,
  GeoGraphicAddress
} from '../../types/registrationCheckout';
import {
  FulfilmentValue,
  PopstationTimeslot
} from '@common-modules/shopping-cart';
import {
  FlowParams,
  DeliveryModesInputs,
  PickupLocation,
  FulfilmentMode,
  PickupMode,
  LeadDateParams,
  NoteSection
} from '../../types/fulfilment.types';
import {
  format,
  addDays,
  parse,
  isBefore,
  startOfDay,
  endOfDay,
  differenceInHours
} from 'date-fns';
import translate from '../../hooks/useTranslation';
import { PRODUCT } from '../../constants/product';
import { UserInformation, config } from '@detox/actions';
import { FULFILLMENT } from '../../constants';
import { SkuVariant, LeadDays } from '../../types/product.types';
import {
  filterBillingAddress,
  getAdditionalBillDescription,
  getDisplayUnitNumber
} from '../../helpers/common';
import { AccessoryVariant } from '../../types/accessory.types';
import { CONFIGS } from '../../config/common';
import { CartItems, CartOrder } from '../../types/shoppingCart.types';

export const DATE_FORMAT = 'MM/dd/yyyy';

export interface PopstationData extends Popstation {
  ZipCode?: string;
  Storey?: string;
}

export type ErrorStateSteps = 'delivery_appointment_slot';

export const formatDate = (date: Date, pattern = DATE_FORMAT): string => {
  return format(date, pattern);
};

export const getDeliveryType = (deliveryMode: string): string => {
  switch (deliveryMode) {
    case FULFILMENT_CONSTANTS.FULFILMENT_TYPES_AEM.DOOR_STEP_DELIVERY: {
      return FULFILMENT_CONSTANTS.FULFILMENT_TYPES.DOOR_STEP_DELIVERY;
    }
    case FULFILMENT_CONSTANTS.FULFILMENT_TYPES_AEM.PARTNER_DELIVERY: {
      return FULFILMENT_CONSTANTS.FULFILMENT_TYPES.PARTNER_DELIVERY;
    }
    case FULFILMENT_CONSTANTS.FULFILMENT_TYPES_AEM.POP_STATION: {
      return FULFILMENT_CONSTANTS.FULFILMENT_TYPES.POP_STATION;
    }
    case FULFILMENT_CONSTANTS.FULFILMENT_TYPES_AEM.COLLECT_AT_STORE: {
      return FULFILMENT_CONSTANTS.FULFILMENT_TYPES.COLLECT_AT_STORE;
    }
    case FULFILMENT_CONSTANTS.FULFILMENT_TYPES_AEM.SNAIL_MAIL: {
      return FULFILMENT_CONSTANTS.FULFILMENT_TYPES.SNAIL_MAIL;
    }
  }
};

const isStoreOnly = (tags: string[] = []) => {
  return tags.includes(PRODUCT.TAGS.STORE_ONLY);
};

const isPreOrderConfig = (flowParams: FlowParams): boolean => {
  return flowParams.isBackOrder || flowParams.isPreOrder;
};

export const getDeliveryModes = ({
  deliveryModes,
  hasOnlineStock,
  isPopStationAvailable,
  hasOfflineStock,
  tags = [],
  flowParams,
  availableStoreLocations,
  selectedAccessories,
  isBib
}: DeliveryModesInputs): PickupMode[] => {
  return deliveryModes.reduce((result, deliveryMode) => {
    const { isCollectAtStore } = getDeliveryModeById(deliveryMode.fulfilmentId);
    if (isBib && !isCollectAtStore) {
      return result;
    }
    const deliveryModeStatus = getDeliveryModeStatus({
      flowParams,
      isPopStationAvailable,
      hasOnlineStock,
      hasOfflineStock,
      deliveryMode,
      tags,
      selectedAccessories
    });

    const pickupLocations =
      isCollectAtStore && deliveryModeStatus.enabled
        ? { pickupLocations: availableStoreLocations || [] }
        : {};
    result.push({
      type: getDeliveryType(deliveryMode.fulfilmentId),
      title: deliveryMode.mobileFulfilment?.title,
      description: deliveryModeStatus.message || deliveryModeStatus.description,
      icon: deliveryModeStatus.icon,
      charges: deliveryMode?.mobileFulfilment?.price,
      explain: {
        icon: deliveryModeStatus.explainIcon,
        message: deliveryModeStatus.explain
      },
      disabled: !deliveryModeStatus.enabled,
      ...pickupLocations
    });

    return result;
  }, []);
};

export const getDeliveryNote = (
  selectedProduct: SkuVariant,
  leadDays: LeadDays[] = [],
  preOrderLinerForFulfilment: string,
  backOrderLinerForFulfilment: string
): NoteSection => {
  let content;
  if (selectedProduct?.isPreOrder) {
    content = preOrderLinerForFulfilment;
  } else if (selectedProduct?.isBackOrder) {
    content = backOrderLinerForFulfilment;
  }
  if (!content) return null;
  const selectedSkuLeadDay =
    Array.isArray(leadDays) &&
    leadDays.find(item => item?.sku.indexOf(selectedProduct?.sku) > -1);
  if (selectedSkuLeadDay) {
    content = content.replace(
      /{deliveryperiod}/g,
      `${selectedSkuLeadDay.deliveryPeriod}`
    );
  }

  return {
    title: '',
    content: content
  };
};

const getAddressUnitNumber = (addressData: Partial<BaseAddress>) => {
  return {
    unitNumber: addressData.unitNumber,
    floor: addressData.floor
  };
};

export const getAddressInfo = ({
  checkoutFlow,
  fulfilmentState,
  t,
  pickupType,
  fulfilmentList,
  isBuyingWithoutDevice,
  newAddressData,
  addressId,
  fulfilmentDataType = 'mobileFulfilment'
}: {
  checkoutFlow: string;
  fulfilmentState: KeyValue;
  t: Translate;
  pickupType: string;
  fulfilmentList: KeyValue;
  isBuyingWithoutDevice: boolean;
  newAddressData: Partial<BaseAddress>;
  addressId?: string;
  fulfilmentDataType?: string;
}): {
  addresses: BaseAddress[];
  addAddressInfo;
  canAddNewAddress;
} => {
  const isNewAddress =
    checkoutFlow === CHECKOUT_CONSTANTS.CHECKOUT_NEW_NEW_CUSTOMER;
  let addresses,
    canAddNewAddress = false,
    addAddressInfo = [];
  if (isNewAddress) {
    const savedAddress = fulfilmentState?.addressInfo?.savedAddress;
    addresses = [
      {
        ...savedAddress,
        ...getAddressUnitNumber(newAddressData),
        addressId:
          addressId || `${savedAddress?.postcode}-${savedAddress?.unitNumber}`
      }
    ];

    const getDeliveryAddressLiner = () => {
      const deliveryAddressLinerItem = fulfilmentList?.filter(
        item =>
          item.fulfilmentId ===
          FULFILMENT_CONSTANTS.FULFILMENT_TYPES_AEM.DOOR_STEP_DELIVERY
      );
      let deliveryAddressText;
      if (isBuyingWithoutDevice) {
        deliveryAddressText =
          deliveryAddressLinerItem[0]?.[fulfilmentDataType]
            ?.deliveryLinerWithoutDevice;
      } else {
        deliveryAddressText =
          deliveryAddressLinerItem[0]?.[fulfilmentDataType]
            ?.deliveryLinerWithDevice;
      }

      return deliveryAddressText;
    };

    addAddressInfo = [
      {
        icon: 'info',
        text:
          pickupType === FULFILMENT_CONSTANTS.FULFILMENT_TYPES.SNAIL_MAIL
            ? t('MAILBOX_ADDRESS_LINER')
            : getDeliveryAddressLiner()
      }
    ];
  } else {
    canAddNewAddress = true;
    addresses = filterBillingAddress(
      fulfilmentState?.delivery?.deliveryDetails?.billingAccountDetails
    )
      ?.map(billingDetails => billingDetails.physicalAddress)
      .filter(
        (billingAddress, index, baList) =>
          baList.findIndex(
            baAddress => baAddress.addressId === billingAddress.addressId
          ) === index
      )
      .slice(0, 3);
  }
  return {
    addresses,
    addAddressInfo,
    canAddNewAddress
  };
};

export const getAddressWithId = (
  address: GeoGraphicAddress,
  savedAddress: GeoGraphicAddress
): GeoGraphicAddress => {
  return {
    ...address,
    addressId: `${savedAddress?.postcode}-${savedAddress?.unitNumber}`,
    isNewAddress: true
  };
};

export const getAvailableFreebies = ({
  freebiesList,
  mtposStockList = []
}: AvailableFreeBiesInputs): FieldObject[] => {
  const freebiesSkuIds = freebiesList.map(freebieItem => freebieItem.skuId);
  const freebiesStock = mtposStockList.filter(mtposStock =>
    freebiesSkuIds.includes(mtposStock.itemCode)
  );
  return freebiesStock
    .filter(
      freebieStock =>
        freebieStock.availableQty > 0 || freebieStock.status !== 'NA'
    )
    .map(freebieStock =>
      freebiesList.find(freebie => freebie.skuId === freebieStock.itemCode)
    );
};

export const getFulfilmentProductsList = ({
  cartOrder,
  selectedProduct,
  fulfillment,
  selectedAccessories,
  isBuyingWithoutDevice,
  priceNeeded = false,
  needFreebies = true,
  nounderline = true
}: ProductsListInputs): ProductsListOutputs | KeyValue => {
  const { t } = translate();

  let cartItemsList: ProductItem[] = [];
  let hasAccessories = false;
  let hasFreebies = false;
  let hasTradeIn = false;
  let hasAppleCare = false;

  if (!cartOrder || Object.keys(cartOrder).length === 0) {
    return {
      productsList: cartItemsList,
      hasAccessories: false,
      hasTradeIn: false
    };
  }
  const commonProductValues = {
    nounderline: nounderline,
    quantity: '01'
  };

  // device details
  // display only if user is purchasing with device.
  if (!isBuyingWithoutDevice) {
    if (cartOrder.device && selectedProduct) {
      const {
        imageGallery,
        title,
        colour,
        size,
        shippingLiner,
        isPreOrder,
        isBackOrder
      } = selectedProduct;
      const productImage = imageGallery.find(
        (image: FieldObject) => image.name === colour
      );
      const name = `${title} ${colour}, ${size}`;
      const priceData = priceNeeded ? { price: cartOrder.deviceCharges } : {};
      const cartItemDevice = {
        ...commonProductValues,
        name,
        imageSrc: productImage?.images[0]?.image?.childImageSharp?.fluid?.src,
        description: shippingLiner,
        ribbon: isPreOrder || isBackOrder ? t('PRE-ORDER') : '',
        ...priceData
      };
      cartItemsList = [...cartItemsList, cartItemDevice];
    }
  }

  // apple-care
  if (cartOrder.appleCare) {
    hasAppleCare = true;
    const { name, price } = cartOrder.appleCare;
    const priceData = priceNeeded ? { price } : {};
    const cartItemAppleCare = {
      name,
      imageSrc: appleCareIcon,
      ...commonProductValues,
      ...priceData
    };
    cartItemsList = [...cartItemsList, cartItemAppleCare];
  }

  // accessories
  if (cartOrder.accessories?.length > 0) {
    hasAccessories = true;
    const accessoriesList = cartOrder.accessories.map(
      (accessory: FieldObject) => {
        const selectedAccessory = selectedAccessories.find(
          (selectedAcc: FieldObject) => selectedAcc.id === accessory.accessoryId
        );
        const imageSrc = selectedAccessory?.images[0]?.src || accessoriesIcon;
        const priceData = priceNeeded
          ? { price: accessory.checkoutPrice?.finalAmountIncludingTax }
          : {};

        return {
          ...commonProductValues,
          name: accessory.name,
          imageSrc,
          ...priceData
        };
      }
    );
    cartItemsList = [...cartItemsList, ...accessoriesList];
  }

  // trade in
  hasTradeIn = !!cartOrder.tradeInDetails;

  // freebies
  const availableFreebies = cartOrder?.freebies?.filter(freebie => {
    return !freebie.unavailable;
  });
  if (availableFreebies?.length > 0) {
    hasFreebies = true;
    const freebies = getAvailableFreebies({
      freebiesList: availableFreebies,
      mtposStockList: fulfillment?.mtposStock?.data
    });
    const priceData = priceNeeded ? { price: 0, freebie: true } : {};
    const updatedFreebies = freebies.map(freebie => ({
      ...commonProductValues,
      name: freebie.name,
      icon: 'rewards',
      ...priceData
    }));

    cartItemsList = [...cartItemsList, ...updatedFreebies];
  }

  // 5G3IN1 | eSIM SIM card
  if (cartOrder?.firstBills?.length && needFreebies) {
    const simCardList = cartOrder.firstBills
      .filter(
        (firstBill: FieldObject) =>
          firstBill.name === 'SIM Card Fee' ||
          firstBill.description === '5G3IN1 SIM Card Fee'
      )
      .map((firstBill: FieldObject) => ({
        ...commonProductValues,
        name: getAdditionalBillDescription(firstBill.description),
        icon: 'simcard'
      }));

    cartItemsList = [...cartItemsList, ...simCardList];
  }

  // early repayment charges
  // priceNeeded is only for order-summary
  if (priceNeeded && cartOrder?.repaymentCharges) {
    const chargesList = [
      {
        name: t('EARLY_REPAYMENT_FEE'),
        price: cartOrder.repaymentCharges
      }
    ];
    cartItemsList = [...cartItemsList, ...chargesList];
  }

  return {
    productsList: cartItemsList,
    hasAccessories: hasAccessories,
    hasFreebies: hasFreebies,
    hasTradeIn: hasTradeIn,
    hasAppleCare: hasAppleCare
  };
};

export const getPopstationTimeSlot = (
  data: PopstationTimeslot[]
): PopstationTimeslot[] => {
  return data?.map(timeSlot => timeSlot.slotDate);
};

const getDeliveryModeById = (fulfilmentId: string) => {
  return {
    isPopStation:
      fulfilmentId === FULFILMENT_CONSTANTS.FULFILMENT_TYPES_AEM.POP_STATION,
    isCollectAtStore:
      fulfilmentId ===
      FULFILMENT_CONSTANTS.FULFILMENT_TYPES_AEM.COLLECT_AT_STORE,
    isDoorStepDelivery:
      fulfilmentId ===
      FULFILMENT_CONSTANTS.FULFILMENT_TYPES_AEM.DOOR_STEP_DELIVERY,
    isMailBoxDelivery:
      fulfilmentId === FULFILMENT_CONSTANTS.FULFILMENT_TYPES_AEM.SNAIL_MAIL
  };
};

const checkFlowType = (flow: string, deliveryMode: FulfilmentMode): boolean => {
  switch (flow) {
    case CHECKOUT_CONSTANTS.CHECKOUT_NEW_NEW_CUSTOMER: {
      return deliveryMode?.mobileFulfilment?.transactionType?.newNewNumber;
    }
    case CHECKOUT_CONSTANTS.CHECKOUT_EXISTING_NEW_CUSTOMER: {
      return deliveryMode?.mobileFulfilment?.transactionType?.existingNewNumber;
    }
    case CHECKOUT_CONSTANTS.CHECKOUT_RECON: {
      return deliveryMode?.mobileFulfilment?.transactionType?.recon;
    }
  }
  return false;
};

const isPlanWithSimAndAccessories = (flowParams: FlowParams): boolean => {
  return (
    (flowParams.isSimOnly || flowParams.isMobileShare) &&
    (flowParams.hasSim || flowParams.hasAccessory) &&
    flowParams.flow === CHECKOUT_CONSTANTS.CHECKOUT_RECON
  );
};

const checkAccessoryForCollectAtStore = (
  accessories: AccessoryVariant[] = []
): boolean => {
  return accessories.every(accessory =>
    accessory.tags?.includes(CONFIGS.TAGS.COLLECT_AT_STORE)
  );
};

export const getDeliveryModeStatus = ({
  flowParams,
  hasOnlineStock,
  hasOfflineStock,
  deliveryMode,
  isPopStationAvailable,
  tags,
  selectedAccessories
}: {
  flowParams: FlowParams;
  hasOnlineStock: boolean;
  hasOfflineStock: boolean;
  deliveryMode: FulfilmentMode;
  isPopStationAvailable: boolean;
  tags?: string[];
  selectedAccessories?: AccessoryVariant[];
}): {
  enabled: boolean;
  message: string;
  description?: string;
  icon?: string;
  explain?: string;
  explainIcon?: string;
} => {
  const { t } = translate();
  const { isPopStation, isDoorStepDelivery } = getDeliveryModeById(
    deliveryMode.fulfilmentId
  );
  const isCollectAtStore =
    deliveryMode.fulfilmentId ===
    FULFILMENT_CONSTANTS.FULFILMENT_TYPES_AEM.COLLECT_AT_STORE;

  const hasStock =
    !flowParams.hasHandset && !flowParams.hasAccessory
      ? true
      : isCollectAtStore
      ? hasOfflineStock
      : hasOnlineStock;

  const {
    enabledForBackOrder,
    enabledForSIM,
    enabledForAccessories,
    enabledForTradeIn,
    enabledForFreeGifts,
    enabledForHandset,
    enableUploadDocument
  } = deliveryMode?.mobileFulfilment || {};
  let enabled = true,
    message = '';
  const flowEnabled = checkFlowType(flowParams.flow, deliveryMode);
  const isSimOnlyCardSelected =
    flowParams.hasSim &&
    !flowParams.hasHandset &&
    !flowParams.hasAccessory &&
    !flowParams.hasFreeGift;

  switch (true) {
    case isCollectAtStore && !hasStock && isPreOrderConfig(flowParams): {
      enabled = false;
      message = t('FULFILMENT_MODE_PRE_ORDER_NOT_AVAILABLE');
      break;
    }

    case !hasStock: {
      enabled = false;
      message = t('FULFILMENT_MODE_NO_STOCK');
      break;
    }

    case flowParams.hasTradeIn && flowParams.isBackOrder && !isDoorStepDelivery:
    case flowParams.hasTradeIn && !enabledForTradeIn: {
      enabled = false;
      message = t('FULFILMENT_MODE_TRADING_NOT_AVAILABLE');
      break;
    }

    case flowParams.hasAccessory && !enabledForAccessories: {
      enabled = false;
      message = t('FULFILMENT_MODE_ACCESSORY_NOT_AVAILABLE');
      break;
    }

    case isPopStation &&
      !isPopStationAvailable &&
      flowParams.hasLifeStyleProduct: {
      enabled = false;
      message = t('FULFILMENT_MODE_BULKY_NOT_AVAILABLE');
      break;
    }

    case isPopStation && !flowEnabled: {
      enabled = false;
      message = t('FULFILMENT_MODE_NEW_SIGNUP_NOT_AVAILABLE');
      break;
    }

    case flowParams.isBackOrder && !enabledForBackOrder:
    case (isPopStation || isDoorStepDelivery) && isStoreOnly(tags):
    case !flowEnabled:
    case flowParams.hasSim &&
      !flowParams.hasHandset &&
      !flowParams.hasAccessory &&
      !enabledForSIM:
    case (isCollectAtStore || isDoorStepDelivery) && isSimOnlyCardSelected:
    case isPopStation && !isPopStationAvailable && flowParams.hasHandset:
    case isPopStation &&
      !flowParams.hasHandset &&
      !isPlanWithSimAndAccessories(flowParams):
    case flowParams.hasFreeGift && !enabledForFreeGifts:
    case flowParams.hasHandset && !enabledForHandset:
    case isCollectAtStore &&
      (flowParams.hasTradeIn ||
        !checkAccessoryForCollectAtStore(selectedAccessories)):
    case flowParams.hasDocument && !enableUploadDocument: {
      enabled = false;
      message = t('FULFILMENT_MODE_NOT_AVAILABLE');
    }
  }

  const deliveryExplain = getDeliveryExplain(deliveryMode.fulfilmentId, t);
  const deliveryDesc = getDeliveryDesc(deliveryMode, !enabled);
  return {
    enabled,
    message,
    ...deliveryDesc,
    ...deliveryExplain
  };
};

export const getDeliveryDesc = (
  deliveryMode: FulfilmentMode,
  isDisable?: boolean
): { description: string | null; icon: string | null } => {
  const { isMailBoxDelivery } = getDeliveryModeById(deliveryMode.fulfilmentId);
  const defaultValue = {
    description: null,
    icon: null
  };
  switch (true) {
    case isDisable: {
      return defaultValue;
    }
    case isMailBoxDelivery: {
      return {
        description: deliveryMode.mobileFulfilment?.desc,
        icon: 'mail'
      };
    }
    default: {
      return {
        icon: null,
        description: deliveryMode.mobileFulfilment?.desc
      };
    }
  }
};

const getDeliveryExplain = (
  deliveryId: string,
  t: Translate
): { explain: string | null; explainIcon: string | null } => {
  const { isPopStation } = getDeliveryModeById(deliveryId);
  switch (true) {
    case isPopStation: {
      return {
        explain: t('POP_STATION_SMS_MESSAGE'),
        explainIcon: 'message'
      };
    }
    default: {
      return {
        explain: null,
        explainIcon: null
      };
    }
  }
};

export const getMax = (value1: number, value2: number): number => {
  return Math.max(Number(value1), Number(value2));
};

export const getDeliveryTimeSlot = (data: Slot[] = []): KeyValue => {
  return data.reduce((acc: { [key: string]: string[] }, cur) => {
    const date = formatDate(new Date(cur.slotStartDate));
    if (date in acc && acc[date].indexOf(cur.timeDescription) === -1) {
      acc[date].push(cur.timeDescription);
    } else if (!(date in acc)) {
      acc = { ...acc, [date]: [cur.timeDescription] };
    }
    return acc;
  }, {});
};

export const getStoreIdsFromLocations = (
  pickupLocations: Partial<PickupLocation>[] = []
): string[] => {
  return pickupLocations.map(pickupLocation => pickupLocation.mtposId);
};

export const getLocationsFromStoreIds = (
  storeIds: string[] = [],
  pickupLocations: Partial<PickupLocation>[] = []
): Partial<PickupLocation>[] => {
  return storeIds
    .map(storeID => {
      const fullLocation = pickupLocations.find(
        pickUp => pickUp.mtposId === storeID
      );
      return fullLocation;
    })
    .map((fullLocation: PickupLocation) => {
      const { lat, lon } = fullLocation || {};

      return { ...fullLocation, lat: +lat, lon: +lon };
    })
    .filter(Boolean);
};

export const getEappointmentLeadDays = (
  endEApptTimeInHours: number
): number => {
  const eAppHours = new Date(
    +new Date() - endEApptTimeInHours * 60 * 60 * 1000
  );
  const diffDays = differenceInHours(new Date(), eAppHours) / 24;
  return Math.round(diffDays);
};

const getValidConfigDate = configuredDate => {
  return configuredDate ? configuredDate : format(new Date(), 'dd/MM/yyyy');
};

export const getLeadDatesFromAEM = ({
  selectedProduct,
  fulfilmentList,
  hasDocument,
  leadDaysForFulfilmentMode = [],
  selectedFulfilmentMode = FULFILMENT_CONSTANTS.FULFILMENT_TYPES_AEM
    .COLLECT_AT_STORE,
  endEApptTimeInHours
}: LeadDateParams): {
  startDate: number;
  endDate: number;
  hasPrelaunchDevice: boolean;
} => {
  const leadDays = endEApptTimeInHours
    ? getEappointmentLeadDays(endEApptTimeInHours) || 5
    : 5;
  let startDate: Date;
  let endDate: Date;
  let hasPrelaunchDevice = false;
  const deviceSKU = selectedProduct?.deviceCode || '';

  const leadDaysData = leadDaysForFulfilmentMode
    .filter(({ sku }) => !!sku)
    .find(({ sku }) => sku.includes(deviceSKU));
  const { offsetWithDocument = 0, offsetWithoutDocument = 0 } =
    fulfilmentList.find(
      ffList => ffList.fulfilmentId === selectedFulfilmentMode
    )?.mobileFulfilment || {};
  const offsetDays = hasDocument ? offsetWithDocument : offsetWithoutDocument;

  if (deviceSKU && leadDaysData) {
    const { firstDeliveryDate, lastDeliveryDate } = leadDaysData;
    const firstDelivery = getValidConfigDate(firstDeliveryDate);
    const lastDelivery = getValidConfigDate(lastDeliveryDate);

    const firstDeliveryDateFormatted = parse(
      firstDelivery,
      'dd/MM/yyyy',
      new Date()
    );
    const isBeforeToday = isBefore(firstDeliveryDateFormatted, new Date());
    const firstDateForDelivery = isBeforeToday
      ? new Date()
      : firstDeliveryDateFormatted;

    startDate = addDays(firstDateForDelivery, offsetDays);
    endDate = endOfDay(parse(lastDelivery, 'dd/MM/yyyy', new Date()));
    hasPrelaunchDevice = true;
  } else {
    startDate = addDays(new Date(), offsetDays);
    endDate = endOfDay(addDays(new Date(), offsetDays + leadDays));
  }

  // fail safe in case of wrong date configuration
  startDate = isNaN(+startDate) ? new Date() : startDate;
  endDate = isNaN(+endDate) ? new Date() : endDate;

  return {
    startDate: new Date(format(startDate, 'MM/dd/yyyy')).getTime(),
    endDate: new Date(format(endDate, 'MM/dd/yyyy HH:mm:ss')).getTime(),
    hasPrelaunchDevice
  };
};

export const getBlockOrHouseNumbers = (
  addresses: GeoGraphicAddress[]
): string[] => {
  const set1 = new Set(addresses?.map(address => address.blockOrHouseNum));
  return Array.from(set1).filter(Boolean);
};

export const getSelectedPopstation = (
  fulfilmentValue: FulfilmentValue,
  popstations: PopstationData[] = []
): PopstationData => {
  return popstations?.find(
    popstation =>
      popstation.KioskId === fulfilmentValue?.popStation?.popStation.kioskId
  );
};

export const getSelectedPopStationTimeSlot = (
  timeslots: PopstationTimeSlot[],
  selectedDate: string
): PopstationTimeSlot => {
  return timeslots.find(timeslot => timeslot.slotDate === selectedDate);
};

export const getFulfilmentParams = ({
  productOrder = {},
  selectedTimeslot,
  address,
  userInfo,
  simOnlyPlan,
  hasDocument,
  isNewSimAdded,
  addressId,
  order,
  selectedFulfilmentMode,
  selectedStoreId,
  customerId,
  isSnailMail,
  cartItem,
  fulfillment,
  ...rest
}:
  | {
      productOrder: KeyValue;
      selectedTimeslot: TimeSlot;
      address: PopstationData;
      userInfo: UserInformation;
      simOnlyPlan: boolean;
      hasDocument: boolean;
      isNewSimAdded: boolean;
      addressId: string;
      order: CartOrder;
      cartItem?: CartItems;
      fulfillment?: FieldObject;
      selectedFulfilmentMode: string;
      selectedStoreId?: string;
      customerId?: string;
      isSnailMail?: boolean;
    }
  | KeyValue): ProceedToOrderSummaryOptions => {
  // freebies
  let freebies;
  const availableFreebies = cartItem?.freebies?.filter(freebie => {
    return !freebie.unavailable;
  });

  if (availableFreebies?.length > 0) {
    const freebiesFiltered = getAvailableFreebies({
      freebiesList: availableFreebies,
      mtposStockList: fulfillment?.mtposStock?.data
    });
    freebies = freebiesFiltered?.reduce((result, freebie) => {
      if (!freebie.unavailable) {
        result.push({
          skuId: freebie.skuId,
          qty: 1,
          type: ORDER_CONSTANTS.EQUIPMENT_TYPES.FREEBIE,
          productID: freebie.productID
        });
      }

      return result;
    }, []) as FieldObject;
  }

  const mainDevice = order?.mobile?.device?.sku && order?.mobile?.device;
  const accessories =
    order?.accessories?.map(accessory => {
      return {
        skuId: accessory.skuId,
        qty: 1,
        type: ORDER_CONSTANTS.EQUIPMENT_TYPES.ACCESSORY
      };
    }) || [];

  const devices = mainDevice
    ? [
        {
          skuId: mainDevice.sku,
          qty: 1,
          type: ORDER_CONSTANTS.EQUIPMENT_TYPES.DEVICE
        },
        ...accessories
      ]
    : accessories;

  const simDetails = order?.newlyAddedSimDetails?.simDetails;
  const sims = simDetails?.skuId ? [{ skuId: simDetails.skuId, qty: 1 }] : [];

  return {
    selectedFulfilmentMode: selectedFulfilmentMode as RequestFulfilmentMode,
    addressId,
    productOrderReferenceNumber: productOrder.productOrderReferenceNumber,
    productOrderItemId: productOrder.productOrderItemId,
    productOrderId: productOrder.productOrderId,
    productId: productOrder.productId,
    selectedTimeSlot: selectedTimeslot,
    address,
    userInfo: userInfo,
    serviceId: productOrder.service?.serviceId,
    simOnlyPlan,
    isDocumentUploadRequired: hasDocument,
    hasSim: productOrder?.sim?.hasSim,
    selectedStoreId: selectedStoreId || config.dealerId,
    type: productOrder.type,
    isNewSimAdded,
    isPayChannelRequired: true,
    isAllocateEquipmentRequired: false,
    isReserveEquipmentRequired: false,
    ignoreCheckFreeGiftOutOfStock: true,
    equipment: { freebies, devices, sims },
    customerId,
    isSnailMail,
    ...rest
  };
};

export const DootStepDelivery = {
  [FULFILLMENT.FULFILMENT_TYPES.SNAIL_MAIL]:
    FULFILLMENT.FULFILMENT_TYPES_AEM.SNAIL_MAIL,
  [FULFILLMENT.FULFILMENT_TYPES.DOOR_STEP_DELIVERY]:
    FULFILLMENT.FULFILMENT_TYPES_AEM.DOOR_STEP_DELIVERY
};

export const getDoorStepDeliveryTimeSlot = ({
  slots = [],
  deliverySelectedSlot = {},
  pickupType
}: KeyValue): KeyValue => {
  if (pickupType === FULFILLMENT.FULFILMENT_TYPES.SNAIL_MAIL) {
    return slots[0];
  }

  const { selectedDate, selectedTime } = deliverySelectedSlot;

  const selectSlot = slots.find(sl => {
    return (
      startOfDay(new Date(sl.slotStartDate)).getTime() ===
        startOfDay(new Date(selectedDate)).getTime() &&
      sl.timeDescription === selectedTime
    );
  });

  return selectSlot || {};
};

export const getDealerData = (
  { dealerCode, siteId, startDateTime, additional = true }: KeyValue,
  productOrder: KeyValue
): KeyValue => {
  const dealerData = { dealerCode, siteId, startTimestamp: startDateTime };
  const flowType =
    productOrder?.type === ORDER_CONSTANTS.TYPE.RECON
      ? ORDER_CONSTANTS.SERVICE_TYPE.RECON_SERVICE
      : ORDER_CONSTANTS.SERVICE_TYPE.NEW_SERVICE;
  const additionalData = {
    reservationDetail: null,
    reserveOnly: true,
    serviceType: 'Mobile',
    transactionType: flowType
  };

  return additional ? { ...dealerData, ...additionalData } : dealerData;
};

export const getContactData = (userInformation: KeyValue): KeyValue => {
  const { contact, customers = [] } = userInformation;

  const {
    email: contactEmail,
    phone = '',
    contactId: customerId,
    registeredName: name
  } = contact;
  const externalId = customers[0]?.customerId;
  const contactNumber = phone.replace(/\+[0-9]{1,}/, '').replace(/ +/g, '');

  return {
    contactEmail,
    contactNumber,
    customerId,
    externalId,
    name
  };
};

export const getAddressPayload = (pickedLocation: KeyValue): KeyValue => {
  const {
    country: city,
    postalCode,
    building: apartment,
    street: streetName,
    name: buildingName
  } = pickedLocation;

  return {
    country: 'SGP',
    countryCode: 'SGP',
    state: 'SNG',
    postcode: `${city} ${postalCode}`,
    city,
    streetName,
    apartment,
    buildingName
  };
};

export const formatAddressId = (addressId: string): string => {
  if (!addressId) return '';
  return addressId?.includes('-') ? addressId.split('-')[0] : addressId;
};

export const formattedString = (listOfValues = []): string => {
  return listOfValues
    .filter(valueFromList => Boolean(valueFromList))
    .join(', ');
};

export const getRetrieveAddressIdParams = (
  address: BaseAddress
): CourierAddress => {
  if (!address) return null;
  const unitNumber = getDisplayUnitNumber({
    unitNumber: address.unitNumber,
    floor: address.floor
  });
  return {
    apartment: unitNumber,
    floor: unitNumber,
    postalCode: address.postcode,
    street: address.streetName,
    streetNumber: address.blockOrHouseNum,
    address2: address.blockOrHouseNum,
    address1: 'Blk',
    address3: ''
  };
};

export const checkIfOOSAndFulfilment = (pickupModes: PickupMode[]): boolean => {
  const { t } = translate();

  return pickupModes.every(
    ({ disabled, description }) =>
      Boolean(disabled) && description === t('FULFILMENT_MODE_NO_STOCK')
  );
};
