import React from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from '@lux/components';
import AddonItem from '../AddonItem';
import AddonGroupItem from '../AddonGroupItem';

const AddonsList = ({
  addonGroups = [],
  addonGroupInfoLookup,
  orderType,
  onAdd,
  onRemove,
  onUpgrade
}) => {
  return (
    <Row>
      <Column>
        {addonGroups.map(group => {
          const { addons, groupName } = group;
          const addonGroupInfo = addonGroupInfoLookup[group.groupName];

          const addonsInGroup = addons.map(addon => ({
            ...(addon || []),
            iconImage: addonGroupInfo?.iconImage
          }));

          if (addonsInGroup.length <= 0) return null;
          if (addonsInGroup.length === 1 || !addonGroupInfo) {
            // These are standalone addons
            const addon = addonsInGroup[0];

            return (
              <AddonItem
                addon={addon}
                key={groupName}
                orderType={orderType}
                onAdd={onAdd}
                onRemove={onRemove}
                onUpgrade={onUpgrade}
              />
            );
          }

          return (
            <AddonGroupItem
              key={groupName}
              group={group}
              groupInfo={addonGroupInfo}
              orderType={orderType}
              onAddonAdd={onAdd}
              onAddonRemove={onRemove}
              onAddonUpgrade={onUpgrade}
            />
          );
        })}
      </Column>
    </Row>
  );
};

AddonsList.propTypes = {
  addonGroups: PropTypes.array,
  addonGroupInfoLookup: PropTypes.object,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onUpgrade: PropTypes.func
};

export default AddonsList;
