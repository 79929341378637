import React, { ReactElement } from 'react';
import { SkeletonLoader, Spacing } from '@dls/web';
import { SkeletonWrapper } from './styles';

interface PropTypes {
  numberOfItem?: number;
  itemType?: string;
}

export const ListSkeletonLoader = ({
  numberOfItem = 3,
  itemType = 'ListItem'
}: PropTypes): ReactElement => {
  return (
    <>
      {Array(numberOfItem)
        .fill(null)
        .map((_, index) => {
          return (
            <Spacing
              key={`skeleton-${index}`}
              topBottom={1}
              data-testid="skeleton-item"
            >
              <SkeletonLoader preset={itemType} loading={true} />
            </Spacing>
          );
        })}
    </>
  );
};

export const PageSkeletonLoader = ({ numberOfItem = 3 }: PropTypes) => {
  return (
    <SkeletonWrapper>
      <ListSkeletonLoader numberOfItem={numberOfItem} />
    </SkeletonWrapper>
  );
};
