export const CONFIGS = {
  EXTEND_SESSION_TIMER: 900000, // 15 mins
  EXPIRED_SESSION_TIMER: 1200000, // 20 mins
  UIAM_EXPIRE_SESSION_TIMER: 1800000, // 30 mins
  TAGS: {
    COLLECT_AT_STORE: 'singtel:eshop-delivery/collect-at-store',
    ACCESSORY_CATEGORY: 'singtel:all-accessory-deals/',
    MAIN_PRODUCT_CATEGORY: 'singtel:product-categories/'
  },
  REQUEST_CODES: {
    GATE_REDIRECT_SUCCESS: 'GATESVC_302',
    GATE_REDIRECT_OUTAGE: 'GATESVC_500'
  },
  YUU_MEMBERSHIP_CODE: 'yuu',
  CUSTOM_404_IMAGES: {
    MOBILE:
      'https://www.singtel.com/content/dam/singtel/eshop/Mobile/detox/holding-page/Detox/iPhone15-waiting-room-detox-banner-1280X750.jpg',
    DESKTOP:
      'https://www.singtel.com/content/dam/singtel/eshop/Mobile/detox/holding-page/Detox/iPhone15-waiting-room-detox-banner-2500X720.jpg'
  },
  IMEI_LENGTH: 15
};

export enum AEM_CONFIG_KEYS {
  DETOX_RRP_IPP_NOT_ELIGIBLE_PLANS = 'DETOX_RRP_IPP_NOT_ELIGIBLE_PLANS',
  DETOX_ENABLE_RRP_PRICE_SLASH = 'DETOX_ENABLE_RRP_PRICE_SLASH',
  DETOX_RRP_PROMO_STRIP = 'DETOX_RRP_PROMO_STRIP',
  DETOX_ADDONS_SOP_GROUP_NAME = 'DETOX_ADDONS_SOP_GROUP_NAME',
  DETOX_BIB_TNC = 'DETOX_BIB_TNC',
  DETOX_BIB_DEVICE_GROUP_ID = 'DETOX_BIB_DEVICE_GROUP_ID'
}

export const CP_ERROR_CODES = {
  CREDIT_LIMIT_UNSUCCESS: '50021',
  CREDIT_LIMIT_REVIEW: '50023'
};

export const CREDIT_LIMIT_FLOWTYPE = 'detoxMobile';

export const URL_PARAMS_MAPPING = {
  phones: 'singtel:instalment-options/ipp-phones',
  'life-style': 'singtel:all-mobile-deals/lifestyle-products',
  'sim-only-plus-plan-with-singtel-paylater':
    'singtel:instalment-options/ipp-phones',
  '0percent-interest-instalment-plan': 'singtel:instalment-options/ipp-phones'
};
