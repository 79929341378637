import { api, config } from '@detox/actions';
import { ORDER } from '../../constants';
import ADDON, { PRODUCT_SPEC_IDS } from '../../constants/addon';
import { isEmpty } from '../../helpers/common';
import omitUndefined from '../../helpers/omitUndefined';
import is5GVasAddon from '../../helpers/is-5g-addon';
import translate from '../../hooks/useTranslation';
import { KeyValue } from '../../types/common.types';
import { trans as t } from '../../helpers/localisation';

import simOnlyIcon from '../../assets/svgs/plan-sim-only.svg';
import mobileShareIcon from '../../assets/svgs/plan-mobile-share.svg';
import formatPrice from '../../helpers/formatPrice';

const { isDeviceCare } = api.mcss.helpersCart;
const { helpersAddons } = api.mcss;
const {
  isDataXProductSpec,
  isNewSelectedAddon,
  getAddonFormFields
} = helpersAddons;
const { addComponentList = [], removeComponentList = [] } = config;
const AddonsByPassList = process.env.GATSBY_BYPASS_ADDON_MODAL_LIST;

const compare = (a, b) => {
  if (a > b) return 1;
  if (a === b) return 0;
  return -1;
};

const updateActionAddons = (actions, type) =>
  actions.map(action => {
    if (action.type === type) {
      return {
        ...action,
        allowed: true
      };
    }
    return action;
  });

// compare tier of addons
// - compare by salesRanking & title
const compareRankingAddons = (addonA = {}, addonB = {}) => {
  if (addonA.salesRanking && addonB.salesRanking) {
    return compare(
      parseInt(addonA.salesRanking),
      parseInt(addonB.salesRanking)
    );
  }
  return compare(
    addonA?.productSpecPricing?.childPricingSchema?.description,
    addonB?.productSpecPricing?.childPricingSchema?.description
  );
};

// updateMobileSwop to update button behavious including disabled/ title
// - Always show ADD button at the first time
// - Disable button when reach to addon's maximum
// - Only show REMOVE button after customer select add button
// - Customer can able to select the existing mobile swop addons if it not each to maximum
const updateMobileSwop = mobileSwopAddon => {
  const {
    selected,
    action,
    status,
    allowedActions,
    selectedMatchesMaxQuantity
  } = mobileSwopAddon;
  if (selected && action === 'UP' && status === 'AC') {
    return {
      ...mobileSwopAddon,
      selected: false,
      disabled: selectedMatchesMaxQuantity,
      // Update 'ADD' button
      allowedActions: updateActionAddons(allowedActions, 'AD')
    };
  }
  return mobileSwopAddon;
};

// Should Hide DATA X if
//    + it is lower exisiting dataX
//    + it is the existing dataX
//    + if it is XO Plus Platinum Prices
const shouldHideDataX = (addon, dependenceData) => {
  const { orderType, selectedDataX, selectedPlan } = dependenceData;
  const isXOPlusPlatinum =
    selectedPlan.planName === translate().t('XO_PLUS_PLATINUM_PLAN');
  const hideLowerExistingDataX =
    selectedDataX && compareRankingAddons(addon, selectedDataX) < 0;
  const hideExistingDataX =
    compareRankingAddons(addon, selectedDataX) === 0 &&
    !isNewSelectedAddon(addon);
  return (
    isXOPlusPlatinum ||
    (orderType === ORDER.TYPE.RECON &&
      (hideLowerExistingDataX || hideExistingDataX))
  );
};

const updateDataX = (dataX, dependenceData = {}) => {
  const { addonInContract, orderType, selectedDataX } = dependenceData;
  if (shouldHideDataX(dataX, dependenceData)) return undefined;
  const canUpgrade =
    orderType === ORDER.TYPE.RECON &&
    selectedDataX &&
    compareRankingAddons(dataX, selectedDataX) > 0;
  return {
    ...dataX,
    allowedActions: canUpgrade
      ? updateActionAddons(dataX.allowedActions, 'UP')
      : dataX.allowedActions,
    addonInContract,
    removedAddon: selectedDataX
  };
};

const getDependenceData = (availableAddons, selectedAddonsBo, options) => {
  const addonInContracts = Object.values({
    ...selectedAddonsBo,
    ...availableAddons
  }).filter(
    addon =>
      isDataXProductSpec(addon.productSpecContainmentID) &&
      helpersAddons.isAddonInContract(addon)
  );

  const selectedDataXes = Object.values({
    ...selectedAddonsBo,
    ...availableAddons
  }).filter(
    addon =>
      isDataXProductSpec(addon.productSpecContainmentID) && addon.selected
  );

  // selectedDataX including existing dataX & new selected dataX
  const selectedDataX =
    selectedDataXes.find(item => item.productTitle) || selectedDataXes?.[0];

  const addonInContract =
    addonInContracts.find(item => item.productTitle) || addonInContracts?.[0];
  return {
    addonInContract,
    hasAddonInContract: !isEmpty(addonInContract),
    selectedDataX,
    ...options
  };
};

const isBibAddon = addon =>
  addon.productSpecContainmentID === PRODUCT_SPEC_IDS.BIB;

// updateAddons will update addons from client
//  + Mobile Swop update - done
//  + DataX  - done
//  + Spotify - done - findAtomicAddons
//  + 5G - done - hide5GVas
// Why does we need to handle it from our side?
//   API doesn't handle correctly
/**
 * updateAddons will update addons from client
 * @param availableAddons
 * @param selectedAddonsBo
 * @param options
 * @param options.orderType {string} recontract order indicator
 * @return {*}
 */
const updateAddons = (availableAddons = {}, selectedAddonsBo, options = {}) => {
  const dependenceData = getDependenceData(
    availableAddons,
    selectedAddonsBo,
    options
  );

  const updatedAddons = Object.entries(availableAddons).reduce(
    (acc, [key, addon]) => {
      const { productSpecContainmentID } = addon;

      // Check if addon is SimOnly Addon
      if (addon?.simOnlyAddon) {
        return {
          ...acc
        };
      }
      // Check if addon is mobile swop
      if (isDeviceCare({ productSpecContainmentID })) {
        const updatedMobileSwopAddon = updateMobileSwop(addon);
        return {
          ...acc,
          [key]: updatedMobileSwopAddon
        };
      }
      if (isDataXProductSpec(productSpecContainmentID)) {
        const updatedDataX = updateDataX(addon, dependenceData);
        return {
          ...acc,
          [key]: updatedDataX
        };
      }
      //Include 5G Vas Addon for all customers.
      if (is5GVasAddon(addon).length) {
        return {
          ...acc
        };
      }
      // check if addon is bring-it-back
      if (isBibAddon({ productSpecContainmentID })) {
        // reusing mobile swop to get updated addon as the logic is same
        return {
          ...acc,
          [key]: updateMobileSwop(addon)
        };
      }
      //Hide addon if customer has existing addon under the same group.
      if (
        isGroupAddon(addon) &&
        hasExistingAddonsInGroup(addon, availableAddons)
      ) {
        return {
          ...acc
        };
      }

      return {
        ...acc,
        [key]: updateGenericAddon(addon, dependenceData, options)
      };
    },
    {}
  );
  return omitUndefined(updatedAddons);
};

/**
 * Indicator to verify if addon is a group addon.
 *
 * @param {*} addon - addon
 */
export const isGroupAddon = addon => {
  return addon.groupName;
};

/**
 * Indicator to search customer existing addon within the same group.
 *
 * @param {*} addon - current addon
 * @param {*} availableAddons - list of available addons
 */
export const hasExistingAddonsInGroup = (addon, availableAddons) => {
  let hasExistingAddon = false;
  Object.entries(availableAddons).forEach(([key, cur]) => {
    const { groupName, selected, action } = cur;
    // search existing addon under same group
    if (
      addon.groupName === groupName &&
      selected &&
      (!action || action === 'UP')
    ) {
      hasExistingAddon = true;
    }
  });
  return hasExistingAddon;
};

// Addons logic is spread out in this helper file and AddonCatalogue.
// TODO: move all logic to this helper. Keep AddonCatalogue pure UI.
function updateGenericAddon(addon, dependenceData, options = {}) {
  const isDisabled = addon.selectedMatchesMaxQuantity;
  // Hide user's existing addons for recontract flow.
  if (isUserHasExistingAddon(addon)) return null;
  return {
    ...addon,
    disabled: isDisabled
  };
}

function isUserHasExistingAddon(addon) {
  const canUpgrade = shopAddonHelpers.canUpgradeBillingOffer(addon);
  const canRemove = helpersAddons.canRemoveBillingOffer(addon);
  const isNewlyAdded = shopAddonHelpers.isNewlyAddedAddon(addon);
  const isAspire = shopAddonHelpers.isAspireAddon(addon);

  // e.g. CallerID,
  const userHasExistingAspireAddon =
    isAspire && !isNewlyAdded && (canRemove || canUpgrade);

  if (isAspire) {
    return userHasExistingAspireAddon;
  } else {
    return !isNewlyAdded && canRemove;
  }
}

const shopAddonHelpers = {
  canUpgradeBillingOffer: addon => {
    if (addon.allowedActions) {
      const upgradeAction = addon.allowedActions.find(
        action => action.type === 'UP'
      );
      return Boolean(upgradeAction?.allowed);
    } else {
      return false;
    }
  },
  isNewlyAddedAddon: addon => {
    return addon.action === 'AD';
  },
  isAspireAddon: addon => {
    const aspireAddonProductSpecContainmentIds = [
      config.callerIDProductSpecContainmentId
    ];
    const addonProductSpecContainmentId = addon.productSpecContainmentID || '';
    return aspireAddonProductSpecContainmentIds.includes(
      addonProductSpecContainmentId
    );
  }
};

const addonMcAfeeTripleCheck = (cID = '') => {
  const bundledMapAddons = ADDON.ADDON_BUNDLE_MAP[ADDON.MCAFEE_BUNDLE_ID];
  return bundledMapAddons.includes(cID);
};

const bundleMcAfeeCheck = (cID = '', bundleAddonsData = {}) => {
  const bundledMcAfeeAddon = bundleAddonsData[ADDON.MCAFEE_BUNDLE_ID];
  const isBundledAddon = addonMcAfeeTripleCheck(cID);

  return {
    bundleHandlingNeeded: bundledMcAfeeAddon && isBundledAddon,
    bundledAddon: bundledMcAfeeAddon
  };
};

const getAddonsCartData = ({
  selectedProduct,
  selectedPlan,
  isSimOnly,
  isMobileShare
}) => {
  if (!selectedPlan) {
    return null;
  }
  const { planName } = selectedPlan;
  let addonsCartData = {};

  if (selectedProduct) {
    const {
      imageGallery,
      title,
      colour,
      size,
      isPreOrder,
      isBackOrder
    } = selectedProduct;

    const selectedProductImage = imageGallery.find(img => img.name === colour);
    addonsCartData = {
      productImage: selectedProductImage?.images[0]?.image,
      productPrimaryText: `${title} ${colour} ${size}`,
      preOrderText: isPreOrder || isBackOrder
    };
  } else if (isSimOnly) {
    addonsCartData.productIcon = simOnlyIcon;
  } else if (isMobileShare) {
    addonsCartData.productIcon = mobileShareIcon;
  }

  return {
    ...addonsCartData,
    productSecondaryText: planName,
    ctaText: t('PROCEED_TO_CART')
  };
};

const getSelectionCost = selectedAddon => {
  if (!selectedAddon) {
    return '';
  }

  return selectedAddon.onetimePrice !== '0'
    ? `${formatPrice(selectedAddon.onetimePrice)}`
    : `${formatPrice(selectedAddon.price)}/mth`;
};

const SIMONLY_ADDONS = {
  data: 'Upsize Data',
  talktime: 'Upsize Talktime',
  sms: 'Upsize SMS'
};

const getSmallestPrice = addons => {
  const sortedValues = addons
    .map(({ price, onetimePrice }) => {
      let priceValue = '0';
      let isOneTimePrice = false;
      if (
        onetimePrice?.trim() &&
        onetimePrice !== '0' &&
        (!price || price === '0' || !price.trim())
      ) {
        priceValue = onetimePrice;
        isOneTimePrice = true;
      } else {
        priceValue = price;
      }

      return { isOneTimePrice, priceValue: parseFloat(priceValue) };
    })
    .sort((a, b) => a.priceValue - b.priceValue);

  let smallestValue = sortedValues[0].priceValue;
  let isItOneTimePrice = sortedValues[0].isOneTimePrice;
  if (smallestValue === 0 && sortedValues.length > 1) {
    smallestValue = sortedValues[1].priceValue;
    isItOneTimePrice = sortedValues[1].isOneTimePrice;
  }

  return isItOneTimePrice
    ? `From ${formatPrice('' + smallestValue)}`
    : `From ${formatPrice('' + smallestValue)}/mth`;
};

const getSimOnlyAddons = (isSimOnly, addonSimOnlyPlan, selectedAddons = []) => {
  if (!isSimOnly || !addonSimOnlyPlan) {
    return {};
  }

  const { data = [], talktime = [], sms = [] } = addonSimOnlyPlan;

  const simOnlyAddonsData = [{ data }, { talktime }, { sms }]
    .filter(sopData => sopData[Object.keys(sopData)[0]].length > 0)
    .map(sopData => {
      const sopKey = Object.keys(sopData)[0];
      const sopDataList = sopData[sopKey];

      const selectedAddonData = sopDataList.find(sopKeyDataItem =>
        selectedAddons?.includes(sopKeyDataItem.addonId)
      );
      const smallestValue = getSmallestPrice(sopDataList);

      return {
        ...sopData,
        selectedAddonData,
        sopDataList,
        selectedAddons,
        smallestValue,
        groupName: SIMONLY_ADDONS[sopKey],
        isSelected: !!selectedAddonData,
        isSimOnly: true
      };
    });

  return simOnlyAddonsData;
};

const extractAddonsWithCategory = ({
  configuredAddons,
  assignedAddons,
  addonSimOnlyPlan,
  selectedAddons,
  isSimOnly,
  usageKeyValue
}) => {
  const allKey = t('ALL');
  const usageKey = usageKeyValue || t('SIM_ONLY_ADDONS_TAB');
  const addonsByCategoryId = {};
  addonsByCategoryId[allKey] = [];

  let hasCategoryConfig = false;
  assignedAddons.forEach(item => {
    addonsByCategoryId[allKey].push(item);

    const configuredItem = configuredAddons[item.groupName] || {
      categoryId: item.categoryId
    };
    if (configuredItem?.categoryId && !hasCategoryConfig) {
      hasCategoryConfig = true;
    }
  });

  if (isSimOnly) {
    if (hasCategoryConfig) addonsByCategoryId[usageKey] = [];

    const simOnlyAddonsData = getSimOnlyAddons(
      isSimOnly,
      addonSimOnlyPlan,
      selectedAddons
    );

    simOnlyAddonsData.forEach(item => {
      addonsByCategoryId[allKey].push(item);
      if (hasCategoryConfig) addonsByCategoryId[usageKey].push(item);
    });
  }

  assignedAddons.forEach(item => {
    const configuredItem = configuredAddons[item.groupName] || {
      categoryId: item.categoryId
    };

    if (
      configuredItem?.categoryId &&
      (configuredItem?.categoryId + '').trim().toLowerCase() !== 'none'
    ) {
      const categoryId = configuredItem.categoryId;
      if (!addonsByCategoryId[categoryId]) {
        addonsByCategoryId[categoryId] = [];
      }
      addonsByCategoryId[categoryId].push(item);
    }
  });

  const sortedCategoryAddons = Object.keys(addonsByCategoryId)
    .filter(key => key !== allKey && key !== usageKey)
    .sort((a, b) => {
      const aMinSeq = Math.min(
        ...addonsByCategoryId[a].map(item => +item.categorySequence)
      );
      const bMinSeq = Math.min(
        ...addonsByCategoryId[b].map(item => +item.categorySequence)
      );
      return aMinSeq - bMinSeq;
    });

  const addonsByCategoryIdSorted = {
    [allKey]: addonsByCategoryId[allKey] || [],
    ...(hasCategoryConfig && isSimOnly
      ? { [usageKey]: addonsByCategoryId[usageKey] || [] }
      : {}),
    ...sortedCategoryAddons.reduce((acc, key) => {
      acc[key] = addonsByCategoryId[key] || [];
      return acc;
    }, {})
  };
  return addonsByCategoryIdSorted;
};

const getAddonThumbnailConfig = ({ addon, groupInfo }) => {
  if (!addon && !groupInfo) {
    return {};
  }

  const thumbnailImg = (groupInfo || addon).iconImage?.childImageSharp
    ?.gatsbyImageData.images.fallback.src;

  const thumbnailConfig = thumbnailImg
    ? {
        thumbnail: { url: thumbnailImg, alt: 'Image' },
        thumbnailSize: 'md',
        thumbnailAlignment: 'top'
      }
    : {};

  return thumbnailConfig;
};

const getAddonPriceText = ({ price, onetimePrice }) => {
  if (price !== '0') {
    return `${formatPrice(price)}/mth`;
  }

  return onetimePrice !== '0'
    ? `${formatPrice(onetimePrice)}`
    : t('PRICE_FREE');
};

const getCharacteristicConfig = addon => {
  let characteristicConfig;

  const { title, formFields } = getAddonFormFields(addon);
  const byPassAddonModal = AddonsByPassList.split('|').find(
    addOnId => addOnId === addon.productSpecPricing?.childPricingSchema?.id
  );

  if (formFields?.length && !byPassAddonModal) {
    characteristicConfig = { title, formFields };
  }
  const hasAllowedFormFields = getAlowedCharFields(characteristicConfig);
  return { characteristicConfig, hasAllowedFormFields };
};

const getAddonTestId = (productTitle = '') => {
  return ('' + productTitle).replace(/ /g, '-').replace(/[^a-zA-Z0-9-]/g, '');
};

const getAlowedCharFields = characteristicConfig => {
  const { formFields = [] } = characteristicConfig || {};

  return formFields.some(
    ({ displayInformation }) =>
      displayInformation?.displayFormat === 'TEXTBOX' ||
      displayInformation?.displayFormat === 'CALENDAR'
  );
};

const getAddonActions = (
  { onAdd, onRemove, onLearnMore = addon => null, addon },
  hasRemoveBtn = false
) => {
  if (!addon) {
    return {};
  }

  const {
    characteristicConfig,
    hasAllowedFormFields
  } = getCharacteristicConfig(addon);
  const canAdd = helpersAddons.canAddBillingOffer(addon);
  const { productTitle = '' } = addon;

  const dataTestID = getAddonTestId(productTitle);

  const ADDON_ACTIONS = {
    ADD: {
      text: t('ADD'),
      onClick: () => onAdd(addon),
      btnProps: { secondary: true },
      dataTestID: 'add-' + dataTestID
    },
    REMOVE: {
      text: t('REMOVE'),
      onClick: () => onRemove(addon),
      btnProps: hasRemoveBtn ? {} : { loading: 'success', text: 'Success' },
      dataTestID: 'remove-' + dataTestID
    },
    LEARN_MORE: {
      text: t('ADDON_LEARN_MORE'),
      onClick: () => {
        onLearnMore(addon);
      },
      dataTestID: 'learn-' + dataTestID
    }
  };
  const addonAction = canAdd ? 'ADD' : 'REMOVE';
  const addonAdded = addonAction === 'REMOVE';

  return {
    ADDON_ACTIONS,
    addonAction,
    addonAdded,
    characteristicConfig,
    hasAllowedFormFields
  };
};

const getPromotionText = ({
  promoDescription,
  promotionText,
  atomic = true
}) => {
  let promoRibbon = '';
  if (promoDescription) {
    promoRibbon = promoDescription;
  } else if (promotionText && atomic) {
    promoRibbon = promotionText;
  }
  return promoRibbon;
};

const getContactText = (contract = '') => {
  const contractTxt = ('' + contract).trim();

  return !contractTxt || contractTxt === '0'
    ? 'No Contract'
    : `${contract}-mth contract`;
};

const getAddRemoveBillingOfferFlags = (addonToAdd, addonToRemove) => {
  let addWithBillingOffer = !addComponentList.includes(
    addonToAdd.productSpecContainmentID || ''
  );
  let removeWithBillingOffer = !removeComponentList.includes(
    addonToRemove.productSpecContainmentID || ''
  );

  if (
    ADDON.FORCE_BILLING_COMPONENT_IDS.includes(
      addonToAdd.productSpecContainmentID || ''
    )
  ) {
    addWithBillingOffer = false;
    removeWithBillingOffer = false;
  }

  return { addWithBillingOffer, removeWithBillingOffer };
};

export {
  updateAddons,
  compareRankingAddons,
  isUserHasExistingAddon,
  bundleMcAfeeCheck,
  addonMcAfeeTripleCheck,
  getAddonsCartData,
  getSelectionCost,
  extractAddonsWithCategory,
  getAddonThumbnailConfig,
  getAddonPriceText,
  getAddonActions,
  getSmallestPrice,
  getCharacteristicConfig,
  getPromotionText,
  getAddonTestId,
  getContactText,
  getAddRemoveBillingOfferFlags
};
