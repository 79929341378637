import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Column, Grid, Row } from '@lux/components';
import { isValidMobileNumber, isValidNric } from '@lux/helpers';
import { connect } from 'react-redux';

import Anchor from '../Anchor';
import { BodySecondary, H1, SubtitleSecondary } from '../Base';
import Button from '../Button';
import Notification from '../Notification';
import Select from '../Select';
import Spacing from '../Spacing';
import TextField from '../TextField';
import SEO from '../SEO';
import CCEMisMatchError from '../CCEPrompt/CCEMisMatchErrorModal';

import { authActions } from '@detox/actions';

import idPrefixes from '../../config/id-prefixes.json';
import idTypes from '../../config/id-types.json';
import notifications from '../../config/notifications.js';
import translate from '../../hooks/useTranslation';
import { getUIAMLoginUrl } from '../../config';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { UIAM_FLOW } from '../../types/featureFlag.types';
import getIsRoiMismatch from '../../helpers/is-roi-mismatch';
import { navigation } from '../../middlewares/navigation-constants';

const StyledSubtitleSecondary = styled(SubtitleSecondary)`
  color: ${p => p.theme.colours.grey_600};
`;

const isFGM = prefix => prefix === 'F' || prefix === 'G' || prefix === 'M';

const getErrors = ({ mobileNumber, idPrefix, idNumber, idType }) => {
  const errors = {};

  if (!isValidMobileNumber(mobileNumber)) {
    errors.mobileNumber = translate().t('MOBILE_NUMBER_VALIDATION');
  }

  const fullId = idPrefix + idNumber;
  if (!isValidNric(fullId)) {
    errors.idNumber = translate().t('ID_NUMBER_VALIDATION');
  }

  if (isFGM(idPrefix) && idType?.length === 0) {
    errors.idType = translate().t('ID_TYPE_VALIDATION');
  }
  return errors;
};

/**
 * @param idPrefix
 * @param passType
 * @param idTypes
 * @see `src/config/id-types.json` with available Id types. e.g. 'NRIC', 'EPASS2'
 */
const getDefaultIdType = (idPrefix, passType, idTypes) => {
  const filtered = idTypes.filter(type => type.prefixes.includes(idPrefix));
  if (filtered && passType) {
    const idTypeMatchingPassType = filtered.find(({ passTypeCode }) =>
      [].concat(passTypeCode).includes(passType)
    );
    if (idTypeMatchingPassType) {
      return idTypeMatchingPassType;
    }
  }
  return filtered.length > 0 && filtered[0].value;
};

export const WorryFreeLogin = props => {
  const {
    worryFreeStatus,
    sendOtp,
    setAuthStatus,
    disabledFields = {},
    roiValidatedData
  } = props;
  const initialFormData = props.initialFormData || {};
  const initialIDPrefix = initialFormData?.idNumber
    ? initialFormData.idNumber[0]
    : '';
  const initialIDNumber = initialFormData?.idNumber
    ? initialFormData.idNumber.substr(1)
    : '';
  const [mobileNumber, setMobileNumber] = useState(
    initialFormData.mobileNumber || ''
  );
  const [idPrefix, setIdPrefix] = useState(
    initialIDPrefix || idPrefixes[0].value
  );
  const showIdType = isFGM(idPrefix);
  const passType = initialFormData.passType;
  const initialIdType =
    getDefaultIdType(initialIDPrefix, passType, idTypes) || idTypes[0];
  const [idNumber, setIdNumber] = useState(initialIDNumber || '');
  const [idType, setIdType] = useState(initialIdType.value);
  const [errors, setErrors] = useState({});
  const [cceMismatch, setCceMismatch] = useState(false);
  const [disabled, setDisabled] = useState({
    idNumber: disabledFields.idNumber && Boolean(initialIDNumber),
    idPrefix: disabledFields.idPrefix && Boolean(initialIDPrefix),
    idType: disabledFields.idType && passType && Boolean(initialIdType)
  });

  const idTypesItems = idTypes.filter(type => type.prefixes.includes(idPrefix));
  const selectedIdTypeIndex = idTypesItems.findIndex(
    type => type.value === idType
  );

  const proceedClicked = () => {
    setCceMismatch(false);
  };

  useEffect(() => {
    initialIDPrefix && handleIdPrefixChange({ value: initialIDPrefix });
  }, [initialIDPrefix]);

  const handleContinueClick = () => {
    const errors = getErrors({
      mobileNumber,
      idPrefix,
      idNumber,
      idType
    });
    const isValidForm = Object.keys(errors).length === 0;
    setErrors(errors);

    if (isValidForm) {
      const identityValue = (idPrefix + idNumber.trim()).toUpperCase();

      const hasRoiDataMismatch = getIsRoiMismatch(
        identityValue,
        roiValidatedData
      );

      if (hasRoiDataMismatch) {
        setCceMismatch(true);
      } else {
        sendOtp({
          cleanWorryFree: true,
          mobileNumber: mobileNumber,
          indentType: idType,
          indentValue: identityValue
        });
      }
    }
  };

  const handleIdPrefixChange = ({ value: newIdPrefix }) => {
    setIdPrefix(newIdPrefix);

    const isSamePrefixGroup =
      isFGM(idPrefix) === isFGM(newIdPrefix) ||
      !isFGM(idPrefix) === !isFGM(newIdPrefix);

    const isSameIdType =
      idType === getDefaultIdType(newIdPrefix, passType, idTypes);
    if (!isSamePrefixGroup || !isSameIdType) {
      const defaultIdType = getDefaultIdType(newIdPrefix, passType, idTypes);
      setIdType(defaultIdType.value);
    }
  };

  const getWorryfreeNotification = status => {
    const notification =
      notifications[status] || notifications.OTP_GENERIC_ERROR;
    const { text, Content } = notification;
    return Content ? <Content cisHomeHref={''} /> : text;
  };

  const handleLoginStatus = () => {
    if (isFeatureFlagEnabled(UIAM_FLOW)) {
      window.open(getUIAMLoginUrl(navigation.CHOOSE_NUMBER_PAGE), '_self');
    } else {
      setAuthStatus('ONE_PASS_LOGIN');
    }
  };

  return (
    <>
      <SEO title="One-Time Password Login" />
      <Spacing bottom={5} bottomMd={9}>
        <Grid>
          <H1>Log in with SMS OTP (One-Time Password)</H1>
          <Row>
            <Column>
              <Spacing top={1} bottom={4}>
                <StyledSubtitleSecondary>
                  Or use{' '}
                  <Anchor onClick={handleLoginStatus}>
                    OnePass OR Singpass
                  </Anchor>
                </StyledSubtitleSecondary>
              </Spacing>
            </Column>
          </Row>
          {notifications[worryFreeStatus] && (
            <Row>
              <Column sm={12} md={8}>
                <Spacing bottom={5}>
                  <Notification state="danger">
                    {getWorryfreeNotification(worryFreeStatus)}
                  </Notification>
                </Spacing>
              </Column>
            </Row>
          )}
          <Row>
            <Column sm={12} md={5} lg={4}>
              <Spacing bottom={4}>
                <TextField
                  className="fs-mask"
                  type="tel"
                  id="mobile-number"
                  data-testid="mobileNumber"
                  placeholder="8-digit mobile number to recontract"
                  maxLength={8}
                  value={mobileNumber}
                  onChange={e => setMobileNumber(e.target.value)}
                  onClick={() => setErrors({ ...errors, mobileNumber: null })}
                  validation={errors.mobileNumber && 'danger'}
                  hintMessage={errors.mobileNumber}
                  tabIndex={0}
                />
              </Spacing>
            </Column>
          </Row>
          <Row top="sm">
            <Column sm={3} md={1}>
              <Spacing bottom={2}>
                <Select
                  className="fs-mask"
                  disabled={disabled.idPrefix}
                  data-testid="idPrefix"
                  selectedIndex={idPrefixes.findIndex(
                    id => id.value === idPrefix
                  )}
                  items={idPrefixes}
                  onChange={e => handleIdPrefixChange(e)}
                  tabIndex={0}
                />
              </Spacing>
            </Column>
            <Column sm={9} md={4} lg={3}>
              <Spacing bottom={2}>
                <TextField
                  className="fs-mask"
                  data-testid="idNumber"
                  id="id-number"
                  placeholder={translate().t('NRIC_FIN_NUMBER')}
                  value={idNumber}
                  disabled={disabled.idNumber}
                  onChange={e => setIdNumber(e.target.value)}
                  onClick={() => setErrors({ ...errors, idNumber: null })}
                  validation={errors.idNumber && 'danger'}
                  hintMessage={errors.idNumber}
                  tabIndex={0}
                />
              </Spacing>
            </Column>
          </Row>
          {showIdType && (
            <Row>
              <Column sm={12} md={5} lg={4}>
                <Spacing top={2} bottom={2}>
                  <Select
                    disabled={disabled.idType}
                    data-testid="id-type"
                    selectedIndex={
                      selectedIdTypeIndex < 0 ? 0 : selectedIdTypeIndex
                    }
                    items={idTypesItems}
                    onChange={e => setIdType(e.value)}
                    hintMessage={errors.idType}
                    tabIndex={0}
                  />
                </Spacing>
              </Column>
            </Row>
          )}
          <Row>
            <Column sm={12} md={5} lg={4}>
              <BodySecondary>
                NRIC/FIN number must belong to the registered owner of the
                mobile number above.
              </BodySecondary>
            </Column>
          </Row>
          <Row>
            <Column>
              <Spacing top={5}>
                <Button
                  block={false}
                  onClick={handleContinueClick}
                  tabIndex={0}
                >
                  Log in
                </Button>
              </Spacing>
            </Column>
          </Row>
        </Grid>
      </Spacing>
      {cceMismatch && <CCEMisMatchError proceedClicked={proceedClicked} />}
    </>
  );
};

WorryFreeLogin.propTypes = {
  /** sendOtp action creator */
  sendOtp: PropTypes.func,
  worryFreeStatus: PropTypes.string,
  roiValidatedData: PropTypes.shape({
    custId: PropTypes.string,
    status: PropTypes.string
  }),
  initialFormData: PropTypes.shape({
    mobileNumber: PropTypes.string,
    idNumber: PropTypes.string
  }),
  disabledFields: PropTypes.shape({
    mobileNumber: PropTypes.bool,
    idNumber: PropTypes.bool
  })
};

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    worryFreeStatus: state.auth.worryFree.status,
    roiValidatedData: state.iphone.validateSkuData
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  const { sendOtp, setAuthStatus } = authActions;
  return {
    sendOtp: payload => dispatch(sendOtp(payload)),
    setAuthStatus: payload => dispatch(setAuthStatus(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorryFreeLogin);
