import { ACTION_TYPES } from '../constants/actions';

const initialState = {
  modified: false,
  orderActionAttributes: null,
  isRemoveBibSuccess: false,
  isAddBibSuccess: false,
  containmentIdData: {}
};

const addonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADDONS_FETCH_LOADING':
    case 'ADDON_ADD_LOADING':
    case 'ADDON_REMOVE_LOADING':
    case 'ADDON_UPGRADE_LOADING':
    case 'ADDON_SIM_ONLY_ADD_LOADING':
    case 'ADDON_REMOVE_ADD_LOADING':
      return {
        ...state,
        modified: false,
        loading: true,
        error: false,
        isRemoveBibSuccess: false,
        isAddBibSuccess: false,
        isBib: false
      };

    case 'ADDONS_FETCH_SUCCESS':
      return {
        ...state,
        atomicAddons: action.value.atomicAddons,
        availableAddons: action.value.availableAddons,
        selectedAddons: action.value.selectedAddons,
        selectedAddonsBo: action.value.selectedAddonsBo,
        addons5G: action.value.addons5G,
        bundleAddonsData: action.value.bundleAddonsData,
        containmentIdData: action.value.containmentIdData || {},
        modified: false,
        loading: false,
        error: false
      };

    case 'ADDON_ADD_SUCCESS':
    case 'ADDON_REMOVE_SUCCESS':
    case 'ADDON_UPGRADE_SUCCESS':
    case 'ADDON_REMOVE_ADD_SUCCESS':
      return {
        ...state,
        modified: true,
        loading: false,
        error: false,
        isRemoveBibSuccess: action.value?.isRemoveBib,
        isAddBibSuccess: action.value?.isAddBib
      };

    case 'ADDON_SIM_ONLY_ADD_SUCCESS':
      return {
        ...state,
        modified: false,
        loading: false,
        error: false,
        selectedAddons: action.payload.selectedAddons
      };

    case 'ADDONS_FETCH_ERROR':
      return {
        ...state,
        availableAddons: null,
        selectedAddons: null,
        modified: false,
        loading: false,
        error: true,
        orderActionAttributes: null
      };
    case 'UPDATE_ORDER_ACTION_SUCCESS':
      return {
        ...state,
        orderActionAttributes: action.value
      };

    case 'ADDON_ADD_ERROR':
    case 'ADDON_REMOVE_ERROR':
    case 'ADDON_UPGRADE_ERROR':
    case 'ADDON_REMOVE_ADD_ERROR': {
      const isBibError = action.value?.isAddBib || action.value?.isRemoveBib;
      return {
        ...state,
        modified: false,
        loading: false,
        error: isBibError || action.value || true,
        isBib: isBibError,
        redirectNeeded: false
      };
    }

    case 'RESET_MODIFY_ADDON':
      return {
        ...state,
        modified: false
      };

    case ACTION_TYPES.ADDONS.RESET_UNKNOWN_ERROR: {
      return {
        ...state,
        error: false,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default addonsReducer;
