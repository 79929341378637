import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { remCalc } from '@lux/helpers';

const BaseButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${p => p.theme.fonts.secondary};
  font-size: ${remCalc(16)};
  line-height: 1.5;
  letter-spacing: ${remCalc(0.5)};
  width: auto;
  border-radius: ${remCalc(8)};
  padding: ${p => p.theme.spacing(1.5)} ${p => p.theme.spacing(2)};
  user-select: none;

  ${p => p.theme.media.md`
   display: ${p.block ? 'flex' : 'inline-flex'};
 `};

  color: ${p => p.theme.colours.white};
  background-color: ${p => p.theme.colours.primary};
  border: ${p => `1px solid ${p.theme.colours.primary}`};

  &[disabled] {
    color: ${p => p.theme.colours.white};
    background-color: ${p => p.theme.colours.grey_cc};
    border: ${p => `1px solid ${p.theme.colours.grey_cc}`};
    pointer-events: none;
    outline: none;
  }

  &:not([disabled]) {
    &:hover {
      color: ${p => p.theme.colours.white};
      background-color: ${p => p.theme.colours.primaryVariant};
      border: ${p => `1px solid ${p.theme.colours.primaryVariant}`};
    }
  }
`;

const SecondaryButton = styled(BaseButton)`
  color: ${p => p.theme.colours.primary};
  background-color: ${p => p.theme.colours.white};

  &[disabled] {
    color: ${p => p.theme.colours.grey_cc};
    background-color: ${p => p.theme.colours.white};
  }
`;

const TertiaryButton = styled(BaseButton)`
  color: ${p => p.theme.colours.link};
  background-color: ${p => p.theme.colours.grey_50};
  border: none;

  &[disabled] {
    color: ${p => p.theme.colours.grey_500};
    background-color: ${p => p.theme.colours.grey_200};
    border: none;
  }

  &:not([disabled]) {
    &:hover {
      color: ${p => p.theme.colours.link};
      background-color: ${p => p.theme.colours.grey_200};
      border: none;
    }
  }
`;

const Button = props => {
  const { disabled, onClick } = props;

  const handleClick = e => {
    e.preventDefault();

    if (!disabled && onClick) {
      onClick(e);
    }
  };

  if (props.secondary) {
    return <SecondaryButton {...props} onClick={handleClick} role="button" />;
  } else if (props.tertiary) {
    return <TertiaryButton {...props} onClick={handleClick} role="button" />;
  } else {
    return <BaseButton {...props} onClick={handleClick} role="button" />;
  }
};

Button.defaultProps = {
  disable: false,
  secondary: false,
  tertiary: false
};

Button.propTypes = {
  /** Button text */
  children: PropTypes.node,
  /** The link for the button tag */
  href: PropTypes.string,
  /** Set the role of the button */
  role: PropTypes.string,
  /** Set the aria label */
  ariaLabel: PropTypes.string,
  /** Set the target of the link */
  target: PropTypes.string,
  /** Add a title to the link */
  title: PropTypes.string,
  /** Set the tab index */
  tabIndex: PropTypes.number,
  /** Event to fire when component is clicked */
  onClick: PropTypes.func,
  /** Disable button */
  disabled: PropTypes.bool,
  /** Invert the colours */
  type: PropTypes.string,
  /** Take the full width of parent */
  block: PropTypes.bool,
  /** Sets the button with an alternative styling */
  secondary: PropTypes.bool,
  /** Sets the button with an alternative styling */
  tertiary: PropTypes.bool
};

export default Button;
