import {
  getBillingGroup,
  getContactGroup,
  getDocumentsUploadGroup,
  getDocumentTitleGroup,
  getFormSpacing,
  getKycUploadGroup,
  getKycUploadTitleGroup,
  getNRIC,
  getNRICGroup,
  getPersSectionDivider,
  getPostalCode,
  getProofUploadGroup,
  getStaffPassUploadGroup,
  getSubmitGroup,
  getTitleAndInfoGroup
} from '../helper';
import {
  BillingAccountDetail,
  BillType
} from '../../../types/registrationCheckout';
import { getFormConfigForExistingNewFlow } from './helpers';

export const getFormData = ({
  t,
  checkoutFormDocumentData,
  billingPrefData,
  verificationData,
  isCisFlow,
  device,
  selectedPlan
}) => {
  const preNricFront =
    checkoutFormDocumentData && checkoutFormDocumentData['nricFrontUpload'];
  const preNricBack =
    checkoutFormDocumentData && checkoutFormDocumentData['nricBackUpload'];
  const preStaff =
    checkoutFormDocumentData && checkoutFormDocumentData['staffPassUpload'];
  const preProof =
    checkoutFormDocumentData && checkoutFormDocumentData['proofUpload'];
  const preKyc =
    checkoutFormDocumentData && checkoutFormDocumentData['kycUpload'];
  const titleAndInfoGroup = getTitleAndInfoGroup(t);
  const contactGroup = getContactGroup(t);
  const postalCode = getPostalCode(t);
  const documentsTitleGroup = getDocumentTitleGroup(t, false);
  // file upload groups
  const documentsUploadGroup = getDocumentsUploadGroup(
    t,
    !!preNricFront,
    !!preNricBack
  );
  const staffPassUploadGroup = getStaffPassUploadGroup(t, !!preStaff);
  const proofUploadGroup = getProofUploadGroup(t, !!preProof);
  const kycUploadGroup = getKycUploadGroup(t, !!preKyc);
  const kycUploadTitleGroup = getKycUploadTitleGroup(t);
  const persSectionDivider = getPersSectionDivider();
  const formSpacing = getFormSpacing();

  const nric = verificationData?.passTypeText
    ? null
    : getNRIC(t, verificationData);
  const nricGroup = getNRICGroup(t, verificationData);
  const formData = {};

  const getNonPersonalGroup = (
    billingAccountDetails: BillingAccountDetail[] = [],
    selectedBillingAccountIndex = 0,
    hasNewAddress = false,
    newBillingAccountDetails: BillingAccountDetail[] = [],
    newAddressSelection = false,
    billingPreference: boolean,
    simType: string,
    hasAccessories: boolean
  ) => {
    const showAddressChanger = !hasNewAddress;
    const eBill =
      billingAccountDetails[selectedBillingAccountIndex]?.billType ===
      BillType.E_BILL;
    const flowConfigs = getFormConfigForExistingNewFlow({
      device,
      selectedPlan,
      isCis: isCisFlow,
      isNric: nric,
      eBill,
      simType,
      hasAccessories
    });

    const buttonText = flowConfigs.byPassFulfilment
      ? t('CO_NN_PROCEED_ORDER_SUMMARY_BTN')
      : t('CO_NN_PROCEED_BTN');
    const submitGroup = getSubmitGroup(t, false, false, buttonText);
    const showBillingPref =
      (!eBill && !newAddressSelection) || !!billingPreference;
    const billingPrefGroup = getBillingGroup(
      t,
      billingPrefData,
      billingAccountDetails,
      showBillingPref,
      showAddressChanger,
      newBillingAccountDetails
    );

    return {
      groups: {
        documentsTitle:
          flowConfigs.showNricFIN || flowConfigs.showStaffUploadDocument
            ? documentsTitleGroup
            : null,
        documents: flowConfigs.showNricFIN ? documentsUploadGroup : null,
        staffPass: flowConfigs.showStaffUploadDocument
          ? staffPassUploadGroup
          : null,
        kycTitle: flowConfigs.showKYCUploadDocument
          ? kycUploadTitleGroup
          : null,
        kyc: flowConfigs.showKYCUploadDocument ? kycUploadGroup : null,
        persSectionDivide:
          flowConfigs.showStaffUploadDocument ||
          flowConfigs.showNricFIN ||
          flowConfigs.showKYCUploadDocument
            ? persSectionDivider
            : null,
        billingPref: billingPrefGroup,
        submit: submitGroup
      },
      groupConfig: {
        showBillingPref: flowConfigs.showBillingPref,
        showNricFIN: flowConfigs.showNricFIN,
        showStaffUploadDocument: flowConfigs.showStaffUploadDocument,
        showExistingBillingAddress: flowConfigs.showExistingBillingAddress,
        byPassFulfilment: flowConfigs.byPassFulfilment
      }
    };
  };

  return {
    getNonPersonalGroup,
    titleAndInfoGroup,
    nric,
    nricGroup,
    contactGroup,
    postalCode,
    documentsUploadGroup,
    staffPassUploadGroup,
    proofUploadGroup,
    kycUploadGroup,
    formData
  };
};
