import React, { ReactElement, useEffect } from 'react';
import {
  Modal,
  TextLink,
  Layout,
  Text,
  Spacing,
  Button,
  Selector,
  Divider
} from '@dls/web';
import { trans as t } from '../../../helpers/localisation';
import { APP_TYPE_ANY, KeyValue } from '../../../types/common.types';
import {
  getAddonActions,
  getAddonPriceText,
  getContactText,
  getPromotionText
} from '../addon-helper';
import AddonCharacteristicModal from '../../AddonCharacteristicModal';

interface Props extends KeyValue {
  isAddAddonOpen: boolean;
  onClose: (values?: KeyValue) => void | Promise<void>;
  onAddWithCharacteristics?: (
    values?: KeyValue,
    addonData?: KeyValue
  ) => void | Promise<void>;
  addonData: KeyValue;
  onAddOnSelection?: (addon: APP_TYPE_ANY) => void;
}

const AddAddonModal = (props: Props): ReactElement => {
  const {
    isAddAddonOpen,
    onClose,
    addonData,
    onRemove = () => null,
    onAdd = () => null,
    onAddWithCharacteristics = () => null,
    onAddOnSelection = () => null
  } = props;

  const {
    productTitle = '',
    productDescription = '',
    onetimePrice,
    price,
    contract,
    promoDescription,
    promotionText,
    atomic
  } = addonData || {};

  const contractText = getContactText(contract);

  useEffect(() => {
    if (isAddAddonOpen) {
      onAddOnSelection(addonData);
    }
  }, [isAddAddonOpen]);

  if (!addonData) {
    return null;
  }

  const {
    ADDON_ACTIONS,
    addonAction,
    addonAdded,
    characteristicConfig,
    hasAllowedFormFields
  } = getAddonActions(
    {
      onAdd,
      onRemove,
      addon: addonData
    },
    true
  );

  return (
    <Modal
      data-testid={`add-addon-modal`}
      visible={isAddAddonOpen}
      title={productTitle}
      closable={true}
      onClose={onClose}
      backdropClosable={false}
      wide={false}
    >
      {
        (
          <Modal.Content>
            {productDescription && (
              <Spacing topBottom={2}>
                <Text {...({ type: 'body' } as APP_TYPE_ANY)}>
                  {productDescription}
                </Text>
              </Spacing>
            )}

            <Spacing bottom={3} key={productTitle}>
              <Selector
                alignment="row"
                endContent={getAddonPriceText({ price, onetimePrice })}
                selected={true}
              >
                <Selector.Body
                  align={'start'}
                  title={contractText}
                  subtitle={getPromotionText({
                    promoDescription,
                    promotionText,
                    atomic
                  })}
                ></Selector.Body>
              </Selector>
            </Spacing>

            {!addonAdded && characteristicConfig && hasAllowedFormFields && (
              <Spacing bottom={2}>
                <Spacing bottom={2}>
                  <Divider />
                </Spacing>
                <Spacing bottom={1}>
                  <Text type="boldBody">{t('ENTER_YOUR_DETAILS')}</Text>
                </Spacing>
                <AddonCharacteristicModal
                  inModal
                  {...characteristicConfig}
                  onConfirm={values => {
                    onAddWithCharacteristics(values, addonData);
                  }}
                  inModalBtnConfig={{
                    fullWidth: true,
                    text: t('ADD'),
                    secondary: true
                  }}
                />
              </Spacing>
            )}

            <Layout.Stack>
              {(!characteristicConfig ||
                !hasAllowedFormFields ||
                addonAdded) && (
                <Spacing bottom={2}>
                  <div onClick={ADDON_ACTIONS[addonAction].onClick}>
                    <Button
                      text={ADDON_ACTIONS[addonAction].text}
                      {...ADDON_ACTIONS[addonAction].btnProps}
                      fullWidth
                      data-testid={`add-addon`}
                    />
                  </div>
                </Spacing>
              )}
              <Layout.Stack align="center">
                <TextLink onClick={onClose}>
                  <TextLink.Text>{t('NO_THANKS')}</TextLink.Text>
                </TextLink>
              </Layout.Stack>
            </Layout.Stack>
          </Modal.Content>
        ) as APP_TYPE_ANY
      }
    </Modal>
  );
};

export default AddAddonModal;
