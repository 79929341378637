import React from 'react';
import { Provider } from 'react-redux';
import createStore from './src/store/createStore';
import PersistStatusProvider from './src/components/PersistStatusProvider';

const { store, persistor } = createStore();

const WrappedComponent = ({ element }) => {
  return (
    <Provider store={store}>
      <PersistStatusProvider persistor={persistor}>
        {element}
      </PersistStatusProvider>
    </Provider>
  );
};

export default WrappedComponent;
