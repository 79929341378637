import React from 'react';
import { DateTime } from 'luxon';
import { TextInput, DatePicker, Modal, Button } from '@dls/web';
import Spacing from '../Spacing';
import { useForm, Controller } from 'react-hook-form';

export const AddonCharacteristicModal = ({
  title = 'AddonCharacteristicModal',
  formFields = [],
  onConfirm,
  onClose = () => {},
  inModal = false,
  inModalBtnConfig = {}
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = handleSubmit(data => {
    onClose();
    onConfirm(data);
  });

  const renderFormFields = () => {
    return (
      <>
        {formFields.map(config => {
          const {
            displayInformation: { displayFormat, mandatory, visible, enabled },
            productSpecCharacteristic: { name, ID }
          } = config;

          if (!visible || !enabled) return null;

          switch (displayFormat) {
            case 'TEXTBOX':
              return (
                <Spacing bottom={2} key={ID}>
                  <Controller
                    name={ID}
                    control={control}
                    render={({ field }) => {
                      /* exclude value to handle un-controlled situation */
                      const { value, ...rest } = field;
                      return (
                        <TextInput
                          {...rest}
                          type="text"
                          label={name}
                          bgColor="haze"
                          error={!!errors?.[ID]}
                        />
                      );
                    }}
                    rules={{ required: mandatory }}
                  />
                </Spacing>
              );

            case 'CALENDAR':
              const todayDate = new Date();
              const endDate = new Date();
              endDate.setFullYear(todayDate.getFullYear() + 1);

              return (
                <Spacing bottom={2} key={ID}>
                  <Controller
                    name={ID}
                    control={control}
                    render={({ field: { onChange } }) => (
                      <DatePicker.SingleDatePicker
                        label={name}
                        bgColor="haze"
                        defaultValue={todayDate}
                        disabledDays={{
                          before: todayDate,
                          after: endDate
                        }}
                        onChange={value => {
                          onChange(DateTime.fromJSDate(value).toMillis());
                        }}
                      />
                    )}
                    defaultValue={DateTime.local().toMillis()}
                    rules={{ required: mandatory }}
                  ></Controller>
                </Spacing>
              );

            default:
              return null;
          }
        })}
      </>
    );
  };

  const renderForm = () => {
    return (
      <>
        <form>{renderFormFields()}</form>
        <Spacing bottom={4}></Spacing>

        <Button
          data-testid="confirm-button"
          text="Confirm"
          onClick={onSubmit}
          {...inModalBtnConfig}
        />
      </>
    );
  };

  if (inModal) {
    return <>{renderForm()}</>;
  }

  return (
    <Modal
      data-testid={`addon-characteristic-modal-${title}`}
      visible={true}
      title={title}
      onClose={onClose}
    >
      <Modal.Content>{renderForm()}</Modal.Content>
    </Modal>
  );
};

export default AddonCharacteristicModal;
