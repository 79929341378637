import React, { useEffect } from 'react';
import {
  ProductCatalogue,
  FilterGroup,
  Product
} from '@common-modules/product-catalogue';
import { planActions } from '@detox/actions';
import { Grid, Row, Column, Spacing } from '@dls/web';
import { KeyValue } from '../../types/common.types';
import { flattenNodes } from '../../helpers/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  ProductCatalogState,
  setFilterData,
  applyFilter as actionApplyFilter,
  clearFilter as actionClearFilter,
  selectFilter as actionSelectFilter,
  resetToPreviousFilter as actionResetToPreviousFilter,
  setProductList,
  setIppFilter,
  setRRPFlow
} from '../../reducers/productCatalogs';
import { Plan } from '../../types/plan.types';
import { navigate } from 'gatsby';
import {
  checkIppChanged,
  getDefaultPhonePlan,
  getSelectedPlan,
  getSimOnlyPlans,
  setUrlParamsMapping,
  updateUrlParamFromFilter
} from '../../helpers/productCatalogHelper';
import { getUrlParams } from '@wec-core/helpers';
import { getUrlByPlan } from '../../helpers/catalogue-helpers';
import { CATALOGUE } from '../../constants';
import getAemComponentByName from '../../helpers/getAemComponentByName';
import PromoStrip from '../PromoStrip/PromoStrip';
import PromoBanner from '../PromoBanner';
import { GatsbyImage } from 'gatsby-plugin-image';
import { UserInformation } from '../../types/user.types';
import CisPointOfEntry from '../CisPointOfEntry';
import isSimOnlyPlan from '../../helpers/is-sim-only-plan';
import { trans as t } from '../../helpers/localisation';
import { useInit } from '../../hooks/useInit';

type PropTypes = {
  data: KeyValue;
  isCisPage: boolean;
  location: Location;
  previewMode: boolean;
};

export const PhoneCatalog = (props: PropTypes) => {
  const { data, previewMode, isCisPage } = props;

  const dispatch = useDispatch();

  const {
    productCatalog,
    selectedPlan,
    cisRates,
    cisLoading,
    userSelection
  } = useSelector((state: KeyValue) => {
    return {
      productCatalog: state.productCatalog as ProductCatalogState,
      selectedPlan: state.plan?.selectedPlan as Plan,
      userSelection: state.user?.selection,
      cisRates: (state.user as UserInformation)?.cis?.information?.rates,
      cisLoading: (state.user as UserInformation)?.cis?.loading
    };
  });

  const {
    allPhoneCatalogCategory,
    allPhoneBrand,
    allPhone,
    allPlan,
    allAemPages,
    allInstalmentOptions
  } = data;
  const plans = flattenNodes(allPlan) as Plan[];
  const brands = flattenNodes(allPhoneBrand);
  const categories = flattenNodes(allPhoneCatalogCategory);
  const ippOptions = flattenNodes(allInstalmentOptions);
  const products = flattenNodes(allPhone);

  const getQueryParams = () => {
    return getUrlParams(window.location.search);
  };

  useEffect(() => {
    dispatch(setRRPFlow(false));
    setUrlParamsMapping([categories, ippOptions]);
  }, []);

  useEffect(() => {
    if (!productCatalog.filterData?.length && !selectedPlan) {
      dispatch(setIppFilter(getQueryParams()?.[CATALOGUE.FILTER_TYPES.PLAN]));
    }
  }, []);

  useEffect(() => {
    if (selectedPlan) {
      dispatch(
        setProductList({
          products,
          selectedPlan,
          simOnlyPlans: getSimOnlyPlans(plans),
          queryParams: getQueryParams() as KeyValue,
          filterData: productCatalog.filterData
        })
      );
    }
  }, [selectedPlan]);

  useInit(() => {
    if (
      productCatalog.productList?.length &&
      !productCatalog.filterData.length
    ) {
      dispatch(
        setFilterData({
          brands,
          categories,
          queryParams: getQueryParams() as KeyValue,
          ippOptions,
          selectedPlan
        })
      );
    }
  }, [productCatalog.productList.length]);

  useEffect(() => {
    if (productCatalog.filterData?.length) {
      updateUrlParamFromFilter(
        productCatalog.filterData,
        selectedPlan,
        getQueryParams() as KeyValue
      );
    }
  }, [productCatalog.filterData]);

  useEffect(() => {
    if (productCatalog.isDoneResetFilter) {
      const plan = getDefaultPhonePlan(plans, cisRates);
      selectPlanAndUpdateUrl(plan);
    }
  }, [productCatalog.isDoneResetFilter]);

  useEffect(() => {
    if (productCatalog.hasIppFilter !== null) {
      const plan = getPlanWhenChangeIpp(productCatalog.hasIppFilter);
      selectPlanAndUpdateUrl(plan);
    }
  }, [productCatalog.hasIppFilter]);

  const getPlanWhenChangeIpp = hasIppFilter => {
    const isSimOnlySelected = isSimOnlyPlan(userSelection?.plan);
    if (hasIppFilter) {
      const simOnlyPlan = isSimOnlySelected
        ? userSelection.plan
        : getSimOnlyPlans(plans)[0];
      return {
        ...simOnlyPlan,
        selectedMonthlyTerm:
          selectedPlan?.selectedMonthlyTerm || CATALOGUE.INSTALLMENT_MONTHS[0]
      };
    } else {
      return getSelectedPlan({
        plans,
        location: window.location,
        queryParams: getQueryParams() as KeyValue,
        fromQueryParams: false,
        cisRates,
        selectedPlan: !isSimOnlySelected && userSelection?.plan
      });
    }
  };

  const selectPlanAndUpdateUrl = plan => {
    const url = getUrlByPlan(plan);
    if (url) {
      updateUrlParamFromFilter(
        productCatalog.filterData,
        plan,
        getQueryParams() as KeyValue
      );
      dispatch(planActions.setSelectedPlan(plan));
    }
  };

  const applyFilterOrSelectFilter = (
    filterData: FilterGroup[],
    isApply = true
  ) => {
    const { isIPPChanged, newValue } = checkIppChanged(
      productCatalog.filterData,
      filterData
    );
    let plan = selectedPlan;
    if (isIPPChanged) {
      plan = getPlanWhenChangeIpp(newValue);
    }
    if (isApply) {
      return dispatch(actionApplyFilter({ filterData, selectedPlan: plan }));
    }
    return dispatch(actionSelectFilter({ filterData, selectedPlan: plan }));
  };
  const clearFilter = () => {
    dispatch(actionClearFilter());
  };

  const onCloseFilterModal = () => {
    dispatch(actionResetToPreviousFilter());
  };

  const onProductClick = (product: Product) => {
    navigate(`/phones/${product.slug}`);
  };

  const promoStrip = getAemComponentByName(allAemPages, 'PromoStrip');
  const promoBanner = getAemComponentByName(allAemPages, 'PromoBanner');

  const renderBanner = () => {
    return (
      <>
        {promoStrip?.enablePromoStrip && (
          <PromoStrip
            primaryLiner={promoStrip.primaryLiner}
            enableSecondaryStrip={promoStrip.enableSecondaryStrip}
            modalContent={{
              mainTitle: promoStrip.mainTitle,
              sectionTitle: promoStrip.sectionTitle,
              sectionDescription: promoStrip.sectionDescription,
              sectionFooter: promoStrip.sectionFooter
            }}
            isCisPage={isCisPage}
          />
        )}
        {promoBanner?.enablePromoBanner && (
          <PromoBanner
            bannerTheme={promoBanner.theme}
            desktopImg={
              <GatsbyImage
                image={
                  promoBanner?.desktopImg?.childImageSharp?.gatsbyImageData
                }
                alt={promoBanner.desktopImgAlt}
              />
            }
            mobileImg={
              <GatsbyImage
                image={promoBanner?.mobileImg?.childImageSharp?.gatsbyImageData}
                alt={promoBanner.mobileImgAlt}
              />
            }
            title={promoBanner.title}
            description={promoBanner.description}
            enableCta={promoBanner.enableCTA}
            ctaText={promoBanner.ctaText}
            modalTitle={promoBanner.sectionTitle}
            modalDescription={promoBanner.sectionDescription}
            desktopFileReference={promoBanner.desktopFileReference}
            mobileFileReference={promoBanner.mobileFileReference}
            previewMode={previewMode}
          />
        )}
        {!cisLoading && !cisRates && <CisPointOfEntry />}
      </>
    );
  };
  const renderCatalog = () => {
    return (
      <Spacing top={0}>
        <Spacing bottom={5}>
          {renderBanner()}
          <Grid>
            <Row>
              <Column>
                <ProductCatalogue
                  data={{
                    productList: productCatalog.productList,
                    filterData: productCatalog.filterData,
                    productCatalogTitle: t('PRODUCT_CATALOG_TITLE') as string
                  }}
                  configs={{
                    enableViewMoreBtn: false,
                    enableFilter: true,
                    enableSorter: false,
                    enablePromoStrip: false,
                    enableBanner: false,
                    hasProductCount: true,
                    hideDisabledFilter: true
                  }}
                  localisation={{
                    SEARCH_MODAL_TITLE: t('SEARCH_MODAL_TITLE')
                  }}
                  callbacks={{
                    applyFilter: filterData => {
                      applyFilterOrSelectFilter(filterData);
                    },
                    onProductClick,
                    clearFilter,
                    onSelectFilter: filterData => {
                      applyFilterOrSelectFilter(filterData, false);
                    },
                    onCloseFilterModal
                  }}
                />
              </Column>
            </Row>
          </Grid>
        </Spacing>
      </Spacing>
    );
  };

  return <>{renderCatalog()}</>;
};
