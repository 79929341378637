import styled, { css } from 'styled-components';
import { Grid, withTheme } from '@dls/web';

export const SkeletonWrapper = withTheme(styled(Grid)`
  ${({ coreTheme }) => css`
    position: absolute;
    background: ${coreTheme.cl_white};
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  `}
`);
