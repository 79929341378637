//todo should get those types from module.
import { APP_TYPE_ANY, KeyValue } from './common.types';
import { Omit } from '@material-ui/core';
import { UserInformation } from './user.types';

export interface SectionContent {
  title?: string;
  content: string[] | TextObject[];
  action?: SectionAction;
}

export interface TextObject {
  text: string;
  style: string;
}

export interface SectionAction {
  type: ActionTypes;
  text: string;
}

export enum ActionTypes {
  CHANGE_PERSONAL_INFO = 'CHANGE_PERSONAL_INFO',
  CHANGE_PERSONAL_INFO_RECON = 'CHANGE_PERSONAL_INFO_RECON',
  CHANGE_PERSONAL_INFO_EXISTING = 'CHANGE_PERSONAL_INFO_EXISTING',
  CHANGE_BILLING_ADDRESS = 'CHANGE_BILLING_ADDRESS',
  CHANGE_DELIVERY_ADDRESS = 'CHANGE_DELIVERY_ADDRESS',
  CHANGE_POPSTATION_ADDRESS = 'CHANGE_POPSTATION_ADDRESS',
  CHANGE_STORE_SELECTION = 'CHANGE_STORE_SELECTION'
}

export interface OrderDetails {
  deviceName?: string;
  device?: string;
  deviceCharges?: number;
  repaymentCharges?: number;
  deliveryCharges?: DeliveryCharges;
  freebies?: Freeby[];
  discount?: Discount;
  monthlyBills?: MonthlyBill[];
  totalCheckOutPrice?: number;
  totalOneTimePrice?: number;
  totalOriginUpfrontPrice?: number;
  totalOriginUpfrontPriceCanBeOffset?: number;
  totalOriginUpfrontPriceCanBeOffsetForTradeIn?: number;
  firstBills?: FirstBill[];
  vouchers?: APP_TYPE_ANY[];
  totalSave?: number;
  serviceId?: string;
  accessories?: APP_TYPE_ANY[];
  serviceRequiredDate?: number;
  discountAddons?: APP_TYPE_ANY[];
}

export interface DeliveryCharges {
  name: string;
  description: string;
  billingOfferID: string;
  totalOneTimeCalculatedPrice: TotalOneTimeCalculatedPrice;
  productSpecContainmentID: string;
  productID: string;
  price: number;
  removableFromCart: boolean;
}

export interface TotalOneTimeCalculatedPrice {
  discountAmount: number;
  discountPercentage: number;
  finalAmount: number;
  finalTaxAmount: number;
  originalAmount: number;
  finalAmountIncludingTax: number;
  currency: string;
}

export interface Freeby {
  name: string;
  productID: string;
  freebieId: string;
  skuId: string;
}

export interface Discount {
  promotions: APP_TYPE_ANY[];
  totalAmount: number;
}

export interface MonthlyBill {
  name: string;
  description: string;
  includeByDefault: string;
  price: APP_TYPE_ANY;
  totalRecurringCalculatedPrice: TotalRecurringCalculatedPrice;
  productID: string;
  productSpecContainmentID: string;
  removableFromCart: boolean;
  billingOfferID: string;
  productSpecPricingID: string;
  simpleProductSpecID?: string;
}

export interface TotalRecurringCalculatedPrice {
  discountAmount?: number;
  discountPercentage?: number;
  finalAmount?: number;
  finalTaxAmount?: number;
  originalAmount?: number;
  finalAmountIncludingTax: APP_TYPE_ANY;
  recurringChargeFrequency?: RecurringChargeFrequency;
  currency?: string;
}

export interface RecurringChargeFrequency {
  frequencyUnit: string;
  frequencyFactor: number;
}

export interface FirstBill {
  name: string;
  description: string;
  price: number;
  totalOneTimeCalculatedPrice: TotalOneTimeCalculatedPrice2;
  billingOfferID: string;
  productID: string;
  productSpecContainmentID: string;
  removableFromCart: boolean;
  productSpecPricing: ProductSpecPricing;
  stockCode?: string;
}

export interface TotalOneTimeCalculatedPrice2 {
  discountAmount: number;
  discountPercentage: number;
  finalAmount: number;
  finalTaxAmount: number;
  originalAmount: number;
  finalAmountIncludingTax: number;
  currency: string;
}

export interface ProductSpecPricing {
  ID: string;
  includeByDefault: string;
  childPricingSchema: ChildPricingSchema;
  id: string;
}

export interface ChildPricingSchema {
  description: string;
  type: string;
  id: string;
  name: string;
}

export interface QuoteData {
  barId?: string;
  oneTimePrice?: number;
  recurringPrice?: number;
  checkOutPrice?: number;
  oneTimePriceBreakdown?: OneTimePriceBreakdown;
  recurringPriceBreakdown?: RecurringPriceBreakdown;
  checkOutPriceBreakdown?: CheckOutPriceBreakdown;
  billingOfferId?: string;
  productName?: string;
  accessories?: APP_TYPE_ANY[];
  hasSim?: boolean;
  newlyAddedSimDetails?: NewlyAddedSimDetails;
  mobile?: Mobile;
  originUpfrontPriceBreakdown?: OriginUpfrontPriceBreakdown;
  originUpfrontPriceCanBeOffset?: OriginUpfrontPriceCanBeOffset;
  originUpfrontPriceCanBeOffsetForTradeIn?: OriginUpfrontPriceCanBeOffsetForTradeIn;
  deliveryAddress?: DeliveryAddress;
  transactionId?: string;
  billingAddress?: BillingAddress;
  deliveryStartDateAndTime?: number;
  deliveryEndDateAndTime?: number;
  serviceRequiredDate?: number;
  deliveryTimeSlot?: string;
  isBlockOrder?: boolean;
  isAmbassadorOrder?: boolean;
  isUpgradePromoOrder?: boolean;
  productSpecificationLevel?: string;
}

export interface OneTimePriceBreakdown {
  finalAmount: number;
  finalTaxAmount: number;
  finalAmountIncludingTax: number;
}

export interface RecurringPriceBreakdown {
  finalAmount: number;
  finalTaxAmount: number;
  finalAmountIncludingTax: number;
}

export interface CheckOutPriceBreakdown {
  finalAmount: number;
  finalTaxAmount: number;
  finalAmountIncludingTax: number;
}

export interface NewlyAddedSimDetails {
  simProductId: string;
  simDetails: SimDetails;
}

export interface SimDetails {
  simType: string;
  skuId: string;
}

export interface Mobile {
  plan: Plan;
  device: Device;
  promotions: Promotions;
  freebies: Freeby[];
  vouchers: APP_TYPE_ANY[];
  deliveryCharges: DeliveryCharges;
  offersWithOneTimePrice: OffersWithOneTimePrice[];
  offersWithRecurringPrice: OffersWithRecurringPrice[];
  serviceId: string;
}

export interface Plan {
  name: string;
  price: number;
  billingOfferID: string;
  productSpecPricingID: string;
  basePriceSchemaId: string;
  productId: string;
}

export interface Device {
  repaymentChanges: RepaymentChanges;
  deviceCharges: DeviceCharges;
  repaymentChangesBreakdown: RepaymentChangesBreakdown;
  deviceChargesBreakdown: DeviceChargesBreakdown;
  model: string;
  brand: string;
  sku: string;
  name: string;
  repaymentChangesBOID: string;
  deviceProductID: string;
}

export interface RepaymentChanges {
  checkoutPrice: number;
  oneTimePrice: number;
  recurringPrice: number;
}

export interface DeviceCharges {
  checkoutPrice: number;
  oneTimePrice: number;
  recurringPrice: number;
}

export interface RepaymentChangesBreakdown {
  finalAmount: number;
  finalTaxAmount: number;
  finalAmountIncludingTax: number;
}

export interface DeviceChargesBreakdown {
  finalAmount: number;
  finalTaxAmount: number;
  finalAmountIncludingTax: number;
}

export interface Promotions {
  promotions: APP_TYPE_ANY[];
  totalAmount: number;
}

export interface Freeby {
  name: string;
  productID: string;
  freebieId: string;
  skuId: string;
}

export interface DeliveryCharges {
  name: string;
  description: string;
  billingOfferID: string;
  totalOneTimeCalculatedPrice: TotalOneTimeCalculatedPrice;
  productSpecContainmentID: string;
  productID: string;
  price: number;
  removableFromCart: boolean;
}

export interface TotalOneTimeCalculatedPrice {
  discountAmount: number;
  discountPercentage: number;
  finalAmount: number;
  finalTaxAmount: number;
  originalAmount: number;
  finalAmountIncludingTax: number;
  currency: string;
}

export interface OffersWithOneTimePrice {
  name: string;
  description: string;
  price: number;
  totalOneTimeCalculatedPrice: TotalOneTimeCalculatedPrice2;
  billingOfferID: string;
  productID: string;
  productSpecContainmentID: string;
  removableFromCart: boolean;
  productSpecPricing: ProductSpecPricing;
  stockCode?: string;
}

export interface TotalOneTimeCalculatedPrice2 {
  discountAmount: number;
  discountPercentage: number;
  finalAmount: number;
  finalTaxAmount: number;
  originalAmount: number;
  finalAmountIncludingTax: number;
  currency: string;
}

export interface ProductSpecPricing {
  ID: string;
  includeByDefault: string;
  childPricingSchema: ChildPricingSchema;
  id: string;
}

export interface ChildPricingSchema {
  description: string;
  type: string;
  id: string;
  name: string;
}

export interface OffersWithRecurringPrice {
  name: string;
  description: string;
  includeByDefault: string;
  price: APP_TYPE_ANY;
  totalRecurringCalculatedPrice: TotalRecurringCalculatedPrice;
  productID: string;
  productSpecContainmentID: string;
  removableFromCart: boolean;
  billingOfferID: string;
  productSpecPricingID: string;
  simpleProductSpecID?: string;
}

export interface TotalRecurringCalculatedPrice {
  discountAmount?: number;
  discountPercentage?: number;
  finalAmount?: number;
  finalTaxAmount?: number;
  originalAmount?: number;
  finalAmountIncludingTax: APP_TYPE_ANY;
  recurringChargeFrequency?: RecurringChargeFrequency;
  currency?: string;
}

export interface RecurringChargeFrequency {
  frequencyUnit: string;
  frequencyFactor: number;
}

export interface OriginUpfrontPriceBreakdown {
  finalAmount: number;
  finalTaxAmount: number;
  finalAmountIncludingTax: number;
}

export interface OriginUpfrontPriceCanBeOffset {
  finalAmount: number;
  finalTaxAmount: number;
  finalAmountIncludingTax: number;
}

export interface OriginUpfrontPriceCanBeOffsetForTradeIn {
  finalAmount: number;
  finalTaxAmount: number;
  finalAmountIncludingTax: number;
}

export type CollectionAddressType = 'pop-station';

export interface MCSSCollectionAddress {
  apartment: string;
  buildingName: string;
  city: string;
  collectionAddressType: CollectionAddressType;
  country: string;
  countryCode: string;
  postcode: string;
  state: string;
  streetName: string;
  unstructAddress: boolean;
}

export interface MCSSRrpFullpriceDeliveryAddress {
  blockOrHouseNum: string;
  country: string;
  postcode: string;
  streetName: string;
  unitNumber?: string;
  unstructAddress: boolean;
  block?: string;
  blockOrHouseAddr?: string;
  buildingClassification?: string;
  floor?: string;
  buildingName?: string;
  houseNumber?: string;
  zipcode?: string;
}

interface MCSSDeliveryAddress {
  addressId?: string;
  blockOrHouseNum?: string;
  buildingClassification?: string;
  buildingName?: string;
  city?: string;
  postcode?: string;
  streetName?: string;
  unitDescription?: string;
  unstructAddress?: boolean;
}

export interface MCSSPopstationAddress {
  KioskId: string;
  UnitNumber?: string;
  HouseBlockNumber: string;
  BuildingName: string;
  StreetName: string;
  PostCode: string;
  ZipCode: string;
  Distance: number;
  POPStationName: string;
  Storey?: string;
}

export interface DeliveryAddress extends MCSSDeliveryAddress {
  unitNumber?: string;
  buildingName?: string;
  streetName: string;
  blockNumber: string;
  postalCode: string;
}

export interface BillingAddress {
  city: string;
  floor: string;
  postcode: string;
  room: string;
  unitNumber: string;
  houseNumber: string;
  streetName: string;
  country: string;
  unstructAddress: boolean;
}

// Product Order
export interface ProductOrder {
  productOrderId?: string;
  productOrderReferenceNumber?: string;
  productOrderItemId?: string;
  productId?: string;
  type?: string;
  service?: Service;
  sim?: Sim;
  hasSilverComponent?: boolean;
}

export interface Service {
  devices?: Device[];
  offeringDescription?: string;
  shared?: boolean;
  paymentCategory?: string;
  chNodeId?: number;
  subscriptionId?: number;
  serviceId?: string;
  personName?: string;
  subscriptionTypeX8?: string;
  lob?: string;
  offeringInstanceId?: string;
  offeringToProductId?: string;
  offeringId?: string;
  customerIdX8?: string;
  spoName?: string;
  spoDescription?: string;
  offeringName?: string;
  fixedServiceIndX8?: boolean;
  productName?: string;
  bundle?: boolean;
  personId?: string;
  plan?: Plan;
  address?: KeyValue;
  id?: string;
  status?: string;
  subscriberCategoryX8?: string;
  reasonCodeX8?: string;
  changeActionAllowed?: boolean;
  multiLineIndX8?: boolean;
  hasPendingOrdersX8?: boolean;
  easyMobileIndX8?: boolean;
  lineOfBusiness?: string;
  catalogItemID?: string;
  links?: Link[];
  pricePackages?: PricePackage[];
  ceaseActionAllowed?: boolean;
  keyLineIndX8?: boolean;
  services?: Service2[];
  is5GSimService?: boolean;
  isMultiSimService?: boolean;
  customerId?: string;
}

export interface Device {
  productSpecContainmentID: string;
  catalogItemName: string;
  catalogItemDescription: string;
  id: string;
  parameters: Parameter[];
}

export interface Parameter {
  businessType: string;
  catalogId: string;
  name: string;
  valueName?: string;
}

export interface Plan {
  catalogItemID: string;
  catalogItemName: string;
  catalogItemDescription: string;
  id: string;
}

export interface Link {
  href: string;
  rel: string;
}

export interface PricePackage {
  catalogItemID: string;
  catalogItemName: string;
  catalogItemDescription: string;
  id: string;
  status: string;
}

export interface Service2 {
  initialActivationDate: number;
  businessType: string;
  catalogItemID: string;
  links: Link2[];
  catalogItemName: string;
  catalogItemDescription: string;
  id: string;
  status: string;
  serviceType: string;
  containedServices?: ContainedService[];
}

export interface Link2 {
  href: string;
  rel: string;
}

export interface ContainedService {
  initialActivationDate: number;
  businessType: string;
  catalogItemID: string;
  catalogItemName: string;
  catalogItemDescription: string;
  id: string;
  status: string;
  serviceType: string;
}

export interface Sim {
  hasSim: boolean;
  productId: string;
  is5GSim: boolean;
}

export interface SelectedBillingOptions {
  type: string;
  contactId: string;
  nric: string;
  skipNotification: boolean;
  intFlowType: string;
  deliveryMethod: string;
  billType: string;
}

export enum PaymentType {
  OFFLINE = 'offline',
  ONLINE = 'online'
}

export interface Contract {
  documentId: string;
  associationId: string;
}

export interface PromoCodeInfo {
  isValid: boolean;
  errorMessage: string;
  promoCode?: string;
  isLoading: boolean;
}

export enum PromoCodeEmpty {
  EMPTY = 'EMPTY',
  CLEAR = 'CLEAR'
}

export interface EAppointmentData {
  eApptId: string;
  eApptState: string;
  storeAptDetails: {
    customerId: string;
    customerIdType: string;
    externalId: string;
    site: {
      shopName: string;
      shopNameShort: string;
      shopCode: string;
      siteId: string;
      dealerCode: string;
      region: string;
    };
    startTimestamp: number;
    endTimestamp: number;
    stateChangeTimestamp: number;
    updateCount: number;
    creationTimestamp: number;
    transactionType: string;
    serviceType: string;
    source: string;
    name: string;
    contactNumber: string;
    contactEmail: string;
    slotDesc: string;
    slotDate: string;
    isChangeAllowed: boolean;
    isCancelAllowed: boolean;
  };
  dealerCode: string;
}

export interface MembershipInfo {
  member: boolean;
  title: string;
  description: string;
  footer: string;
  icon: string;
  value: string;
}

export interface OrderSummaryDataModel extends KeyValue {
  membershipData?: {
    value?: string;
    title: string;
    subTitle: string;
    note?: string;
    icon?: string;
    isValid?: boolean;
    isComingFromApp?: boolean;
  };
}

interface DeliveryOption {
  popStation: boolean;
  courier: boolean;
  snailMail: boolean;
  storePickUp: boolean;
  collectNow: boolean;
}

interface TransactionType {
  newNewNumber: boolean;
  newNewPortin: boolean;
  existingNewNumber: boolean;
  existingNewPortin: boolean;
  recon: boolean;
}

export interface PaymentOption {
  pageTitle: string;
  paymentMethodKey: string;
  paymentTitle: string;
  paymentDesc: string;
  paymentMinAmount: number;
  paymentMaxAmount: number;
  minTenure: number;
  deposit: boolean;
  deliveryOption: DeliveryOption;
  deliveryOptionKeys: string[];
  transactionType: TransactionType;
}

export interface FormattedPaymentOption {
  type: string;
  icon: string;
  title: string;
  description: string;
}

export type IsPaymentEnabledParams = Omit<
  GetPaymentOptionsParams,
  'paymentOptions'
> & {
  paymentOption: PaymentOption;
};

export interface GetPaymentOptionsParams {
  paymentOptions: PaymentOption[];
  deliveryMethod: string;
  flow: string;
  isExistingCustomer: boolean;
}

export interface PaymentRequest {
  orderId: string;
  referenceId: string;
  paymentAmount: number;
  paymentStatus: string;
  paymentMethod: string;
  account: string;
  email: string;
  customerName: string;
  customerDetails: string;
  orderDetails: string;
  contextDetails: string;
  merchantCode: string;
  comments: string;
  currency: string;
}

export interface PaymentContextDetails {
  methodType?: string;
  customerID?: number;
  orderId?: string;
  orderDetails?: OrderDetail[];
  productOrderReferenceNumber?: string;
  contactId?: string;
  barId?: string;
  deviceResDetails?: APP_TYPE_ANY[];
  selectedOption?: string;
  allocationIDs?: string[];
  reservationIDs?: string[];
  paymentMode?: string;
  selectedBillOption?: SelectedBillOption;
  nric?: string;
  billPayer?: string;
  dwfmTokenId?: null;
  collectNowData?: null;
  smsNotification?: APP_TYPE_ANY[];
  emailNotification?: APP_TYPE_ANY[];
  isRrpIpp?: boolean;
  dashPayment?: boolean;
}

export interface OrderDetail {
  productOrderItemID?: string;
  productID?: string;
  level?: string;
  lob?: string;
  flowType?: string;
  orderType?: string;
  isPPOrder?: boolean;
  msisdn?: string;
  simProductId?: string;
  deviceProductId?: string;
  freebiesIds?: APP_TYPE_ANY[];
  accessoriesIds?: APP_TYPE_ANY[];
  notFreeMBB?: boolean;
  planOnly?: boolean;
}

export interface SelectedBillOption {
  type?: string;
  contactId?: string;
  nric?: string;
  skipNotification?: boolean;
  deliveryMethod?: string;
  billType?: string;
  intFlowType?: string;
}

export interface ShowBillingCheck {
  isESimOrder: boolean;
  isNewNewFlow: boolean;
  userCISMyInfoAvailable: boolean;
  isCompleteMyInfoData: boolean;
}

export interface PromoCodeOptions {
  productOrderId?: string;
  updateProducts?: boolean;
  productOrder: ProductOrder;
  userInformation: UserInformation;
  orderSummaryPayload: KeyValue;
  customerId?: string;
}
