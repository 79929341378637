import styled from 'styled-components';
import Theme from '../../theme';
import { remCalc } from '@lux/helpers';

export const H1 = styled.h1`
  display: block;
  color: ${({ theme }) => theme.colours.page_text_colour_pri};
  font-family: ${Theme.fonts.headingMobile};
  font-weight: 300;
  font-size: ${remCalc(32)};
  line-height: 1.25;
  margin: 0;
  ${Theme.media.md`
  font-family: ${Theme.fonts.headingDesktop};
  font-weight: 100; 
  font-size: ${remCalc(48)};
  line-height: 1.2;
  `};
`;

export const H2 = styled.h2`
  display: block;
  color: ${({ theme }) => theme.colours.page_text_colour_pri};
  font-family: ${Theme.fonts.secondary};
  font-weight: normal;
  font-size: ${remCalc(28)};
  line-height: 1.25;
  margin: 0;
  ${Theme.media.md`
  font-size: ${remCalc(40)};
  line-height: 1.2;
  `};
`;

export const H3 = styled.h3`
  display: block;
  color: ${({ theme }) => theme.colours.page_text_colour_pri};
  font-family: ${Theme.fonts.secondary};
  font-weight: normal;
  font-size: ${remCalc(24)};
  line-height: 1.3;
  margin: 0;
  ${Theme.media.md`
  font-size: ${remCalc(32)};
  line-height: 1.25;
  `};
`;

export const H4 = styled.h4`
  display: block;
  color: ${({ theme }) => theme.colours.page_text_colour_pri};
  font-family: ${Theme.fonts.secondary};
  font-weight: normal;
  font-size: ${remCalc(20)};
  line-height: 1.3;
  margin: 0;
  ${Theme.media.md`
  font-size: ${remCalc(24)};
  `};
`;

export const H5 = styled.h5`
  display: block;
  color: ${({ theme }) => theme.colours.page_text_colour_pri};
  font-family: ${Theme.fonts.secondary};
  font-weight: normal;
  font-size: ${remCalc(18)};
  line-height: 1.3;
  margin: 0;
  ${Theme.media.md`
  font-size: ${remCalc(20)};
  `};
`;

export const H6 = styled.h6`
  display: block;
  font-family: ${Theme.fonts.secondary};
  font-weight: normal;
  font-size: ${remCalc(17)};
  line-height: 1.3;
  margin: 0;
  ${Theme.media.md`
  font-size: ${remCalc(18)}
  `};
`;

const Subtitle = styled.span`
  display: block;
  font-weight: normal;
  font-size: ${remCalc(16)};
  line-height: 1.5;
  margin: 0;
`;

export const SubtitlePrimary = styled(Subtitle)`
  font-family: ${Theme.fonts.secondary};
`;

export const SubtitleSecondary = styled(Subtitle)`
  font-family: ${Theme.fonts.primary};
`;

const Body = styled.p`
  display: block;
  font-weight: normal;
  font-size: ${remCalc(14)};
  line-height: 1.5;
  letter-spacing: ${remCalc(0.25)};
  margin: 0;
`;

export const BodyPrimary = styled(Body)`
  font-family: ${Theme.fonts.secondary};
`;

export const BodySecondary = styled(Body)`
  font-family: ${Theme.fonts.primary};
`;

const Footnote = styled.span`
  display: block;
  font-weight: normal;
  font-size: ${remCalc(12)};
  line-height: 1.5;
  letter-spacing: ${remCalc(0.25)};
  margin: 0;
`;

export const FootnotePrimary = styled(Footnote)`
  font-family: ${Theme.fonts.secondary};
`;

export const FootnoteSecondary = styled(Footnote)`
  font-family: ${Theme.fonts.primary};
`;

export const Caption = styled.span`
  display: block;
  color: ${({ theme }) => theme.colours.page_text_colour_pri};
  font-family: ${Theme.fonts.secondary};
  font-weight: normal;
  font-size: ${remCalc(12)};
  line-height: 1.5;
  letter-spacing: ${remCalc(0.5)};
  text-transform: uppercase;
  margin: 0;
  ${Theme.media.md`
  font-size: ${remCalc(12)};
  `};
`;

export const HR = styled.hr`
  margin: 0;
  border: none;
  border-top: 1px solid ${p => p.theme.colours.grey_400};
`;

export const UnorderedList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export default {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  SubtitlePrimary,
  SubtitleSecondary,
  BodyPrimary,
  BodySecondary,
  FootnotePrimary,
  FootnoteSecondary,
  Caption,
  HR,
  UnorderedList
};
