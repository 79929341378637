import React, { useEffect } from 'react';
import { getCookie, getUrlParams } from '@lux/helpers';
import PropTypes from 'prop-types';
import OnePassLoginPage from '../OnePassLoginPage';
import OldWorryFreeLogin from '../WorryFreeLogin';
import WorryFreeOtp from '../WorryFreeOtp';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { WorryFreeLogin } from '../WorryFreeLogin/NewWorryFreeLogin';
import {
  FEATURE_FLAG_ENABLE_NEW_WORRY_FREE,
  UIAM_FLOW
} from '../../types/featureFlag.types';
import { getUIAMLoginUrl } from '../../config';

import { navigation } from '../../middlewares/navigation-constants';
import CONSTANT from '../../constants/common';

const WORRY_FREE_LOGIN = 'WORRY_FREE_LOGIN';
const WORRY_FREE_OTP = 'WORRY_FREE_OTP';
const ONE_PASS_LOGIN = 'ONE_PASS_LOGIN';
const LOGGED_IN = 'LOGGED_IN';

const Auth = props => {
  const {
    isAuthenticated,
    setAuthStatus,
    authStatus,
    isWorryFreeEnabled,
    worryFreeInitialFormData,
    loading
  } = props;

  useEffect(() => {
    const urlParams = getUrlParams();

    if (
      ((urlParams.loginstatus || !isWorryFreeEnabled) && !isAuthenticated) ||
      urlParams.redirectionUrl
    ) {
      // Navigate to One Pass if invalid onepass username and password combination is entered
      if (isFeatureFlagEnabled(UIAM_FLOW)) {
        const uiamAccessToken = getCookie(CONSTANT.UIAM_ACCESS_TOKEN);
        if (!uiamAccessToken) {
          window.open(getUIAMLoginUrl(navigation.CHOOSE_NUMBER_PAGE), '_self');
        }
      } else {
        setAuthStatus(ONE_PASS_LOGIN);
      }
    } else if (!isAuthenticated) {
      // Navigate to Worry Free if we are not logged in
      setAuthStatus(WORRY_FREE_LOGIN);
    } else {
      setAuthStatus(LOGGED_IN);
    }
  }, [isAuthenticated, isWorryFreeEnabled, setAuthStatus]);

  if (loading) {
    return (
      <div data-testid="auth-loading">
        <LoadingOverlay />
      </div>
    );
  }

  if (isFeatureFlagEnabled(FEATURE_FLAG_ENABLE_NEW_WORRY_FREE)) {
    if (authStatus === LOGGED_IN || !authStatus) {
      return props.children;
    }
    return <WorryFreeLogin initialFormData={worryFreeInitialFormData} />;
  } else {
    switch (authStatus) {
      case WORRY_FREE_LOGIN:
        return (
          <OldWorryFreeLogin
            initialFormData={worryFreeInitialFormData}
            disabledFields={{
              idNumber: true,
              idPrefix: true,
              idType: true
            }}
          />
        );
      case WORRY_FREE_OTP:
        return <WorryFreeOtp />;
      case ONE_PASS_LOGIN:
        return <OnePassLoginPage showBackButton={isWorryFreeEnabled} />;
      default:
        break;
    }
  }

  return props.children;
};

Auth.defaultProps = {
  isWorryFreeEnabled: true
};

Auth.propTypes = {
  isAuthenticated: PropTypes.bool,
  setAuthStatus: PropTypes.func,
  authStatus: PropTypes.oneOf([
    WORRY_FREE_LOGIN,
    WORRY_FREE_OTP,
    ONE_PASS_LOGIN,
    LOGGED_IN
  ]),
  /** Set the if Worry Free login is enabled */
  isWorryFreeEnabled: PropTypes.bool,
  worryFreeInitialFormData: PropTypes.shape({
    mobileNumber: PropTypes.string,
    idNumber: PropTypes.string
  }),
  loading: PropTypes.bool,
  children: PropTypes.any
};

export default Auth;
