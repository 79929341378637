import React from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from '@lux/components';
import styled from 'styled-components';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import ShowMore from 'react-show-more';

import { api } from '@detox/actions';

import AddonItem from '../AddonItem';
import RoundedPlanCard from '../RoundedPlanCard';
import { H4, HR, SubtitleSecondary } from '../Base';
import Notification from '../Notification';

import { isEmpty } from '../../helpers/common';

import addonErrors, {
  dataXDynamicProductTitle
} from '../../config/addon-errors.js';

const ShowMoreWrapper = styled.div`
  line-height: 1.5;
`;

const GroupPrimaryInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const GroupPrimaryInfoRow = styled.div`
  display: flex;
  margin-bottom: ${p => p.theme.spacing(2)};
  ${p => p.theme.media.md`
    margin-bottom: ${p.theme.spacing(3)}
  `};
`;

const StyledColumn = styled(props => <Column {...props} />)`
  ${p => p.theme.media.sm`
    padding-bottom: ${p.theme.spacing(3)};
  `};
`;

const GroupIcon = styled(Img)`
  min-height: 72px;
  max-height: 72px;
  min-width: 72px;
  max-width: 72px;
  border-radius: ${p => p.theme.spacing(2)};
  margin-right: ${p => p.theme.spacing(2)};
  border: ${p => `1px solid ${p.theme.colours.grey_400}`};

  ${p => p.theme.media.md`
    min-height: 80px;
    max-height: 80px;
    min-width: 80px;
    max-width: 80px;
  `};
`;

const NotificationWrapper = styled.div`
  margin-top: ${p => p.theme.spacing(2)};

  ${p => p.theme.media.md`
    margin-top: ${p.theme.spacing(2)};
  `};

  ${p => p.theme.media.lg`
    margin-top: 0;
  `};
`;

const ExpandText = styled.span`
  font-family: inherit;
  font-size: inherit;
  color: ${p => p.theme.colours.link};

  &:hover {
    color: ${p => p.theme.colours.linkVariant};
  }
`;

const AddonGroupItem = ({
  group,
  groupInfo,
  onAddonAdd,
  onAddonRemove,
  onAddonUpgrade
}) => {
  const { helpersAddons } = api.mcss;
  const { isDataXProductSpec } = helpersAddons;

  const { addons, groupName } = group;
  const addonsInGroup = addons;

  const addonInContract = addons?.[0]?.addonInContract;
  const hasAddonInContract = !isEmpty(addonInContract);

  const renderNotification = (sm, lg) => {
    const selectedAddonError = addonErrors[group.productSpecContainmentID];
    let addonErrorMessage = selectedAddonError && selectedAddonError.message;

    if (
      hasAddonInContract &&
      isDataXProductSpec(group.productSpecContainmentID)
    ) {
      addonErrorMessage = selectedAddonError.message.replace(
        dataXDynamicProductTitle,
        addonInContract.productTitle
      );
    }

    return (
      (hasAddonInContract || group.disabled) && (
        <Row>
          <Column sm={sm} lg={lg}>
            <NotificationWrapper>
              <Notification state="info">{addonErrorMessage}</Notification>
            </NotificationWrapper>
          </Column>
        </Row>
      )
    );
  };

  return (
    <RoundedPlanCard key={groupName}>
      <Row>
        <StyledColumn sm={12} lg={5}>
          <GroupPrimaryInfoRow>
            {groupInfo.iconImage && (
              <GroupIcon
                image={groupInfo.iconImage.childImageSharp.gatsbyImageData}
                alt={groupName}
              />
            )}
            <GroupPrimaryInfo>
              <H4>{groupName}</H4>
              <ShowMoreWrapper>
                <ShowMore
                  lines={2}
                  more={<ExpandText>more</ExpandText>}
                  less={<ExpandText>less</ExpandText>}
                >
                  <SubtitleSecondary>
                    {groupInfo.shortDescription}
                  </SubtitleSecondary>
                </ShowMore>
              </ShowMoreWrapper>
            </GroupPrimaryInfo>
          </GroupPrimaryInfoRow>

          {groupInfo.setupFee && (
            <SubtitleSecondary>
              Setup fee: {groupInfo.setupFee}
            </SubtitleSecondary>
          )}
          {renderNotification(12, false)}
        </StyledColumn>
        <Column sm={12} md={false} lg={false}>
          <HR />
        </Column>
        <Column sm={12} lg={6} lgOffset={1}>
          {renderNotification(false, 12)}
          {addonsInGroup
            .sort((a, b) => a.productTitle.localeCompare(b.productTitle))
            .map((addon, index) => (
              <AddonItem
                key={index}
                isGroupItem
                addon={addon}
                onAdd={onAddonAdd}
                onRemove={onAddonRemove}
                onUpgrade={onAddonUpgrade}
              />
            ))}
        </Column>
      </Row>
    </RoundedPlanCard>
  );
};

AddonGroupItem.propTypes = {
  group: PropTypes.object,
  groupInfo: PropTypes.object,
  onAddonAdd: PropTypes.func,
  onAddonRemove: PropTypes.func,
  onAddonUpgrade: PropTypes.func
};

export default AddonGroupItem;
