import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseState } from '../../types/state.types';
import AUTH_CONSTANTS from '../../constants/auth';
import {
  authActions,
  rrpCartActions,
  SubscriptionIppDetail,
  userActions
} from '@detox/actions';

import { navigation } from '../../middlewares/navigation-constants';
import { Spacing, Text, Row, Column, Grid, Selector } from '@dls/web';
import { trans as t } from '../../helpers/localisation';
import { navigate } from 'gatsby';
import { resetCartState } from '../../reducers/rrpCart';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useLoader } from '../../hooks/useLoader';
import { useModal } from '../../hooks/useModal';
import { getUIAMEnabledWithToken } from '../../selectors';
import { dataLayerPush } from '../../helpers/common';
import { getRrpProductDetailsAddToCartDataLayer } from '../../helpers/dataLayerHelpers';
import { EventLabel } from '../../types/dataLayer.type';
import { useFlowCheck } from '../../hooks/useFlowCheck/useFlowCheck';

export const RRPServiceList = (): ReactElement => {
  const dispatch = useDispatch();
  const { user, auth, rrpCart, apigeeTokenEnabled } = useSelector<
    BaseState,
    Partial<BaseState>
  >(state => ({
    user: state.user,
    auth: state.auth,
    rrpCart: state.rrpCart,
    apigeeTokenEnabled: getUIAMEnabledWithToken(state)
  }));
  useFlowCheck({
    flowData: [rrpCart?.tempCartItem?.product],
    navigateUrl: `/${navigation.RRP_PRODUCT_CATALOG}`
  });
  const { renderError } = useErrorHandler({
    states: [user, auth, rrpCart],
    shouldRedirect: false
  });
  const { Loader } = useLoader([user, auth, rrpCart]);
  const authStatus = auth.status;
  const { setModalData, renderedModal } = useModal();
  const isUserLoggedIn = user?.information?.clientContext;
  useEffect(() => {
    if (apigeeTokenEnabled) {
      dispatch(userActions.getUserInformation());
    }
  }, [apigeeTokenEnabled]);

  const backToSelection = () => {
    dispatch(authActions.resetAuth());
    if (rrpCart.pagePathname) {
      navigate(rrpCart.pagePathname, { replace: true });
    } else {
      window.history.go(-1);
    }
  };

  useEffect(() => {
    if (rrpCart.isEligibleForIPP === false) {
      setModalData({
        visible: true,
        title: t('RRP_NOT_MET_ELIGIBILITY_TITLE') as string,
        message: t('RRP_NOT_MET_ELIGIBILITY_MESSAGE') as string,
        buttonText: t('BACK_TO_SELECTION_TEXT') as string,
        secondaryButtonText: t('BROWSE_OTHER_PRODUCT_TEXT') as string,
        secondaryCallback: () => {
          dispatch(authActions.resetAuth());
          navigate(`/${navigation.RRP_PRODUCT_CATALOG}`, { replace: true });
        },
        callback: () => {
          backToSelection();
        },
        onClose: () => {
          backToSelection();
        }
      });
    }
  }, [rrpCart.isEligibleForIPP]);

  useEffect(() => {
    if (
      authStatus === AUTH_CONSTANTS.LOGGED_IN &&
      !user.information?.clientContext
    ) {
      dispatch(userActions.getUserInformation());
    }
  }, [authStatus, user.information?.clientContext]);

  useEffect(() => {
    if (isUserLoggedIn && !rrpCart.ippSubscriptions?.length) {
      dispatch(
        rrpCartActions.retrieveRRPIPPSubscriptions(
          user.information.clientContext?.contact?.contactId,
          {
            dob: user.information.clientContext?.contact?.dateOfBirth
          }
        )
      );
    }
  }, [user.information]);

  useEffect(() => {
    if (rrpCart.finishCreateIppOrder) {
      navigateToShoppingCart();
    }
  }, [rrpCart.finishCreateIppOrder]);

  const createRrpIppOrder = (subscription: SubscriptionIppDetail) => {
    const product = rrpCart.tempCartItem?.product;
    dispatch(
      rrpCartActions.createRRPIPPOrder({
        contactId: user.information.clientContext?.contact?.contactId,
        customerId: subscription.customerID,
        serviceId: subscription.serviceID,
        assignedProductID: subscription.apID,
        sku: product?.sku,
        simpleProductSpecID: product?.deviceId,
        numberOfInstalments: rrpCart.tempCartItem?.selectedPaymentTerm?.value,
        createdIppOrderData: rrpCart.ippOrderData
      })
    );
  };

  const navigateToShoppingCart = () => {
    dispatch(resetCartState());
    dataLayerPush(
      getRrpProductDetailsAddToCartDataLayer({
        baseParams: {
          cartItems: rrpCart?.addedItems,
          location: window.location,
          ippDetails: rrpCart?.tempCartItem
        },
        eventLabel: EventLabel.ADD_TO_CART
      })
    );
    navigate(`/${navigation.RRP_IPP_SHOPPING_CART}`);
  };

  const onServiceClicked = (subscription: SubscriptionIppDetail) => {
    createRrpIppOrder(subscription);
  };

  const renderServiceList = (): ReactElement => {
    return (
      <Spacing top={2}>
        <Row>
          <Column xs={12}>
            <Text type="pageTitle">{t('RRP_SERVICE_LIST_HEADER')}</Text>
            <Spacing topBottom={2} responsive={false}>
              <Text type="body">{t('YOUR_CURRENT_SERVICE_TEXT')}</Text>
            </Spacing>
          </Column>
        </Row>
        <Loader>
          <Row>
            {rrpCart.ippSubscriptions.map((subscription, index) => (
              <Column
                data-testid={`service-${index}`}
                key={subscription.serviceID}
                xs={12}
                sm={6}
              >
                <div>
                  <Selector
                    autoHeight={false}
                    alignment="row"
                    selected={
                      subscription.serviceID === rrpCart.ippOrderData?.serviceId
                    }
                    value={subscription.serviceID}
                    onClick={() => {
                      onServiceClicked(subscription);
                    }}
                    disabled={!subscription.isEligible}
                  >
                    <Selector.Body
                      disabled={!subscription.isEligible}
                      title={subscription.serviceID}
                      subtitle={subscription.planDetails?.planName}
                      align="start"
                    />
                  </Selector>
                </div>
                {!subscription.isEligible && (
                  <Spacing top={1} responsive={false}>
                    <Text type="smallBody">
                      {t('RRP_SERVICE_NO_ELIGIBLE_TEXT')}
                    </Text>
                  </Spacing>
                )}
              </Column>
            ))}
          </Row>
        </Loader>
      </Spacing>
    );
  };

  return (
    <Grid>
      {renderServiceList()} {renderError()}
      {renderedModal}
    </Grid>
  );
};
