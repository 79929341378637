import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SubtitleSecondary } from '../Base';

import IconSuccess from '../../assets/svgs/tick-circle.svg';
import IconDanger from '../../assets/svgs/exclamation-circle.svg';

const BaseNotification = styled.div`
  display: flex;
  color: ${p => p.theme.states[p.state].colour};
  background-color: ${p => p.theme.states[p.state].background};
  padding: ${p => p.theme.spacing(1.5)} ${p => p.theme.spacing(2)};
  border-radius: 4px;
`;

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${p => p.theme.spacing(0.5)};
  margin-right: ${p => p.theme.spacing(1.5)};

  > svg,
  path {
    fill: ${p => p.theme.states[p.state].iconColour};
  }
`;

const icons = {
  success: <IconSuccess width={16} height={16} />,
  danger: <IconDanger width={16} height={16} />,
  info: <IconDanger width={16} height={16} />
};

const Notification = props => {
  const { state, children } = props;

  return (
    <BaseNotification state={state}>
      <StyledIcon state={state}>{icons[state]}</StyledIcon>
      <SubtitleSecondary as="span">{children}</SubtitleSecondary>
    </BaseNotification>
  );
};

Notification.defaultProps = {
  state: 'success'
};

Notification.propTypes = {
  children: PropTypes.node,
  state: PropTypes.oneOf(['success', 'danger', 'info'])
};

export default Notification;
