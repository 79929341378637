import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Column, Row } from '@lux/components';
import { noop } from '@lux/helpers';

import { H4, SubtitlePrimary, SubtitleSecondary } from '../Base';
import Button from '../Button';

import formatPrice from '../../helpers/formatPrice';
import { ADDON } from '../../constants';

const StyledCard = styled.div`
  padding: ${p => p.theme.spacing(3)};
  margin-bottom: ${p => p.theme.spacing(3)};
  border-radius: ${p => p.theme.spacing(2)};
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.03),
    0 8px 23px 8px rgba(0, 0, 0, 0.05), 0 24px 27px -8px rgba(0, 0, 0, 0.08);

  ${p => p.theme.media.md`
    padding: ${p.theme.spacing(4)};
    box-shadow: 0 8px 23px 8px rgba(0, 0, 0, 0.05);
  `};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledCardItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${p => p.theme.spacing(3)} 0;
  border-bottom: 1px solid ${p => p.theme.colours.grey_400};

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
    padding-bottom: 0;
  }

  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
  }
`;

const StyledH4 = styled(H4)`
  margin-bottom: ${p => p.theme.spacing(3)};
`;

const StyledAlignLeft = styled.div`
  ${p => p.theme.media.md`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `};
`;

const StyledButton = styled(Button)`
  float: right;
  min-width: 100px;
  width: 100px;
  height: 32px;

  ${p => p.theme.media.md`
    height: 48px;
    margin-left: ${p.theme.spacing(5)};
  `};
`;

const AddonSimOnlyItem = ({
  addon,
  selectedAddons,
  isGroupSelected,
  onAdd,
  onRemove,
  type
}) => {
  const { addonId, quantity, price } = addon;

  const canAdd = !selectedAddons?.includes(addonId);

  const handleClick = () => {
    if (canAdd) {
      onAdd(addon, type);
    } else {
      onRemove(addon, type);
    }
  };

  return (
    <StyledCardItem key={addonId} disabled={isGroupSelected && canAdd}>
      <StyledAlignLeft>
        <SubtitlePrimary as="div">{`${quantity}`}</SubtitlePrimary>
        <SubtitleSecondary as="div">{`${formatPrice(
          price
        )}/mth`}</SubtitleSecondary>
      </StyledAlignLeft>
      <StyledButton
        tertiary
        onClick={handleClick}
        data-testid={`${quantity} $${price}`}
      >
        {canAdd ? 'Add' : 'Remove'}
      </StyledButton>
    </StyledCardItem>
  );
};

const AddonsSimOnlyList = ({
  selectedAddons = [],
  addonSimOnlyPlan,
  onAdd = noop,
  onRemove = noop,
  showingAddonsCount,
  showAllAddons = true
}) => {
  const { data, talktime, sms } = addonSimOnlyPlan;

  const dataIds = data.map(addon => addon.addonId);
  const talkTimeIds = talktime.map(addon => addon.addonId);
  const smsIds = sms.map(addon => addon.addonId);

  const isDataSelected = selectedAddons?.some(id => dataIds.includes(id));
  const isTalkTimeSelected = selectedAddons?.some(id =>
    talkTimeIds.includes(id)
  );
  const isSmsSelected = selectedAddons?.some(id => smsIds.includes(id));

  let totalShowingAddonsCount = showingAddonsCount;

  const checkAddonDisplay = () => {
    if (!showAllAddons) {
      totalShowingAddonsCount = totalShowingAddonsCount + 1;
      return totalShowingAddonsCount <= ADDON.DEFAULT_SHOW_ADDONS_COUNT;
    }
    return true;
  };

  return (
    <Fragment>
      {data.length > 0 && checkAddonDisplay() && (
        <StyledCard>
          <Row>
            <Column sm={12} lg={6}>
              <StyledH4>Upsize Data</StyledH4>
            </Column>
            <Column>
              {data.map(addon => (
                <AddonSimOnlyItem
                  key={addon.addonId}
                  addon={addon}
                  selectedAddons={selectedAddons}
                  isGroupSelected={isDataSelected}
                  onAdd={onAdd}
                  onRemove={onRemove}
                  type="data"
                />
              ))}
            </Column>
          </Row>
        </StyledCard>
      )}
      {talktime.length > 0 && checkAddonDisplay() && (
        <StyledCard>
          <Row>
            <Column sm={12} lg={6}>
              <StyledH4>Upsize Talktime</StyledH4>
            </Column>
            <Column>
              {talktime.map(addon => (
                <AddonSimOnlyItem
                  key={addon.addonId}
                  addon={addon}
                  selectedAddons={selectedAddons}
                  isGroupSelected={isTalkTimeSelected}
                  onAdd={onAdd}
                  onRemove={onRemove}
                  type="talktime"
                />
              ))}
            </Column>
          </Row>
        </StyledCard>
      )}
      {sms.length > 0 && checkAddonDisplay() && (
        <StyledCard>
          <Row>
            <Column sm={12} lg={6}>
              <StyledH4>Upsize SMS</StyledH4>
            </Column>
            <Column>
              {sms.map(addon => (
                <AddonSimOnlyItem
                  key={addon.addonId}
                  addon={addon}
                  selectedAddons={selectedAddons}
                  isGroupSelected={isSmsSelected}
                  onAdd={onAdd}
                  onRemove={onRemove}
                  type="sms"
                />
              ))}
            </Column>
          </Row>
        </StyledCard>
      )}
    </Fragment>
  );
};

AddonsSimOnlyList.propTypes = {
  /** Set the selected addon */
  selectedAddons: PropTypes.array,
  /** Set the Sim Only plan upsize addons */
  addonSimOnlyPlan: PropTypes.object,
  /** Event handler to add the addon */
  onAdd: PropTypes.func,
  /** Event handler to remove the addon */
  onRemove: PropTypes.func
};

export default AddonsSimOnlyList;
