export const dataXDynamicProductTitle = `{{ productTitle }}`;

export default {
  11982: {
    message: 'Free trial is applicable for first-time users only'
  },
  9722: {
    message:
      'To remove this add-on, please go to My Singtel app > Manage plan and add-ons'
  },
  8472632: {
    message:
      'Maximum 3 subscriptions reached. To add another subscription, please remove one MobileSwop subscription via My Singtel app > ‘Manage plan and add-ons’ first'
  },
  14438091: {
    message: `To remove your existing ${dataXDynamicProductTitle} add-on, please go to My Singtel app > Manage plan and add-ons`
  }
};
