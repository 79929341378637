import styled from 'styled-components';
import { Column, Grid } from '@dls/web';
import {
  Plus as PlusIcon,
  BackArrow as BackArrowIcon
} from '@dls/assets/dist/icons/web';
import { SubtitlePrimary, H5, BodySecondary } from '../Base';
import { GatsbyImage as Img } from 'gatsby-plugin-image';

export const StyledImg = styled.img`
  height: 100px;
  margin-bottom: 10px;

  ${p => p.theme.media.lg`
    margin-bottom: 0;
    margin-right: 20px;
  `};
`;

export const StyledPlusIcon = styled(PlusIcon)`
  font-weight: bold;
  height: 40px;
  width: 40px;
  margin: 0 10px;
  ${p => p.theme.media.md`
    margin: 0 40px;
  `};
  ${p => p.theme.media.lg`
    margin: 0 50px;
  `};
`;

export const StyledImageCaptionContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 270px;
  flex-direction: column;
  ${p => p.theme.media.lg`
    flex-direction: row;
    flex: 0 1 auto;
  `};
`;

export const StyledImageListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${p => p.theme.media.md`
    flex-direction: row;
    justify-content: flex-start;
  `};
  ${p => p.theme.media.lg`
    flex-direction: row;
    justify-content: flex-start;
  `};
`;

export const StyledBlackArrow = styled.div`
  margin: 6px 5px 0 0;
`;

export const StyledHeroImage = styled(Img)`
  margin-bottom: ${p => p.theme.spacing(2)};
  ${p => p.theme.media.md`
  margin-bottom: ${p.theme.spacing(2)};
`};
`;

export const StyledMainGridContainer = styled(Grid)`
  ${p => p.theme.media.md`
    padding: 0;
  `};
  ${p => p.theme.media.lg`
    padding: 0;
  `};
`;

export const StyledTitle = styled(H5)`
  margin-bottom: ${p => p.theme.spacing(3)};
  height: ${p => p.theme.spacing(6)};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${p => p.theme.colours.nearBlack};
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${p => p.theme.media.lg`
    flex-direction: row;
  `};
`;

export const StyledBackArrowIcon = styled(BackArrowIcon)`
  color: ${p => p.theme.colours.page_link_colour};
`;

export const StyledImgPlus = styled.img`
  width: 30px;
  margin: 0 10px;
  ${p => p.theme.media.md`
    margin: 0 40px;
  `};
  ${p => p.theme.media.lg`
    margin: 0 50px;
  `};
`;

export const StyledSimOnlyImageCaption = styled(SubtitlePrimary)`
  text-align: center;
  max-width: 130px;

  ${p => p.theme.media.lg`
    max-width: none;
    text-align: left;
  `};
  color: ${p => getTextColor(p)};
`;

const getTextColor = p => {
  const isProduction = process.env.NODE_ENV === 'production';
  return p.isCis && !isProduction ? p => p.theme.colours.white : 'inherit';
};

export const StyledDeviceInstallmentPlanImageCaption = styled(SubtitlePrimary)`
  text-align: center;
  max-width: 130px;
  ${p => p.theme.media.lg`
    max-width: none;
    text-align: left;
  `};
  color: ${p => getTextColor(p)};
`;

export const StyledPlanSelectionArrowContainer = styled(Column)`
  margin-bottom: 20px;
  padding-right: 0;
  ${p => p.theme.media.md`
    margin-bottom: 0;
    padding-right: 18px;
  `};
`;

export const StyledNameCard = styled(BodySecondary)`
  color: ${p => p.theme.colours.grey_600};
  padding-bottom: 5px;
`;

export const StyledPhoneSelectionContainer = styled(Column)`
  padding-left: 60px;
  @media (max-width: 48em) {
    padding-left: 0;
  }
`;

export const StyledListItemPlan = styled.li`
  &:before {
    content: '✓';
    color: ${p => p.theme.colours.turqoise};
    margin-right: ${p => p.theme.spacing(1)};
  }
`;

export const StyledUnorderedListPlan = styled.ul`
  list-style: none;
  color: ${p => p.theme.colours.grey_600};
  margin: 0;
  padding: 0 0 0 ${p => p.theme.spacing(2.5)};
  text-indent: ${p => p.theme.spacing(-2.5)};

  li > p {
    display: inline;
  }
`;

export const StyledPlanDescContainer = styled.div`
  @media (max-width: 48em) {
    display: none;
  }
`;
