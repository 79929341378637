import React from 'react';
import { Carousel } from '@dls/web';

type PropTypes = {
  banners: Banner[];
};

type Banner = {
  title: string;
  description: string;
  theme: string;
  sectionTitle: string;
  sectionDescription: string;
  ctaText: string;
  ctaLink: string;
  desktopFileReference: string;
  mobileFileReference: string;
  enableCTA: boolean;
};

export const BannerList = (props: PropTypes) => {
  const { banners } = props;

  const onBannerClicked = (banner: Banner) => {
    banner.ctaLink && window.open(banner.ctaLink, '_self');
  };

  const renderBanner = () => {
    return banners.map(banner => {
      return (
        <Carousel.CarouselContent
          imageRatio="1:2"
          desktopImage={banner.desktopFileReference}
          mobileImage={banner.mobileFileReference}
          title={banner.title}
          body={banner.description}
          buttonText={banner.enableCTA && banner.ctaText}
          onClick={() => {
            onBannerClicked(banner);
          }}
          position="left"
        />
      );
    });
  };

  return (
    <Carousel fullWidth type="image">
      {renderBanner()}
    </Carousel>
  );
};
