import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { getUrlParams } from '@lux/helpers';
import { connect } from 'react-redux';

import CisError from '../components/CisError';
import SEO from '../components/SEO';
import { PhoneCatalog } from '../components/PhoneCatalog/PhoneCatalog';

import { userActions } from '@detox/actions';

import notifications from '../config/notifications.js';

import '../fragments/catalog-phone-detail';
import '../fragments/plan-detail';
import '../fragments/aem-components';
import { useLocation } from '@reach/router';
import isFeatureFlagEnabled from '../helpers/feature-flags';
import { CIS_FLOW } from '../types/featureFlag.types';
import { CATALOGUE } from '../constants';
import { getParamsStringFromParamsObject } from '../helpers/stringHelper';
import { CatalogSkeletonLoader } from '../components/PhoneCatalog/SkeletonLoader';
import { useGatsbyInit } from '../hooks/useGatsbyInit';
import { getUIAMEnabledWithToken } from '../selectors';

export const CisIndexPage = ({
  location,
  data,
  validateCISToken,
  cisError,
  cisPlanRates,
  appState
}) => {
  const apigeeTokenEnabled = getUIAMEnabledWithToken(appState);
  const { isInit } = useGatsbyInit();

  // Check if token exists
  useEffect(() => {
    const urlParams = getUrlParams();

    if (urlParams.sessionToken && !cisPlanRates && apigeeTokenEnabled) {
      // if it exists, validate token
      if (isFeatureFlagEnabled(CIS_FLOW)) {
        validateCISToken(urlParams.sessionToken);
      }
    } else if (!cisPlanRates && apigeeTokenEnabled) {
      // if it does not, redirect to RES
      navigate('/');
    }
  }, [cisPlanRates, validateCISToken, apigeeTokenEnabled]);

  const [urlParams, setUrlParams] = useState({});
  useEffect(() => {
    const _urlParams = getUrlParams();
    setUrlParams(_urlParams);
    if (!isFeatureFlagEnabled(CIS_FLOW) && _urlParams.sessionToken) {
      window.location.replace(
        `${CATALOGUE.SPRING_CIS_URL}?${getParamsStringFromParamsObject(
          _urlParams
        )}`
      );
    }
  }, []);

  const renderPhoneCatalog = () => {
    if (!isInit) {
      return <CatalogSkeletonLoader />;
    }
    return (
      <PhoneCatalog
        isCisPage={true}
        data={data}
        previewMode={urlParams.preview === 'true'}
        location={location}
      />
    );
  };

  let renderContent = renderPhoneCatalog();

  // if it is invalid, show cis error
  if (cisError) {
    renderContent = (
      <CisError
        title={notifications[cisError].title}
        subTitle={notifications[cisError].subTitle}
        text={notifications[cisError].text}
        ctaText={notifications[cisError].ctaText}
        ctaUrl={notifications[cisError].ctaUrl}
      />
    );
  }

  return (
    <div className="fs-unmask">
      <SEO
        title="Phones"
        description="No Queuing. Beat the queues and shop from the comfort of your own home. Flexible Collection &amp; Delivery."
      />
      {renderContent}
    </div>
  );
};

CisIndexPage.defaultProps = {
  location: {}
};

CisIndexPage.propTypes = {
  data: PropTypes.object,
  validateCISToken: PropTypes.func,
  cisError: PropTypes.string,
  cisPlanRates: PropTypes.arrayOf(
    PropTypes.shape({
      planID: PropTypes.string,
      discountedPrice: PropTypes.string
    })
  ),
  appState: PropTypes.any
};

/* istanbul ignore next */
const mapStateToProps = state => {
  const { user } = state;
  return {
    cisError: user.cis && user.cis.error,
    cisPlanRates: user.cis.information && user.cis.information.rates,
    appState: state
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  const { validateCISToken } = userActions;
  return {
    validateCISToken: token => dispatch(validateCISToken(token))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(props => <CisIndexPage {...props} location={useLocation()} />);

export const query = graphql`
  {
    allPhone {
      edges {
        node {
          ...CatalogPhoneDetailFragment
        }
      }
    }
    allPlan {
      edges {
        node {
          ...PlanDetailFragment
        }
      }
    }
    allPlanGroup(filter: { segment: { in: "cis" } }) {
      edges {
        node {
          groupName
        }
      }
    }
    allPhoneBrand {
      edges {
        node {
          text
          value
        }
      }
    }
    allPhoneCatalogCategory {
      edges {
        node {
          text
          value
        }
      }
    }
    allAemPages(filter: { pageUrl: { eq: "/eshop/mobile/cis/phones.xjson" } }) {
      edges {
        node {
          title
          components {
            ...AemComponentsFragment
          }
        }
      }
    }
    allInstalmentOptions {
      edges {
        node {
          text
          value
        }
      }
    }
  }
`;
