import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { authActions } from '@detox/actions';
import { Column, Grid, Row, Spacing, Text, Button } from '@dls/web';
import {
  noop,
  isValidAlphaNumericNoSpace,
  isValidMobileNumber,
  isValidNric
} from '@lux/helpers';

import { HR } from '../Base';
import Notification from '../Notification';
import Select from '../Select';
import TextField from '../TextField';

import { splitNumber } from '../../helpers/common';

import idPrefixes from '../../config/id-prefixes.json';
import idTypes from '../../config/id-types.json';
import prepaidIdTypes from '../../config/prepaid-id-types.json';
import notifications from '../../config/notifications';
import translate from '../../hooks/useTranslation';

const isFGM = prefix => prefix === 'F' || prefix === 'G' || prefix === 'M';
const isPassport = type => type === translate().t('PASSPORT_NUMBER');

const getDefaultIdType = (idPrefix, idTypes) => {
  const filtered = idTypes.filter(type => type.prefixes.includes(idPrefix));
  return filtered.length > 0 && filtered[0].value;
};

export const NumberVerification = props => {
  const {
    portInNumber,
    productOrder,
    resetWorryFree,
    sendOtp,
    worryFreeStatus
  } = props;
  const { telcoType } = productOrder;
  const mobileNumber = portInNumber;

  const [errors, setErrors] = useState({});
  const [idNumber, setIdNumber] = useState('');
  const [idPrefix, setIdPrefix] = useState(idPrefixes[0].value);
  const [idType, setIdType] = useState(idTypes[0].value);
  const [prepaidIdType, setPrepaidIdType] = useState(prepaidIdTypes[0].value);
  const [passportNumber, setPassportNumber] = useState('');

  const showIdType = isFGM(idPrefix);
  const idTypesItems = idTypes.filter(type => type.prefixes.includes(idPrefix));
  const selectedIndex = idTypesItems.findIndex(type => type.value === idType);

  const handleIdTypeChange = ({ value }) => {
    setPrepaidIdType(value);
  };

  const handleIdPrefixChange = ({ value }) => {
    setIdPrefix(value);

    const isSamePrefixGroup =
      isFGM(idPrefix) === isFGM(value) || !isFGM(idPrefix) === !isFGM(value);

    if (!isSamePrefixGroup) {
      const defaultIdType = getDefaultIdType(value, idTypes);
      setIdType(defaultIdType);
    }
  };

  const getErrors = ({
    mobileNumber,
    passportNumber,
    idPrefix,
    idNumber,
    idType
  }) => {
    const errors = {};

    if (!isValidMobileNumber(mobileNumber)) {
      errors.mobileNumber = translate().t('MOBILE_NUMBER_VALIDATION');
    }

    const fullId = idPrefix + idNumber;
    if (!isValidNric(fullId)) {
      errors.idNumber = translate().t('ID_NUMBER_VALIDATION');
    }

    if (isFGM(idPrefix) && idType.length === 0) {
      errors.idType = translate().t('ID_TYPE_VALIDATION');
    }

    if (
      isPassport(prepaidIdType) &&
      !isValidAlphaNumericNoSpace(passportNumber)
    ) {
      errors.passportNumber = translate().t('PASSPORT_NUMBER_VALIDATION');
    }

    return errors;
  };

  const handleContinueClick = () => {
    resetWorryFree();

    const errorMessages = getErrors({
      mobileNumber,
      passportNumber,
      idPrefix,
      idNumber,
      idType
    });

    const isValidForm = Object.keys(errorMessages).length === 0;
    setErrors(errorMessages);

    if (isValidForm) {
      const identityValue = (idPrefix + idNumber.trim()).toUpperCase();

      let otpParams = {
        cleanWorryFree: true,
        mobileNumber: mobileNumber,
        indentType: idType,
        indentValue: identityValue
      };

      if (isSingtelPrepaid) {
        otpParams = {
          ...otpParams,
          acceptorIndentType: idType,
          acceptorIndentValue: identityValue,
          indentType: isPassportType ? prepaidIdType : idType,
          indentValue: isPassportType ? passportNumber : identityValue,
          type: 'PREPAID'
        };
      } else {
        otpParams = {
          ...otpParams,
          acceptorIndentType: idType,
          acceptorIndentValue: identityValue,
          type: 'GOMO'
        };
      }

      sendOtp(otpParams);
    }
  };

  const renderTitle = () => {
    switch (telcoType) {
      case 'SINGTELPREPAID':
        return `Enter the registered ID for ${splitNumber(mobileNumber)}`;
      case 'SINGTELPOSTPAID':
      default:
        return `Enter the registered NRIC/FIN for ${splitNumber(mobileNumber)}`;
    }
  };

  const isSingtelPrepaid = telcoType === 'SINGTELPREPAID';
  const isPassportType = prepaidIdType === translate().t('PASSPORT_NUMBER');

  return (
    <Grid>
      <Row>
        <Column>
          <Text type="bannerTitle" tag="h2">
            {renderTitle()}
          </Text>
          <Spacing bottom={1}>
            <Text type="smallBody">
              This verifies that you're authorised to transfer this number
            </Text>
          </Spacing>
          {notifications[worryFreeStatus] && (
            <Row>
              <Column sm={12} md={8}>
                <Spacing bottom={1}>
                  <Notification state="danger">
                    {notifications[worryFreeStatus] &&
                      notifications[worryFreeStatus].text}
                  </Notification>
                </Spacing>
              </Column>
            </Row>
          )}
          {isSingtelPrepaid && (
            <Fragment>
              <Row>
                <Column xs={12} sm={12} md={5} lg={5}>
                  <Spacing bottom={1}>
                    <Select
                      data-testid="idType"
                      items={prepaidIdTypes}
                      selectedIndex={prepaidIdTypes.findIndex(
                        id => id.value === prepaidIdType
                      )}
                      onChange={handleIdTypeChange}
                      tabIndex={0}
                    />
                  </Spacing>
                </Column>
                <Column sm={false} xs={false} md={true} lg={true} />
              </Row>
              {isPassportType && (
                <Fragment>
                  <Row>
                    <Column xs={12} sm={12} md={5} lg={5}>
                      <Spacing bottom={2}>
                        <TextField
                          data-testid="passportNumber"
                          id="passport-number"
                          placeholder="Passport number"
                          value={passportNumber}
                          onChange={e => setPassportNumber(e.target.value)}
                          onClick={() =>
                            setErrors({ ...errors, passportNumber: null })
                          }
                          validation={errors.passportNumber && 'danger'}
                          hintMessage={errors.passportNumber}
                          tabIndex={0}
                        />
                      </Spacing>
                    </Column>
                    <Column sm={false} xs={false} md={true} lg={true} />
                  </Row>
                  <Spacing bottom={2}>
                    <HR />
                  </Spacing>
                  <Spacing bottom={1}>
                    <Text type="smallBody">Help us confirm your identity</Text>
                  </Spacing>
                </Fragment>
              )}
            </Fragment>
          )}
          <Row>
            <Column xs={3} sm={3} md={1} lg={1}>
              <Spacing bottom={1}>
                <Select
                  data-testid="idPrefix"
                  selectedIndex={idPrefixes.findIndex(
                    id => id.value === idPrefix
                  )}
                  items={idPrefixes}
                  onChange={e => handleIdPrefixChange(e)}
                  tabIndex={0}
                />
              </Spacing>
            </Column>
            <Column xs={9} sm={9} md={4} lg={4}>
              <Spacing bottom={2}>
                <TextField
                  data-testid="idNumber"
                  id="id-number"
                  placeholder={translate().t('NRIC_FIN_NUMBER')}
                  value={idNumber}
                  onChange={e => setIdNumber(e.target.value)}
                  onClick={() => setErrors({ ...errors, idNumber: null })}
                  validation={errors.idNumber && 'danger'}
                  hintMessage={errors.idNumber}
                  tabIndex={0}
                />
              </Spacing>
            </Column>
            <Column />
          </Row>
          {showIdType && (
            <Row>
              <Column xs={12} sm={12} md={5} lg={5}>
                <Select
                  data-testid="id-type"
                  selectedIndex={selectedIndex < 0 ? 0 : selectedIndex}
                  items={idTypesItems}
                  onChange={e => setIdType(e.value)}
                  hintMessage={errors.idType}
                  tabIndex={0}
                />
              </Column>
              <Column sm={false} xs={false} md={true} lg={true} />
            </Row>
          )}
          <Spacing top={2} bottom={2} bottomMd={5}>
            <Button onClick={handleContinueClick}>Next</Button>
          </Spacing>
        </Column>
      </Row>
    </Grid>
  );
};

NumberVerification.defaultProps = {
  resetWorryFree: noop
};

/* istanbul ignore next */
const mapStateToProps = state => {
  const { auth, order } = state;

  return {
    portInNumber: order.portInNumber,
    productOrder: order.productOrder,
    worryFreeStatus: auth.worryFree.status
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  const { resetWorryFree, sendOtp, setAuthStatus } = authActions;

  return {
    resetWorryFree: () => dispatch(resetWorryFree()),
    sendOtp: payload => dispatch(sendOtp(payload)),
    setAuthStatus: status => dispatch(setAuthStatus(status))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NumberVerification);
