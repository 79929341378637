import { format } from 'date-fns';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Banner,
  Grid,
  Row,
  Column,
  Text,
  DropdownInput,
  Divider,
  Spacing,
  withTheme
} from '@dls/web';
import styled, { css } from 'styled-components';
import ServicePromotions from './ServicePromotions';
import PromotionsError from './PromotionsError';
import PromotionsNotFoundModal from './PromotionsNotFoundModal';

import { UIAM_FLOW } from '../../types/featureFlag.types';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { getCookie } from '@lux/helpers';
import LoadingOverlayComponent from '../LoadingOverlay/LoadingOverlay';
import CONSTANT from '../../constants/common';
import { getUIAMLoginUrl } from '../../config';
import { navigation } from '../../middlewares/navigation-constants';
import { trans as t } from '../../helpers/localisation';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, catalogBannerActions } from '@detox/actions';
import { TObject } from '../../types/registrationCheckout';
import { getContactData } from '../Fulfilment/helpers';
import BannerDesktop from '../../assets/images/promotions/CCDE_desktop_1440x480.jpg';
import BannerMobile from '../../assets/images/promotions/CCDE_mobile_400x600.jpg';
import { springDPromotionsPageUrl } from '../../config/links';
import PromotionsEmpty from './PromotionsEmpty';
import { ListSkeletonLoader } from '../SkeletonLoader';
import theme from '../../theme';

const FirstContentContainer = withTheme(styled.div`
  ${({ coreTheme }) => css`
    margin-top: -${theme.spacing(5)};
    @media (min-width: ${coreTheme.brk_md}) {
      margin-top: -${theme.spacing(9)};
    }
  `}
`);

const MainContentContainer = withTheme(styled(Grid)`
  ${({ coreTheme }) => css`
    padding-top: ${theme.spacing(5)};
    padding-left: ${theme.spacing(3)};
    padding-right: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(8)};
    @media (min-width: ${coreTheme.brk_md}) {
      padding-bottom: ${theme.spacing(15)};
    }
  `}
`);

const SkeletonLoaderRow = styled(Row)`
  margin: -8px;
`;
const SkeletonLoaderContainer = styled(Column)`
  margin-top: -${theme.spacing(1)};
  margin-bottom: -${theme.spacing(1)};
`;

const getProductDisplayList = (
  products: TObject[] = [],
  userSubscriptions: TObject[] = []
) => {
  return (
    userSubscriptions?.flatMap((subscription: TObject) => {
      const product = products.find(
        productObj =>
          ((subscription.subscriberId as unknown) as string) ===
          `${productObj.subscriptionId}`
      );
      if (!product) {
        return [];
      }

      const { productName, serviceId, subscriptionId } = product;
      return [
        {
          text: `${productName} ${serviceId || ''}`,
          value: subscriptionId
        }
      ];
    }) || []
  );
};
const getFirstValueFromProductDisplayList = (
  productDisplayList: ReturnType<typeof getProductDisplayList>
) => productDisplayList[0]?.value;

const getDateForGMTString = (gmtString: string): Date | null => {
  const gmtStringFormatRegex = /^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})\.(\d{3})\sGMT$/;
  if (gmtStringFormatRegex.test(gmtString)) {
    const [
      ,
      year,
      month,
      day,
      hours,
      minutes,
      seconds,
      milliseconds
    ] = gmtString.match(gmtStringFormatRegex);
    return new Date(
      `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds} GMT`
    );
  }
  return null;
};

const formatDateToDisplay = (date: Date): string | void => {
  if (!date || isNaN(date.valueOf())) {
    return;
  }
  const timeZoneOffset = date.getTimezoneOffset();
  const TIMEZONE_OFFSET_SGT = -480; // value defined here is equivalent to return value of `Date.prototype.getTimezoneOffset()` executed in SG time locale
  const timeZoneAdjustedLocalDate = new Date(
    date.valueOf() + (timeZoneOffset - TIMEZONE_OFFSET_SGT) * 60 * 1000 // adjust local datetime to display as if locale is in SGT
  );
  const displayDate = format(timeZoneAdjustedLocalDate, 'dd MMM yyyy');
  return displayDate;
};

const getCustomerFromUserInformation = userInformation =>
  userInformation?.clientContext &&
  getContactData(userInformation?.clientContext);

const navigateToSpringDPromotionsPage = () => {
  window.open(springDPromotionsPageUrl, '_self');
};

enum AuthState {
  Loading = 'Loading',
  Authenticated = 'Authenticated',
  NotAuthenticated = 'NotAuthenticated',
  Error = 'Error'
}

const Promotions = (): JSX.Element => {
  const dispatch = useDispatch();
  const [authState, setAuthState] = useState<AuthState>(AuthState.Loading);
  const {
    userInformationState,
    productsState,
    apigeeToken,
    catalogBannerState
  } = useSelector((state: TObject) => {
    return {
      userInformationState: {
        userInformation: state?.user?.information,
        isUserInformationLoading: state?.user?.loading,
        isUserInformationError: state?.user?.error
      },
      productsState: {
        products: state?.userPromotions?.products,
        isProductsLoading: state?.userPromotions?.isLoading,
        isProductsError: state?.userPromotions?.isError
      },
      apigeeToken: state?.apigeeAuth?.apigeeToken,
      catalogBannerState: {
        isCatalogLoading: state?.userPromotions?.isCatalogLoading,
        isCatalogError: state?.userPromotions?.isCatalogError,
        catalogData: state?.userPromotions?.catalogData
      }
    };
  });
  const isApigeeTokenAvailable = !!(
    apigeeToken || window.sessionStorage.getItem(CONSTANT.APIGEE_AUTH_TOKEN)
  );

  const handleSetUserNotAuthenticated = useCallback(() => {
    setAuthState(AuthState.NotAuthenticated);
    window.open(getUIAMLoginUrl(navigation.USER_PROMOTIONS), '_self');
  }, []);

  // Handle UIAM access token and user data loading
  useEffect(() => {
    if (!isFeatureFlagEnabled(UIAM_FLOW)) {
      navigateToSpringDPromotionsPage();
    }

    if (!isApigeeTokenAvailable) {
      return;
    }

    const uiamAccessToken = getCookie(CONSTANT.UIAM_ACCESS_TOKEN);
    if (uiamAccessToken) {
      // Only load user information if access token is available
      dispatch(userActions.getUserInformation());
    } else {
      handleSetUserNotAuthenticated();
    }
  }, [isApigeeTokenAvailable, handleSetUserNotAuthenticated, dispatch]);

  // Handle user information data state
  useEffect(() => {
    if (
      !userInformationState.userInformation &&
      !userInformationState.isUserInformationError
    ) {
      return; // do nothing if user information API was not called or is still loading
    }

    if (userInformationState.isUserInformationError) {
      setAuthState(AuthState.Error); // user information API error
    } else if (userInformationState.userInformation?.anonymousUser === true) {
      handleSetUserNotAuthenticated();
    } else if (
      !userInformationState.userInformation?.clientContext?.subscriptions ||
      userInformationState.userInformation.clientContext.subscriptions
        .length === 0
    ) {
      // Navigate to BAU promotions page if user has no active subscriptions
      navigateToSpringDPromotionsPage();
    } else {
      // Check if user information data contains the required values to retrieve products
      const customer = getCustomerFromUserInformation(
        userInformationState.userInformation
      );
      const customerId = customer?.externalId;
      if (customerId) {
        dispatch(userActions.getUserMainProducts({ customerId }));
        setAuthState(AuthState.Authenticated);
      } else {
        setAuthState(AuthState.Error); // user information data error
      }
    }
  }, [
    !!userInformationState.userInformation,
    !!userInformationState.isUserInformationError,
    handleSetUserNotAuthenticated
  ]);

  const customerName = useMemo(
    () =>
      getCustomerFromUserInformation(userInformationState.userInformation)
        ?.name,
    [userInformationState.userInformation]
  );

  const productsDisplayList = getProductDisplayList(
    productsState.products,
    userInformationState.userInformation?.clientContext?.subscriptions
  );

  const [selectedService, setSelectedService] = useState<
    typeof productsDisplayList[number]['value'] | void
  >();

  // Handle selected service change
  const handleSetSelectedServiceChange = useCallback(
    (nextSelectedService: typeof selectedService) => {
      if (nextSelectedService === selectedService) {
        return; // do nothing if service is already selected
      }

      setSelectedService(nextSelectedService);
      if (nextSelectedService) {
        dispatch(
          catalogBannerActions.getCatalogBanner(
            (nextSelectedService as unknown) as string
          )
        );
      }
    },
    [selectedService]
  );

  // Auto-select first service
  useEffect(() => {
    if (!selectedService && productsDisplayList.length > 0) {
      const firstSubscriptionIdentifier = getFirstValueFromProductDisplayList(
        productsDisplayList
      );
      handleSetSelectedServiceChange(firstSubscriptionIdentifier);
    }
  }, [selectedService, productsDisplayList]);

  // Handle service dropdown option change
  const onServiceDropdownChange = useCallback(item => {
    const subscriptionIdentifier = item.value;
    handleSetSelectedServiceChange(subscriptionIdentifier);
  }, []);

  const selectedProductName = useMemo(
    () =>
      productsState.products.find(
        product => product.subscriptionId === selectedService
      )?.productName,
    [productsState.products, selectedService]
  );

  const initialCatalogDataNotLoaded =
    !catalogBannerState.isCatalogError && !catalogBannerState.catalogData;

  // Process catalog banner data
  const cardsData = useMemo(() => {
    const nextBestActionContainerRankedResults = catalogBannerState.catalogData?.body?.containerList.find(
      container => container.containerName === 'NextBestAction'
    )?.rankedResults;
    return nextBestActionContainerRankedResults
      ?.map(result => ({
        rank: result.rank,
        category: result.category,
        productID: result.productID,
        productName: result.productName,
        productDescription: result.productDescription,
        sellableEndDate: formatDateToDisplay(
          getDateForGMTString(result.sellableEndDate)
        ),
        clickThroughUrl: result.clickThroughURL
      }))
      .sort((a, b) => a.rank - b.rank);
  }, [catalogBannerState.catalogData]);
  const cardsDataError = catalogBannerState.isCatalogError;

  if (authState === AuthState.Loading || productsState.isProductsLoading) {
    return <LoadingOverlayComponent />;
  }

  if (authState === AuthState.NotAuthenticated) {
    return null; // don't show anything if not authenticated - user will be redirected to login page
  }

  if (authState === AuthState.Error || productsState.isProductsError) {
    return (
      <FirstContentContainer>
        <MainContentContainer>
          <PromotionsError onTextLinkClick={navigateToSpringDPromotionsPage} />
        </MainContentContainer>
      </FirstContentContainer>
    );
  }

  return (
    <>
      {cardsDataError && <PromotionsNotFoundModal />}

      <FirstContentContainer>
        <Banner
          desktopImage={BannerDesktop}
          mobileImage={BannerMobile}
          headerText={t('CCDE_BANNER_TITLE', {
            name: customerName || ''
          })}
          bodyText={t('CCDE_BANNER_DESCRIPTION')}
        />
      </FirstContentContainer>

      <MainContentContainer>
        {productsDisplayList?.length > 0 ? (
          <Row>
            <Column noGutter>
              <Spacing bottom={2}>
                <Text type="header">{t('CCDE_CHOOSE_A_SERVICE')}</Text>
              </Spacing>

              <Column xs={12} sm={4} noGutter>
                <DropdownInput
                  bgColor="haze"
                  id="serviceDropdown"
                  label={t('CCDE_YOUR_CURRENT_SERVICES')}
                  onChange={onServiceDropdownChange}
                  items={productsDisplayList}
                  defaultValue={productsDisplayList[0]?.value}
                />
              </Column>

              <Spacing top={3} bottom={3}>
                <Divider />
              </Spacing>

              {initialCatalogDataNotLoaded ||
              catalogBannerState.isCatalogLoading ? (
                <SkeletonLoaderRow>
                  <SkeletonLoaderContainer xs={12} md={4}>
                    <ListSkeletonLoader itemType="Card" numberOfItem={1} />
                  </SkeletonLoaderContainer>
                  <SkeletonLoaderContainer xs={12} md={4}>
                    <ListSkeletonLoader itemType="Card" numberOfItem={1} />
                  </SkeletonLoaderContainer>
                  <SkeletonLoaderContainer xs={12} md={4}>
                    <ListSkeletonLoader itemType="Card" numberOfItem={1} />
                  </SkeletonLoaderContainer>
                </SkeletonLoaderRow>
              ) : !cardsData || cardsData.length === 0 ? (
                <PromotionsEmpty
                  product={selectedProductName}
                  onTextLinkClick={navigateToSpringDPromotionsPage}
                />
              ) : (
                <ServicePromotions data={cardsData} />
              )}
            </Column>
          </Row>
        ) : (
          // Don't show anything when user has no active subscription/services
          // User will be redirected BAU promotions page in useEffect handler for user information data
          <></>
        )}
      </MainContentContainer>
    </>
  );
};

export default Promotions;
