/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, ReactNode } from 'react';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextLink } from '@dls/web';
import { formatDate } from '../../components/Fulfilment/helpers';
import { OrderSummaryRewamp } from '@common-modules/shopping-cart';
import {
  Total,
  OrderType,
  InfoType
} from '@common-modules/shopping-cart/dist/index';
import TermsAndConditions from '../TermsAndConditions';
import { rrpOrderSummaryActions } from '@detox/actions';

import { useErrorHandler } from '../..../../../hooks/useErrorHandler';
import {
  formatNumber,
  formatContactNumber,
  flattenNodes,
  dataLayerPush
} from '../../helpers/common';
import { getAddressLines } from '../../helpers/address';
import slugify from '../../helpers/slugify';
import formatPrice from '../../helpers/formatPrice';
import { REQUEST_STATUS } from '../../constants';
import CONSTANTS from '../../constants/common';
import ORDER_CONSTANTS from '../../constants/order';
import FULFILMENT_CONSTANTS from '../../constants/fulfillment';
import { ORDER_SUMMARY_CONSTANTS } from '../../constants/ordersummary';
import { trans as t } from '../../helpers/localisation';
import LoadingOverlayComponent from '../LoadingOverlay';
import ModalTemplate from '../ModalTemplate';
import modals, { RRP_MODAL } from '../../config/modals';
import { navigation } from '../../middlewares/navigation-constants';
import { dppLink } from '../../config/links';
import { PROMO_ERROR_CODE, promoCodeError } from '../../config/notifications';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';
import { format } from 'date-fns';
import { rrpOrderSummaryProceedDataLayer } from '../../helpers/dataLayerHelpers';
import { getCatalogPath } from '../../helpers/rrpCart';

const { REMOVE_PROMO, CLEAR_FIELD_WITHOUT_API } = ORDER_SUMMARY_CONSTANTS;
const { UPDATE_STATUS } = ORDER_CONSTANTS;

const { CHANGE_PERSONAL_DETAILS } = RRP_MODAL;

const {
  getRrpOrderSummaryData,
  rrpAddPromoCode,
  rrpRemovePromoCode,
  requestPayment
} = rrpOrderSummaryActions;

export const DELIVERY_METHOD_TO_TEXT = {
  courier: 'Doorstep Delivery',
  'pop-station': 'POPStation',
  'drop-shipping': 'Delivery via partners',
  'singtel-store': 'Collect at store',
  'sms-mail': 'Mailbox'
};

const getAddressInfoContent = (
  orderDetail: KeyValue,
  rrpOrderModel: KeyValue
) => {
  // pop-station
  if (
    orderDetail.delivery.deliveryMethod ===
    FULFILMENT_CONSTANTS.MAPPED_FULFILMENT_TYPES.popstation
  ) {
    const popStationAddressData = rrpOrderModel.find(
      ({ orderId = '' }) => orderId === orderDetail.orderId
    );
    const popStationList =
      popStationAddressData?.methods?.data?.popStations || [];
    const selectedPopStation = popStationList.find(
      ({ KioskId = '' }) => KioskId === orderDetail.delivery?.popStationCode
    );

    if (selectedPopStation) {
      return [
        `${format(
          new Date(orderDetail.delivery.slotStartDateEpoch),
          'dd MMM yyyy'
        )}`,
        ...getAddressLines(selectedPopStation)
      ];
    }
  }

  // courier-delivery

  const dateTime = `${formatDate(
    new Date(orderDetail.delivery.slotStartDateEpoch),
    'dd MMM yyyy'
  )}, ${orderDetail.delivery.timeDescription}`;

  return [dateTime, ...getAddressLines(orderDetail.delivery.deliveryAddress)];
};

const getOrderSummaryDataModel = ({
  orderSummary,
  promoCodeResult,
  loading,
  variantBySku,
  managePromoCode,
  setModal,
  rrpOrderModel
}) => {
  const orders = [];
  const infos: InfoType[] = [];
  let total: Total = {
    payTotalAmount: 0
  };

  if (orderSummary && Object.keys(orderSummary).length > 0) {
    if (Object.keys(orderSummary?.personalDetails)?.length) {
      const personalDetails = { ...orderSummary.personalDetails };
      if (personalDetails.phone) {
        personalDetails.phone = formatContactNumber(personalDetails.phone);
      }

      infos.push({
        title: t('PERSONAL_DETAILS'),
        content: Object.values(personalDetails),
        showDivider: false,
        action: {
          onClick: () => setModal(CHANGE_PERSONAL_DETAILS),
          text: t('CHANGE')
        }
      });
    }

    if (orderSummary.orders?.length) {
      orderSummary.orders.forEach((orderDetail, index: number) => {
        const order: OrderType = {};

        order.title = {
          text: t('BAG_NO', {
            number: formatNumber(index + 1)
          })
        };

        if (orderDetail.delivery) {
          const addressInfoContent = getAddressInfoContent(
            orderDetail,
            rrpOrderModel
          );

          order.info = {
            title:
              DELIVERY_METHOD_TO_TEXT[
                slugify(orderDetail.delivery.deliveryMethod)
              ],
            content: addressInfoContent,
            action: {
              onClick: () => navigate(`/${navigation.RRP_FULFILMENT}`),
              text: t('CHANGE')
            }
          };
        }

        if (orderDetail.items?.length) {
          order.productsList = {
            items: orderDetail.items.map(item => {
              let productVariantDetails;
              if (item.sku in variantBySku) {
                productVariantDetails = variantBySku[item.sku];
              }

              return {
                imageSrc:
                  process.env.GATSBY_AEM_URL +
                  productVariantDetails?.smallImage?.src,
                name: `${item.title}, ${item.color}`,
                description: productVariantDetails?.shippingLiner,
                price: formatPrice(item?.finalAmount?.afterTax),
                quantity: item?.quantity && formatNumber(item.quantity)
              };
            }),
            showQtyInMobileView: true
          };
        }

        if (orderDetail?.promotions?.length) {
          order.discount = {
            data: {
              dataSource: orderDetail.promotions.map(item => ({
                name: item.couponCode
                  ? t('DROP_SHIPPING.APPLIED_PROMOCODE.TEXT', {
                      couponCode: item.couponCode
                    })
                  : item.promoText,
                price: formatPrice(
                  item?.price?.afterTax || -item?.stockCodeAmount
                )
              })),
              totalAmount: formatPrice(
                Math.abs(orderDetail.discountAmount.afterTax)
              )
            }
          };
        }

        const promoCode = {
          promoCodeResult: {
            errorMessage: undefined,
            appliedPromoCode: '',
            promoText: undefined,
            isSuccess: false,
            keepPromoOpen: false,
            disabled: false,
            isLoading: loading
          }
        };

        if (orderSummary?.coupon?.orderId) {
          if (orderSummary.coupon.orderId === orderDetail.orderId) {
            promoCode.promoCodeResult = {
              ...promoCode.promoCodeResult,
              appliedPromoCode: orderSummary.coupon.couponCode,
              isSuccess: true,
              keepPromoOpen: true,
              disabled: true
            };
          } else {
            promoCode.promoCodeResult = {
              ...promoCode.promoCodeResult,
              errorMessage: true,
              promoText: t('DROP_SHIPPING.PROMO_CODE_REMOVE_TO_ADD.TEXT'),
              disabled: true
            };
          }
        } else if (promoCodeResult?.orderId === orderDetail.orderId) {
          promoCode.promoCodeResult = {
            ...promoCode.promoCodeResult,
            errorMessage: promoCodeError[promoCodeResult?.errorCode]?.text,
            appliedPromoCode: promoCodeResult?.promoCode,
            keepPromoOpen: true
          };
        }

        order.promoCode = promoCode;
        order.promoCode.applyPromo = (value: string) => {
          managePromoCode({
            promoCode: value,
            masterOrderId: orderSummary.masterOrderId,
            orderId: orderDetail.orderId
          });
        };

        if (orderDetail.finalAmount) {
          const list = [];

          if (orderDetail?.delivery?.deliveryPrice?.afterTax) {
            list.push({
              name: t('DROP_SHIPPING.DELIVERY_FEE.TEXT'),
              amount: orderDetail.delivery.deliveryPrice.afterTax
            });
          }
          order.total = {
            list,
            finalAmount: formatPrice(orderDetail.finalAmount.afterTax),
            originalAmount:
              orderDetail?.originalAmount?.afterTax >
              orderDetail?.finalAmount?.afterTax
                ? formatPrice(orderDetail.originalAmount.afterTax)
                : undefined
          };
        }

        orders.push(order);
      });
    }

    const { originalAmount, finalAmount, saveAmount = {} } = orderSummary;

    total = {
      payTotalAmount: formatPrice(finalAmount.afterTax),
      ...(saveAmount > 0 && {
        saveAmount,
        beforeDiscountAmount: formatPrice(originalAmount.afterTax)
      })
    };
  }

  return {
    orders,
    infos,
    total
  };
};

const RRPOrderSummary = ({ data }: KeyValue): ReactNode => {
  const dispatch = useDispatch();
  const { rrpOrderSummary, rrpCheckout, rrpCart } = useSelector(
    (state: KeyValue) => ({
      rrpOrderSummary: state.rrpOrderSummary,
      rrpCheckout: state.rrpCheckout,
      rrpCart: state.rrpCart
    })
  );
  const rrpMasterOrderId = rrpCheckout?.rrpMasterOrderId || undefined;
  const orderSummary = rrpOrderSummary.orderSummary || undefined;
  const promoCodeResult = rrpOrderSummary.promoCodeResult || undefined;
  const paymentRequestResult =
    rrpOrderSummary.paymentRequestResult || undefined;
  const loading = rrpOrderSummary.status === REQUEST_STATUS.PENDING;
  const hasError = rrpOrderSummary.status === REQUEST_STATUS.FAILED;
  const rrpOrderModel = rrpCheckout.orderModel || [];

  const [modal, setModal] = useState(null);
  const [tncModal, setTncModal] = useState(false);
  const allRrpProduct = useMemo(() => flattenNodes(data.allRrpProduct), [
    data.allRrpProduct
  ]);
  const { coupon } = orderSummary || {};

  const { renderError } = useErrorHandler({
    states: [
      {
        hasError,
        redirectUrl: getCatalogPath()
      }
    ]
  });

  const variantBySku = useMemo(() => {
    return allRrpProduct.reduce((acc, cur) => {
      cur.variants.forEach(variant => {
        const { sku, ...data } = variant;
        if (!(sku in acc)) {
          acc[sku] = {
            ...data,
            shippingLiner: cur?.shippingLiner
          };
        }
      });
      return acc;
    }, {});
  }, [allRrpProduct]);

  const fetchData = async () => {
    await dispatch(
      getRrpOrderSummaryData({
        masterOrderId: rrpMasterOrderId
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (orderSummary && !orderSummary?.coupon) {
      dispatch({ type: 'RESET_RRP_PROMO_CODE' });
    }
  }, [orderSummary]);

  useEffect(() => {
    if (paymentRequestResult?.redirect_url) {
      pushDataLayer();
      window.open(paymentRequestResult.redirect_url, '_self');
    }
  }, [paymentRequestResult]);

  const validatePromocode = ({ orderId, promoCode }) => {
    const promocodePayload = {
      appliedPromoCode: promoCode,
      errorCode: undefined,
      isSuccess: false,
      keepPromoOpen: true,
      isLoading: false,
      orderId
    };

    if (!promoCode) {
      promocodePayload.errorCode = PROMO_ERROR_CODE.EMPTY;
    } else if (coupon) {
      promocodePayload.errorCode =
        coupon.orderId === orderId
          ? PROMO_ERROR_CODE.ONLY_ONE_PROMO_CODE_IS_ALLOWED
          : PROMO_ERROR_CODE.REMOVE_APPLIED_PROMO_CODE;
    }

    return promocodePayload.errorCode ? promocodePayload : false;
  };

  const managePromoCode = async ({ masterOrderId, orderId, promoCode }) => {
    const isAdd = REMOVE_PROMO !== promoCode;
    const canRemoveWithoutAPI = CLEAR_FIELD_WITHOUT_API.some(
      code => code === promoCodeResult?.errorCode
    );

    if (isAdd) {
      const error = validatePromocode({ orderId, promoCode });

      if (error) {
        dispatch({
          type: 'RRP_PROMO_CODE_ERROR',
          value: error
        });
      } else {
        await dispatch(
          rrpAddPromoCode({
            masterOrderId,
            orderId,
            promoCode
          })
          // TODO: to handle properly with await to traditional try catch
          // @ts-ignore
        ).then((res?: string) => {
          if (res === UPDATE_STATUS.SUCCESS) fetchData();
        });
      }
    } else {
      if (!canRemoveWithoutAPI) {
        await dispatch(
          rrpRemovePromoCode({
            masterOrderId,
            orderId: coupon?.orderId,
            promoCode: promoCode
          })
          // TODO: to handle properly with await to traditional try catch
          // @ts-ignore
        ).then((res?: string) => {
          if (res === UPDATE_STATUS.SUCCESS) fetchData();
        });
      }
    }
  };

  const { infos, orders, total } = useMemo(
    () =>
      getOrderSummaryDataModel({
        orderSummary,
        promoCodeResult,
        loading,
        variantBySku,
        rrpOrderModel,
        managePromoCode,
        setModal
      }),
    [orderSummary, promoCodeResult, loading]
  );

  const modalToShow = modals[modal];

  const handleTermsLinkClick = () => {
    setTncModal(true);
  };

  const handleDataProtectionLinkClick = () => {
    window.open(dppLink, '_blank', 'noopener,noreferrer');
  };

  const pushDataLayer = () => {
    dataLayerPush(
      rrpOrderSummaryProceedDataLayer({
        baseParams: {
          cartItems: rrpCart?.addedItems,
          orderModel: rrpCheckout?.orderModel,
          location: window.location
        }
      })
    );
  };

  return (
    <Grid>
      <div data-testId="mfe-order-summary">
        {loading && <LoadingOverlayComponent />}
        {renderError()}

        {modalToShow && (
          <ModalTemplate
            templateId={modalToShow.templateId}
            open={!!modalToShow}
            title={modalToShow.title}
            content={modalToShow.text}
            ctaText={modalToShow.okText}
            onCtaClick={modalToShow.onOk}
            onClose={() => setModal(null)}
          />
        )}

        <OrderSummaryRewamp
          data={{
            infos: infos,
            orders: orders,
            bills: [],
            total: total
          }}
          configs={{
            placeOrderButton: { loading },
            showQtyInMobileView: true
          }}
          callbacks={{
            onPlaceOrder: () => {
              if (orderSummary.finalAmount.afterTax === 0) {
                pushDataLayer();
                navigate(
                  `/${navigation.RRP_ORDER_CONFIRMATION}?pgStatus=${ORDER_CONSTANTS.STATUS.SUCCESS}&${ORDER_CONSTANTS.URL_PARAMS.ZERO_UPFRONT}=true`
                );
              } else {
                window.sessionStorage.setItem(
                  'flow',
                  CONSTANTS.PAYMENT_REDIRECT_FLOW.RRP
                );
                dispatch(
                  requestPayment({
                    customerInfo: {
                      ...orderSummary?.personalDetails,
                      postcode:
                        orderSummary.orders[0]?.delivery?.deliveryAddress
                          ?.postcode,
                      billingAddress: JSON.stringify(
                        orderSummary.orders[0]?.delivery?.deliveryAddress
                      )
                    },
                    order: orderSummary
                  })
                );
              }
            }
          }}
          localisation={{
            ORDER_TOTAL: t('RRP_BAG_TOTAL'),
            ORDER_SUMMARY_TNC_LABEL: t('TERMS_AND_CONDITION', {
              tnc: (
                <TextLink
                  type="body"
                  data-testid="terms-link"
                  onClick={handleTermsLinkClick}
                >
                  Terms and Condition
                </TextLink>
              ) as APP_TYPE_ANY,
              sdpp: (
                <TextLink
                  type="body"
                  onClick={handleDataProtectionLinkClick}
                  data-testid="data-protection-link"
                >
                  Singtel Data Protection Policy.
                </TextLink>
              ) as APP_TYPE_ANY
            })
          }}
        />
        {tncModal ? (
          <TermsAndConditions
            isModalOpen={tncModal}
            setIsModalOpen={setTncModal}
          />
        ) : null}
      </div>
    </Grid>
  );
};

export default RRPOrderSummary;
