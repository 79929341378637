import styled, { css } from 'styled-components';
import { withTheme } from '@dls/web';

type ColorContainerProps = {
  type?: string;
  padding?: string;
};

export const TextLinkWrapper = styled.div`
  ${p => `
    padding: ${p.theme.spacing(3)} 0 0;
  `};
  text-align: center;
`;
export const IconWrapper = styled.div`
  ${p => `
    margin-right: ${p.theme.spacing(2)};
    margin-top: 4px
  `};
`;

export const ColorContainer = withTheme(styled.div<ColorContainerProps>`
  ${({ coreTheme, type, padding, ...p }) => css`
${`
    padding: ${p.theme.spacing(3)};
    margin: ${p.theme.spacing(5)} 0;
  `};
  border-radius: 12px;
  display: flex;

  ${type === 'primary' && `background-color: ${coreTheme.cl_pri_l3};`}
  
  ${type === 'secondary' && `background-color: #EFEDFD;`}
  
  ${type === 'tertiary' && `background-color: ${coreTheme.cl_ter_l6};`} 
  ${padding && `padding: ${padding}`}
`}
`);
