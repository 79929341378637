import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: ${p => p.theme.spacing(p.top)};
  margin-bottom: ${p => p.theme.spacing(p.bottom)};

  ${p =>
    p.topMd &&
    p.theme.media.md`
      margin-top: ${p.theme.spacing(p.topMd)};
  `};

  ${p =>
    p.bottomMd &&
    p.theme.media.md`
      margin-bottom: ${p.theme.spacing(p.bottomMd)};
  `};

  ${p =>
    p.bottomLg &&
    p.theme.media.lg`
      margin-bottom: ${p.theme.spacing(p.bottomLg)};
  `};
`;

const Spacing = props => {
  const {
    top,
    bottom,
    topMd,
    bottomMd,
    topLg,
    bottomLg,
    children,
    ...rest
  } = props;

  return (
    <Container
      top={top}
      bottom={bottom}
      topMd={topMd}
      bottomMd={bottomMd}
      topLg={topLg}
      bottomLg={bottomLg}
      {...rest}
    >
      {children}
    </Container>
  );
};

Spacing.defaultProps = {
  top: 0,
  bottom: 0,
  topMd: 0,
  bottomMd: 0,
  topLg: 0,
  bottomLg: 0
};

Spacing.propTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  topMd: PropTypes.number,
  bottomMd: PropTypes.number,
  topLg: PropTypes.number,
  bottomLg: PropTypes.number,
  children: PropTypes.node
};

export default Spacing;
