import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkoutActions } from '@detox/actions';
import { navigate } from 'gatsby';

import {
  createCustomer,
  getFormConfigForNewNewFlow,
  updateCustomer
} from '../helper';

import ORDER_CONSTANT from '../../../constants/order';
import { navigation } from '../../../middlewares/navigation-constants';

import isSimOnlyPlan from '../../../helpers/is-sim-only-plan';
import { KeyValue } from '../../../types/common.types';
import { getCartError, getCartErrorDataModel } from '../../../helpers/cart';
import {
  BillingPrefType,
  FormConfig
} from '../../../types/registrationCheckout';
import useStockCheck, { STOCK_CHECK_TYPE } from '../../../hooks/useStockCheck';

interface RootState {
  checkout: KeyValue;
  cart: KeyValue;
  plan: KeyValue;
  order: KeyValue;
  fulfillment: KeyValue;
  product: KeyValue;
  user: KeyValue;
}

interface CheckoutHelperReturnType {
  handleOnFormSubmit: (values: KeyValue, passValues: KeyValue) => Promise<void>;
}

interface CheckoutHelperInputsType {
  onErrorScenarioFound: (cartErrorDataModel: KeyValue) => void;
  errorScenariosData: KeyValue[];
  onFormSubmit?: (values: KeyValue, path: string) => void;
  goToOrderSummary: () => void;
  billingPrefData: BillingPrefType[];
  onGettingFormConfigs: (formConfigs: FormConfig) => void;
}

export const useCheckOutHelper = ({
  onErrorScenarioFound,
  errorScenariosData,
  onFormSubmit,
  goToOrderSummary,
  billingPrefData,
  onGettingFormConfigs
}: CheckoutHelperInputsType): CheckoutHelperReturnType => {
  const {
    checkout,
    cart,
    selectedPlan,
    order,
    addressInfo = {},
    selectedProduct,
    cisUser
  } = useSelector((state: RootState) => {
    return {
      checkout: state.checkout,
      cart: state.cart,
      selectedPlan: state.plan?.selectedPlan,
      order: state.order,
      addressInfo: state.fulfillment?.addressInfo,
      selectedProduct: state.product?.selectedProduct,
      cisUser: state.user?.cis?.information
    };
  });
  const dispatch = useDispatch();
  const {
    stockAvailable: { notAvailableSkus }
  } = useStockCheck(STOCK_CHECK_TYPE.MTPOS);

  const { productOrder, addedToContact } = order;

  const showValidateError = useCallback(() => {
    const errorScenarioData = getCartError(
      cart?.checkoutErrorData,
      errorScenariosData
    );
    const cartErrorDataModel = getCartErrorDataModel(
      errorScenarioData,
      selectedPlan?.planName
    );
    onErrorScenarioFound(cartErrorDataModel);
  }, [
    cart.checkoutErrorData,
    errorScenariosData,
    onErrorScenarioFound,
    selectedPlan.planName
  ]);

  const getFlowConfigs = () => {
    const device = cart?.cartOrder?.device;
    const hasAccessories = cart?.cartOrder?.accessories?.length > 0;
    const simType = cart?.order?.newlyAddedSimDetails?.simDetails?.simType;
    const isCisFlow = cisUser;

    return getFormConfigForNewNewFlow({
      device,
      isCis: isCisFlow,
      isNric: false, // does not matter as we need only eSim type
      isNricSilver: false,
      hasAccessories,
      simType
    });
  };

  const onCustomerCreated = useCallback(async () => {
    await checkoutActions.allocateEquipmentForAvailableProducts({
      cart,
      simOptions: productOrder?.sim,
      dispatch,
      productOrderId: productOrder?.productOrderId,
      notAvailableSkus
    });
    dispatch(checkoutActions.resetCreateCustomerStatus());

    // Simonly order should go to order summary
    const flowConfig: KeyValue = getFlowConfigs();
    if (flowConfig.byPassFulfilment) {
      goToOrderSummary();
    } else {
      navigate(`/${navigation.ORDER_FULFILMENT_PAGE}`);
    }
  }, [
    cart,
    dispatch,
    notAvailableSkus,
    // onFormSubmit,
    productOrder.productOrderId,
    productOrder.sim
  ]);

  useEffect(() => {
    const flowConfig: KeyValue = getFlowConfigs();

    if (onGettingFormConfigs) {
      onGettingFormConfigs({
        hasBillingPref: false,
        hasUploadID: false,
        byPassFulfilment: flowConfig.byPassFulfilment
      });
    }
    dispatch(checkoutActions.resetCreateCustomerStatus());
  }, []);

  useEffect(() => {
    if (checkout?.customerCreated) {
      const hasError =
        cart?.checkoutErrorData &&
        cart?.checkoutOrderError &&
        cart?.checkoutErrorScenarioCheckCompleted;
      const finishedWithoutError =
        cart?.checkoutErrorScenarioCheckCompleted &&
        cart?.checkoutOrderError === false;

      if (hasError) {
        showValidateError();
      } else if (finishedWithoutError) {
        onCustomerCreated();
      }
    }
  }, [
    cart.checkoutErrorData,
    cart.checkoutErrorScenarioCheckCompleted,
    cart.checkoutOrderError,
    checkout.customerCreated,
    onCustomerCreated,
    showValidateError
  ]);

  const handleOnFormSubmit = useCallback(
    async (values, passValues): Promise<void> => {
      const identityCheckResult = (await dispatch(
        checkoutActions.checkIdentity({
          identityType: passValues?.passType,
          identity: passValues?.id
        })
      )) as KeyValue;

      const params = {
        formValues: values,
        addressInfo: addressInfo?.address,
        passType: passValues.passType,
        passId: passValues.id,
        addedToContact,
        portIn: productOrder?.type === ORDER_CONSTANT.TYPE.PORTIN,
        productOrder,
        isSimOnlyIPP: isSimOnlyPlan(selectedPlan) && selectedProduct,
        dispatch
      };

      if (
        identityCheckResult?.customerExists &&
        identityCheckResult?.contactId
      ) {
        return await updateCustomer({
          ...params,
          contactId: identityCheckResult.contactId,
          customerId: identityCheckResult?.customerIds?.[0],
          selectedPlan,
          billingPrefData
        });
      }

      await createCustomer({
        billingPrefData,
        identityCheckResult,
        selectedPlan,
        ...params
      });
    },
    [
      addedToContact,
      billingPrefData,
      addressInfo.address,
      dispatch,
      productOrder,
      selectedPlan,
      selectedProduct
    ]
  );

  return { handleOnFormSubmit };
};
