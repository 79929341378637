import React, { ReactElement } from 'react';
import LoadingOverlayComponent from '../../components/LoadingOverlay';
import { AppState } from '../../types/common.types';
import { ListSkeletonLoader } from '../../components/SkeletonLoader';
import { Column } from '@dls/web';

export const useLoader = (states: AppState[]): { Loader } => {
  const shouldShowLoader = states.reduce((result, state) => {
    result = result || state.isLoading || state.loading;
    return result;
  }, false);

  const shouldShowSkeletonLoader = states.reduce((result, state) => {
    result = result || state.skeletonLoading;
    return result;
  }, false);

  const renderSkeletonLoader = () => {
    if (!shouldShowSkeletonLoader) {
      return null;
    }
    return (
      <Column xs={12} sm={8} md={6}>
        <ListSkeletonLoader numberOfItem={3} />
      </Column>
    );
  };

  const Loader = ({
    children,
    hasSkeletonLoader = true
  }: {
    children: ReactElement;
    hasSkeletonLoader?: boolean;
  }) => {
    return (
      <>
        <>
          {hasSkeletonLoader && renderSkeletonLoader()}
          {shouldShowLoader && <LoadingOverlayComponent />}
          {!shouldShowSkeletonLoader && children}
        </>
      </>
    );
  };
  return {
    Loader
  };
};
