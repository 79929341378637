/**
 * Checking Plan based on Silver Component tag.
 * nxt:Category/All Plans/Mobile Plans/Silver-Customer-Plans
 *
 * @param {*} tags plan
 */
export const isSilverTagPlan = (tags = []) => {
  const result = tags.filter(tag =>
    tag.toUpperCase().match(/\/SILVER-CUSTOMER-PLANS$/)
  );
  return result.length > 0;
};
