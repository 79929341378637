import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Row, Column } from '@lux/components';
import { withTheme } from '@dls/web';

import { BodySecondary, H3, SubtitlePrimary, SubtitleSecondary } from '../Base';
import Button from '../Button';

import { splitNumber } from '../../helpers/common';
import theme from '../../theme';

const StyledServiceItem = withTheme(styled.div`
  background-color: ${theme.colours.white};
  padding: ${theme.spacing(3)} ${theme.spacing(2)};
  ${({ coreTheme }) => css`
    @media (min-width: ${coreTheme.brk_md}) {
      padding: ${theme.spacing(3)};
    }
  `}
`);

const ColumnRightAlign = withTheme(styled(props => <Column {...props} />)`
  ${({ coreTheme }) => css`
    @media (min-width: ${coreTheme.brk_md}) {
      text-align: right;
    }
  `}
`);

const StyledServiceNumber = styled(H3)`
  margin-bottom: ${theme.spacing(2)};
`;

const Message = withTheme(styled(BodySecondary)`
  ${({ coreTheme }) => css`
    color: ${theme.colours.primaryVariant};
    margin: ${theme.spacing(2)} 0 ${theme.spacing(3)};
    @media (min-width: ${coreTheme.brk_md}) {
      margin: 0 0 ${theme.spacing(2)};
      min-height: ${theme.spacing(4)};
    }
  `}
`);

const StyledButton = withTheme(styled(Button)`
  ${({ coreTheme }) => css`
    width: 100%;
    @media (min-width: ${coreTheme.brk_md}) {
      width: auto;
    }
  `}
`);

const Plan = styled.div`
  color: ${theme.colours.grey_600};
`;

const ServiceListItem = props => {
  const {
    eligible,
    message,
    onClick,
    planDescription,
    planName,
    serviceNumber,
    buttonText
  } = props;

  if (!serviceNumber) return null;

  return (
    <StyledServiceItem>
      <Row between="sm">
        <Column sm={12} md={6}>
          <div className="fs-mask">
            <StyledServiceNumber>
              {splitNumber(serviceNumber)}
            </StyledServiceNumber>
          </div>
          <Plan>
            <SubtitlePrimary>{planName}</SubtitlePrimary>
            {planDescription && (
              <SubtitleSecondary>{planDescription}</SubtitleSecondary>
            )}
          </Plan>
        </Column>
        <ColumnRightAlign sm={12} md={6}>
          <Message>
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </Message>
          <StyledButton
            className="RecontractButton"
            disabled={!eligible}
            onClick={e => onClick(e)}
            data-testid={`recontract-button-${
              !eligible ? 'disabled' : 'enabled'
            }`}
          >
            {buttonText}
          </StyledButton>
        </ColumnRightAlign>
      </Row>
    </StyledServiceItem>
  );
};

ServiceListItem.defaultProps = {
  buttonText: 'Recontract'
};

ServiceListItem.propTypes = {
  /** Set if the service number is eligible for recontract */
  eligible: PropTypes.bool,
  /** Set the message for recontract eligibility */
  message: PropTypes.string,
  /** Set the click event */
  onClick: PropTypes.func,
  /** Set the plan description for the plan name */
  planDescription: PropTypes.string,
  /** Set the plan name for the service number */
  planName: PropTypes.string,
  /** Set service number e.g. 81234567 */
  serviceNumber: PropTypes.string,
  buttonText: PropTypes.string
};

export default ServiceListItem;
