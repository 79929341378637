import { APP_TYPE_ANY, KeyValue } from '../types/common.types';

const bridgeData = {};

const setBridgeData = (key: string, value: APP_TYPE_ANY): void => {
  bridgeData[key] = value;
};

const getBridgeData = (): KeyValue => {
  return bridgeData;
};

const getBridgeDataByKey = (key: string): APP_TYPE_ANY => {
  return bridgeData[key];
};

export { setBridgeData, getBridgeData, getBridgeDataByKey };
