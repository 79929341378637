/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import convert from 'htmr';
import PropTypes from 'prop-types';
import {
  addonsActions,
  orderActions,
  userActions,
  api,
  config
} from '@detox/actions';
import { Grid, Row, Column } from '@lux/components';
import { isValidEmail } from '@lux/helpers';
import Sticky from 'react-sticky-el';

import AddonsSimOnlyList from '../AddonsSimOnlyList';
import AddonsList from '../AddonsList';
import Anchor from '../Anchor';
import { H1, BodySecondary, SubtitleSecondary } from '../Base';
import Button from '../Button';
import CartSummaryWithButtons from '../CartSummaryWithButtons';
import ChangeSimModal from './ChangeSimModal';
import LoadingOverlayComponent from '../LoadingOverlay';
import Notification from '../Notification';
import Spacing from '../Spacing';
import TextField from '../TextField';
import Modal from '../Modal';
import AddonCharacteristicModal from '../AddonCharacteristicModal';
import useTranslate from '../../hooks/useTranslation';

import notifications from '../../config/notifications';

import isSimOnlyPlan from '../../helpers/is-sim-only-plan';
import isMobileSharePlan from '../../helpers/is-mobile-share-plan';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { flattenNodes, isTagged } from '../../helpers/common';
import formatPrice from '../../helpers/formatPrice';
import isAddonGroup from '../../helpers/is-addon-group';
import has5GAddon from '../../helpers/has-5g-addon';
import {
  addonMcAfeeTripleCheck,
  bundleMcAfeeCheck,
  updateAddons
} from './addon-helper';
import { planTagsConfig } from '../../config/plan-group-config';

import simOnlyIcon from '../../assets/svgs/plan-sim-only.svg';
import mobileShareIcon from '../../assets/svgs/plan-mobile-share.svg';
import { navigation } from '../../middlewares/navigation-constants';

import Theme from '../../theme';
import {
  DISABLE_SKIP_TO_SHOPPING_CART,
  FEATURE_FLAG_ENABLE_5GSA,
  ACCESSORIES_FLOW,
  PHONE_FIRST_CART_FLOW,
  PLAN_FIRST_CART_FLOW,
  FEATURE_FLAG_ENABLE_ESIM
} from '../../types/featureFlag.types';
import isReContractAndWithNoSim from '../../helpers/is-re-contract-and-with-no-sim';
import {
  getSimCardOptionPrice,
  getSimCardOptionTitle
} from '../../helpers/sim-card-option-helper';

import { ADDON } from '../../constants';
import { FLOW } from '../../types/plan.types';
import AddonModal from './AddonModal';
import SelectSimTypeModal from './SelectSimTypeModal';
import AddonRemoveAckModal from './AddonRemoveAckModal';
import { useAmendedTab } from '../../hooks/useAmendedTab/useAmendedTab';
import { CHOOSE_SIM_TYPE } from '../../constants/choose-sim-type';
import { simCardType } from '../../config/simcard-types';
import { getTradeInDetails } from '../../selectors';
import { CONTAINMENT_IDS } from '../../constants/addon';

const { sortAddonsByGroup, getAddonFormFields } = api.mcss.helpersAddons;
const { groupByArray } = api.mcss.helpers;

const StyledH1 = styled(H1)`
  ${p => p.theme.media.md`
  text-align: center;
`};
`;

const StyledButtonGroup = styled.div`
  ${p => p.theme.media.md`
  display: flex;
  flex-direction: row-reverse;
  text-align: right;
`};
`;

const StyledButton = styled(Button)`
  margin-bottom: ${p => p.theme.spacing(2)};

  &:last-child {
    margin-bottom: 0;
  }

  ${p => p.theme.media.md`
  width: 200px;
  height: 48px;
  margin-left: ${p.theme.spacing(2)};
  margin-bottom: 0;
`};
`;

const ExpandText = styled.span`
  cursor: pointer;
  font-family: ${p => p.theme.fonts.secondary};
  font-size: inherit;

  color: ${p => p.theme.colours.link};

  &:hover {
    color: ${p => p.theme.colours.linkVariant};
  }
`;

const StyledSticky = styled(props => <Sticky {...props} />)`
  position: relative;
  background-color: ${p => p.theme.colours.white};
  z-index: 9;
`;

const StyledColumnNoPadding = styled(props => <Column {...props} />)`
  padding: 0;
`;

export const validationMessages = {
  email: 'Ensure this is a valid email address'
};

const getErrors = ({ email }) => {
  const errors = {};

  if (!isValidEmail(email)) {
    errors.email = validationMessages.email;
  }

  return errors;
};

const is5GSAEnabled = isFeatureFlagEnabled(FEATURE_FLAG_ENABLE_5GSA);
const AddonsByPassList = process.env.GATSBY_BYPASS_ADDON_MODAL_LIST;

export const AddonCatalog = ({
  data,
  order,
  bridge,
  selectedService,
  selectedPlan,
  selectedProduct,
  selectedSimType,
  getAddonsFromOrder,
  getUserInformation,
  addAddonToOrder,
  removeAddonFromOrder,
  upgradeAddonToOrder,
  removeAndAddAddonFromOrder,
  updateOrderToRunRules,
  goToSpringDPage,
  promotions,
  addons,
  addSimToOrder,
  cisInfo,
  information,
  accessories,
  tradeInCID
}) => {
  const isEsimEnabled = isFeatureFlagEnabled(FEATURE_FLAG_ENABLE_ESIM);

  const { t } = useTranslate();
  const [characteristicModal, setCharacteristicModal] = useState(null);
  const [showRecontractSim, setShowRecontractSim] = useState(false);
  const [nextPage, setNextPage] = useState('addons');
  const [selectedAddon, setSelectedAddon] = useState();
  const [showAppleCareModal, setShowAppleCareModal] = useState(false);
  const [appleCareEmail, setAppleCareEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [changeSim, setChangeSim] = useState(null);
  const [showAllAddons, setShowAllAddons] = useState(false);
  const [totalAddonsCount, setTotalAddonsCount] = useState(0);
  const [openErrorModal, setOpenErrorModal] = useState(true);
  const [showSecondaryPopupModal, setShowSecondaryPopupModal] = useState(false);
  const [showRemoveAck, setShowRemoveAck] = useState(null);

  let {
    allAddon,
    allAddonGroup,
    allPhone: allApplePhone,
    addonSimOnlyPlan,
    simCardOptions,
    allPlanGroup,
    allChooseSimOptions
  } = data;
  allAddon = useMemo(() => flattenNodes(allAddon), [allAddon]);
  // We only need Apple branded phones for AppleCare related addons
  allApplePhone = useMemo(() => flattenNodes(allApplePhone), [allApplePhone]);
  allPlanGroup = useMemo(() => flattenNodes(allPlanGroup), [allPlanGroup]);
  const chooseSIMOptions = useMemo(() => flattenNodes(allChooseSimOptions), [
    allChooseSimOptions
  ]);
  const showAccessoriesFlag =
    allPlanGroup?.find(d => d.groupName === selectedPlan?.groupName) || {};
  const flowType = cisInfo?.information ? FLOW.CIS : FLOW.RES;
  const showAccessories =
    (cisInfo?.information && showAccessoriesFlag.enableAccessoriesPageForCIS) ||
    (!cisInfo?.information && showAccessoriesFlag.enableAccessoriesPageForRES);
  const userContact = information?.clientContext?.contact;
  const infoCustomerId = information?.clientContext?.customers?.[0]?.customerId;

  const cartBtnText = showAccessories
    ? t('SKIP_TO_CART')
    : t('PROCEED_TO_CART');

  const appleCareAddons = useMemo(
    () =>
      allAddon.filter(({ productTitle }) => productTitle.match(/AppleCare/i)),
    [allAddon]
  );

  const { productOrder } = { ...order };
  const {
    productOrderId,
    productOrderItemId,
    type: productOrderType,
    service: productOrderService,
    sim
  } = { ...productOrder };

  const { renderAmendedErrorPopup } = useAmendedTab();

  //Check if order includes a 5G plan or a 5G VAS
  const isHaving5gPlanOr5gVas = ({ selectedPlan, addons }) => {
    const { availableAddons, selectedAddons } = addons;
    return (
      isTagged(selectedPlan?.tags, planTagsConfig['5g-speed'].tagName) ||
      has5GAddon(availableAddons, selectedAddons)
    );
  };

  const _isHaving5gPlanOr5gVas = isHaving5gPlanOr5gVas({
    selectedPlan,
    addons
  });

  // Determine if the customer's existing SIM card is still on 4G and the new order is having a 5G SIM
  const is4gTo5gSimChange = sim && !sim.is5GSim && _isHaving5gPlanOr5gVas;

  const simCardOption = is4gTo5gSimChange
    ? simCardOptions.changeSIM4GTo5G
    : simCardOptions.changeSIM;

  // This isSimOnly indicator is used to determine if selected plan is a simonly plan without ipp.
  const isSimOnly =
    selectedPlan && isSimOnlyPlan(selectedPlan) && !selectedProduct;
  const isMobileShare = selectedPlan && isMobileSharePlan(selectedPlan);

  const isAddonsFeatureFlag = isFeatureFlagEnabled('ADDON_FLOW');

  /* refresh addon data if anything is changed */
  useEffect(() => {
    if (addons.modified) {
      handleRefresh();
    }
  }, [addons.modified]);

  useEffect(() => {
    if (!productOrder) {
      return navigate('/', { replace: true });
    }
  }, [productOrder]);

  useEffect(() => {
    if (addons?.error?.length) {
      setOpenErrorModal(true);
    }
  }, [addons?.error]);

  const handleRefresh = useCallback(async () => {
    if (productOrderItemId && information) {
      const productOrderHasDevice = Boolean(selectedProduct);
      const customerId = productOrderService?.customerId;
      let addonsForSelectedDevice;

      if (productOrderHasDevice) {
        addonsForSelectedDevice = allAddon.filter(
          ({ phoneList, flowType: addOnFlowType }) => {
            /**
             * If the addon is for a device, it must contain the selected device
             * Allow addons that are not supposed to have a device list.
             */
            const isAddonForFlow =
              !addOnFlowType?.length || addOnFlowType?.includes(flowType);
            if (phoneList?.length === 0) return isAddonForFlow;

            const selectedDeviceGroupId = selectedProduct.groupId;
            return phoneList?.includes(selectedDeviceGroupId) && isAddonForFlow;
          }
        );
      } else {
        const { data, sms, talktime } = addonSimOnlyPlan;

        const dataIds = data.map(({ addonId, quantity }) => ({
          productTitle: quantity,
          productSpecContainmentID: addonId,
          simOnlyAddon: true
        }));
        const talkTimeIds = talktime.map(({ addonId, quantity }) => ({
          productTitle: quantity,
          productSpecContainmentID: addonId,
          simOnlyAddon: true
        }));
        const smsIds = sms.map(({ addonId, quantity }) => ({
          productTitle: quantity,
          productSpecContainmentID: addonId,
          simOnlyAddon: true
        }));

        /**
         * Filter all AEM addons that match the selected device only.
         */
        // TODO: unit test for this update
        addonsForSelectedDevice = allAddon.concat([
          ...dataIds,
          ...smsIds,
          ...talkTimeIds
        ]);

        // If device is not selected, skip addons with requiredDevice=true.
        addonsForSelectedDevice = addonsForSelectedDevice.filter(
          addon => !addon.requiredDevice
        );
      }
      const isOrderActionCalled =
        addons.orderActionAttributes &&
        productOrderItemId === addons.orderActionAttributes?.productOrderItemId;
      const userInfoData = userContact
        ? {
            ...userContact,
            contactId: infoCustomerId,
            selectedAddonsPayload: false,
            tradeInProductId: CONTAINMENT_IDS.TRADE_IN
          }
        : {
            selectedAddonsPayload: false,
            tradeInProductId: CONTAINMENT_IDS.TRADE_IN
          };
      await getAddonsFromOrder({
        devices: allApplePhone,
        addons: appleCareAddons,
        productOrderItemId,
        aemAddonsList: addonsForSelectedDevice,
        customerId,
        productOrderId,
        orderActionsPayload: !isOrderActionCalled ? userInfoData : null,
        bundleAddonsIds: ADDON.BUNDLE_ADDON_IDS,
        containmentIds: ADDON.CONTAINMENT_IDS
      });
    }
  }, [
    addonSimOnlyPlan,
    allAddon,
    allApplePhone,
    appleCareAddons,
    getAddonsFromOrder,
    productOrderItemId,
    productOrderService,
    productOrderId,
    information
  ]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh, information]);

  useEffect(() => {
    if (!information) {
      getUserInformation();
    }
  }, [information]);

  const handleAddSimOnlyAddon = async addon => {
    const addonToAdd = addons.availableAddons[addon.addonId];

    await addAddonToOrder({
      addon: addonToAdd,
      productOrderItemId
    });
  };

  const handleRemoveSimOnlyAddon = async (addon, type) => {
    const addonToRemove = addons.availableAddons[addon.addonId];

    await removeAddonFromOrder({
      addon: addonToRemove,
      productOrderItemId
    });
  };

  const handleGoToNextPage = async page => {
    const { productId } = productOrder;
    const { promoOrderDetails } = promotions;
    const includeTradeInCID =
      tradeInCID && !(addons.selectedAddons || []).includes(tradeInCID);

    try {
      await updateOrderToRunRules({
        productId,
        productOrderItemId,
        selectedAddonIds: includeTradeInCID
          ? [...addons.selectedAddons, tradeInCID]
          : addons.selectedAddons,
        promoOrderDetails: {
          ...promoOrderDetails,
          sourcePlan: selectedService?.offeringId
        }
      });
    } catch (e) {
      return;
    }

    const productOrderHasDevice = Boolean(selectedProduct);
    if (
      ((productOrderHasDevice && isFeatureFlagEnabled(PHONE_FIRST_CART_FLOW)) ||
        (!productOrderHasDevice &&
          isFeatureFlagEnabled(PLAN_FIRST_CART_FLOW))) &&
      page === navigation.SHOPPING_CART_PAGE
    ) {
      return navigate(`/${navigation.SHOPPING_CART_PAGE}`);
    } else if (
      isFeatureFlagEnabled(ACCESSORIES_FLOW) &&
      page === navigation.ACCESSORIES_PAGE
    ) {
      return navigate(`/${navigation.ACCESSORIES_PAGE}`);
    } else {
      goToSpringDPage(page || nextPage);
    }
  };

  const handleReviewCartWithPreselection = async page => {
    if (
      selectedSimType === CHOOSE_SIM_TYPE.SIM_TYPE_PhysicalSIM ||
      selectedSimType === CHOOSE_SIM_TYPE.SIM_TYPE_ESIM
    ) {
      await handleRecontractWithSim();
    } else if (selectedSimType === CHOOSE_SIM_TYPE.SIM_TYPE_noSIM) {
      await handleRecontractWithOutSim();
    }

    return await handleGoToNextPage(page);
  };

  const handleGo = async page => {
    setNextPage(page);
    // If we are recontracting but do not have a sim card in the order,
    // we ask the user if they want a sim card
    // we also do not check if customer is going next to accessories
    // because SpringD accessories also asks
    if (
      isReContractAndWithNoSim(productOrder) &&
      page !== navigation.ACCESSORIES_PAGE
    ) {
      if (isEsimEnabled && !is4gTo5gSimChange) {
        // prevent showing the SIM modal as user already made a selection
        return await handleReviewCartWithPreselection(page);
      }

      if (
        isEsimEnabled &&
        is4gTo5gSimChange &&
        selectedSimType !== CHOOSE_SIM_TYPE.SIM_TYPE_noSIM
      ) {
        return await handleReviewCartWithPreselection(page);
      }

      if (simCardOption.changeSIMOptionEnabled) {
        // Showing Popup for New Sim Card Options
        return setShowRecontractSim(true);
      } else {
        // No Popup for New Sim Card Options
        setShowRecontractSim(false);
        return await handleGoToNextPage(page);
      }
    } else {
      return await handleGoToNextPage(page);
    }
  };

  const addAddonToTheOrder = async (addon, additionalProps = {}) => {
    const cID = addon?.productSpecPricing?.childPricingSchema.id;
    // mcafee bundle handling
    const { bundleHandlingNeeded = false, bundledAddon } = bundleMcAfeeCheck(
      cID,
      addons.bundleAddonsData
    );

    if (bundleHandlingNeeded) {
      await removeAndAddAddonFromOrder({
        addonToRemove: bundledAddon,
        addonToAdd: addon,
        productOrderItemId,
        ...additionalProps
      });
    } else {
      await addAddonToOrder({
        addon,
        productOrderItemId,
        ...additionalProps
      });
    }
  };

  const handleAddAppleCare = async () => {
    const appleCareErrors = getErrors({
      email: appleCareEmail
    });

    const isValidForm = Object.keys(appleCareErrors).length === 0;
    setErrors(appleCareErrors);

    if (isValidForm) {
      setShowAppleCareModal(false);
      await addAddonToOrder({
        addon: selectedAddon,
        productOrderItemId,
        appleCareEmail
      });
    }
  };

  const handleCharacteristicModal = async changedCharacteristic => {
    addAddonToTheOrder(selectedAddon, { changedCharacteristic });
  };

  const handleAddFamilyPlan = async () => {
    setShowSecondaryPopupModal(false);
    await addAddonToOrder({
      addon: selectedAddon,
      productOrderItemId
    });
  };

  const handleAdd = addon => {
    if (isAddonGroup(addon, 'APPLECARE')) {
      setShowAppleCareModal(true);
      setSelectedAddon(addon);
      return;
    }

    if (addon?.addonPopupMessage) {
      setShowSecondaryPopupModal(true);
      setSelectedAddon(addon);
      return;
    }

    /* prompts user input, this will be in 1.46 */
    /* For FREE MBB, pop up will be disabled. */
    const { title, formFields } = getAddonFormFields(addon);
    /**
     * TODO: to get the AddonsByPassList from AEM instead of Bamboo
     */
    const byPassAddonModal = AddonsByPassList.split('|').find(
      addOnId => addOnId === addon.productSpecPricing?.childPricingSchema?.id
    );
    if (formFields?.length && !byPassAddonModal) {
      setCharacteristicModal({ title, formFields });
      setSelectedAddon(addon);
      return;
    }

    addAddonToTheOrder(addon);
  };

  const handleRemove = async (addon, removeAck = false) => {
    const addonsCID = addon.productSpecPricing?.childPricingSchema?.id || '';

    // adding ack modal for specific addons
    // REMOVE_ACK_ADDONS is hardcoded as constants as there are no more req currently
    const isPresent = Object.values(ADDON.REMOVE_ACK_ADDONS).includes(
      addonsCID
    );

    if (addonsCID && isPresent && !removeAck) {
      setShowRemoveAck(addon);
    } else {
      setShowRemoveAck(null);
      // triple protect handling
      const mcafeeTriple = addonMcAfeeTripleCheck(addonsCID);

      await removeAddonFromOrder({
        addon,
        productOrderItemId,
        removeWithBillingProduct: Boolean(mcafeeTriple)
      });
    }
  };

  const handleUpgrade = async ({ addon, removedAddon }) => {
    await upgradeAddonToOrder({
      addon,
      removedAddon,
      productOrderItemId
    });
  };

  const handleRecontractWithSim = async selected4Gto5GSim => {
    const { sim } = productOrder;
    const { productId } = sim;
    let selectedSimTypeValue = selectedSimType;
    setShowRecontractSim(false);
    if (selected4Gto5GSim) {
      selectedSimTypeValue = selected4Gto5GSim;
    }

    const simType5GValue =
      selectedSimTypeValue === CHOOSE_SIM_TYPE.SIM_TYPE_ESIM
        ? simCardType.simTypeESim
        : config.simType5G;
    const simTypeValue = is5GSAEnabled ? simType5GValue : config.simType4G;

    await addSimToOrder({
      productOrderItemId,
      simProductId: productId,
      simType: simTypeValue
    });
  };

  const handleRecontractWithOutSim = async () => {
    setShowRecontractSim(false);
  };

  const handleReviewCart = async selected4Gto5GSim => {
    changeSim || is4gTo5gSimChange
      ? await handleRecontractWithSim(selected4Gto5GSim)
      : await handleRecontractWithOutSim();

    return await handleGoToNextPage(navigation.SHOPPING_CART_PAGE);
  };

  const updateAddonCount = addonsCount => {
    let count = addonsCount;
    if (isSimOnly) {
      const { data, sms, talktime } = addonSimOnlyPlan;
      if (data.length > 0) {
        count = count + 1;
      }
      if (sms.length > 0) {
        count = count + 1;
      }
      if (talktime.length > 0) {
        count = count + 1;
      }
    }
    setTotalAddonsCount(count);
  };

  // We form another object from which we query add on group information from.
  // Key = group name
  // Value = information for that group, like setupFee
  // We calculate this value only once
  const addonGroupInfoLookup = useMemo(
    () =>
      allAddonGroup.edges.reduce((accum, cur) => {
        accum[cur.node.displayName] = cur.node;
        return accum;
      }, {}),
    [allAddonGroup.edges]
  );

  // We form an object from which we can query from.
  // Key = group name
  // Value = array of addons belonging to that group
  // We calculate this value only when availableAddons change
  //
  const addonsSortedByGroupName = useMemo(() => {
    const { availableAddons, selectedAddonsBo } = addons;

    if (availableAddons) {
      let result = [];
      const updatedAvailableAddons = updateAddons(
        availableAddons,
        selectedAddonsBo,
        {
          orderType: productOrderType,
          selectedPlan: selectedPlan
        }
      );

      const sequence = groupByArray(allAddon, 'groupName', 'productTitle').map(
        item => item.key
      );

      result = sortAddonsByGroup(updatedAvailableAddons, sequence);
      updateAddonCount(result.length);

      if (!showAllAddons) {
        result = result.slice(0, ADDON.DEFAULT_SHOW_ADDONS_COUNT);
      }

      return result;
    }
    return [];
  }, [addons, allAddon, order, showAllAddons]);

  const handleNextClick = () => handleGo(navigation.ACCESSORIES_PAGE);
  const handleSkipToCartClick = () => handleGo(navigation.SHOPPING_CART_PAGE);

  let cartSummProps = {};
  if (selectedProduct) {
    const {
      imageGallery,
      title,
      colour,
      size,
      isPreOrder,
      isBackOrder
    } = selectedProduct;
    const productImage = imageGallery.find(img => img.name === colour);
    cartSummProps.productImage = productImage?.images[0].image;
    cartSummProps.productPrimaryText = `${title} ${colour} ${size}`;
    cartSummProps.preOrder = isPreOrder || isBackOrder;
  } else if (isSimOnly) {
    cartSummProps.icon = simOnlyIcon;
  } else if (isMobileShare) {
    cartSummProps.icon = mobileShareIcon;
  }

  const { planName } = { ...selectedPlan };
  cartSummProps.productSecondaryText = planName;

  const isLoading = addons.loading || order.loading || bridge?.loading;
  const hasAvailableAddons =
    (!addons.error || addons?.error?.length) && addons.availableAddons;

  if (!isLoading && !hasAvailableAddons) {
    return (
      <Grid>
        <Spacing bottom={2}>
          <H1>Add-ons are unavailable at the moment</H1>
        </Spacing>
        <Spacing bottom={3}>
          <SubtitleSecondary>
            This may be due to a technical difficulty.
          </SubtitleSecondary>
        </Spacing>
        <Button onClick={() => navigate(`/${navigation.SHOPPING_CART_PAGE}`)}>
          Proceed to Cart
        </Button>
      </Grid>
    );
  } else {
    const primaryItem = simCardOption.changeSIMOption1
      ? {
          title: getSimCardOptionTitle(simCardOption.changeSIMOption1),
          price: getSimCardOptionPrice(simCardOption.changeSIMOption1),
          selected: changeSim === true
        }
      : null;
    const secondaryItem = simCardOption.changeSIMOption2
      ? {
          title: simCardOption.changeSIMOption2,
          price: '',
          selected: changeSim === false
        }
      : null;
    return (
      <Fragment>
        {isLoading && <LoadingOverlayComponent />}
        {characteristicModal && (
          <AddonCharacteristicModal
            {...characteristicModal}
            onConfirm={handleCharacteristicModal}
            onClose={() => setCharacteristicModal(null)}
          />
        )}

        {!!showRemoveAck && (
          <AddonRemoveAckModal
            addon={showRemoveAck}
            isModalOpen={!!showRemoveAck}
            onClose={() => {
              setShowRemoveAck(null);
            }}
            onProceed={addon => {
              handleRemove(addon, true);
            }}
          />
        )}

        {isEsimEnabled ? (
          <SelectSimTypeModal
            title={simCardOption.title}
            subTitle={
              _isHaving5gPlanOr5gVas && is5GSAEnabled
                ? simCardOption.blueNotificationMessage
                : ''
            }
            chooseSIMOptions={chooseSIMOptions}
            isModalOpen={showRecontractSim}
            selectedSimType={selectedSimType}
            selectedProduct={selectedProduct}
            onClose={() => setShowRecontractSim(false)}
            onProceed={selected4Gto5GSim => handleReviewCart(selected4Gto5GSim)}
          />
        ) : (
          <ChangeSimModal
            title={simCardOption.title}
            open={showRecontractSim}
            primaryItem={primaryItem}
            secondaryItem={secondaryItem}
            ctaText={simCardOption.ctaLabel}
            onClose={() => setShowRecontractSim(false)}
            onPrimaryClick={() => setChangeSim(true)}
            onSecondaryClick={() => setChangeSim(false)}
            onButtonClick={() => handleReviewCart()}
            message={
              _isHaving5gPlanOr5gVas && is5GSAEnabled
                ? simCardOption.blueNotificationMessage
                : ''
            }
          />
        )}

        {addons?.error?.length && (
          <div data-testid="production-configuration-error-modal">
            <AddonModal
              isModalOpen={openErrorModal}
              modaltitle={t('PRODUCT_CONFIGURATION_ERROR_TITLE')}
              modalContent={t('PRODUCT_CONFIGURATION_ERROR_DESCRIPTION')}
              modalItems={addons?.error}
              secondaryType={false}
              onClose={() => setOpenErrorModal(false)}
            />
          </div>
        )}
        {showSecondaryPopupModal && (
          <AddonModal
            isModalOpen={showSecondaryPopupModal}
            disableClose={false}
            modaltitle={selectedAddon?.addonPopupTitle}
            modalContent={selectedAddon?.addonPopupMessage}
            ctaText={selectedAddon?.addonPopupButtonLabel}
            secondaryType={true}
            onConfirm={handleAddFamilyPlan}
            onClose={() => setShowSecondaryPopupModal(false)}
          />
        )}
        <Modal
          open={showAppleCareModal}
          title="AppleCare+"
          onClose={() => setShowAppleCareModal(false)}
        >
          <Spacing bottom={4}>
            {selectedAddon && (
              <SubtitleSecondary>
                {selectedAddon.onetimePrice !== '0'
                  ? `${formatPrice(selectedAddon.onetimePrice)}`
                  : `${formatPrice(selectedAddon.price)}/mth`}
              </SubtitleSecondary>
            )}
          </Spacing>
          <Spacing bottom={2}>
            <TextField
              data-testid="input-email"
              id="email"
              placeholder="Email"
              value={appleCareEmail}
              onChange={e => setAppleCareEmail(e.target.value)}
              hintMessage={errors.email}
              validation={errors.email && 'danger'}
              onClick={() => setErrors({ ...errors, email: null })}
              tabIndex={0}
            />
          </Spacing>
          <Spacing bottom={4}>
            <BodySecondary>
              Your AppleCare+ Proof of Coverage will be sent to this email
            </BodySecondary>
          </Spacing>
          <Button onClick={handleAddAppleCare}>Confirm</Button>
        </Modal>
        <Spacing bottom={6} bottomMd={8}>
          <StyledColumnNoPadding sm={12} md={false}>
            <CartSummaryWithButtons
              isPreOrderPhone={cartSummProps.preOrder}
              productPrimaryText={cartSummProps.productPrimaryText}
              productSecondaryText={cartSummProps.productSecondaryText}
              productImage={cartSummProps.productImage}
              icon={cartSummProps.icon}
              primaryCtaText={t('BUY_ACCESSORIES')}
              secondaryCtaText={
                isFeatureFlagEnabled(DISABLE_SKIP_TO_SHOPPING_CART)
                  ? null
                  : cartBtnText
              }
              onPrimaryCtaClick={handleNextClick}
              onSecondaryCtaClick={handleSkipToCartClick}
              showAccessories={showAccessories}
            />
          </StyledColumnNoPadding>
          <StyledColumnNoPadding sm={false} md={12}>
            <StyledSticky
              stickyStyle={{
                boxShadow: `0 2px 5px 0 ${Theme.colours.grey_400}`
              }}
            >
              <CartSummaryWithButtons
                isPreOrderPhone={cartSummProps.preOrder}
                productPrimaryText={cartSummProps.productPrimaryText}
                productSecondaryText={cartSummProps.productSecondaryText}
                productImage={cartSummProps.productImage}
                icon={cartSummProps.icon}
                primaryCtaText={t('BUY_ACCESSORIES')}
                secondaryCtaText={
                  isFeatureFlagEnabled(DISABLE_SKIP_TO_SHOPPING_CART)
                    ? null
                    : cartBtnText
                }
                onPrimaryCtaClick={handleNextClick}
                onSecondaryCtaClick={handleSkipToCartClick}
                showAccessories={showAccessories}
              />
            </StyledSticky>
          </StyledColumnNoPadding>
        </Spacing>
        <Grid>
          <Row>
            <Column sm={12} lg={10} lgOffset={1}>
              <Spacing bottom={6} bottomMd={5}>
                <Spacing bottom={3} bottomMd={4}>
                  <StyledH1>Essential add-ons</StyledH1>
                </Spacing>
                {order.error && (
                  <Spacing bottom={2} bottomMd={3}>
                    <Notification
                      state={notifications.ADDON_GENERIC_ERROR.state}
                    >
                      {convert(`${notifications.ADDON_GENERIC_ERROR.text}`, {
                        transform: {
                          a: Anchor
                        }
                      })}
                    </Notification>
                  </Spacing>
                )}
                {(isSimOnly || isAddonsFeatureFlag) && (
                  <AddonsList
                    addonGroups={addonsSortedByGroupName}
                    addonGroupInfoLookup={addonGroupInfoLookup}
                    orderType={productOrderType}
                    onAdd={handleAdd}
                    onRemove={handleRemove}
                    onUpgrade={handleUpgrade}
                  />
                )}
                {isSimOnly &&
                  (showAllAddons ||
                    addonsSortedByGroupName.length <
                      ADDON.DEFAULT_SHOW_ADDONS_COUNT) && (
                    <Spacing top={3}>
                      <AddonsSimOnlyList
                        showingAddonsCount={addonsSortedByGroupName.length}
                        showAllAddons={showAllAddons}
                        orderType={productOrderType}
                        onAdd={handleAddSimOnlyAddon}
                        onRemove={handleRemoveSimOnlyAddon}
                        selectedAddons={addons.selectedAddons}
                        addonSimOnlyPlan={addonSimOnlyPlan}
                      />
                    </Spacing>
                  )}
                <Row center="sm">
                  <Spacing top={3}>
                    {totalAddonsCount > ADDON.DEFAULT_SHOW_ADDONS_COUNT && (
                      <ExpandText
                        onClick={() => setShowAllAddons(!showAllAddons)}
                      >
                        {!showAllAddons && 'Show more'}
                        {showAllAddons && 'Show less'}
                      </ExpandText>
                    )}
                  </Spacing>
                </Row>
              </Spacing>
              <StyledButtonGroup>
                {showAccessories && (
                  <StyledButton onClick={handleNextClick}>
                    {t('BUY_ACCESSORIES')}
                  </StyledButton>
                )}
                {!isFeatureFlagEnabled('DISABLE_SKIP_TO_SHOPPING_CART') && (
                  <StyledButton
                    onClick={handleSkipToCartClick}
                    role="skip-to-cart"
                    secondary={showAccessories}
                  >
                    {cartBtnText}
                  </StyledButton>
                )}
              </StyledButtonGroup>
            </Column>
          </Row>
        </Grid>
        {renderAmendedErrorPopup()}
      </Fragment>
    );
  }
};

AddonCatalog.propTypes = {
  data: PropTypes.any.isRequired,
  order: PropTypes.any.isRequired,
  bridge: PropTypes.any.isRequired,
  selectedService: PropTypes.any.isRequired,
  selectedPlan: PropTypes.any.isRequired,
  selectedProduct: PropTypes.any.isRequired,
  selectedSimType: PropTypes.any.isRequired,
  getAddonsFromOrder: PropTypes.any.isRequired,
  getUserInformation: PropTypes.any.isRequired,
  addAddonToOrder: PropTypes.any.isRequired,
  removeAddonFromOrder: PropTypes.any.isRequired,
  upgradeAddonToOrder: PropTypes.any.isRequired,
  updateOrderToRunRules: PropTypes.any.isRequired,
  goToSpringDPage: PropTypes.any.isRequired,
  promotions: PropTypes.any.isRequired,
  addons: PropTypes.any.isRequired,
  addSimToOrder: PropTypes.any.isRequired,
  cisInfo: PropTypes.any.isRequired,
  information: PropTypes.any.isRequired
};

/* istanbul ignore next */
const mapStateToProps = state => {
  const {
    addons,
    order,
    plan,
    product,
    promotions,
    user,
    bridge,
    accessory
  } = state;
  const { selectedService, cis, information } = user;
  const { selectedPlan } = plan;
  const { selectedProduct, selectedSimType } = product;
  const { tradeInCID } = getTradeInDetails(state);

  return {
    addons,
    order,
    promotions,
    selectedPlan,
    selectedProduct,
    selectedSimType,
    selectedService,
    bridge,
    cisInfo: cis,
    information,
    accessories: accessory?.selectedAccessories,
    tradeInCID
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  const {
    getAddonsFromOrder,
    addAddonToOrder,
    removeAddonFromOrder,
    upgradeAddonToOrder,
    removeAndAddAddonFromOrder
  } = addonsActions;
  const { updateOrderToRunRules, addSimToOrder } = orderActions;
  const { getUserInformation } = userActions;
  return {
    getAddonsFromOrder: options => dispatch(getAddonsFromOrder(options)),
    addAddonToOrder: options => dispatch(addAddonToOrder(options)),
    removeAndAddAddonFromOrder: options =>
      dispatch(removeAndAddAddonFromOrder(options)),
    removeAddonFromOrder: options => dispatch(removeAddonFromOrder(options)),
    upgradeAddonToOrder: options => dispatch(upgradeAddonToOrder(options)),
    updateOrderToRunRules: options => dispatch(updateOrderToRunRules(options)),
    goToSpringDPage: page =>
      dispatch({ type: 'GO_TO_SPRINGD_PAGE', value: page }),
    addSimToOrder: options => dispatch(addSimToOrder(options)),
    getUserInformation: () => dispatch(getUserInformation())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddonCatalog);
