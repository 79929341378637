import React from 'react';
import { getUrlParams } from '@lux/helpers';
import BackgroundColourToggle from '../BackgroundColourToggle';
import OnePassLogin from '../OnePassLogin';
import SEO from '../SEO';
import { Spacing } from '@dls/web';

import { onePassLoginUrl } from '../../config';

interface Props {
  redirectTo: string;
}

export const OnePassLoginPage: React.FC<Props> = ({ redirectTo }) => {
  const urlParams = getUrlParams();

  return (
    <BackgroundColourToggle enabled={true}>
      <SEO title="OnePass Login" />
      <Spacing bottom={2} bottomMd={4}>
        <OnePassLogin
          loginUrl={onePassLoginUrl}
          hostUrl={process.env.GATSBY_AEM_URL}
          referenceUrl={urlParams?.redirectionUrl || redirectTo}
        />
      </Spacing>
    </BackgroundColourToggle>
  );
};

OnePassLoginPage.defaultProps = {
  redirectTo: '/choose-your-number'
};

export default OnePassLoginPage;
