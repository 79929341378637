import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noop } from '@lux/helpers';
import theme from '../../theme';

const StyledAnchor = styled.a`
  color: ${theme.colours.page_link_colour};
  text-decoration: none;
  cursor: pointer;
  outline: none;

  &:hover {
    color: ${theme.colours.page_link_colour_active};
  }
`;

const Anchor = props => {
  const { onClick, ...rest } = props;

  return <StyledAnchor onClick={e => onClick(e)} {...rest} />;
};

Anchor.defaultProps = {
  onClick: noop
};

Anchor.propTypes = {
  /** Anchor text */
  children: PropTypes.node,
  /** The link for the anchor tag */
  href: PropTypes.string,
  /** Set the role of the anchor */
  role: PropTypes.string,
  /** Set the aria label */
  ariaLabel: PropTypes.string,
  /** Set the target of the link */
  target: PropTypes.string,
  /** Add a title to the link */
  title: PropTypes.string,
  /** Set the tab index */
  tabIndex: PropTypes.number,
  /** Event to fire when component is clicked */
  onClick: PropTypes.func
};

export default Anchor;
