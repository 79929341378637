import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  Modal,
  TextLink,
  Layout,
  Text,
  Spacing,
  Button,
  Selector,
  Divider
} from '@dls/web';
import { Form, FORM_FIELD_TYPES } from '@wec-core/form-engine';
import { trans as t } from '../../../helpers/localisation';
import { APP_TYPE_ANY, KeyValue } from '../../../types/common.types';
import formatPrice from '../../../helpers/formatPrice';
import { getCharacteristicConfig, getContactText } from '../addon-helper';
import AddonCharacteristicModal from '../../AddonCharacteristicModal';
import isAddonGroup from '../../../helpers/is-addon-group';

const formFieldsData = [
  {
    component: FORM_FIELD_TYPES.FORM_TITLE,
    name: 'formTitle',
    title: t('CC_E_EMAIL'),
    subTitle: t('ADDON_APPLECARE_MSG')
  },
  {
    component: FORM_FIELD_TYPES.TEXT_INPUT,
    name: 'email',
    label: t('EMAIL'),
    bgColor: 'haze',
    placeholder: t('EMAIL'),
    validations: [
      'string',
      {
        type: 'required',
        params: { message: t('PRE_F_CIS_EMAIL_REQUIRED') }
      },
      {
        type: 'email',
        params: { message: t('PRE_F_CIS_EMAIL_REQUIRED') }
      }
    ]
  },
  {
    component: FORM_FIELD_TYPES.FORM_SUBMIT_BUTTON,
    name: 'submitButton',
    label: t('ADD'),
    sizing: 3,
    btnProps: {
      fullWidth: true,
      secondary: true
    }
  }
];

interface Props extends KeyValue {
  isAddAddonOpen: boolean;
  onClose: (values?: KeyValue) => void | Promise<void>;
  addonData: KeyValue;
  onRemove: (addon) => void;
  onAdd: (addon) => void;
  onRemoveAndAdd: (
    addonToRemove: KeyValue,
    addonToAdd: KeyValue
  ) => void | Promise<void>;
  onAddWithCharacteristics?: (
    values?: KeyValue,
    addonData?: KeyValue,
    isAppleCare?: boolean,
    addonToRemove?: KeyValue
  ) => void | Promise<void>;
}

const AddGroupAddonsModal = (props: Props): ReactElement => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const [selectedAddon, setSelectedAddon] = useState(null);
  const [characteristicsData, setCharacteristicsData] = useState(null);
  const [appleCareCharsData, setAppleCareCharsData] = useState(null);
  const [hasAllowedCharFormFields, setHasAllowedCharFormFields] = useState(
    true
  );
  const {
    isAddAddonOpen,
    onClose,
    addonData,
    onRemove = () => null,
    onAdd = () => null,
    onAddWithCharacteristics = () => null,
    onRemoveAndAdd = () => null
  } = props;
  const { group, groupInfo } = addonData || {};
  const { addons = [], groupName = '' } = group || {};

  const groupAddonAdded = addons.find(({ disabled }) => disabled);
  const addedAddon = groupAddonAdded
    ? addons.find(({ disabled }) => !disabled)
    : null;

  const setCharacteristics = useCallback(addon => {
    if (isAddonGroup(addon, 'APPLECARE')) {
      setCharacteristicsData(null);
      setAppleCareCharsData(formFieldsData);
    } else {
      const {
        characteristicConfig,
        hasAllowedFormFields
      } = getCharacteristicConfig(addon);

      setAppleCareCharsData(null);
      setHasAllowedCharFormFields(hasAllowedFormFields);
      setCharacteristicsData(characteristicConfig || null);
    }
  }, []);

  const resetCharacteristics = () => {
    setCharacteristicsData(null);
    setAppleCareCharsData(null);
  };

  const closeHandler = () => {
    resetCharacteristics();
    onClose();
  };

  useEffect(() => {
    setSelectedAddon(null);
  }, []);

  useEffect(() => {
    if (!isAddAddonOpen) {
      setSelectedAddon(null);
      resetCharacteristics();
    }
  }, [isAddAddonOpen]);

  useEffect(() => {
    const groupAddonIndex = addons.findIndex(({ disabled }) => !disabled);

    setSelectedOptionIndex(groupAddonAdded ? groupAddonIndex : -1);
  }, [addons, groupAddonAdded]);

  const renderCharsAndFooter = () => {
    const isRemoveAndAdd =
      selectedAddon &&
      addedAddon &&
      (addedAddon.productSpecPricing?.childPricingSchema?.id !==
        selectedAddon.productSpecPricing?.childPricingSchema?.id ||
        addedAddon.id !== selectedAddon.id);

    const removeAddCharData = isRemoveAndAdd ? addedAddon : null;

    // apple care handling
    if (
      (!addedAddon && appleCareCharsData) ||
      (isRemoveAndAdd && appleCareCharsData)
    ) {
      const initialFormValues = {
        email: ''
      };

      return (
        <>
          <Spacing bottom={2}>
            <Divider />
          </Spacing>
          <Form
            stackSpacing={2}
            enableReinitialize={true}
            initialValues={initialFormValues}
            data={{}}
            onSubmit={values => {
              onAddWithCharacteristics(
                { appleCareEmail: values.email },
                selectedAddon,
                true,
                removeAddCharData
              );
              resetCharacteristics();
            }}
            formFieldsConfig={formFieldsData}
            callbacks={{}}
          />
        </>
      );
    }

    if (
      (!addedAddon && characteristicsData) ||
      (isRemoveAndAdd && characteristicsData)
    ) {
      return (
        <Spacing bottom={2}>
          <Spacing bottom={2}>
            <Divider />
          </Spacing>

          {hasAllowedCharFormFields && (
            <Spacing bottom={1}>
              <Text type="boldBody">{t('ENTER_YOUR_DETAILS')}</Text>
            </Spacing>
          )}

          <AddonCharacteristicModal
            inModal
            {...characteristicsData}
            onConfirm={values => {
              onAddWithCharacteristics(
                values,
                selectedAddon,
                false,
                removeAddCharData
              );
              resetCharacteristics();
            }}
            inModalBtnConfig={{
              fullWidth: true,
              text: t('ADD'),
              secondary: true
            }}
          />
        </Spacing>
      );
    }

    if (isRemoveAndAdd) {
      return (
        <Spacing bottom={2}>
          <Button
            secondary
            fullWidth
            onClick={() => {
              if (selectedAddon && addedAddon) {
                onRemoveAndAdd(addedAddon, selectedAddon);
              }
            }}
            text={t('ADD')}
            data-testid="remove-add-button"
          />
        </Spacing>
      );
    }

    if (groupAddonAdded && selectedOptionIndex !== -1) {
      return (
        <Spacing bottom={2}>
          <Button
            fullWidth
            onClick={() => {
              if (addedAddon) {
                onRemove(addedAddon);
              }
            }}
            text={t('REMOVE')}
            data-testid="remove-button"
          />
        </Spacing>
      );
    }

    return (
      <Spacing bottom={2}>
        <Button
          secondary
          fullWidth
          onClick={() => {
            if (selectedAddon) {
              onAdd(selectedAddon);
            }
          }}
          text={t('ADD')}
          data-testid="add-button"
        />
      </Spacing>
    );
  };

  return (
    <Modal
      data-testid={`add-group-addon-modal`}
      visible={isAddAddonOpen}
      title={groupName}
      closable={true}
      onClose={closeHandler}
      wide={false}
      backdropClosable={false}
    >
      {
        (
          <Modal.Content>
            {groupInfo?.shortDescription && (
              <Spacing topBottom={2}>
                <Text {...({ type: 'body' } as APP_TYPE_ANY)}>
                  {groupInfo?.shortDescription}
                </Text>
              </Spacing>
            )}

            <Spacing>
              <Text type="boldBody">{t('ADDON_SELECTION')}</Text>
              {/* TODO: remove later based on testing */}
              {/* Not needed as of now, experementing with remove and add togethere */}
              {/* {groupAddonAdded && selectedOptionIndex !== -1 && (
                <Text type="smallBody">{t('ADDON_REMOVE_SELECTION')}</Text>
              )} */}
            </Spacing>

            <Spacing top={1} bottom={1}>
              {addons
                .sort((a, b) => a.productTitle.localeCompare(b.productTitle))
                .map((addon, addonIndex) => {
                  const {
                    price,
                    onetimePrice,
                    contract,
                    productTitle,
                    promoDescription,
                    id
                  } = addon;

                  let priceText = `${formatPrice(price)}/mth`;
                  if (
                    onetimePrice?.trim() &&
                    onetimePrice !== '0' &&
                    (!price || price === '0' || !price.trim())
                  ) {
                    priceText = `${formatPrice(onetimePrice)}`;
                  } else {
                    priceText = `${formatPrice(price)}/mth`;
                  }

                  const contractText = getContactText(contract);

                  return (
                    <Spacing bottom={1} key={id}>
                      <Selector
                        alignment="row"
                        endContent={priceText}
                        selected={selectedOptionIndex === addonIndex}
                        onClick={() => {
                          setSelectedAddon(addon);
                          setCharacteristics(addon);
                          setSelectedOptionIndex(addonIndex);
                        }}
                        data-testid={`selector-opt-${addonIndex}`}
                      >
                        <Selector.Body
                          title={`${productTitle} (${contractText})`}
                          subtitle={promoDescription || ''}
                          align={'start'}
                        ></Selector.Body>
                      </Selector>
                    </Spacing>
                  );
                })}
            </Spacing>

            <Layout.Stack>
              {renderCharsAndFooter()}

              <Layout.Stack align="center">
                <TextLink onClick={closeHandler}>
                  <TextLink.Text>{t('NO_THANKS')}</TextLink.Text>
                </TextLink>
              </Layout.Stack>
            </Layout.Stack>
          </Modal.Content>
        ) as APP_TYPE_ANY
      }
    </Modal>
  );
};

export default AddGroupAddonsModal;
