import {
  ADDON_FLOW,
  SIMONLYPLUS_UPSELL_DESIGN,
  GOMO_FLOW,
  ONLINE_STOCK_FROM_ESHOP,
  PLAN_GROUP_TABS,
  ACCESSORIES_FLOW,
  SHOPPING_CART_FLOW,
  DISABLE_SKIP_TO_SHOPPING_CART,
  FEATURE_FLAG_ENABLE_5GSA,
  PHONE_FIRST_CART_FLOW,
  PLAN_FIRST_CART_FLOW,
  VOUCHER,
  TRADEIN,
  CIS_FLOW,
  SHOP_CHECKOUT,
  DTCP,
  FULLSTORY_ENABLE,
  SHOP_ORDER_SUMMARY,
  SHOP_THANK_YOU_PAGE,
  LI_HOOK_SIGNUP,
  RED_PHASE,
  CP9_FLOW,
  TOMO_PHASE_TWO,
  UIAM_FLOW,
  GATEMANAGER,
  CCE_IPHONE_FLOW,
  YUU_MEMBERSHIP,
  RRP_FLOW,
  UPDATE_PARTIAL_CONTACT,
  FEATURE_FLAG_ENABLE_ESIM,
  FEATURE_FLAG_ENABLE_NEW_WORRY_FREE,
  ENABLE_MANUAL_FLOW,
  ENABLE_PSIM_MYINFO,
  FEATURE_FLAG_ENABLE_QUALTRICS,
  FEATURE_FLAG_ENABLE_RRP,
  FEATURE_RECON_PD_UPDATE,
  NEW_ADDONS_CATALOG,
  FEATURE_FLAG_ENABLE_TRADEIN_FOR_SOP,
  FEATURE_FLAG_ENABLE_BIB
} from '../types/featureFlag.types';

const clientFeatureFlag = flag =>
  typeof window !== 'undefined' && window.localStorage.getItem(flag) === 'true';

const isClientFlagPresentInStaging = flag => {
  const isProduction =
    process.env.NODE_ENV === 'production' &&
    process.env.GATSBY_STAGING_ENV !== 'true';

  return (
    !isProduction &&
    typeof window !== 'undefined' &&
    window.localStorage.getItem(flag)
  );
};

/**
 * If we have feature flag enabled to continue with the Detox flow, otherwise we direct to SpringD
 */
const isFeatureFlagEnabled = flag => {
  switch (flag) {
    case ADDON_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ADDON_FLOW);
    case ACCESSORIES_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ACCESSORIES_FLOW);
    case ONLINE_STOCK_FROM_ESHOP:
      return /^true$/i.test(
        process.env.GATSBY_FEATURE_FLAG_ONLINE_STOCK_FROM_ESHOP
      );
    case GOMO_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_GOMO_FLOW);
    case PLAN_GROUP_TABS:
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_PLAN_GROUP_TABS);
    case SHOPPING_CART_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_SHOPPING_CART_FLOW);
    // This feature flag is no longer needed after the launch of PP2.0
    case SIMONLYPLUS_UPSELL_DESIGN:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(
        process.env.GATSBY_FEATURE_FLAG_SIMONLYPLUS_UPSELL_DESIGN
      );
    case DISABLE_SKIP_TO_SHOPPING_CART:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(
        process.env.GATSBY_FEATURE_FLAG_DISABLE_SKIP_TO_SHOPPING_CART
      );
    case FEATURE_FLAG_ENABLE_5GSA:
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ENABLE_5GSA);
    case TRADEIN:
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_TRADEIN);
    case VOUCHER:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_VOUCHER);
    case DTCP:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_DTCP);
    case PHONE_FIRST_CART_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_PHONE_FIRST_CART_FLOW);
    case PLAN_FIRST_CART_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_PLAN_FIRST_CART_FLOW);
    case SHOP_CHECKOUT:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_SHOP_CHECKOUT);
    case FULLSTORY_ENABLE:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FULLSTORY_ENABLE);
    case CIS_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_CIS_FLOW);

    case SHOP_ORDER_SUMMARY: {
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_SHOP_ORDER_SUMMARY);
    }

    case SHOP_THANK_YOU_PAGE: {
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(
        process.env.GATSBY_FEATURE_FLAG_SHOP_THANK_YOU_PAGE
      );
    }

    case LI_HOOK_SIGNUP: {
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_LI_HOOK_SIGNUP);
    }

    case RED_PHASE: {
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_RED_PHASE);
    }

    case CP9_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_CP9_FLOW);

    case TOMO_PHASE_TWO: {
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_TOMO_PHASE_TWO);
    }

    case CCE_IPHONE_FLOW: {
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_CCE_IPHONE_FLOW);
    }

    case UIAM_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_UIAM_FLOW);

    case GATEMANAGER:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_APIGEE_GATE_MANAGER);

    case YUU_MEMBERSHIP:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_YUU_MEMBERSHIP);
    case RRP_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_RRP_FLOW);

    case UPDATE_PARTIAL_CONTACT:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(
        process.env.GATSBY_FEATURE_FLAG_UPDATE_PARTIAL_CONTACT
      );
    case FEATURE_FLAG_ENABLE_NEW_WORRY_FREE:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(
        process.env.GATSBY_FEATURE_FLAG_ENABLE_NEW_WORRY_FREE
      );

    case FEATURE_FLAG_ENABLE_ESIM:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ENABLE_ESIM);

    case ENABLE_PSIM_MYINFO:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ENABLE_PSIM_MYINFO);
    case FEATURE_FLAG_ENABLE_RRP:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ENABLE_RRP);

    case ENABLE_MANUAL_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ENABLE_MANUAL_FLOW);

    case FEATURE_FLAG_ENABLE_QUALTRICS:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ENABLE_QUALTRICS);
    case FEATURE_RECON_PD_UPDATE:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_RECON_PD_UPDATE);

    case NEW_ADDONS_CATALOG:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_NEW_ADDONS_CATALOG);

    case FEATURE_FLAG_ENABLE_TRADEIN_FOR_SOP:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(
        process.env.GATSBY_FEATURE_FLAG_ENABLE_TRADEIN_FOR_SOP
      );

    case FEATURE_FLAG_ENABLE_BIB:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ENABLE_BIB);

    default:
      return false;
  }
};

export default isFeatureFlagEnabled;
