import React, { Fragment, useEffect } from 'react';
import {
  FilterGroup,
  Product,
  ProductCatalogue
} from '@common-modules/product-catalogue';
import CONSTANTS from '../../constants/common';
import { Column, Grid, Row, Notification } from '@dls/web';
import { KeyValue } from '../../types/common.types';
import { flattenNodes, getAemConfig } from '../../helpers/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  applyFilter as actionApplyFilter,
  clearFilter as actionClearFilter,
  ProductCatalogState,
  resetToPreviousFilter as actionResetToPreviousFilter,
  selectFilter as actionSelectFilter,
  setFilterData,
  setIppFilter,
  setProductList,
  setRRPFlow,
  sortProducts
} from '../../reducers/productCatalogs';
import { navigate } from 'gatsby';
import {
  getCatalogHeader,
  navigateToSDRRP,
  setUrlParamsMapping,
  updateUrlParamFromFilter
} from '../../helpers/productCatalogHelper';
import { getUrlParams } from '@wec-core/helpers';
import { CATALOGUE } from '../../constants';
import Spacing from '../Spacing';
import { trans as t } from '../../helpers/localisation';
import { useInit } from '../../hooks/useInit';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { FEATURE_FLAG_ENABLE_RRP } from '../../types/featureFlag.types';
import { BannerList } from '../RrpBanner';
import { initRRP } from '../../reducers/rrpCart';
import { BaseState } from '../../types/state.types';
import { AEM_CONFIG_KEYS } from '../../config/common';

type PropTypes = {
  data: KeyValue;
  location: Location;
};

export const RRPPhoneCatalog = (props: PropTypes) => {
  const { data } = props;

  const dispatch = useDispatch();

  const { productCatalog, rrpCart } = useSelector<
    BaseState,
    Partial<BaseState>
  >((state: KeyValue) => {
    return {
      productCatalog: state.productCatalog as ProductCatalogState,
      rrpCart: state.rrpCart
    };
  });

  const {
    allRrpCategory,
    allRrpBrand,
    allRrpProduct,
    allRrpDeal,
    allRrpPaymentOption,
    allRrpPriceRange,
    allRrpBanner
  } = data;
  const brands = flattenNodes(allRrpBrand);
  const categories = flattenNodes(allRrpCategory);
  const rrpPaymentOptions = flattenNodes(allRrpPaymentOption);
  const products = flattenNodes(allRrpProduct);
  const deals = flattenNodes(allRrpDeal);
  const priceRanges = flattenNodes(allRrpPriceRange);
  const banners = flattenNodes(allRrpBanner);
  const variableConfigs = flattenNodes(data.allVariableConfig) || [];
  const promoStrip =
    getAemConfig(variableConfigs, AEM_CONFIG_KEYS.DETOX_RRP_PROMO_STRIP) || '';
  const getQueryParams = () => {
    return getUrlParams(window.location.search);
  };
  useEffect(() => {
    dispatch(initRRP(variableConfigs));
    if (!isFeatureFlagEnabled(FEATURE_FLAG_ENABLE_RRP)) {
      navigateToSDRRP();
    } else {
      setUrlParamsMapping([categories, rrpPaymentOptions, deals]);
      dispatch(setRRPFlow(true));
    }
  }, []);
  useInit(() => {
    const sortType =
      getQueryParams()?.[CONSTANTS.URL_PARAMS.SORT_TYPE] ||
      productCatalog.selectedSortType;
    if (sortType) {
      dispatch(sortProducts(sortType));
    }
  }, [productCatalog.productList?.length]);

  useEffect(() => {
    if (!productCatalog.filterData?.length) {
      dispatch(setIppFilter(getQueryParams()?.[CATALOGUE.FILTER_TYPES.PLAN]));
    }
  }, []);

  useEffect(() => {
    dispatch(
      setProductList({
        products,
        queryParams: getQueryParams() as KeyValue,
        filterData: productCatalog.filterData
      })
    );
  }, []);

  useInit(() => {
    if (
      productCatalog.productList?.length &&
      !productCatalog.filterData.length
    ) {
      dispatch(
        setFilterData({
          brands,
          categories,
          deals,
          queryParams: getQueryParams() as KeyValue,
          rrpPaymentOptions,
          priceRanges,
          allProduct: productCatalog.originalProductList
        })
      );
    }
  }, [productCatalog.productList.length]);

  useEffect(() => {
    if (productCatalog.filterData?.length) {
      updateUrlParamFromFilter(
        productCatalog.filterData,
        undefined,
        getQueryParams() as KeyValue
      );
    }
  }, [productCatalog.filterData]);

  useEffect(() => {
    if (productCatalog.isDoneResetFilter) {
      updateUrl([CONSTANTS.URL_PARAMS.SORT_TYPE]);
      dispatch(
        setProductList({
          products,
          queryParams: getQueryParams() as KeyValue,
          filterData: productCatalog.filterData
        })
      );
    }
  }, [productCatalog.isDoneResetFilter]);

  const updateUrl = (ignoreParams: string[] = []) => {
    updateUrlParamFromFilter(
      productCatalog.filterData,
      undefined,
      getQueryParams() as KeyValue,
      ignoreParams
    );
  };

  const applyOrSelectFilter = (filterData: FilterGroup[], isApply = true) => {
    if (isApply) {
      return dispatch(actionApplyFilter({ filterData }));
    }
    return dispatch(actionSelectFilter({ filterData }));
  };
  const clearFilter = () => {
    dispatch(actionClearFilter());
  };

  const onCloseFilterModal = () => {
    dispatch(actionResetToPreviousFilter());
  };

  const onProductClick = (product: Product) => {
    navigate(`/${CONSTANTS.URL_PATHS.RRP_PHONE}/${product.slug}`);
  };

  const renderBanner = () => {
    return (
      <>
        {promoStrip && (
          <Notification type="promo" content={promoStrip} fullWidth />
        )}
        <BannerList banners={banners} />
      </>
    );
  };

  const getFilterData = () => {
    return [...productCatalog.filterData];
  };

  const sortProduct = sortType => {
    dispatch(sortProducts(sortType));
  };

  const renderCatalog = () => {
    return (
      <Fragment>
        <Spacing>
          {renderBanner()}
          <Spacing top={3}>
            <Grid>
              <Row>
                <Column>
                  <ProductCatalogue
                    data={{
                      productDetails: {},
                      productList: productCatalog.productList,
                      filterData: getFilterData(),
                      productCatalogTitle: getCatalogHeader(
                        productCatalog.filterData
                      ),
                      sorterData: productCatalog.sorterData
                    }}
                    configs={{
                      enableViewMoreBtn: false,
                      enableFilter: true,
                      enableSorter: true,
                      enablePromoStrip: false,
                      enableBanner: false,
                      enableViewMore: true,
                      enableFilterChips: true,
                      hasProductCount: true,
                      hasPriceSlash: rrpCart.hasPriceSlash,
                      hideDisabledFilter: true
                    }}
                    localisation={{
                      SEARCH_MODAL_TITLE: t('SEARCH_MODAL_TITLE')
                    }}
                    callbacks={{
                      applyFilter: filterData => {
                        applyOrSelectFilter(filterData);
                      },
                      onProductClick,
                      clearFilter,
                      onSelectFilter: filterData => {
                        applyOrSelectFilter(filterData, false);
                      },
                      onCloseFilterModal,
                      sortProduct
                    }}
                  />
                </Column>
              </Row>
            </Grid>
          </Spacing>
        </Spacing>
      </Fragment>
    );
  };

  return <>{renderCatalog()}</>;
};
